import React from 'react';
import {
  Box,
  Typography,
  Card,
  Grid,
  Stack,
  alpha,
  useTheme
} from '@mui/material';
import { History } from '@mui/icons-material';

const DischargeHistory = ({ 
  resident,
  formatUserName
}) => {
  const theme = useTheme();
  
  if (!resident.dischargeHistory?.length) return null;

  return (
    <Card
      elevation={0}
      sx={{ 
        height: '100%',
        background: `linear-gradient(45deg, ${alpha(theme.palette.primary.main, 0.02)}, ${alpha(theme.palette.primary.light, 0.05)})`,
        borderRadius: 1,
        border: '1px solid',
        borderColor: 'divider'
      }}
    >
      <Box
        sx={{
          background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
          p: 2,
          borderRadius: '4px 4px 0 0',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <History sx={{ color: 'white' }} />
          <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 600 }}>
            Discharge History
          </Typography>
        </Box>
      </Box>

      <Box sx={{ p: 2 }}>
        <Stack spacing={2}>
          {resident.dischargeHistory.map((record, index) => (
            <Card 
              key={index} 
              elevation={0}
              sx={{ 
                p: 2,
                borderRadius: 1.5,
                bgcolor: alpha(theme.palette.info.main, 0.05),
                border: `1px solid ${alpha(theme.palette.info.main, 0.1)}`
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <Box>
                      <Typography 
                        variant="subtitle2" 
                        color="info.main"
                        sx={{ 
                          fontSize: '0.7rem',
                          fontWeight: 500,
                          mb: 0.5
                        }}
                      >
                        Period
                      </Typography>
                      <Typography 
                        variant="body2"
                        sx={{ fontWeight: 500 }}
                      >
                        {new Date(record.moveInDate).toLocaleDateString()} - {new Date(record.moveOutDate).toLocaleDateString()}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography 
                        variant="subtitle2" 
                        color="info.main"
                        sx={{ 
                          fontSize: '0.7rem',
                          fontWeight: 500,
                          mb: 0.5
                        }}
                      >
                        Discharge Reason
                      </Typography>
                      <Typography 
                        variant="body2"
                        sx={{ fontWeight: 500 }}
                      >
                        {record.dischargeReason}
                      </Typography>
                    </Box>
                    {record.dischargeNotes && (
                      <Box>
                        <Typography 
                          variant="subtitle2" 
                          color="info.main"
                          sx={{ 
                            fontSize: '0.7rem',
                            fontWeight: 500,
                            mb: 0.5
                          }}
                        >
                          Notes
                        </Typography>
                        <Typography 
                          variant="body2"
                          sx={{ fontWeight: 500 }}
                        >
                          {record.dischargeNotes}
                        </Typography>
                      </Box>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    {record.dischargeFlagged && (
                      <Box>
                        <Typography 
                          variant="subtitle2" 
                          color="info.main"
                          sx={{ 
                            fontSize: '0.7rem',
                            fontWeight: 500,
                            mb: 0.5
                          }}
                        >
                          Flag Reason
                        </Typography>
                        <Typography 
                          variant="body2" 
                          color="error"
                          sx={{ fontWeight: 500 }}
                        >
                          {record.flagReason}
                        </Typography>
                      </Box>
                    )}
                    <Box>
                      <Typography 
                        variant="subtitle2" 
                        color="info.main"
                        sx={{ 
                          fontSize: '0.7rem',
                          fontWeight: 500,
                          mb: 0.5
                        }}
                      >
                        Discharged By
                      </Typography>
                      <Typography 
                        variant="body2"
                        sx={{ fontWeight: 500 }}
                      >
                        {formatUserName(record.dischargedBy)} on {new Date(record.dischargedAt).toLocaleString()}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography 
                        variant="subtitle2" 
                        color="info.main"
                        sx={{ 
                          fontSize: '0.7rem',
                          fontWeight: 500,
                          mb: 0.5
                        }}
                      >
                        Reactivated By
                      </Typography>
                      <Typography 
                        variant="body2"
                        sx={{ fontWeight: 500 }}
                      >
                        {formatUserName(record.reactivatedBy)} on {new Date(record.reactivatedAt).toLocaleString()}
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          ))}
        </Stack>
      </Box>
    </Card>
  );
};

export default DischargeHistory; 