import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../../utils/axios';
import {
  Box,
  Container,
  Typography,
  Paper,
  Alert,
  ToggleButton,
  ToggleButtonGroup,
  Tabs,
  Tab,
  Grid,
  Stack,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  alpha
} from '@mui/material';
import { 
  ViewModule as ViewModuleIcon, 
  ViewList as ViewListIcon,
  Refresh,
  Error as ErrorIcon,
  Add as AddIcon,
  Email as EmailIcon,
  Sms as SmsIcon
} from '@mui/icons-material';
import ResidentCard from './ResidentCard';
import ResidentList from './ResidentList';
import NewResidentDialog from './dialogs/NewResidentDialog';
import EmailDialog from './dialogs/EmailDialog';
import SmsDialog from './dialogs/SmsDialog';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const ResidentsPage = () => {
  const navigate = useNavigate();
  const [residents, setResidents] = useState([]);
  const [properties, setProperties] = useState([]);
  const [viewMode, setViewMode] = useState('grid');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showMoveOutColumn, setShowMoveOutColumn] = useState(false);
  const [statusFilter, setStatusFilter] = useState('active');
  const [propertyFilter, setPropertyFilter] = useState('all');
  const [genderFilter, setGenderFilter] = useState('all');
  const [newResidentDialogOpen, setNewResidentDialogOpen] = useState(false);
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [smsDialogOpen, setSmsDialogOpen] = useState(false);
  const [messageMenuAnchor, setMessageMenuAnchor] = useState(null);
  const [selectedResidents, setSelectedResidents] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setShowMoveOutColumn(residents.some(resident => resident.status === 'discharged'));
  }, [residents]);

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null);
      const [residentsRes, propertiesRes] = await Promise.all([
        axios.get('/api/auth/residents'),
        axios.get('/api/properties')
      ]);

      const residentsWithAssignments = residentsRes.data.map(resident => {
        const assignment = propertiesRes.data.reduce((found, property) => {
          if (found) return found;
          
          for (const room of property.rooms) {
            const bed = room.beds.find(bed => bed.resident?._id === resident._id);
            if (bed) {
              return {
                property: property.name,
                room: room.name,
                bed: bed.name
              };
            }
          }
          return null;
        }, null);

        return {
          ...resident,
          assignment
        };
      });

      setResidents(residentsWithAssignments);
      setProperties(propertiesRes.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.response?.data?.message || 'Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  const handleResidentClick = (residentId) => {
    navigate(`/residents/${residentId}`);
  };

  const handleStatusChange = () => {
    fetchData();
  };

  const filteredResidents = residents.filter(resident => {
    if (statusFilter !== 'all' && resident.status !== statusFilter) {
      return false;
    }
    if (propertyFilter !== 'all' && (!resident.assignment || resident.assignment.property !== propertyFilter)) {
      return false;
    }
    if (genderFilter !== 'all' && resident.gender !== genderFilter) {
      return false;
    }
    return true;
  });

  const handleNewResidentSuccess = (newResident) => {
    fetchData(); // Refresh the list after adding a new resident
  };

  const handleSelectResidents = (residents) => {
    setSelectedResidents(residents);
  };

  const handleSelectResident = (resident) => {
    const selectedIndex = selectedResidents.findIndex(r => r._id === resident._id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selectedResidents, resident];
    } else {
      newSelected = selectedResidents.filter(r => r._id !== resident._id);
    }

    setSelectedResidents(newSelected);
  };

  const handleMessageMenuOpen = (event) => {
    setMessageMenuAnchor(event.currentTarget);
  };

  const handleMessageMenuClose = () => {
    setMessageMenuAnchor(null);
  };

  return (
    <Box sx={{ py: 3 }}>
      <Container maxWidth="xl">
        <Paper
          elevation={0}
          sx={{
            mb: 3,
            p: { xs: 2, sm: 2.5 },
            backgroundColor: '#ffffff',
            borderRadius: 1,
            border: '1px solid',
            borderColor: 'divider',
          }}
        >
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: { xs: 'stretch', md: 'center' },
            gap: { xs: 2, md: 0 },
            mb: { xs: 2, md: 2.5 }
          }}>
            <Box sx={{ flex: 1 }}>
              <Typography 
                variant="h5" 
                component="h1" 
                sx={{ 
                  fontWeight: 600, 
                  color: 'primary.main',
                  mb: { xs: 0.5, md: 0.25 }
                }}
              >
                Residents
              </Typography>
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ 
                  display: { xs: 'none', sm: 'block' },
                  fontWeight: 500
                }}
              >
                Manage and track all residents in the system
              </Typography>
            </Box>
            <Stack 
              direction={{ xs: 'row', sm: 'row' }} 
              spacing={2}
              sx={{ 
                width: { xs: '100%', md: 'auto' },
                alignItems: { xs: 'stretch', sm: 'center' }
              }}
            >
              <Button
                startIcon={<AddIcon />}
                onClick={() => setNewResidentDialogOpen(true)}
                variant="outlined"
                sx={{
                  borderRadius: 1,
                  textTransform: 'none',
                  fontWeight: 500,
                  borderWidth: 1.5,
                  minWidth: { sm: 120 },
                  flex: { xs: 1, sm: 'none' },
                  py: 1
                }}
              >
                Add Resident
              </Button>
              <Button
                startIcon={<Refresh />}
                onClick={fetchData}
                variant="outlined"
                sx={{
                  borderRadius: 1,
                  textTransform: 'none',
                  fontWeight: 500,
                  borderWidth: 1.5,
                  minWidth: { sm: 120 },
                  flex: { xs: 1, sm: 'none' },
                  py: 1
                }}
              >
                Refresh
              </Button>
              <ToggleButtonGroup
                value={viewMode}
                exclusive
                onChange={(event, newValue) => {
                  if (newValue !== null) {
                    setViewMode(newValue);
                  }
                }}
                size="small"
                aria-label="view mode"
                sx={{
                  display: { xs: 'none', sm: 'flex' },
                  '& .MuiToggleButtonGroup-grouped': {
                    px: { xs: 3, sm: 2 },
                    py: 1,
                    borderRadius: '4px !important',
                    borderColor: 'divider',
                    '&.Mui-selected': {
                      bgcolor: 'primary.main',
                      color: 'white',
                      '&:hover': {
                        bgcolor: 'primary.dark',
                      },
                    },
                  },
                }}
              >
                <ToggleButton value="grid" aria-label="grid view">
                  <ViewModuleIcon sx={{ mr: 1 }} />
                  Grid
                </ToggleButton>
                <ToggleButton value="list" aria-label="list view">
                  <ViewListIcon sx={{ mr: 1 }} />
                  List
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </Box>

          <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            gap: 2
          }}>
            <Box sx={{ 
              borderRadius: 1,
              bgcolor: '#f5f6f8',
              border: '1px solid',
              borderColor: 'divider',
              overflow: { xs: 'auto', sm: 'hidden' },
              mx: { xs: -2, sm: 0 },
              px: { xs: 2, sm: 0 }
            }}>
              <Tabs
                value={statusFilter}
                onChange={(e, newValue) => setStatusFilter(newValue)}
                variant="scrollable"
                scrollButtons={{ xs: false, sm: 'auto' }}
                allowScrollButtonsMobile
                sx={{
                  minHeight: 44,
                  px: { xs: 0, sm: 2 },
                  '& .MuiTabs-flexContainer': {
                    gap: { xs: 2, sm: 0 }
                  },
                  '& .MuiTab-root': {
                    textTransform: 'none',
                    fontWeight: 500,
                    fontSize: '0.875rem',
                    minHeight: 44,
                    minWidth: { xs: 'auto', sm: 100 },
                    px: { xs: 0, sm: 3 }
                  }
                }}
              >
                <Tab label="All Residents" value="all" />
                <Tab 
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      Active
                      <Chip 
                        label={residents.filter(r => r.status === 'active').length} 
                        size="small"
                        color="success"
                        sx={{ 
                          height: 20,
                          '& .MuiChip-label': { px: 1, fontSize: '0.75rem' }
                        }}
                      />
                    </Box>
                  } 
                  value="active" 
                />
                <Tab 
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      Pending
                      <Chip 
                        label={residents.filter(r => r.status === 'pending').length}
                        size="small"
                        color="warning"
                        sx={{ 
                          height: 20,
                          '& .MuiChip-label': { px: 1, fontSize: '0.75rem' }
                        }}
                      />
                    </Box>
                  } 
                  value="pending" 
                />
                <Tab 
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      Rejected
                      <Chip 
                        label={residents.filter(r => r.status === 'rejected').length}
                        size="small"
                        color="error"
                        sx={{ 
                          height: 20,
                          '& .MuiChip-label': { px: 1, fontSize: '0.75rem' }
                        }}
                      />
                    </Box>
                  } 
                  value="rejected" 
                />
                <Tab 
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      Discharged
                      <Chip 
                        label={residents.filter(r => r.status === 'discharged').length}
                        size="small"
                        color="default"
                        sx={{ 
                          height: 20,
                          '& .MuiChip-label': { px: 1, fontSize: '0.75rem' }
                        }}
                      />
                    </Box>
                  } 
                  value="discharged" 
                />
              </Tabs>
            </Box>

            <Stack 
              direction={{ xs: 'column', sm: 'row' }} 
              spacing={2}
              sx={{
                width: '100%',
                flexWrap: 'wrap'
              }}
            >
              <Box sx={{ 
                display: 'flex', 
                gap: 2,
                flex: 1,
                flexDirection: { xs: 'column', sm: 'row' }
              }}>
                <FormControl 
                  size="large" 
                  sx={{ 
                    minWidth: { xs: '100%', sm: 240 },
                    flex: { xs: '1 1 100%', sm: '0 1 auto' }
                  }}
                >
                  <InputLabel>Property Filter</InputLabel>
                  <Select
                    value={propertyFilter}
                    onChange={(e) => setPropertyFilter(e.target.value)}
                    label="Property Filter"
                    sx={{
                      borderRadius: 2,
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderWidth: 2,
                        borderColor: 'divider',
                      },
                    }}
                  >
                    <MenuItem value="all">All Properties</MenuItem>
                    {properties.map((property) => (
                      <MenuItem key={property._id} value={property.name}>
                        {property.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl 
                  size="large" 
                  sx={{ 
                    minWidth: { xs: '100%', sm: 240 },
                    flex: { xs: '1 1 100%', sm: '0 1 auto' }
                  }}
                >
                  <InputLabel>Gender Filter</InputLabel>
                  <Select
                    value={genderFilter}
                    onChange={(e) => setGenderFilter(e.target.value)}
                    label="Gender Filter"
                    sx={{
                      borderRadius: 2,
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderWidth: 2,
                        borderColor: 'divider',
                      },
                    }}
                  >
                    <MenuItem value="all">All Genders</MenuItem>
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                    <MenuItem value={null}>Not Set</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Stack 
                direction="row" 
                spacing={2}
                sx={{ 
                  ml: { xs: 0, sm: 'auto' },
                  width: { xs: '100%', sm: 'auto' }
                }}
              >
                {selectedResidents.length > 0 ? (
                  <>
                    <Button
                      variant="outlined"
                      onClick={() => setSelectedResidents([])}
                      sx={{
                        borderRadius: 2,
                        textTransform: 'none',
                        fontWeight: 600,
                        py: 1,
                        px: 2,
                        height: 'fit-content',
                        borderWidth: 2,
                        flex: { xs: 1, sm: 'none' },
                        '&:hover': {
                          borderWidth: 2,
                        }
                      }}
                    >
                      Deselect All
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleMessageMenuOpen}
                      sx={{
                        borderRadius: 2,
                        textTransform: 'none',
                        fontWeight: 600,
                        py: 1,
                        px: 2,
                        height: 'fit-content',
                        flex: { xs: 1, sm: 'none' },
                        boxShadow: 'none',
                        '&:hover': {
                          boxShadow: 'none',
                        }
                      }}
                    >
                      Message ({selectedResidents.length})
                    </Button>
                    <Menu
                      anchorEl={messageMenuAnchor}
                      open={Boolean(messageMenuAnchor)}
                      onClose={handleMessageMenuClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: 'visible',
                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          mt: 1.5,
                          '& .MuiMenuItem-root': {
                            px: 2,
                            py: 1,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                      <MenuItem onClick={() => {
                        setEmailDialogOpen(true);
                        handleMessageMenuClose();
                      }}>
                        <ListItemIcon>
                          <EmailIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Send Email</ListItemText>
                      </MenuItem>
                      <MenuItem onClick={() => {
                        setSmsDialogOpen(true);
                        handleMessageMenuClose();
                      }}>
                        <ListItemIcon>
                          <SmsIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Send SMS</ListItemText>
                      </MenuItem>
                    </Menu>
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={() => setSelectedResidents(filteredResidents)}
                    sx={{
                      borderRadius: 2,
                      textTransform: 'none',
                      fontWeight: 600,
                      py: 1,
                      px: 2,
                      height: 'fit-content',
                      borderWidth: 2,
                      flex: { xs: 1, sm: 'none' },
                      '&:hover': {
                        borderWidth: 2,
                      }
                    }}
                  >
                    Select All
                  </Button>
                )}
              </Stack>
            </Stack>
          </Box>
        </Paper>

        {loading ? (
          viewMode === 'grid' ? (
            <Grid container spacing={3}>
              {[...Array(6)].map((_, index) => (
                <Grid item key={index} xs={12} sm={6} lg={4}>
                  <Paper
                    elevation={0}
                    sx={{
                      height: '100%',
                      border: '1px solid',
                      borderColor: 'divider',
                      borderRadius: 2,
                      p: 3,
                      bgcolor: '#ffffff',
                      boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
                    }}
                  >
                    <Stack spacing={2}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Skeleton variant="circular" width={56} height={56} />
                        <Box sx={{ flex: 1 }}>
                          <Skeleton width="70%" height={24} />
                          <Skeleton width="40%" height={24} />
                        </Box>
                      </Box>
                      <Skeleton width="100%" height={60} />
                      <Skeleton width="100%" height={60} />
                      <Skeleton width="100%" height={60} />
                    </Stack>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Paper
              elevation={0}
              sx={{ 
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 2,
                overflow: 'hidden',
                bgcolor: '#ffffff',
                boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
              }}
            >
              <TableContainer 
                component={Paper}
                elevation={0}
                sx={{ 
                  border: '1px solid',
                  borderColor: 'divider',
                  borderRadius: 1,
                  overflow: 'hidden'
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      {[...Array(7)].map((_, index) => (
                        <TableCell key={index} sx={{ bgcolor: '#f5f6f8' }}>
                          <Skeleton width={100} height={24} />
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[...Array(5)].map((_, index) => (
                      <TableRow key={index}>
                        {[...Array(7)].map((_, cellIndex) => (
                          <TableCell key={cellIndex}>
                            <Skeleton width={cellIndex === 6 ? 120 : 100} height={24} />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )
        ) : filteredResidents.length === 0 ? (
          <Paper
            elevation={0}
            sx={{
              p: 6,
              textAlign: 'center',
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 2,
              bgcolor: '#ffffff',
              boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
            }}
          >
            <ErrorIcon sx={{ fontSize: 64, color: 'text.secondary', mb: 3 }} />
            <Typography variant="h5" sx={{ fontWeight: 700, mb: 1 }}>
              No Residents Found
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
              {statusFilter === 'all' && propertyFilter === 'all'
                ? 'There are no residents in the system.'
                : 'No residents match your current filters.'}
            </Typography>
            {(statusFilter !== 'all' || propertyFilter !== 'all') && (
              <Button
                variant="outlined"
                size="large"
                onClick={() => {
                  setStatusFilter('all');
                  setPropertyFilter('all');
                }}
                sx={{
                  borderRadius: 2,
                  textTransform: 'none',
                  fontWeight: 600,
                  borderWidth: 2,
                  py: 1.5,
                  px: 4,
                  '&:hover': {
                    borderWidth: 2,
                  }
                }}
              >
                Clear All Filters
              </Button>
            )}
          </Paper>
        ) : viewMode === 'grid' ? (
          <Grid 
            container 
            spacing={3}
            alignItems="stretch"
          >
            {filteredResidents.map((resident) => (
              <Grid 
                item 
                key={resident._id} 
                xs={12} 
                sm={6} 
                lg={4}
                sx={{
                  display: 'flex',
                  '& > *': {
                    width: '100%'
                  }
                }}
              >
                <ResidentCard 
                  resident={resident} 
                  onClick={() => handleResidentClick(resident._id)} 
                  onStatusChange={handleStatusChange}
                  selected={selectedResidents.some(r => r._id === resident._id)}
                  onSelect={handleSelectResident}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <ResidentList 
            residents={filteredResidents}
            onResidentClick={handleResidentClick}
            showMoveOutColumn={showMoveOutColumn}
            onStatusChange={handleStatusChange}
            selectedResidents={selectedResidents}
            onSelectResidents={handleSelectResidents}
          />
        )}
      </Container>

      <NewResidentDialog
        open={newResidentDialogOpen}
        onClose={() => setNewResidentDialogOpen(false)}
        onSuccess={handleNewResidentSuccess}
      />

      <EmailDialog
        open={emailDialogOpen}
        onClose={() => {
          setEmailDialogOpen(false);
          setError('');
        }}
        selectedResidents={selectedResidents}
        error={error}
        setError={setError}
        onEmailSent={() => setSelectedResidents([])}
      />

      <SmsDialog
        open={smsDialogOpen}
        onClose={() => {
          setSmsDialogOpen(false);
          setError('');
        }}
        selectedResidents={selectedResidents}
        error={error}
        setError={setError}
        onSmsSent={() => setSelectedResidents([])}
      />
    </Box>
  );
};

export default ResidentsPage; 