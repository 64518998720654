import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  Alert,
  CircularProgress,
  Paper,
  Divider,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import axios from '../../utils/axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://192.168.0.135:5001';
axios.defaults.baseURL = API_URL;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentFormContent = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    amount: '',
    description: ''
  });
  const [userProperty, setUserProperty] = useState(null);
  const [rentInfo, setRentInfo] = useState({
    rentAmount: 0,
    dueDate: 1,
    isCustom: false
  });
  const [currentMonthPayments, setCurrentMonthPayments] = useState([]);
  const [totalPaid, setTotalPaid] = useState(0);

  useEffect(() => {
    const fetchUserProperty = async () => {
      try {
        const token = localStorage.getItem('token');
        const userId = JSON.parse(atob(token.split('.')[1])).userId;

        // Fetch property, rent information, and current month's payments in parallel
        const [propertyResponse, rentResponse, paymentsResponse] = await Promise.all([
          axios.get('/api/properties'),
          axios.get(`/api/settings/rent/${userId}`),
          axios.get('/api/payments/my-payments')
        ]);

        let foundProperty = null;
        let foundRoom = null;

        for (const property of propertyResponse.data) {
          for (const room of property.rooms) {
            const bed = room.beds.find(bed => bed.resident && bed.resident._id === userId);
            if (bed) {
              foundProperty = property;
              foundRoom = room;
              break;
            }
          }
          if (foundProperty) break;
        }

        if (!foundProperty || !foundRoom) {
          setError('Property assignment not found. Please contact an administrator.');
          return;
        }

        setUserProperty({
          _id: foundProperty._id,
          unit: foundRoom.name
        });

        // Set rent information
        setRentInfo(rentResponse.data);

        // Filter current month's payments
        const now = new Date();
        const currentMonthPayments = paymentsResponse.data.filter(payment => {
          const paymentDate = new Date(payment.createdAt);
          return paymentDate.getMonth() === now.getMonth() && 
                 paymentDate.getFullYear() === now.getFullYear();
        });

        setCurrentMonthPayments(currentMonthPayments);
        const totalPaid = currentMonthPayments.reduce((sum, payment) => sum + payment.amount, 0);
        setTotalPaid(totalPaid);

        // Pre-fill the amount with the remaining balance
        const remainingBalance = Math.max(0, rentResponse.data.rentAmount - totalPaid);
        setFormData(prev => ({
          ...prev,
          amount: remainingBalance.toString(),
          description: 'Monthly Rent Payment'
        }));
      } catch (error) {
        console.error('Error fetching user information:', error);
        setError('Failed to fetch property information');
      }
    };

    fetchUserProperty();
  }, []);

  const formatAmount = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    setLoading(true);
    setError('');

    try {
      // Create payment intent
      const token = localStorage.getItem('token');
      const intentResponse = await axios.post('/api/payments/create-payment-intent', {
        amount: parseFloat(formData.amount),
        description: formData.description
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      // Confirm card payment
      const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(
        intentResponse.data.clientSecret,
        {
          payment_method: {
            card: elements.getElement(CardElement),
          }
        }
      );

      if (stripeError) {
        setError(stripeError.message);
        return;
      }

      // Save payment record
      await axios.post('/api/payments', {
        amount: parseFloat(formData.amount),
        description: formData.description,
        stripePaymentId: paymentIntent.id,
        stripePaymentIntentId: intentResponse.data.paymentIntentId,
        property: userProperty._id,
        unit: userProperty.unit
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      setSuccess(true);
      setTimeout(() => {
        navigate('/my-payments');
      }, 2000);
    } catch (error) {
      console.error('Payment error:', error);
      setError(error.response?.data?.message || 'Payment failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', mt: 4 }}>
      <Paper
        elevation={0}
        sx={{
          p: 3,
          borderRadius: 2,
          border: '1px solid',
          borderColor: 'divider'
        }}
      >
        <Typography variant="h5" gutterBottom>
          Make a Payment
        </Typography>

        {/* Rent Information */}
        <Box sx={{ mb: 3, p: 2, bgcolor: 'background.default', borderRadius: 1 }}>
          <Typography variant="subtitle2" gutterBottom>
            Your Rent Information:
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" color="text.secondary">
                Total Amount Due: {formatAmount(rentInfo.rentAmount)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Due Date: Day {rentInfo.dueDate} of each month
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" color="success.main" sx={{ fontWeight: 500 }}>
                Amount Paid: {formatAmount(totalPaid)}
              </Typography>
              <Typography variant="body2" color="error.main" sx={{ fontWeight: 500 }}>
                Remaining Balance: {formatAmount(Math.max(0, rentInfo.rentAmount - totalPaid))}
              </Typography>
            </Grid>
          </Grid>

          {currentMonthPayments.length > 0 && (
            <>
              <Divider sx={{ my: 2 }} />
              <Typography variant="subtitle2" gutterBottom>
                Current Month's Payments:
              </Typography>
              <Box sx={{ mt: 1 }}>
                {currentMonthPayments.map((payment, index) => (
                  <Box key={payment._id} sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Typography variant="body2" color="text.secondary">
                      {formatDate(payment.createdAt)} - {payment.paymentMethod === 'stripe' ? 'Online Payment' : payment.paymentMethod.charAt(0).toUpperCase() + payment.paymentMethod.slice(1)}
                      {payment.referenceNumber && ` (Ref: ${payment.referenceNumber})`}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {formatAmount(payment.amount)}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </>
          )}

          {rentInfo.isCustom && (
            <Typography variant="body2" color="primary" sx={{ mt: 1, fontStyle: 'italic' }}>
              * You have a custom payment arrangement
            </Typography>
          )}
        </Box>

        {success && (
          <Alert severity="success" sx={{ mb: 2 }}>
            Payment successful! Redirecting...
          </Alert>
        )}

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Amount ($)"
                name="amount"
                type="number"
                value={formData.amount}
                onChange={handleChange}
                required
                inputProps={{ min: 0, step: 0.01 }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                required
                multiline
                rows={3}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Card Details
              </Typography>
              <Box
                sx={{
                  border: '1px solid',
                  borderColor: 'divider',
                  borderRadius: 1,
                  p: 2,
                  bgcolor: '#fff'
                }}
              >
                <CardElement
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        color: '#424770',
                        '::placeholder': {
                          color: '#aab7c4',
                        },
                      },
                      invalid: {
                        color: '#9e2146',
                      },
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading || !stripe}
                fullWidth
                size="large"
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  `Pay $${formData.amount || '0.00'}`
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
};

const PaymentForm = () => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentFormContent />
    </Elements>
  );
};

export default PaymentForm; 