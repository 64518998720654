import React, { useState, useCallback } from 'react';
import { 
  Typography, 
  Box,
  Stack,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import { styled } from '@mui/material/styles';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { format } from 'date-fns';
import ApprovalDialog from '../../residents/list/dialogs/ApprovalDialog';
import axios from '../../../utils/axios';
import { Link } from 'react-router-dom';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: '#ffffff',
  border: '1px solid',
  borderColor: theme.palette.divider,
  borderRadius: `${theme.spacing(2)} !important`,
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
  transition: 'all 0.3s ease-in-out',
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    boxShadow: '0 12px 48px rgba(0, 0, 0, 0.12)',
    transform: 'scale(1.02)',
    borderColor: 'indigo.main'
  },
  '& .MuiAccordionSummary-root': {
    padding: theme.spacing(2),
    minHeight: 'auto',
    '&.Mui-expanded': {
      minHeight: 'auto',
    }
  },
  '& .MuiAccordionSummary-content': {
    margin: '0 !important'
  },
  '& .MuiAccordionDetails-root': {
    padding: theme.spacing(0, 2, 2, 2),
    borderTop: '1px solid',
    borderTopColor: theme.palette.divider
  }
}));

const PendingApprovalsCard = ({ residents = [], onStatusChange }) => {
  const [expanded, setExpanded] = useState(false);
  const [selectedResident, setSelectedResident] = useState(null);
  const [approvalDialogOpen, setApprovalDialogOpen] = useState(false);
  const [page, setPage] = useState(0);
  const itemsPerPage = 5;

  // Filter pending residents
  const pendingResidents = residents.filter(resident => resident.status === 'pending');
  const totalPages = Math.ceil(pendingResidents.length / itemsPerPage);
  const paginatedResidents = pendingResidents.slice(
    page * itemsPerPage,
    (page + 1) * itemsPerPage
  );

  const handleNextPage = () => {
    setPage(prev => Math.min(prev + 1, totalPages - 1));
  };

  const handlePrevPage = () => {
    setPage(prev => Math.max(prev - 1, 0));
  };

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  const handleReject = useCallback(async (residentId) => {
    try {
      await axios.put(`/api/auth/residents/${residentId}/approve`, {
        approved: false
      });
      await new Promise(resolve => setTimeout(resolve, 100));
      if (onStatusChange) {
        await onStatusChange();
      }
      const remainingPendingResidents = residents.filter(resident => resident.status === 'pending');
      const remainingPages = Math.ceil(remainingPendingResidents.length / itemsPerPage);
      if (page >= remainingPages) {
        setPage(Math.max(0, remainingPages - 1));
      }
    } catch (error) {
      console.error('Error rejecting resident:', error);
    }
  }, [residents, page, itemsPerPage, onStatusChange]);

  const handleApprovalSuccess = useCallback(async () => {
    try {
      setApprovalDialogOpen(false);
      setSelectedResident(null);
      await new Promise(resolve => setTimeout(resolve, 100));
      if (onStatusChange) {
        await onStatusChange();
      }
      const remainingPendingResidents = residents.filter(resident => resident.status === 'pending');
      const remainingPages = Math.ceil(remainingPendingResidents.length / itemsPerPage);
      if (page >= remainingPages) {
        setPage(Math.max(0, remainingPages - 1));
      }
    } catch (error) {
      console.error('Error handling approval success:', error);
    }
  }, [residents, page, itemsPerPage, onStatusChange]);

  const handleRejectAll = useCallback(async () => {
    try {
      await Promise.all(
        pendingResidents.map(resident =>
          axios.put(`/api/auth/residents/${resident._id}/approve`, {
            approved: false
          })
        )
      );
      if (onStatusChange) {
        await onStatusChange();
      }
      setPage(0);
    } catch (error) {
      console.error('Error rejecting all residents:', error);
    }
  }, [pendingResidents, onStatusChange]);

  const getInitials = (firstName, lastName) => {
    return `${firstName?.[0] || ''}${lastName?.[0] || ''}`.toUpperCase();
  };

  return (
    <>
      <StyledAccordion 
        expanded={expanded} 
        onChange={handleAccordionChange}
      >
        <AccordionSummary
          expandIcon={<NavigateNextIcon sx={{ color: 'indigo.main' }} />}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '100%' }}>
            <Box
              sx={{
                width: 48,
                height: 48,
                borderRadius: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'rgba(63, 81, 181, 0.08)'
              }}
            >
              <HourglassEmptyIcon sx={{ color: 'indigo.main', fontSize: 28 }} />
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h5" sx={{ fontWeight: 700, color: 'text.primary', mb: 0.5 }}>
                {pendingResidents.length}
              </Typography>
              <Typography variant="body1" sx={{ color: 'text.secondary', fontWeight: 500 }}>
                Pending Approvals
              </Typography>
            </Box>
          </Box>
        </AccordionSummary>

        <AccordionDetails>
          <List sx={{ 
            width: '100%',
            bgcolor: 'background.paper',
            '& .MuiListItem-root': {
              px: 0,
              py: 1,
            }
          }}>
            {paginatedResidents.map((resident) => (
              <ListItem
                key={resident._id}
                disablePadding
                secondaryAction={
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <IconButton
                      edge="end"
                      size="small"
                      onClick={() => {
                        setSelectedResident(resident);
                        setApprovalDialogOpen(true);
                      }}
                      sx={{
                        color: 'success.main',
                        bgcolor: 'success.light',
                        '&:hover': {
                          bgcolor: 'success.main',
                          color: 'white'
                        }
                      }}
                    >
                      <CheckIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      edge="end"
                      size="small"
                      onClick={() => handleReject(resident._id)}
                      sx={{
                        color: 'error.main',
                        bgcolor: 'error.light',
                        '&:hover': {
                          bgcolor: 'error.main',
                          color: 'white'
                        }
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Box>
                }
              >
                <ListItemButton 
                  component={Link} 
                  to={`/residents/${resident._id}`}
                  sx={{
                    borderRadius: 2,
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      bgcolor: 'rgba(63, 81, 181, 0.08)'
                    }
                  }}
                >
                  <ListItemAvatar>
                    <Avatar 
                      src={resident.imageUrl ? `${process.env.REACT_APP_S3_URL}/${resident.imageUrl}` : undefined}
                      sx={{ 
                        width: 40,
                        height: 40,
                        bgcolor: resident.imageUrl ? 'transparent' : 'rgba(63, 81, 181, 0.08)',
                        color: 'indigo.main'
                      }}
                    >
                      {!resident.imageUrl && getInitials(resident.firstName, resident.lastName)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="body2" sx={{ fontWeight: 600, color: 'text.primary' }}>
                        {resident.firstName} {resident.lastName}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                        Applied {format(new Date(resident.createdAt), 'MMM d, yyyy')}
                      </Typography>
                    }
                  />
                  <ChevronRightIcon sx={{ color: 'action.disabled', fontSize: 20 }} />
                </ListItemButton>
              </ListItem>
            ))}
            {pendingResidents.length === 0 && (
              <Box
                sx={{
                  p: 3,
                  textAlign: 'center',
                  bgcolor: 'rgba(63, 81, 181, 0.04)',
                  borderRadius: 2
                }}
              >
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  No pending approvals
                </Typography>
              </Box>
            )}
          </List>

          {pendingResidents.length > itemsPerPage && (
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center',
              gap: 2,
              mt: 2 
            }}>
              <IconButton
                size="small"
                onClick={handlePrevPage}
                disabled={page === 0}
                sx={{
                  color: 'indigo.main',
                  bgcolor: 'rgba(63, 81, 181, 0.08)',
                  '&:hover': {
                    bgcolor: 'rgba(63, 81, 181, 0.16)'
                  }
                }}
              >
                <NavigateBeforeIcon />
              </IconButton>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Page {page + 1} of {totalPages}
              </Typography>
              <IconButton
                size="small"
                onClick={handleNextPage}
                disabled={page >= totalPages - 1}
                sx={{
                  color: 'indigo.main',
                  bgcolor: 'rgba(63, 81, 181, 0.08)',
                  '&:hover': {
                    bgcolor: 'rgba(63, 81, 181, 0.16)'
                  }
                }}
              >
                <NavigateNextIcon />
              </IconButton>
            </Box>
          )}

          {pendingResidents.length > 0 && (
            <Box sx={{ mt: 3 }}>
              <Button
                fullWidth
                variant="contained"
                onClick={handleRejectAll}
                startIcon={<CloseIcon />}
                sx={{
                  bgcolor: 'error.main',
                  color: 'white',
                  borderRadius: 1,
                  textTransform: 'none',
                  '&:hover': {
                    bgcolor: 'error.dark'
                  }
                }}
              >
                Reject All
              </Button>
            </Box>
          )}
        </AccordionDetails>
      </StyledAccordion>

      <ApprovalDialog
        open={approvalDialogOpen}
        onClose={() => {
          setApprovalDialogOpen(false);
          setSelectedResident(null);
        }}
        resident={selectedResident}
        onApproved={handleApprovalSuccess}
      />
    </>
  );
};

export default PendingApprovalsCard; 