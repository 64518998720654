import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Paper,
  Typography,
  Grid,
  Avatar,
  Stack,
  Alert,
  TextField,
  Button,
  CircularProgress,
  Divider,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import {
  Person,
  Edit,
  Home,
  CalendarToday,
  Phone,
  Add as AddIcon,
  Delete as DeleteIcon,
  LocalHospital,
  Work,
  HealthAndSafety,
  ContactPhone,
  Security
} from '@mui/icons-material';
import axios from '../../utils/axios';

function ResidentProfile() {
  const [profile, setProfile] = useState(null);
  const [assignment, setAssignment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const [profileRes, propertiesRes] = await Promise.all([
        axios.get('/api/auth/profile'),
        axios.get('/api/properties')
      ]);
      
      setProfile(profileRes.data);

      // Find resident's bed assignment
      const assignment = propertiesRes.data.reduce((found, property) => {
        if (found) return found;
        
        for (const room of property.rooms) {
          const bed = room.beds.find(bed => bed.resident?._id === profileRes.data._id);
          if (bed) {
            return {
              property,
              room,
              bed,
              assignedAt: new Date(bed.assignedAt).toLocaleDateString()
            };
          }
        }
        return null;
      }, null);

      setAssignment(assignment);
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to fetch profile');
    }
  };

  const renderSection = (title, icon, content) => (
    <Grid item xs={12}>
      <Paper
        variant="outlined"
        sx={{
          p: 3,
          borderRadius: 2,
          bgcolor: 'background.paper'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Stack direction="row" spacing={1} alignItems="center">
            {icon}
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              {title}
            </Typography>
          </Stack>
        </Box>
        {content}
      </Paper>
    </Grid>
  );

  if (!profile) {
    return (
      <Container>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Paper
          elevation={0}
          sx={{
            p: 3,
            backgroundImage: 'linear-gradient(145deg, #ffffff 0%, #f8f9ff 100%)',
            borderRadius: 2,
            border: '1px solid',
            borderColor: 'divider',
            boxShadow: '0 4px 20px -4px rgba(0, 0, 0, 0.1)'
          }}
        >
          <Grid container spacing={3}>
            {/* Profile Header */}
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Avatar
                  sx={{
                    width: 80,
                    height: 80,
                    bgcolor: 'primary.main',
                    fontSize: '2rem',
                    fontWeight: 600
                  }}
                >
                  {profile.firstName[0]}{profile.lastName[0]}
                </Avatar>
                <Box>
                  <Typography variant="h4" sx={{ fontWeight: 600, mb: 1 }}>
                    {profile.firstName} {profile.lastName}
                  </Typography>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Person color="primary" />
                      <Typography
                        variant="subtitle1"
                        sx={{ color: 'text.secondary', fontWeight: 500 }}
                      >
                        Resident
                      </Typography>
                    </Stack>
                    {profile.isApproved ? (
                      <Chip
                        label="Approved"
                        color="success"
                        size="small"
                        sx={{ borderRadius: 1 }}
                      />
                    ) : (
                      <Chip
                        label="Pending Approval"
                        color="warning"
                        size="small"
                        sx={{ borderRadius: 1 }}
                      />
                    )}
                    {profile.status === 'discharged' && (
                      <Chip
                        label="Discharged"
                        color="error"
                        size="small"
                        sx={{ borderRadius: 1 }}
                      />
                    )}
                  </Stack>
                </Box>
              </Box>
            </Grid>

            {/* Profile Information */}
            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
              <Box sx={{ mt: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    Profile Information
                  </Typography>
                </Box>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={2}>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">
                          First Name
                        </Typography>
                        <Typography variant="body1">{profile.firstName}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">
                          Last Name
                        </Typography>
                        <Typography variant="body1">{profile.lastName}</Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={2}>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">
                          Email
                        </Typography>
                        <Typography variant="body1">{profile.email}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">
                          Phone
                        </Typography>
                        <Typography variant="body1">
                          {profile.phone || 'Not specified'}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            {/* Housing Assignment */}
            {assignment && (
              <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
                <Box sx={{ mt: 3 }}>
                  <Typography variant="h6" sx={{ fontWeight: 600, mb: 3 }}>
                    Housing Assignment
                  </Typography>
                  <Paper
                    variant="outlined"
                    sx={{
                      p: 3,
                      borderRadius: 2,
                      bgcolor: 'background.paper'
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={2}>
                          <Box>
                            <Stack direction="row" spacing={1} alignItems="center">
                              <Home color="primary" fontSize="small" />
                              <Typography variant="subtitle2" color="text.secondary">
                                Property
                              </Typography>
                            </Stack>
                            <Typography variant="body1">{assignment.property.name}</Typography>
                            <Typography variant="body2" color="text.secondary">
                              {assignment.property.address}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="subtitle2" color="text.secondary">
                              Room
                            </Typography>
                            <Typography variant="body1">{assignment.room.name}</Typography>
                          </Box>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={2}>
                          <Box>
                            <Typography variant="subtitle2" color="text.secondary">
                              Bed
                            </Typography>
                            <Typography variant="body1">{assignment.bed.name}</Typography>
                          </Box>
                          <Box>
                            <Stack direction="row" spacing={1} alignItems="center">
                              <CalendarToday color="primary" fontSize="small" />
                              <Typography variant="subtitle2" color="text.secondary">
                                Assigned Date
                              </Typography>
                            </Stack>
                            <Typography variant="body1">{assignment.assignedAt}</Typography>
                          </Box>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Paper>
                </Box>
              </Grid>
            )}
          </Grid>

          {/* Emergency Contact Information */}
          {renderSection(
            'Emergency Contact',
            <ContactPhone color="primary" />,
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle2" color="text.secondary">Name</Typography>
                <Typography>{profile.emergencyContact?.name || 'Not specified'}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle2" color="text.secondary">Relationship</Typography>
                <Typography>{profile.emergencyContact?.relationship || 'Not specified'}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle2" color="text.secondary">Phone</Typography>
                <Typography>{profile.emergencyContact?.phone || 'Not specified'}</Typography>
              </Grid>
            </Grid>
          )}

          {/* Health Information */}
          {renderSection(
            'Health Information',
            <LocalHospital color="primary" />,
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  Allergies
                </Typography>
                <List dense>
                  {profile.allergies?.length > 0 ? (
                    profile.allergies.map((allergy, index) => (
                      <ListItem key={index}>
                        <ListItemText primary={allergy} />
                      </ListItem>
                    ))
                  ) : (
                    <Typography color="text.secondary">No allergies listed</Typography>
                  )}
                </List>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  Pre-existing Conditions
                </Typography>
                <List dense>
                  {profile.preexistingConditions?.length > 0 ? (
                    profile.preexistingConditions.map((condition, index) => (
                      <ListItem key={index}>
                        <ListItemText primary={condition} />
                      </ListItem>
                    ))
                  ) : (
                    <Typography color="text.secondary">No conditions listed</Typography>
                  )}
                </List>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  Medications
                </Typography>
                <List dense>
                  {profile.medications?.length > 0 ? (
                    profile.medications.map((medication, index) => (
                      <ListItem key={index}>
                        <ListItemText
                          primary={medication.name}
                          secondary={`${medication.dosage} - ${medication.frequency} (Prescribed by: ${medication.prescribedBy})`}
                        />
                      </ListItem>
                    ))
                  ) : (
                    <Typography color="text.secondary">No medications listed</Typography>
                  )}
                </List>
              </Grid>
            </Grid>
          )}

          {/* Insurance Information */}
          {renderSection(
            'Insurance Information',
            <Security color="primary" />,
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Typography variant="subtitle2" color="text.secondary">Provider</Typography>
                <Typography>{profile.insurance?.provider || 'Not specified'}</Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant="subtitle2" color="text.secondary">Policy Number</Typography>
                <Typography>{profile.insurance?.policyNumber || 'Not specified'}</Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant="subtitle2" color="text.secondary">Group Number</Typography>
                <Typography>{profile.insurance?.groupNumber || 'Not specified'}</Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant="subtitle2" color="text.secondary">Primary Insured</Typography>
                <Typography>{profile.insurance?.primaryInsured || 'Not specified'}</Typography>
              </Grid>
            </Grid>
          )}

          {/* Employment Information */}
          {renderSection(
            'Employment Information',
            <Work color="primary" />,
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle2" color="text.secondary">Employer</Typography>
                <Typography>{profile.employment?.employer || 'Not specified'}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle2" color="text.secondary">Position</Typography>
                <Typography>{profile.employment?.position || 'Not specified'}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle2" color="text.secondary">Supervisor</Typography>
                <Typography>{profile.employment?.supervisor || 'Not specified'}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="text.secondary">Work Address</Typography>
                <Typography>{profile.employment?.address || 'Not specified'}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="text.secondary">Work Phone</Typography>
                <Typography>{profile.employment?.phone || 'Not specified'}</Typography>
              </Grid>
            </Grid>
          )}
        </Paper>

        {/* Messages */}
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mt: 2 }}>
            {success}
          </Alert>
        )}
      </Box>
    </Container>
  );
}

export default ResidentProfile; 