import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Chip,
  Button,
  CircularProgress,
  Alert,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
  Paper,
} from '@mui/material';
import {
  Refresh,
  ViewModule as ViewModuleIcon,
  ViewList as ViewListIcon,
  Error as ErrorIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from '../../utils/axios';

const MyFormSubmissions = () => {
  const navigate = useNavigate();
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [viewMode, setViewMode] = useState('grid');
  const [currentTab, setCurrentTab] = useState('all');

  const fetchSubmissions = async () => {
    try {
      setError(null);
      setLoading(true);
      const response = await axios.get('/api/forms/my-submissions');
      setSubmissions(response.data);
    } catch (error) {
      setError('Failed to fetch submissions. Please try again.');
      console.error('Error fetching submissions:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubmissions();
  }, []);

  const getStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return 'warning';
      case 'reviewed':
        return 'info';
      case 'approved':
        return 'success';
      case 'rejected':
        return 'error';
      default:
        return 'default';
    }
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const filteredSubmissions = submissions.filter(submission => {
    if (currentTab !== 'all' && submission.status !== currentTab) {
      return false;
    }
    return true;
  });

  const renderFieldValue = (field, value) => {
    if (['header', 'paragraph', 'divider'].includes(field.type)) {
      return null; // Don't show layout elements in submission view
    }

    return (
      <Box key={field._id} sx={{ mb: 1 }}>
        <Typography variant="body2" color="text.secondary">
          {field.label}:
        </Typography>
        <Typography variant="body2">
          {Array.isArray(value) ? value.join(', ') : value || 'No response'}
        </Typography>
      </Box>
    );
  };

  const SubmissionCard = ({ submission }) => (
    <Card
      elevation={0}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        backgroundColor: '#ffffff',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
          borderColor: 'primary.main',
        }
      }}
    >
      <CardContent sx={{ p: 2.5, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.main' }}>
            {submission.form.title}
          </Typography>
          <Chip
            label={submission.status}
            color={getStatusColor(submission.status)}
            size="small"
            sx={{ fontWeight: 500, height: 24 }}
          />
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Responses:
          </Typography>
          {submission.responses.map((response) => {
            const field = submission.form.fields.find(f => f._id === response.field);
            if (!field) return null;
            return renderFieldValue(field, response.value);
          })}
        </Box>

        {submission.notes && submission.notes.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              Latest Note:
            </Typography>
            <Typography variant="body2">
              {submission.notes[submission.notes.length - 1].content}
            </Typography>
          </Box>
        )}

        <Box sx={{ mt: 'auto' }}>
          <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mb: 1 }}>
            Submitted: {formatDate(submission.createdAt)}
          </Typography>
          {submission.reviewedAt && (
            <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
              Reviewed: {formatDate(submission.reviewedAt)}
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      {error && (
        <Alert 
          severity="error" 
          sx={{ mb: 3 }}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => setError(null)}
            >
              Dismiss
            </Button>
          }
        >
          {error}
        </Alert>
      )}

      <Paper
        elevation={0}
        sx={{
          mb: 3,
          p: 2.5,
          backgroundColor: '#ffffff',
          borderRadius: 1,
          border: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: { xs: 'stretch', md: 'center' },
          gap: { xs: 2, md: 0 },
          mb: 2.5
        }}>
          <Box sx={{ flex: 1 }}>
            <Typography variant="h5" sx={{ fontWeight: 600, color: 'primary.main', mb: 0.25 }}>
              My Form Submissions
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
              View and track your submitted forms
            </Typography>
          </Box>
          <Stack 
            direction={{ xs: 'column', sm: 'row' }} 
            spacing={2}
            sx={{ 
              width: { xs: '100%', md: 'auto' },
              alignItems: { xs: 'stretch', sm: 'center' }
            }}
          >
            <Button
              startIcon={<Refresh />}
              onClick={fetchSubmissions}
              variant="outlined"
              sx={{
                borderRadius: 1,
                textTransform: 'none',
                fontWeight: 500,
                borderWidth: 1.5,
                minWidth: { sm: 120 },
                order: { xs: 2, sm: 1 },
                py: 1
              }}
            >
              Refresh
            </Button>
            <ToggleButtonGroup
              value={viewMode}
              exclusive
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  setViewMode(newValue);
                }
              }}
              size="small"
              aria-label="view mode"
              sx={{
                display: 'flex',
                order: { xs: 1, sm: 2 },
                '& .MuiToggleButtonGroup-grouped': {
                  flex: { xs: 1, sm: 'none' },
                  px: { xs: 3, sm: 2 },
                  py: 1,
                  borderRadius: '4px !important',
                  borderColor: 'divider',
                  '&.Mui-selected': {
                    bgcolor: 'primary.main',
                    color: 'white',
                    '&:hover': {
                      bgcolor: 'primary.dark',
                    },
                  },
                },
              }}
            >
              <ToggleButton value="grid" aria-label="grid view">
                <ViewModuleIcon sx={{ mr: 1 }} />
                Grid
              </ToggleButton>
              <ToggleButton value="list" aria-label="list view">
                <ViewListIcon sx={{ mr: 1 }} />
                List
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Box>

        <Box sx={{ 
          borderRadius: 1,
          bgcolor: '#f5f6f8',
          border: '1px solid',
          borderColor: 'divider',
          overflow: 'hidden'
        }}>
          <ToggleButtonGroup
            value={currentTab}
            exclusive
            onChange={(e, newValue) => {
              if (newValue !== null) {
                setCurrentTab(newValue);
              }
            }}
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              '& .MuiToggleButton-root': {
                flex: { xs: '1 1 auto', sm: 'initial' },
                border: 'none',
                borderRadius: '0 !important',
                py: 1.5,
                px: 3,
                color: 'text.secondary',
                '&.Mui-selected': {
                  bgcolor: '#ffffff',
                  color: 'primary.main',
                  fontWeight: 600,
                },
              },
            }}
          >
            <ToggleButton value="all">
              All ({submissions.length})
            </ToggleButton>
            <ToggleButton value="pending">
              Pending ({submissions.filter(s => s.status === 'pending').length})
            </ToggleButton>
            <ToggleButton value="reviewed">
              Reviewed ({submissions.filter(s => s.status === 'reviewed').length})
            </ToggleButton>
            <ToggleButton value="approved">
              Approved ({submissions.filter(s => s.status === 'approved').length})
            </ToggleButton>
            <ToggleButton value="rejected">
              Rejected ({submissions.filter(s => s.status === 'rejected').length})
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Paper>

      {filteredSubmissions.length === 0 ? (
        <Paper
          elevation={0}
          sx={{
            p: 4,
            textAlign: 'center',
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 1,
            bgcolor: '#ffffff'
          }}
        >
          <ErrorIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
          <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
            No Submissions Found
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            {currentTab === 'all'
              ? 'You have not submitted any forms yet.'
              : 'No submissions match your current filter.'}
          </Typography>
          {currentTab !== 'all' ? (
            <Button
              variant="outlined"
              size="small"
              onClick={() => setCurrentTab('all')}
              sx={{
                borderRadius: 1,
                textTransform: 'none',
                fontWeight: 500,
                borderWidth: 1.5
              }}
            >
              Show All Submissions
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() => navigate('/resident/forms')}
              sx={{
                borderRadius: 1,
                textTransform: 'none',
                fontWeight: 500
              }}
            >
              Browse Available Forms
            </Button>
          )}
        </Paper>
      ) : (
        <Grid container spacing={2}>
          {filteredSubmissions.map((submission) => (
            <Grid item key={submission._id} xs={12} sm={6} lg={4}>
              <SubmissionCard submission={submission} />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default MyFormSubmissions; 