import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  Alert,
  Typography,
  TextField,
  Box,
  Chip,
  CircularProgress,
  Snackbar
} from '@mui/material';
import { Email as EmailIcon, Check as CheckIcon } from '@mui/icons-material';
import axios from '../../../../utils/axios';

const EmailDialog = ({
  open,
  onClose,
  selectedResidents,
  error,
  setError,
  onEmailSent = () => {},
  initialSubject = '',
  initialMessage = ''
}) => {
  const [subject, setSubject] = useState(initialSubject);
  const [message, setMessage] = useState(initialMessage);
  const [sending, setSending] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (open) {
      setSubject(initialSubject);
      setMessage(initialMessage);
    }
  }, [open, initialSubject, initialMessage]);

  const handleSend = async () => {
    if (!subject.trim() || !message.trim()) {
      setError('Subject and message are required');
      return;
    }

    setSending(true);
    try {
      await axios.post('/api/auth/residents/send-email', {
        residentIds: selectedResidents.map(r => r._id),
        subject: subject.trim(),
        message: message.trim()
      });
      
      setSubject('');
      setMessage('');
      setShowSuccess(true);
      setTimeout(() => {
        onClose();
        setShowSuccess(false);
        onEmailSent();
      }, 1500);
    } catch (error) {
      console.error('Error sending email:', error);
      setError(error.response?.data?.message || 'Failed to send email');
    } finally {
      setSending(false);
    }
  };

  return (
    <>
      <Dialog 
        open={open} 
        onClose={!sending ? onClose : undefined}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.12)'
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid',
          borderColor: 'divider',
          bgcolor: '#f8f9fb',
          py: 2.5,
          px: 3
        }}>
          <Typography variant="h5" component="div" sx={{ fontWeight: 700, color: 'primary.main' }}>
            Send Email
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mt: 0.5, fontWeight: 500 }}>
            Send email to selected residents
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Stack spacing={3}>
            {error && (
              <Alert 
                severity="error"
                sx={{ 
                  borderRadius: 2,
                  '& .MuiAlert-icon': {
                    color: 'error.main'
                  }
                }}
              >
                {error}
              </Alert>
            )}

            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {selectedResidents.map((resident) => (
                <Chip
                  key={resident._id}
                  label={`${resident.firstName} ${resident.lastName}`}
                  sx={{
                    borderRadius: 1,
                    bgcolor: 'primary.lighter',
                    color: 'primary.dark',
                    '& .MuiChip-label': { px: 1.5, fontSize: '0.875rem' }
                  }}
                />
              ))}
            </Box>

            <TextField
              fullWidth
              label="Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              disabled={sending}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2
                }
              }}
            />

            <TextField
              fullWidth
              multiline
              rows={8}
              label="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              disabled={sending}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2
                }
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ 
          px: 3, 
          py: 2.5, 
          bgcolor: '#f8f9fb',
          borderTop: '1px solid',
          borderColor: 'divider'
        }}>
          <Button 
            onClick={onClose}
            disabled={sending}
            sx={{ 
              borderRadius: 2,
              textTransform: 'none',
              fontWeight: 600,
              px: 3,
              py: 1
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleSend}
            variant="contained"
            startIcon={sending ? <CircularProgress size={20} color="inherit" /> : <EmailIcon />}
            disabled={sending}
            sx={{ 
              borderRadius: 2,
              textTransform: 'none',
              fontWeight: 600,
              px: 3,
              py: 1,
              boxShadow: 'none',
              '&:hover': {
                boxShadow: 'none',
              }
            }}
          >
            {sending ? 'Sending...' : 'Send Email'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={showSuccess}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          '& .MuiPaper-root': {
            bgcolor: 'success.main',
            color: 'success.contrastText',
            borderRadius: 2,
            boxShadow: '0 4px 16px rgba(0, 0, 0, 0.12)'
          }
        }}
      >
        <Alert 
          icon={<CheckIcon />}
          severity="success"
          sx={{ 
            width: '100%',
            alignItems: 'center',
            '& .MuiAlert-icon': {
              fontSize: 24
            }
          }}
        >
          Emails sent successfully!
        </Alert>
      </Snackbar>
    </>
  );
}

export default EmailDialog; 