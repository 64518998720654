import React from 'react';
import {
  Box,
  Typography,
  Button,
  Stack,
  Grid,
  IconButton,
  Tooltip,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Chip,
  Divider,
  alpha,
  useTheme
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  PersonAdd as PersonAddIcon,
  PersonOff as PersonOffIcon,
  MeetingRoom as RoomIcon,
  BedOutlined as BedIcon,
  Person as PersonIcon,
  SquareFoot as SquareFootIcon
} from '@mui/icons-material';

const RoomsAndAssignments = ({
  property,
  onAssignBed,
  onUnassignBed,
  onAddRoom,
  onEditRoom,
  onDeleteRoom,
  onAddBed,
  onEditBed,
  onDeleteBed
}) => {
  const theme = useTheme();

  const getInitials = (firstName, lastName) => {
    return `${firstName?.[0] || ''}${lastName?.[0] || ''}`.toUpperCase();
  };

  return (
    <Card
      elevation={0}
      sx={{ 
        height: '100%',
        background: `linear-gradient(45deg, ${alpha(theme.palette.primary.main, 0.02)}, ${alpha(theme.palette.primary.light, 0.05)})`,
        borderRadius: 1,
        border: '1px solid',
        borderColor: 'divider'
      }}
    >
      <Box
        sx={{
          background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
          p: 2,
          borderRadius: '4px 4px 0 0',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <RoomIcon sx={{ color: 'white' }} />
          <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 600 }}>
            Rooms and Assignments
          </Typography>
        </Box>
        <Button
          variant="contained"
          onClick={onAddRoom}
          startIcon={<AddIcon />}
          sx={{
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            color: theme.palette.primary.main,
            '&:hover': {
              bgcolor: 'rgba(255, 255, 255, 1)',
            },
            boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
          }}
        >
          Add Room
        </Button>
      </Box>

      <Box sx={{ p: 2 }}>
        <Grid container spacing={3}>
          {property.rooms.map((room) => (
            <Grid item xs={12} key={room._id}>
              <Card 
                elevation={0}
                sx={{ 
                  borderRadius: 1.5,
                  bgcolor: alpha(theme.palette.info.main, 0.05),
                  border: `1px solid ${alpha(theme.palette.info.main, 0.1)}`
                }}
              >
                <CardHeader
                  sx={{ pb: 1 }}
                  title={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
                        {room.name}
                      </Typography>
                      <Chip
                        icon={<SquareFootIcon sx={{ fontSize: 16 }} />}
                        label={`${room.squareFootage} sq ft`}
                        size="small"
                        sx={{ 
                          bgcolor: alpha(theme.palette.info.main, 0.1),
                          color: theme.palette.info.main,
                          borderRadius: 1,
                          '& .MuiChip-icon': { 
                            color: theme.palette.info.main 
                          }
                        }}
                      />
                    </Box>
                  }
                  action={
                    <Stack direction="row" spacing={1}>
                      <IconButton
                        size="small"
                        onClick={() => onEditRoom(room)}
                        sx={{
                          bgcolor: 'rgba(255, 255, 255, 0.9)',
                          color: theme.palette.primary.main,
                          '&:hover': {
                            bgcolor: 'white',
                          },
                          width: 32,
                          height: 32,
                          boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => onDeleteRoom(room)}
                        sx={{
                          bgcolor: 'rgba(255, 255, 255, 0.9)',
                          color: theme.palette.error.main,
                          '&:hover': {
                            bgcolor: 'white',
                          },
                          width: 32,
                          height: 32,
                          boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Stack>
                  }
                />
                
                <Divider sx={{ mx: 2, mb: 2 }} />

                <CardContent sx={{ pt: 0 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <BedIcon sx={{ color: theme.palette.info.main, fontSize: 20 }} />
                      <Typography variant="subtitle2" sx={{ color: 'text.secondary', fontWeight: 500 }}>
                        Beds ({room.beds.length})
                      </Typography>
                    </Box>
                    <Button
                      size="small"
                      onClick={() => onAddBed(room)}
                      startIcon={<AddIcon />}
                      sx={{
                        bgcolor: 'rgba(255, 255, 255, 0.9)',
                        color: theme.palette.primary.main,
                        '&:hover': {
                          bgcolor: 'white',
                        },
                        boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                      }}
                    >
                      Add Bed
                    </Button>
                  </Box>

                  <Grid container spacing={2}>
                    {room.beds.map((bed) => (
                      <Grid item xs={12} sm={6} md={4} key={bed._id}>
                        <Card
                          elevation={0}
                          sx={{
                            border: '1px solid',
                            borderColor: bed.resident ? theme.palette.primary.main : alpha(theme.palette.divider, 0.8),
                            borderRadius: 1,
                            bgcolor: bed.resident ? alpha(theme.palette.primary.main, 0.04) : 'background.paper',
                            transition: 'all 0.2s ease-in-out',
                            '&:hover': {
                              borderColor: bed.resident ? theme.palette.primary.main : theme.palette.primary.light,
                              boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
                            }
                          }}
                        >
                          <CardContent sx={{ p: '16px !important' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                              <BedIcon sx={{ color: theme.palette.text.secondary, fontSize: 20 }} />
                              <Typography variant="subtitle2" sx={{ fontWeight: 600, color: 'text.primary' }}>
                                {bed.name}
                              </Typography>
                            </Box>

                            {bed.resident ? (
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5, mb: 2 }}>
                                <Avatar
                                  src={bed.resident.imageUrl ? `${process.env.REACT_APP_S3_URL}/${bed.resident.imageUrl}` : undefined}
                                  alt={`${bed.resident.firstName} ${bed.resident.lastName}`}
                                  sx={{ 
                                    width: 40, 
                                    height: 40,
                                    bgcolor: theme.palette.primary.main,
                                    color: 'white',
                                    fontSize: '1rem'
                                  }}
                                >
                                  {getInitials(bed.resident.firstName, bed.resident.lastName)}
                                </Avatar>
                                <Box>
                                  <Typography variant="subtitle2" sx={{ fontWeight: 600, color: 'text.primary' }}>
                                    {bed.resident.firstName} {bed.resident.lastName}
                                  </Typography>
                                  <Typography variant="caption" sx={{ color: 'text.secondary', display: 'block' }}>
                                    Assigned Resident
                                  </Typography>
                                </Box>
                              </Box>
                            ) : (
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5, mb: 2 }}>
                                <Avatar
                                  sx={{ 
                                    width: 40, 
                                    height: 40,
                                    bgcolor: alpha(theme.palette.divider, 0.2)
                                  }}
                                >
                                  <PersonIcon sx={{ color: theme.palette.text.secondary }} />
                                </Avatar>
                                <Box>
                                  <Typography variant="subtitle2" sx={{ fontWeight: 500, color: 'text.secondary' }}>
                                    Unassigned
                                  </Typography>
                                  <Typography variant="caption" sx={{ color: 'text.secondary', display: 'block' }}>
                                    No resident assigned
                                  </Typography>
                                </Box>
                              </Box>
                            )}

                            <Stack direction="row" spacing={1} justifyContent="flex-end">
                              {bed.resident ? (
                                <Tooltip title="Unassign Resident">
                                  <IconButton
                                    size="small"
                                    onClick={() => onUnassignBed(room._id, bed._id)}
                                    sx={{
                                      bgcolor: 'rgba(255, 255, 255, 0.9)',
                                      color: theme.palette.error.main,
                                      '&:hover': {
                                        bgcolor: 'white',
                                      },
                                      width: 28,
                                      height: 28,
                                      boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                                    }}
                                  >
                                    <PersonOffIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Assign Resident">
                                  <IconButton
                                    size="small"
                                    onClick={() => onAssignBed(room._id, bed._id)}
                                    sx={{
                                      bgcolor: 'rgba(255, 255, 255, 0.9)',
                                      color: theme.palette.success.main,
                                      '&:hover': {
                                        bgcolor: 'white',
                                      },
                                      width: 28,
                                      height: 28,
                                      boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                                    }}
                                  >
                                    <PersonAddIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              )}
                              <IconButton
                                size="small"
                                onClick={() => onEditBed(room, bed)}
                                sx={{
                                  bgcolor: 'rgba(255, 255, 255, 0.9)',
                                  color: theme.palette.primary.main,
                                  '&:hover': {
                                    bgcolor: 'white',
                                  },
                                  width: 28,
                                  height: 28,
                                  boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => onDeleteBed(room, bed)}
                                sx={{
                                  bgcolor: 'rgba(255, 255, 255, 0.9)',
                                  color: theme.palette.error.main,
                                  '&:hover': {
                                    bgcolor: 'white',
                                  },
                                  width: 28,
                                  height: 28,
                                  boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                                }}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Stack>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Card>
  );
};

export default RoomsAndAssignments; 