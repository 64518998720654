import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Stack,
  Paper,
  Tabs,
  Tab,
  Skeleton,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Alert,
  CircularProgress,
} from '@mui/material';
import {
  Person,
  Refresh,
  ViewModule as ViewModuleIcon,
  ViewList as ViewListIcon,
  Error as ErrorIcon,
  Description as DescriptionIcon,
  Check as CheckIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../../utils/axios';

const FormSubmissionList = () => {
  const { id: formId } = useParams();
  const navigate = useNavigate();
  const [submissions, setSubmissions] = useState([]);
  const [form, setForm] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [note, setNote] = useState('');
  const [newStatus, setNewStatus] = useState('');
  const [viewMode, setViewMode] = useState('grid');
  const [currentTab, setCurrentTab] = useState('all');
  const [error, setError] = useState(null);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const fetchData = async () => {
    try {
      setError(null);
      setLoading(true);
      const [formRes, submissionsRes] = await Promise.all([
        axios.get(`/api/forms/${formId}`),
        axios.get(`/api/forms/${formId}/submissions`)
      ]);
      setForm(formRes.data);
      setSubmissions(submissionsRes.data);
    } catch (error) {
      setError('Failed to fetch submissions. Please try again.');
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [formId]);

  const handleStatusUpdate = async () => {
    try {
      setLoadingUpdate(true);
      setError(null);
      await axios.patch(`/api/forms/submissions/${selectedSubmission._id}/review`, {
        status: newStatus,
        note: note
      });
      await fetchData();
      setDialogOpen(false);
      setNote('');
      setNewStatus('');
    } catch (error) {
      setError('Failed to update status. Please try again.');
      console.error('Error updating status:', error);
    } finally {
      setLoadingUpdate(false);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return 'warning';
      case 'reviewed':
        return 'info';
      case 'approved':
        return 'success';
      case 'rejected':
        return 'error';
      default:
        return 'default';
    }
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const filteredSubmissions = submissions.filter(submission => {
    if (currentTab !== 'all' && submission.status !== currentTab) {
      return false;
    }
    return true;
  });

  const renderFieldValue = (field, value) => {
    if (['header', 'paragraph', 'divider'].includes(field.type)) {
      return null; // Don't show layout elements in submission view
    }

    return (
      <Box key={field._id} sx={{ mb: 1 }}>
        <Typography variant="body2" color="text.secondary">
          {field.label}:
        </Typography>
        <Typography variant="body2">
          {Array.isArray(value) ? value.join(', ') : value || 'No response'}
        </Typography>
      </Box>
    );
  };

  const SubmissionCard = ({ submission }) => (
    <Card
      elevation={0}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        backgroundColor: '#ffffff',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
          borderColor: 'primary.main',
        }
      }}
    >
      <CardContent sx={{ p: 2.5, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Person sx={{ fontSize: 18, color: 'text.secondary' }} />
            <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.main' }}>
              {submission.resident.firstName} {submission.resident.lastName}
            </Typography>
          </Box>
          <Chip
            label={submission.status}
            color={getStatusColor(submission.status)}
            size="small"
            sx={{ fontWeight: 500, height: 24 }}
          />
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            Responses:
          </Typography>
          {submission.responses.map((response) => {
            const field = form.fields.find(f => f._id === response.field);
            if (!field) return null;
            return renderFieldValue(field, response.value);
          })}
        </Box>

        {submission.notes && submission.notes.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              Latest Note:
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {submission.notes[submission.notes.length - 1].content}
            </Typography>
          </Box>
        )}

        <Box sx={{ mt: 'auto' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'text.secondary', mb: 2 }}>
            <DescriptionIcon sx={{ fontSize: 18 }} />
            <Typography variant="caption" sx={{ fontWeight: 500 }}>
              Submitted: {formatDate(submission.createdAt)}
            </Typography>
          </Box>

          <Button
            variant="outlined"
            fullWidth
            startIcon={submission.status === 'approved' ? <CheckIcon /> : <DescriptionIcon />}
            onClick={() => {
              setSelectedSubmission(submission);
              setNewStatus(submission.status);
              setDialogOpen(true);
            }}
            color={submission.status === 'approved' ? 'success' : 'primary'}
            sx={{
              borderRadius: 1,
              textTransform: 'none',
              fontWeight: 500,
              py: 0.75,
              borderWidth: 1.5
            }}
          >
            Review Submission
          </Button>
        </Box>
      </CardContent>
    </Card>
  );

  const SubmissionTable = ({ submissions }) => (
    <TableContainer 
      component={Paper}
      elevation={0}
      sx={{ 
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        overflow: 'hidden',
        backgroundColor: '#ffffff'
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Resident</TableCell>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Status</TableCell>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Submitted</TableCell>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Last Updated</TableCell>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {submissions.map((submission) => (
            <TableRow 
              key={submission._id}
              sx={{ 
                '&:hover': {
                  bgcolor: '#f8f9fb'
                }
              }}
            >
              <TableCell sx={{ py: 1.5, color: 'primary.main', fontWeight: 500 }}>
                {submission.resident.firstName} {submission.resident.lastName}
              </TableCell>
              <TableCell sx={{ py: 1.5 }}>
                <Chip
                  label={submission.status}
                  color={getStatusColor(submission.status)}
                  size="small"
                  sx={{ fontWeight: 500, height: 24 }}
                />
              </TableCell>
              <TableCell sx={{ py: 1.5 }}>
                {formatDate(submission.createdAt)}
              </TableCell>
              <TableCell sx={{ py: 1.5 }}>
                {submission.updatedAt ? formatDate(submission.updatedAt) : '-'}
              </TableCell>
              <TableCell sx={{ py: 1.5 }}>
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={submission.status === 'approved' ? <CheckIcon /> : <DescriptionIcon />}
                  onClick={() => {
                    setSelectedSubmission(submission);
                    setNewStatus(submission.status);
                    setDialogOpen(true);
                  }}
                  color={submission.status === 'approved' ? 'success' : 'primary'}
                  sx={{
                    borderRadius: 1,
                    textTransform: 'none',
                    fontWeight: 500,
                    borderWidth: 1.5,
                    py: 0.5
                  }}
                >
                  Review
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Container maxWidth="lg" sx={{ py: 3 }}>
      {error && (
        <Alert 
          severity="error" 
          sx={{ 
            mb: 3,
            borderRadius: 1,
            '& .MuiAlert-icon': {
              color: 'error.main'
            }
          }}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => setError(null)}
              sx={{ 
                textTransform: 'none',
                fontWeight: 500
              }}
            >
              Dismiss
            </Button>
          }
        >
          {error}
        </Alert>
      )}

      <Paper
        elevation={0}
        sx={{
          mb: 3,
          p: { xs: 2, sm: 2.5 },
          backgroundColor: '#ffffff',
          borderRadius: 1,
          border: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: { xs: 'stretch', md: 'center' },
          gap: { xs: 2, md: 0 },
          mb: { xs: 2, md: 2.5 }
        }}>
          <Box sx={{ flex: 1 }}>
            <Typography 
              variant="h5" 
              component="h1" 
              sx={{ 
                fontWeight: 600, 
                color: 'primary.main',
                mb: { xs: 0.5, md: 0.25 }
              }}
            >
              {form?.title} - Submissions
            </Typography>
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{ 
                display: { xs: 'none', sm: 'block' },
                fontWeight: 500
              }}
            >
              Review and manage form submissions
            </Typography>
          </Box>
          <Stack 
            direction={{ xs: 'column', sm: 'row' }} 
            spacing={2}
            sx={{ 
              width: { xs: '100%', md: 'auto' },
              alignItems: { xs: 'stretch', sm: 'center' }
            }}
          >
            <Button
              startIcon={<Refresh />}
              onClick={fetchData}
              variant="outlined"
              sx={{
                borderRadius: 1,
                textTransform: 'none',
                fontWeight: 500,
                borderWidth: 1.5,
                minWidth: { sm: 120 },
                order: { xs: 2, sm: 1 },
                py: 1
              }}
            >
              Refresh
            </Button>
            <Stack 
              direction="row" 
              spacing={1}
              sx={{
                order: { xs: 1, sm: 2 }
              }}
            >
              <Button
                variant={viewMode === 'grid' ? 'contained' : 'outlined'}
                startIcon={<ViewModuleIcon />}
                onClick={() => setViewMode('grid')}
                sx={{
                  borderRadius: 1,
                  textTransform: 'none',
                  fontWeight: 500,
                  flex: { xs: 1, sm: 'initial' },
                  py: 1
                }}
              >
                Grid
              </Button>
              <Button
                variant={viewMode === 'list' ? 'contained' : 'outlined'}
                startIcon={<ViewListIcon />}
                onClick={() => setViewMode('list')}
                sx={{
                  borderRadius: 1,
                  textTransform: 'none',
                  fontWeight: 500,
                  flex: { xs: 1, sm: 'initial' },
                  py: 1
                }}
              >
                List
              </Button>
            </Stack>
          </Stack>
        </Box>

        <Box sx={{ 
          borderRadius: 1,
          bgcolor: '#f5f6f8',
          border: '1px solid',
          borderColor: 'divider',
          overflow: 'hidden'
        }}>
          <Tabs
            value={currentTab}
            onChange={(e, newValue) => setCurrentTab(newValue)}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            sx={{
              minHeight: 44,
              px: { xs: 1, sm: 2 },
              '& .MuiTab-root': {
                textTransform: 'none',
                fontWeight: 500,
                fontSize: '0.875rem',
                minHeight: 44,
                minWidth: { xs: 'auto', sm: 100 },
                px: { xs: 2, sm: 3 }
              }
            }}
          >
            <Tab label="All Submissions" value="all" />
            <Tab 
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  Pending
                  <Chip 
                    label={submissions.filter(s => s.status === 'pending').length} 
                    size="small"
                    color="warning"
                    sx={{ 
                      height: 20,
                      '& .MuiChip-label': { px: 1, fontSize: '0.75rem' }
                    }}
                  />
                </Box>
              } 
              value="pending" 
            />
            <Tab 
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  Reviewed
                  <Chip 
                    label={submissions.filter(s => s.status === 'reviewed').length}
                    size="small"
                    color="info"
                    sx={{ 
                      height: 20,
                      '& .MuiChip-label': { px: 1, fontSize: '0.75rem' }
                    }}
                  />
                </Box>
              } 
              value="reviewed" 
            />
            <Tab 
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  Approved
                  <Chip 
                    label={submissions.filter(s => s.status === 'approved').length}
                    size="small"
                    color="success"
                    sx={{ 
                      height: 20,
                      '& .MuiChip-label': { px: 1, fontSize: '0.75rem' }
                    }}
                  />
                </Box>
              } 
              value="approved" 
            />
            <Tab 
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  Rejected
                  <Chip 
                    label={submissions.filter(s => s.status === 'rejected').length}
                    size="small"
                    color="error"
                    sx={{ 
                      height: 20,
                      '& .MuiChip-label': { px: 1, fontSize: '0.75rem' }
                    }}
                  />
                </Box>
              } 
              value="rejected" 
            />
          </Tabs>
        </Box>
      </Paper>

      {loading ? (
        viewMode === 'grid' ? (
          <Grid container spacing={2}>
            {[...Array(6)].map((_, index) => (
              <Grid item key={index} xs={12} sm={6} lg={4}>
                <Card
                  elevation={0}
                  sx={{
                    height: '100%',
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 1,
                  }}
                >
                  <CardContent sx={{ p: 2.5 }}>
                    <Stack spacing={2}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Skeleton width="60%" height={24} />
                        <Skeleton width={80} height={24} />
                      </Box>
                      <Skeleton width="100%" height={60} />
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Skeleton width={100} height={24} />
                        <Skeleton width={120} height={24} />
                      </Box>
                      <Skeleton width="100%" height={36} />
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <TableContainer 
            component={Paper}
            elevation={0}
            sx={{ 
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 1,
              overflow: 'hidden'
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {[...Array(5)].map((_, index) => (
                    <TableCell key={index} sx={{ bgcolor: '#f5f6f8' }}>
                      <Skeleton width={100} height={24} />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {[...Array(5)].map((_, index) => (
                  <TableRow key={index}>
                    {[...Array(5)].map((_, cellIndex) => (
                      <TableCell key={cellIndex}>
                        <Skeleton width={cellIndex === 4 ? 120 : 100} height={24} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )
      ) : filteredSubmissions.length === 0 ? (
        <Paper
          elevation={0}
          sx={{
            p: 4,
            textAlign: 'center',
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 1,
            bgcolor: '#ffffff'
          }}
        >
          <ErrorIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
          <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
            No Submissions Found
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            {currentTab === 'all'
              ? 'There are no submissions for this form yet.'
              : 'No submissions match your current filter.'}
          </Typography>
          {currentTab !== 'all' && (
            <Button
              variant="outlined"
              size="small"
              onClick={() => setCurrentTab('all')}
              sx={{
                borderRadius: 1,
                textTransform: 'none',
                fontWeight: 500,
                borderWidth: 1.5
              }}
            >
              Show All Submissions
            </Button>
          )}
        </Paper>
      ) : (
        viewMode === 'grid' ? (
          <Grid container spacing={2}>
            {filteredSubmissions.map((submission) => (
              <Grid item key={submission._id} xs={12} sm={6} lg={4}>
                <SubmissionCard submission={submission} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <SubmissionTable submissions={filteredSubmissions} />
        )
      )}

      <Dialog 
        open={dialogOpen} 
        onClose={() => !loadingUpdate && setDialogOpen(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 1,
            boxShadow: '0 8px 32px -4px rgba(0, 0, 0, 0.1)'
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid',
          borderColor: 'divider',
          bgcolor: '#f5f6f8',
          py: 2
        }}>
          Review Submission
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            {error && (
              <Alert 
                severity="error"
                sx={{ 
                  borderRadius: 1,
                  '& .MuiAlert-icon': {
                    color: 'error.main'
                  }
                }}
              >
                {error}
              </Alert>
            )}

            <FormControl fullWidth disabled={loadingUpdate}>
              <InputLabel>Status</InputLabel>
              <Select
                value={newStatus}
                onChange={(e) => setNewStatus(e.target.value)}
                label="Status"
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'divider',
                  },
                }}
              >
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="reviewed">Reviewed</MenuItem>
                <MenuItem value="approved">Approved</MenuItem>
                <MenuItem value="rejected">Rejected</MenuItem>
              </Select>
            </FormControl>

            <TextField
              fullWidth
              label="Add a note"
              multiline
              rows={4}
              value={note}
              onChange={(e) => setNote(e.target.value)}
              disabled={loadingUpdate}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'divider',
                },
              }}
            />

            {selectedSubmission && (
              <Box>
                <Typography variant="subtitle2" gutterBottom>
                  Submission Details:
                </Typography>
                {selectedSubmission.responses.map((response) => {
                  const field = form.fields.find(f => f._id === response.field);
                  if (!field) return null;
                  return renderFieldValue(field, response.value);
                })}
              </Box>
            )}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 2.5, borderTop: '1px solid', borderColor: 'divider' }}>
          <Button 
            onClick={() => setDialogOpen(false)}
            disabled={loadingUpdate}
            sx={{
              borderRadius: 1,
              textTransform: 'none',
              fontWeight: 500,
              color: 'text.secondary'
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleStatusUpdate}
            variant="contained"
            disabled={loadingUpdate}
            sx={{
              borderRadius: 1,
              textTransform: 'none',
              fontWeight: 500,
              px: 3,
              minWidth: 100,
              position: 'relative'
            }}
          >
            {loadingUpdate ? (
              <CircularProgress
                size={24}
                sx={{
                  color: 'primary.light',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px'
                }}
              />
            ) : (
              'Update'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default FormSubmissionList; 