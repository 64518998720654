import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Stack,
  Divider,
  ToggleButtonGroup,
  ToggleButton,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  CircularProgress,
  Alert,
  Skeleton,
} from '@mui/material';
import {
  Check as CheckIcon,
  Close as CloseIcon,
  Person,
  Refresh,
  ViewModule as ViewModuleIcon,
  ViewList as ViewListIcon,
  Error as ErrorIcon,
  Gavel as GavelIcon,
} from '@mui/icons-material';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://192.168.0.135:5001';
axios.defaults.baseURL = API_URL;

const GrievanceList = () => {
  const [grievances, setGrievances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedGrievance, setSelectedGrievance] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [note, setNote] = useState('');
  const [newStatus, setNewStatus] = useState('');
  const [rejectionReason, setRejectionReason] = useState('');
  const [viewMode, setViewMode] = useState('grid');
  const [currentTab, setCurrentTab] = useState('all');
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [error, setError] = useState(null);

  const fetchGrievances = async () => {
    try {
      setError(null);
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/grievances/all', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setGrievances(response.data);
    } catch (error) {
      setError('Failed to fetch grievances. Please try again.');
      console.error('Error fetching grievances:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGrievances();
  }, []);

  const handleStatusUpdate = async () => {
    try {
      setLoadingUpdate(true);
      setError(null);
      const token = localStorage.getItem('token');
      await axios.patch(`/api/grievances/${selectedGrievance._id}/status`, {
        status: newStatus,
        note: note,
        rejectionReason: newStatus === 'Rejected' ? rejectionReason : undefined
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      await fetchGrievances();
      setDialogOpen(false);
      setNote('');
      setNewStatus('');
      setRejectionReason('');
    } catch (error) {
      setError('Failed to update status. Please try again.');
      console.error('Error updating status:', error);
    } finally {
      setLoadingUpdate(false);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Pending':
        return 'warning';
      case 'In Progress':
        return 'info';
      case 'Resolved':
        return 'success';
      case 'Rejected':
        return 'error';
      default:
        return 'default';
    }
  };

  const getResidentName = (resident) => {
    if (!resident) return 'Unknown';
    return `${resident.firstName} ${resident.lastName}`;
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const filteredGrievances = grievances.filter(grievance => {
    if (currentTab !== 'all' && grievance.status.toLowerCase() !== currentTab) {
      return false;
    }
    return true;
  });

  const GrievanceCard = ({ grievance }) => (
    <Card
      elevation={0}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        backgroundColor: '#ffffff',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
          borderColor: 'primary.main',
        }
      }}
    >
      <CardContent sx={{ p: 2.5, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Person sx={{ fontSize: 18, color: 'text.secondary' }} />
            <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.main' }}>
              {getResidentName(grievance.resident)}
            </Typography>
          </Box>
          <Chip
            label={grievance.status}
            color={getStatusColor(grievance.status)}
            size="small"
            sx={{ fontWeight: 500, height: 24 }}
          />
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            Right Violated:
          </Typography>
          <Typography variant="body2">
            {grievance.violatedRight}
          </Typography>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            Statement:
          </Typography>
          <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
            {grievance.statement}
          </Typography>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            Signature:
          </Typography>
          <Box 
            component="img"
            src={grievance.signature}
            alt="Resident's Signature"
            sx={{ 
              maxWidth: '100%',
              height: 'auto',
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 1,
              bgcolor: '#fff'
            }}
          />
        </Box>

        {grievance.notes && grievance.notes.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              Latest Update:
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {grievance.notes[grievance.notes.length - 1].content}
            </Typography>
          </Box>
        )}

        {grievance.rejectionReason && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2" color="error" sx={{ fontWeight: 500 }}>
              Rejection Reason: {grievance.rejectionReason}
            </Typography>
          </Box>
        )}

        <Box sx={{ mt: 'auto' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'text.secondary', mb: 2 }}>
            <GavelIcon sx={{ fontSize: 18 }} />
            <Typography variant="caption" sx={{ fontWeight: 500 }}>
              Submitted: {formatDate(grievance.createdAt)}
            </Typography>
          </Box>

          <Button
            variant="outlined"
            fullWidth
            startIcon={grievance.status === 'Resolved' ? <CheckIcon /> : <GavelIcon />}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedGrievance(grievance);
              setNewStatus(grievance.status);
              setDialogOpen(true);
            }}
            color={grievance.status === 'Resolved' ? 'success' : 'primary'}
            sx={{
              borderRadius: 1,
              textTransform: 'none',
              fontWeight: 500,
              py: 0.75,
              borderWidth: 1.5
            }}
          >
            Update Status
          </Button>
        </Box>
      </CardContent>
    </Card>
  );

  const GrievanceTable = ({ grievances }) => (
    <TableContainer 
      component={Paper}
      elevation={0}
      sx={{ 
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        overflow: 'hidden',
        backgroundColor: '#ffffff'
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Resident</TableCell>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Right Violated</TableCell>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Statement</TableCell>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Status</TableCell>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Submitted</TableCell>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {grievances.map((grievance) => (
            <TableRow 
              key={grievance._id}
              sx={{ 
                '&:hover': {
                  bgcolor: '#f8f9fb'
                }
              }}
            >
              <TableCell sx={{ py: 1.5, color: 'primary.main', fontWeight: 500 }}>
                {getResidentName(grievance.resident)}
              </TableCell>
              <TableCell sx={{ py: 1.5 }}>{grievance.violatedRight}</TableCell>
              <TableCell sx={{ py: 1.5 }}>
                <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                  {grievance.statement}
                </Typography>
              </TableCell>
              <TableCell sx={{ py: 1.5 }}>
                <Chip
                  label={grievance.status}
                  color={getStatusColor(grievance.status)}
                  size="small"
                  sx={{ fontWeight: 500, height: 24 }}
                />
              </TableCell>
              <TableCell sx={{ py: 1.5 }}>
                {formatDate(grievance.createdAt)}
              </TableCell>
              <TableCell sx={{ py: 1.5 }}>
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={grievance.status === 'Resolved' ? <CheckIcon /> : <GavelIcon />}
                  onClick={() => {
                    setSelectedGrievance(grievance);
                    setNewStatus(grievance.status);
                    setDialogOpen(true);
                  }}
                  color={grievance.status === 'Resolved' ? 'success' : 'primary'}
                  sx={{
                    borderRadius: 1,
                    textTransform: 'none',
                    fontWeight: 500,
                    borderWidth: 1.5,
                    py: 0.5
                  }}
                >
                  Update
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Container maxWidth="lg" sx={{ py: 3 }}>
      {error && (
        <Alert 
          severity="error" 
          sx={{ 
            mb: 3,
            borderRadius: 1,
            '& .MuiAlert-icon': {
              color: 'error.main'
            }
          }}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => setError(null)}
              sx={{ 
                textTransform: 'none',
                fontWeight: 500
              }}
            >
              Dismiss
            </Button>
          }
        >
          {error}
        </Alert>
      )}

      <Paper
        elevation={0}
        sx={{
          mb: 3,
          p: { xs: 2, sm: 2.5 },
          backgroundColor: '#ffffff',
          borderRadius: 1,
          border: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: { xs: 'stretch', md: 'center' },
          gap: { xs: 2, md: 0 },
          mb: { xs: 2, md: 2.5 }
        }}>
          <Box sx={{ flex: 1 }}>
            <Typography 
              variant="h5" 
              component="h1" 
              sx={{ 
                fontWeight: 600, 
                color: 'primary.main',
                mb: { xs: 0.5, md: 0.25 }
              }}
            >
              Grievances
            </Typography>
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{ 
                display: { xs: 'none', sm: 'block' },
                fontWeight: 500
              }}
            >
              Manage and review resident grievances
            </Typography>
          </Box>
          <Stack 
            direction={{ xs: 'column', sm: 'row' }} 
            spacing={2}
            sx={{ 
              width: { xs: '100%', md: 'auto' },
              alignItems: { xs: 'stretch', sm: 'center' }
            }}
          >
            <Button
              startIcon={<Refresh />}
              onClick={fetchGrievances}
              variant="outlined"
              sx={{
                borderRadius: 1,
                textTransform: 'none',
                fontWeight: 500,
                borderWidth: 1.5,
                minWidth: { sm: 120 },
                order: { xs: 2, sm: 1 },
                py: 1
              }}
            >
              Refresh
            </Button>
            <ToggleButtonGroup
              value={viewMode}
              exclusive
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  setViewMode(newValue);
                }
              }}
              size="small"
              aria-label="view mode"
              sx={{
                display: 'flex',
                order: { xs: 1, sm: 2 },
                '& .MuiToggleButtonGroup-grouped': {
                  flex: { xs: 1, sm: 'none' },
                  px: { xs: 3, sm: 2 },
                  py: 1,
                  borderRadius: '4px !important',
                  borderColor: 'divider',
                  '&.Mui-selected': {
                    bgcolor: 'primary.main',
                    color: 'white',
                    '&:hover': {
                      bgcolor: 'primary.dark',
                    },
                  },
                },
              }}
            >
              <ToggleButton value="grid" aria-label="grid view">
                <ViewModuleIcon sx={{ mr: 1 }} />
                Grid
              </ToggleButton>
              <ToggleButton value="list" aria-label="list view">
                <ViewListIcon sx={{ mr: 1 }} />
                List
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Box>

        <Box sx={{ 
          borderRadius: 1,
          bgcolor: '#f5f6f8',
          border: '1px solid',
          borderColor: 'divider',
          overflow: 'hidden'
        }}>
          <Tabs
            value={currentTab}
            onChange={(e, newValue) => setCurrentTab(newValue)}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            sx={{
              minHeight: 44,
              px: { xs: 1, sm: 2 },
              '& .MuiTab-root': {
                textTransform: 'none',
                fontWeight: 500,
                fontSize: '0.875rem',
                minHeight: 44,
                minWidth: { xs: 'auto', sm: 100 },
                px: { xs: 2, sm: 3 }
              }
            }}
          >
            <Tab label="All Grievances" value="all" />
            <Tab 
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  Pending
                  <Chip 
                    label={grievances.filter(r => r.status.toLowerCase() === 'pending').length} 
                    size="small"
                    color="warning"
                    sx={{ 
                      height: 20,
                      '& .MuiChip-label': { px: 1, fontSize: '0.75rem' }
                    }}
                  />
                </Box>
              } 
              value="pending" 
            />
            <Tab 
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  In Progress
                  <Chip 
                    label={grievances.filter(r => r.status.toLowerCase() === 'in progress').length}
                    size="small"
                    color="info"
                    sx={{ 
                      height: 20,
                      '& .MuiChip-label': { px: 1, fontSize: '0.75rem' }
                    }}
                  />
                </Box>
              } 
              value="in progress" 
            />
            <Tab 
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  Resolved
                  <Chip 
                    label={grievances.filter(r => r.status.toLowerCase() === 'resolved').length}
                    size="small"
                    color="success"
                    sx={{ 
                      height: 20,
                      '& .MuiChip-label': { px: 1, fontSize: '0.75rem' }
                    }}
                  />
                </Box>
              } 
              value="resolved" 
            />
            <Tab 
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  Rejected
                  <Chip 
                    label={grievances.filter(r => r.status.toLowerCase() === 'rejected').length}
                    size="small"
                    color="error"
                    sx={{ 
                      height: 20,
                      '& .MuiChip-label': { px: 1, fontSize: '0.75rem' }
                    }}
                  />
                </Box>
              } 
              value="rejected" 
            />
          </Tabs>
        </Box>
      </Paper>

      {loading ? (
        viewMode === 'grid' ? (
          <Grid container spacing={2}>
            {[...Array(6)].map((_, index) => (
              <Grid item key={index} xs={12} sm={6} lg={4}>
                <Card
                  elevation={0}
                  sx={{
                    height: '100%',
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 1,
                  }}
                >
                  <CardContent sx={{ p: 2.5 }}>
                    <Stack spacing={2}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Skeleton width="60%" height={24} />
                        <Skeleton width={80} height={24} />
                      </Box>
                      <Skeleton width="100%" height={60} />
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Skeleton width={100} height={24} />
                        <Skeleton width={120} height={24} />
                      </Box>
                      <Skeleton width="100%" height={36} />
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <TableContainer 
            component={Paper}
            elevation={0}
            sx={{ 
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 1,
              overflow: 'hidden'
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {[...Array(6)].map((_, index) => (
                    <TableCell key={index} sx={{ bgcolor: '#f5f6f8' }}>
                      <Skeleton width={100} height={24} />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {[...Array(5)].map((_, index) => (
                  <TableRow key={index}>
                    {[...Array(6)].map((_, cellIndex) => (
                      <TableCell key={cellIndex}>
                        <Skeleton width={cellIndex === 5 ? 120 : 100} height={24} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )
      ) : filteredGrievances.length === 0 ? (
        <Paper
          elevation={0}
          sx={{
            p: 4,
            textAlign: 'center',
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 1,
            bgcolor: '#ffffff'
          }}
        >
          <ErrorIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
          <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
            No Grievances Found
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            {currentTab === 'all'
              ? 'There are no grievances in the system.'
              : 'No grievances match your current filter.'}
          </Typography>
          {currentTab !== 'all' && (
            <Button
              variant="outlined"
              size="small"
              onClick={() => setCurrentTab('all')}
              sx={{
                borderRadius: 1,
                textTransform: 'none',
                fontWeight: 500,
                borderWidth: 1.5
              }}
            >
              Clear Filter
            </Button>
          )}
        </Paper>
      ) : (
        viewMode === 'grid' ? (
          <Grid container spacing={2}>
            {filteredGrievances.map((grievance) => (
              <Grid item key={grievance._id} xs={12} sm={6} lg={4}>
                <GrievanceCard grievance={grievance} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <GrievanceTable grievances={filteredGrievances} />
        )
      )}

      {/* Status Update Dialog */}
      <Dialog 
        open={dialogOpen} 
        onClose={() => !loadingUpdate && setDialogOpen(false)} 
        maxWidth="sm" 
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 1,
            boxShadow: '0 8px 32px -4px rgba(0, 0, 0, 0.1)'
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid',
          borderColor: 'divider',
          bgcolor: '#f5f6f8',
          py: 2
        }}>
          Update Grievance Status
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            {error && (
              <Alert 
                severity="error"
                sx={{ 
                  borderRadius: 1,
                  '& .MuiAlert-icon': {
                    color: 'error.main'
                  }
                }}
              >
                {error}
              </Alert>
            )}
            <FormControl fullWidth disabled={loadingUpdate}>
              <InputLabel>Status</InputLabel>
              <Select
                value={newStatus}
                onChange={(e) => {
                  setNewStatus(e.target.value);
                  if (e.target.value !== 'Rejected') {
                    setRejectionReason('');
                  }
                }}
                label="Status"
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'divider',
                  },
                }}
              >
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="In Progress">In Progress</MenuItem>
                <MenuItem value="Resolved">Resolved</MenuItem>
                <MenuItem value="Rejected">Rejected</MenuItem>
              </Select>
            </FormControl>

            {newStatus === 'Rejected' && (
              <TextField
                fullWidth
                label="Rejection Reason"
                value={rejectionReason}
                onChange={(e) => setRejectionReason(e.target.value)}
                required
                error={newStatus === 'Rejected' && !rejectionReason}
                helperText={newStatus === 'Rejected' && !rejectionReason ? 'Rejection reason is required' : ''}
                disabled={loadingUpdate}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'divider',
                  },
                }}
              />
            )}

            <TextField
              fullWidth
              label="Add a note"
              multiline
              rows={4}
              value={note}
              onChange={(e) => setNote(e.target.value)}
              disabled={loadingUpdate}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'divider',
                },
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 2.5, borderTop: '1px solid', borderColor: 'divider' }}>
          <Button 
            onClick={() => setDialogOpen(false)}
            disabled={loadingUpdate}
            sx={{
              borderRadius: 1,
              textTransform: 'none',
              fontWeight: 500,
              color: 'text.secondary'
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleStatusUpdate} 
            variant="contained" 
            color="primary"
            disabled={loadingUpdate || (newStatus === 'Rejected' && !rejectionReason)}
            sx={{
              borderRadius: 1,
              textTransform: 'none',
              fontWeight: 500,
              px: 3,
              minWidth: 100,
              position: 'relative'
            }}
          >
            {loadingUpdate ? (
              <CircularProgress
                size={24}
                sx={{
                  color: 'primary.light',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px'
                }}
              />
            ) : (
              'Update'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default GrievanceList; 