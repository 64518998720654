import React, { useRef } from 'react';
import { IconButton, Box } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import axios from '../../../utils/axios';

const ImageUpload = ({ property, onImageUpdate }) => {
  const fileInputRef = useRef();

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await axios.post(`/api/properties/${property._id}/upload-image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      onImageUpdate(response.data.imageUrl);
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const buttonStyle = {
    bgcolor: 'rgba(255, 255, 255, 0.9)',
    '&:hover': {
      bgcolor: 'rgba(255, 255, 255, 1)',
    },
    width: 40,
    height: 40,
    boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
  };

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        onChange={handleImageUpload}
        style={{ display: 'none' }}
      />
      <IconButton
        onClick={() => fileInputRef.current.click()}
        sx={buttonStyle}
      >
        <PhotoCamera sx={{ fontSize: 20 }} />
      </IconButton>
    </Box>
  );
};

export default ImageUpload; 