import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Alert,
  CircularProgress,
} from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import axios from '../../utils/axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://192.168.0.135:5001';
axios.defaults.baseURL = API_URL;

const MaintenanceRequestForm = () => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    priority: 'Low',
  });
  const [selectedImages, setSelectedImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [userProperty, setUserProperty] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  // Fetch user's property assignment when component mounts
  useEffect(() => {
    const fetchUserProperty = async () => {
      try {
        const token = localStorage.getItem('token');
        // Get user profile
        const userResponse = await axios.get('/api/auth/profile', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const userId = userResponse.data._id;
        const userEmail = userResponse.data.email;

        // Get all properties
        const propertyResponse = await axios.get('/api/properties', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        // Find the property where the user is assigned
        let foundProperty = null;
        let foundRoom = null;
        let foundBed = null;

        for (const property of propertyResponse.data) {
          for (const room of property.rooms) {
            const bed = room.beds.find(bed => 
              bed.resident && (bed.resident._id === userId || bed.resident.email === userEmail)
            );
            if (bed) {
              foundProperty = property;
              foundRoom = room;
              foundBed = bed;
              break;
            }
          }
          if (foundProperty) break;
        }

        if (!foundProperty || !foundRoom || !foundBed) {
          console.error('No property assignment found for user:', userId, userEmail);
          console.log('Available properties:', JSON.stringify(propertyResponse.data, null, 2));
          setError('No property assignment found. Please contact your administrator.');
          return;
        }

        setUserProperty({
          propertyId: foundProperty._id,
          propertyName: foundProperty.name,
          roomName: foundRoom.name,
          bedName: foundBed.name
        });
      } catch (error) {
        console.error('Error fetching user property:', error);
        setError('Failed to fetch property assignment');
      }
    };
    fetchUserProperty();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 5) {
      setError('You can only upload up to 5 images');
      return;
    }
    setSelectedImages(files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userProperty) {
      setError('No property assignment found');
      return;
    }

    setLoading(true);
    setError('');
    setSuccess(false);
    setUploadProgress(0);

    try {
      // First, upload all images to S3
      const uploadedImageUrls = [];
      if (selectedImages.length > 0) {
        for (let i = 0; i < selectedImages.length; i++) {
          const formData = new FormData();
          formData.append('image', selectedImages[i]);

          const response = await axios.post('/api/maintenance/upload-image', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
              const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              setUploadProgress(((i * 100) + progress) / selectedImages.length);
            }
          });
          uploadedImageUrls.push(response.data.imageUrl);
        }
      }

      // Then create the maintenance request with the S3 URLs
      const maintenanceData = {
        title: formData.title,
        description: formData.description,
        priority: formData.priority,
        property: userProperty.propertyId,
        unit: `${userProperty.roomName} - ${userProperty.bedName}`,
        images: uploadedImageUrls
      };

      const token = localStorage.getItem('token');
      await axios.post('/api/maintenance', maintenanceData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      setSuccess(true);
      setFormData({
        title: '',
        description: '',
        priority: 'Low'
      });
      setSelectedImages([]);
      setUploadProgress(0);
    } catch (err) {
      console.error('Error submitting request:', err);
      setError(err.response?.data?.message || 'Failed to submit maintenance request');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ maxWidth: 800, mx: 'auto', p: 3 }}>
      <Card elevation={3}>
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
            Submit Maintenance Request
          </Typography>

          {userProperty && (
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              Property: {userProperty.propertyName}
              <br />
              Room: {userProperty.roomName}
              <br />
              Bed: {userProperty.bedName}
            </Typography>
          )}

          {success && (
            <Alert severity="success" sx={{ mb: 2 }}>
              Maintenance request submitted successfully!
            </Alert>
          )}

          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Title"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  required
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  required
                  multiline
                  rows={4}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Priority</InputLabel>
                  <Select
                    name="priority"
                    value={formData.priority}
                    onChange={handleChange}
                    label="Priority"
                  >
                    <MenuItem value="Low">Low</MenuItem>
                    <MenuItem value="Medium">Medium</MenuItem>
                    <MenuItem value="High">High</MenuItem>
                    <MenuItem value="Emergency">Emergency</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  component="label"
                  startIcon={<CloudUpload />}
                  sx={{ mb: 1 }}
                >
                  Upload Images (Max 5)
                  <input
                    type="file"
                    hidden
                    multiple
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </Button>
                {selectedImages.length > 0 && (
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="body2" color="textSecondary">
                      {selectedImages.length} image(s) selected
                    </Typography>
                    {uploadProgress > 0 && uploadProgress < 100 && (
                      <Typography variant="body2" color="primary">
                        Uploading: {Math.round(uploadProgress)}%
                      </Typography>
                    )}
                  </Box>
                )}
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading || !userProperty}
                  fullWidth
                  sx={{
                    position: 'relative',
                    height: 48
                  }}
                >
                  {loading ? (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: 'primary.light',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px'
                      }}
                    />
                  ) : (
                    'Submit Request'
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default MaintenanceRequestForm; 