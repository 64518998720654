import React, { useState } from 'react';
import { 
  Typography, 
  Box,
  Grid,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import { styled } from '@mui/material/styles';
import BuildIcon from '@mui/icons-material/Build';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ErrorIcon from '@mui/icons-material/Error';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: '#ffffff',
  border: '1px solid',
  borderColor: theme.palette.divider,
  borderRadius: `${theme.spacing(2)} !important`,
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
  transition: 'all 0.3s ease-in-out',
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    boxShadow: '0 12px 48px rgba(0, 0, 0, 0.12)',
    transform: 'scale(1.02)',
    borderColor: 'warning.main'
  },
  '& .MuiAccordionSummary-root': {
    padding: theme.spacing(2),
    minHeight: 'auto',
    '&.Mui-expanded': {
      minHeight: 'auto',
    }
  },
  '& .MuiAccordionSummary-content': {
    margin: '0 !important'
  },
  '& .MuiAccordionDetails-root': {
    padding: theme.spacing(0, 2, 2, 2),
    borderTop: '1px solid',
    borderTopColor: theme.palette.divider
  }
}));

const StatBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1.5),
  backgroundColor: 'rgba(255, 152, 0, 0.08)',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: 'rgba(255, 152, 0, 0.12)',
    transform: 'translateY(-2px)'
  }
}));

const MaintenanceRequestsCard = ({ requests = [] }) => {
  const [expanded, setExpanded] = useState(false);

  // Calculate request statistics by status
  const totalRequests = requests.length;
  const completedRequests = requests.filter(r => r.status === 'Completed').length;
  const pendingRequests = requests.filter(r => r.status === 'Pending').length;
  const inProgressRequests = requests.filter(r => r.status === 'In Progress').length;

  // Calculate request statistics by priority
  const emergencyRequests = requests.filter(r => r.priority === 'Emergency' && r.status !== 'Completed').length;
  const highPriorityRequests = requests.filter(r => r.priority === 'High' && r.status !== 'Completed').length;
  const mediumPriorityRequests = requests.filter(r => r.priority === 'Medium' && r.status !== 'Completed').length;
  const lowPriorityRequests = requests.filter(r => r.priority === 'Low' && r.status !== 'Completed').length;

  const completionRate = totalRequests > 0 ? ((completedRequests / totalRequests) * 100).toFixed(0) : 0;

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  return (
    <StyledAccordion 
      expanded={expanded} 
      onChange={handleAccordionChange}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: 'warning.main' }} />}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '100%' }}>
          <Box
            sx={{
              width: 48,
              height: 48,
              borderRadius: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'rgba(255, 152, 0, 0.08)'
            }}
          >
            <BuildIcon sx={{ color: 'warning.main', fontSize: 28 }} />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography variant="h5" sx={{ fontWeight: 700, color: 'text.primary', mb: 0.5 }}>
              {pendingRequests}
            </Typography>
            <Typography variant="body1" sx={{ color: 'text.secondary', fontWeight: 500 }}>
              Pending Maintenance
            </Typography>
            {emergencyRequests > 0 && (
              <Typography variant="caption" sx={{ color: 'error.main', fontWeight: 600 }}>
                {emergencyRequests} Emergency {emergencyRequests === 1 ? 'Request' : 'Requests'}
              </Typography>
            )}
          </Box>
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <StatBox>
              <CheckCircleIcon sx={{ color: 'success.main', fontSize: 24 }} />
              <Box>
                <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                  Completed
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                  {completedRequests}
                </Typography>
              </Box>
            </StatBox>
          </Grid>

          <Grid item xs={6}>
            <StatBox>
              <PendingIcon sx={{ color: 'warning.main', fontSize: 24 }} />
              <Box>
                <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                  Pending
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                  {pendingRequests}
                </Typography>
              </Box>
            </StatBox>
          </Grid>

          <Grid item xs={6}>
            <StatBox>
              <EngineeringIcon sx={{ color: 'info.main', fontSize: 24 }} />
              <Box>
                <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                  In Progress
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                  {inProgressRequests}
                </Typography>
              </Box>
            </StatBox>
          </Grid>

          <Grid item xs={6}>
            <StatBox>
              <ErrorIcon sx={{ color: 'error.main', fontSize: 24 }} />
              <Box>
                <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                  High Priority
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                  {highPriorityRequests + emergencyRequests}
                </Typography>
              </Box>
            </StatBox>
          </Grid>
        </Grid>

        <Box sx={{ 
          mt: 3, 
          p: 2.5, 
          borderRadius: 2,
          bgcolor: 'rgba(255, 152, 0, 0.04)',
          border: '1px solid',
          borderColor: 'warning.light',
        }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                Completion Rate
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 700, color: 'warning.main' }}>
                {completionRate}%
              </Typography>
            </Box>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                Active Requests
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 700 }}>
                {pendingRequests + inProgressRequests}
              </Typography>
            </Box>
          </Stack>
        </Box>
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default MaintenanceRequestsCard; 