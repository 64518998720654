import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Stack,
  Alert
} from '@mui/material';

const DetailsDialog = ({
  open,
  onClose,
  onSubmit,
  data,
  onChange,
  error
}) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 1,
          boxShadow: '0 8px 32px -4px rgba(0, 0, 0, 0.1)'
        }
      }}
    >
      <DialogTitle sx={{ 
        borderBottom: '1px solid',
        borderColor: 'divider',
        bgcolor: '#f5f6f8',
        py: 2
      }}>
        Edit Resident Details
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3} sx={{ mt: 2 }}>
          {error && (
            <Alert 
              severity="error"
              sx={{ 
                borderRadius: 1,
                '& .MuiAlert-icon': {
                  color: 'error.main'
                }
              }}
            >
              {error}
            </Alert>
          )}
          <TextField
            fullWidth
            label="Level of Care (LOC)"
            value={data.levelOfCare || ''}
            onChange={(e) => onChange({ ...data, levelOfCare: e.target.value })}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 1,
                '& fieldset': {
                  borderColor: 'divider'
                }
              }
            }}
          />
          <TextField
            fullWidth
            label="Treatment Agency"
            value={data.treatmentAgency || ''}
            onChange={(e) => onChange({ ...data, treatmentAgency: e.target.value })}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 1,
                '& fieldset': {
                  borderColor: 'divider'
                }
              }
            }}
          />
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Notes"
            value={data.notes || ''}
            onChange={(e) => onChange({ ...data, notes: e.target.value })}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 1,
                '& fieldset': {
                  borderColor: 'divider'
                }
              }
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2, bgcolor: '#f8f9fb' }}>
        <Button 
          onClick={onClose}
          sx={{ 
            borderRadius: 1,
            textTransform: 'none',
            fontWeight: 500,
            px: 2
          }}
        >
          Cancel
        </Button>
        <Button 
          onClick={onSubmit} 
          variant="contained"
          sx={{ 
            borderRadius: 1,
            textTransform: 'none',
            fontWeight: 500,
            px: 2
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DetailsDialog; 