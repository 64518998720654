import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import SignatureCanvas from 'react-signature-canvas';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL || 'http://192.168.0.135:5001';
axios.defaults.baseURL = API_URL;

// List of resident rights that can be violated
const RESIDENT_RIGHTS = [
  'Right to Dignity and Respect',
  'Right to Privacy',
  'Right to Safe and Clean Environment',
  'Right to File Complaints Without Retaliation',
  'Right to Participate in Activities',
  'Right to Receive Visitors',
  'Right to Personal Property',
  'Right to Be Informed of Rules and Regulations',
  'Right to Religious Freedom',
  'Right to Medical Care Access',
  'Other'
];

const GrievanceForm = () => {
  const navigate = useNavigate();
  const signatureRef = useRef();
  const [formData, setFormData] = useState({
    violatedRight: '',
    statement: '',
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [signatureError, setSignatureError] = useState('');
  const [userProperty, setUserProperty] = useState(null);

  // Fetch user's property information
  useEffect(() => {
    const fetchUserProperty = async () => {
      try {
        const token = localStorage.getItem('token');
        // First get all properties to find user's bed assignment
        const response = await axios.get('/api/properties', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        // Find the property and bed where the user is assigned
        const userId = JSON.parse(atob(token.split('.')[1])).userId;
        let foundProperty = null;
        let foundRoom = null;
        let foundBed = null;

        for (const property of response.data) {
          for (const room of property.rooms) {
            const bed = room.beds.find(bed => bed.resident && bed.resident._id === userId);
            if (bed) {
              foundProperty = property;
              foundRoom = room;
              foundBed = bed;
              break;
            }
          }
          if (foundProperty) break;
        }

        if (!foundProperty || !foundRoom || !foundBed) {
          setError('Property assignment not found. Please contact an administrator.');
          return;
        }

        setUserProperty({
          _id: foundProperty._id,
          unit: foundRoom.name // Using room name as the unit
        });
      } catch (error) {
        console.error('Error fetching user property:', error);
        setError('Failed to fetch property information');
      }
    };

    fetchUserProperty();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const clearSignature = () => {
    signatureRef.current.clear();
    setSignatureError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSignatureError('');

    if (!userProperty) {
      setError('Property information not available');
      return;
    }

    // Validate signature
    if (signatureRef.current.isEmpty()) {
      setSignatureError('Please provide your signature');
      return;
    }

    // Validate form data
    if (!formData.violatedRight || !formData.statement.trim()) {
      setError('Please fill in all required fields');
      return;
    }

    setLoading(true);

    try {
      const token = localStorage.getItem('token');
      const signature = signatureRef.current.toDataURL();

      await axios.post('/api/grievances', {
        ...formData,
        signature,
        property: userProperty._id,
        unit: userProperty.unit
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      setSuccess(true);
      setFormData({
        violatedRight: '',
        statement: '',
      });
      clearSignature();

      // Redirect after successful submission
      setTimeout(() => {
        navigate('/my-grievances');
      }, 2000);
    } catch (err) {
      console.error('Error submitting grievance:', err);
      setError(err.response?.data?.message || 'Failed to submit grievance');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ maxWidth: 800, mx: 'auto', p: 3 }}>
      <Card elevation={3}>
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
            Submit Grievance
          </Typography>

          {success && (
            <Alert severity="success" sx={{ mb: 2 }}>
              Grievance submitted successfully! Redirecting...
            </Alert>
          )}

          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl fullWidth required>
                  <InputLabel>Right Violated</InputLabel>
                  <Select
                    name="violatedRight"
                    value={formData.violatedRight}
                    onChange={handleChange}
                    label="Right Violated"
                  >
                    {RESIDENT_RIGHTS.map((right) => (
                      <MenuItem key={right} value={right}>
                        {right}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Statement"
                  name="statement"
                  value={formData.statement}
                  onChange={handleChange}
                  required
                  multiline
                  rows={6}
                  variant="outlined"
                  placeholder="Please describe the situation in detail..."
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Signature *
                </Typography>
                <Box
                  sx={{
                    border: '1px solid',
                    borderColor: signatureError ? 'error.main' : 'divider',
                    borderRadius: 1,
                    bgcolor: '#fff',
                    mb: 1
                  }}
                >
                  <SignatureCanvas
                    ref={signatureRef}
                    canvasProps={{
                      width: 750,
                      height: 200,
                      className: 'signature-canvas'
                    }}
                    backgroundColor="white"
                  />
                </Box>
                {signatureError && (
                  <Typography color="error" variant="caption">
                    {signatureError}
                  </Typography>
                )}
                <Button
                  variant="outlined"
                  size="small"
                  onClick={clearSignature}
                  sx={{ mt: 1 }}
                >
                  Clear Signature
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  fullWidth
                  size="large"
                >
                  {loading ? 'Submitting...' : 'Submit Grievance'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default GrievanceForm; 