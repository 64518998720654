import React, { useState } from 'react';
import { 
  Typography, 
  Box,
  Grid,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Button,
  IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: '#ffffff',
  border: '1px solid',
  borderColor: theme.palette.divider,
  borderRadius: `${theme.spacing(2)} !important`,
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
  transition: 'all 0.3s ease-in-out',
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    boxShadow: '0 12px 48px rgba(0, 0, 0, 0.12)',
    transform: 'scale(1.02)',
    borderColor: 'cyan.main'
  },
  '& .MuiAccordionSummary-root': {
    padding: theme.spacing(2),
    minHeight: 'auto',
    '&.Mui-expanded': {
      minHeight: 'auto',
    }
  },
  '& .MuiAccordionSummary-content': {
    margin: '0 !important'
  },
  '& .MuiAccordionDetails-root': {
    padding: theme.spacing(0, 2, 2, 2),
    borderTop: '1px solid',
    borderTopColor: theme.palette.divider
  }
}));

function NewResidentsCard({ residents = [] }) {
  const [expanded, setExpanded] = useState(false);
  const [page, setPage] = useState(0);
  const itemsPerPage = 5;

  // Function to get initials from name
  const getInitials = (firstName, lastName) => {
    return `${firstName?.[0] || ''}${lastName?.[0] || ''}`.toUpperCase();
  };

  // Filter active residents created in the last 30 days
  const newResidents = residents.filter(resident => {
    const createdDate = new Date(resident.createdAt);
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    return createdDate >= thirtyDaysAgo && resident.status === 'active';
  });

  const totalPages = Math.ceil(newResidents.length / itemsPerPage);
  const paginatedResidents = newResidents.slice(
    page * itemsPerPage,
    (page + 1) * itemsPerPage
  );

  const handleNextPage = () => {
    setPage(prev => Math.min(prev + 1, totalPages - 1));
  };

  const handlePrevPage = () => {
    setPage(prev => Math.max(prev - 1, 0));
  };

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  return (
    <StyledAccordion 
      expanded={expanded} 
      onChange={handleAccordionChange}
    >
      <AccordionSummary
        expandIcon={<NavigateNextIcon sx={{ color: 'cyan.main' }} />}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '100%' }}>
          <Box
            sx={{
              width: 48,
              height: 48,
              borderRadius: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'rgba(0, 188, 212, 0.08)'
            }}
          >
            <PersonAddIcon sx={{ color: 'cyan.main', fontSize: 28 }} />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography variant="h5" sx={{ fontWeight: 700, color: 'text.primary', mb: 0.5 }}>
              {newResidents.length}
            </Typography>
            <Typography variant="body1" sx={{ color: 'text.secondary', fontWeight: 500 }}>
              New Residents
            </Typography>
          </Box>
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <List sx={{ 
          width: '100%',
          bgcolor: 'background.paper',
          '& .MuiListItem-root': {
            px: 0,
            py: 1,
          }
        }}>
          {paginatedResidents.map((resident) => (
            <ListItem key={resident._id} disablePadding>
              <ListItemButton 
                component={Link} 
                to={`/residents/${resident._id}`}
                sx={{
                  borderRadius: 2,
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    bgcolor: 'rgba(0, 188, 212, 0.08)'
                  }
                }}
              >
                <ListItemAvatar>
                  <Avatar 
                    src={resident.imageUrl ? `${process.env.REACT_APP_S3_URL}/${resident.imageUrl}` : undefined}
                    sx={{ 
                      width: 40,
                      height: 40,
                      bgcolor: resident.imageUrl ? 'transparent' : 'rgba(0, 188, 212, 0.08)',
                      color: 'cyan.main'
                    }}
                  >
                    {!resident.imageUrl && getInitials(resident.firstName, resident.lastName)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="body2" sx={{ fontWeight: 600, color: 'text.primary' }}>
                      {resident.firstName} {resident.lastName}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                      Joined {format(new Date(resident.createdAt), 'MMM d, yyyy')}
                    </Typography>
                  }
                />
                <ChevronRightIcon sx={{ color: 'action.disabled', fontSize: 20 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        {newResidents.length > itemsPerPage && (
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            gap: 2,
            mt: 2 
          }}>
            <IconButton
              size="small"
              onClick={handlePrevPage}
              disabled={page === 0}
              sx={{
                color: 'cyan.main',
                bgcolor: 'rgba(0, 188, 212, 0.08)',
                '&:hover': {
                  bgcolor: 'rgba(0, 188, 212, 0.16)'
                }
              }}
            >
              <NavigateBeforeIcon />
            </IconButton>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Page {page + 1} of {totalPages}
            </Typography>
            <IconButton
              size="small"
              onClick={handleNextPage}
              disabled={page >= totalPages - 1}
              sx={{
                color: 'cyan.main',
                bgcolor: 'rgba(0, 188, 212, 0.08)',
                '&:hover': {
                  bgcolor: 'rgba(0, 188, 212, 0.16)'
                }
              }}
            >
              <NavigateNextIcon />
            </IconButton>
          </Box>
        )}

        <Box sx={{ mt: 3, textAlign: 'center' }}>
          <Button
            component={Link}
            to="/residents"
            variant="outlined"
            endIcon={<ArrowForwardIcon />}
            sx={{
              borderRadius: 1,
              textTransform: 'none',
              color: 'cyan.main',
              borderColor: 'rgba(0, 188, 212, 0.5)',
              '&:hover': {
                borderColor: 'cyan.main',
                bgcolor: 'rgba(0, 188, 212, 0.04)'
              }
            }}
          >
            View All Residents
          </Button>
        </Box>
      </AccordionDetails>
    </StyledAccordion>
  );
}

export default NewResidentsCard; 