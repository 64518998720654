import React from 'react';
import {
  Box,
  Typography,
  Button,
  Stack,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  List,
  ListItem,
  Divider,
  useTheme,
  alpha
} from '@mui/material';
import {
  Home as HomeIcon,
  PhotoCamera as PhotoCameraIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  DeleteOutline as DeleteImageIcon,
  Delete as Delete
} from '@mui/icons-material';
import ImageUpload from './ImageUpload';
import axios from '../../../utils/axios';

const PropertySummary = ({ 
  property,
  onImageUpdate,
  setError,
  onEdit,
  onDelete,
  fetchData
}) => {
  const theme = useTheme();

  const buttonStyle = {
    bgcolor: 'rgba(255, 255, 255, 0.9)',
    '&:hover': {
      bgcolor: 'rgba(255, 255, 255, 1)',
    },
    width: 40,
    height: 40,
    boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
  };

  const handleDeleteImage = async () => {
    try {
      await axios.delete(`/api/properties/${property._id}/image`);
      onImageUpdate(null);
    } catch (error) {
      console.error('Error deleting image:', error);
      setError(error.response?.data?.message || 'Failed to delete image');
    }
  };

  const summaryItems = [
    {
      label: 'Total Rooms',
      value: property.rooms.length
    },
    {
      label: 'Total Beds',
      value: property.rooms.reduce(
        (total, room) => total + room.beds.length,
        0
      )
    },
    {
      label: 'Occupied Beds',
      value: property.rooms.reduce(
        (total, room) => total + room.beds.filter(bed => bed.resident).length,
        0
      )
    },
    {
      label: 'Total Square Footage',
      value: `${property.rooms.reduce(
        (total, room) => total + Number(room.squareFootage),
        0
      )} sq ft`
    }
  ];

  return (
    <Card
      elevation={0}
      sx={{
        borderRadius: 3,
        border: '1px solid',
        borderColor: 'divider',
        position: 'relative',
        overflow: 'hidden',
        bgcolor: '#ffffff',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          borderColor: 'primary.main',
          boxShadow: '0 8px 24px rgba(0, 0, 0, 0.12)',
        }
      }}
    >
      {/* Header */}
      <Box
        sx={{
          background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
          p: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <HomeIcon sx={{ color: 'white' }} />
          <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 600 }}>
            Property Details
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <ImageUpload 
            property={property} 
            onImageUpdate={onImageUpdate}
          />
          {property.imageUrl && (
            <IconButton
              onClick={handleDeleteImage}
              sx={{
                ...buttonStyle,
                color: 'error.main',
                '&:hover': {
                  bgcolor: 'rgba(255, 255, 255, 1)',
                  color: 'error.dark',
                }
              }}
            >
              <Delete sx={{ fontSize: 20 }} />
            </IconButton>
          )}
        </Box>
      </Box>

      {/* Image Section with Overlay */}
      <Box sx={{ position: 'relative' }}>
        <CardMedia
          component="img"
          height="300"
          image={property.imageUrl ? `${process.env.REACT_APP_S3_URL}/${property.imageUrl}` : '/default-property.jpg'}
          alt={property.name}
          sx={{
            objectFit: 'cover',
            objectPosition: 'center',
            backgroundColor: 'grey.100'
          }}
        />
        
        {/* Property Info Overlay */}
        <Box
          sx={{ 
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            background: 'linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,0) 100%)',
            pt: 8,
            pb: 2,
            px: 3,
          }}
        >
          <Stack spacing={1}>
            <Typography variant="h5" sx={{ 
              color: '#fff',
              fontWeight: 700,
              textShadow: '0 2px 4px rgba(0,0,0,0.2)'
            }}>
              {property.name}
            </Typography>
            
            <Typography variant="body1" sx={{ 
              color: 'rgba(255,255,255,0.9)',
              textShadow: '0 1px 2px rgba(0,0,0,0.2)'
            }}>
              {property.address}
            </Typography>

            <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
              <Button
                size="small"
                startIcon={<EditIcon />}
                onClick={onEdit}
                sx={{ 
                  height: 28,
                  borderRadius: 2,
                  textTransform: 'none',
                  fontWeight: 600,
                  px: 2,
                  color: '#fff',
                  borderColor: 'rgba(255,255,255,0.5)',
                  backgroundColor: 'rgba(25,118,210,0.2)',
                  '&:hover': {
                    borderColor: '#fff',
                    backgroundColor: 'rgba(25,118,210,0.3)',
                  }
                }}
                variant="outlined"
              >
                Edit
              </Button>
              <Button
                size="small"
                startIcon={<DeleteIcon />}
                onClick={onDelete}
                sx={{ 
                  height: 28,
                  borderRadius: 2,
                  textTransform: 'none',
                  fontWeight: 600,
                  px: 2,
                  color: '#fff',
                  borderColor: 'rgba(255,255,255,0.5)',
                  backgroundColor: 'rgba(211,47,47,0.2)',
                  '&:hover': {
                    borderColor: '#fff',
                    backgroundColor: 'rgba(211,47,47,0.3)',
                  }
                }}
                variant="outlined"
              >
                Delete
              </Button>
            </Box>
          </Stack>
        </Box>
      </Box>

      {/* Summary Section */}
      <CardContent sx={{ p: 3 }}>
        <List disablePadding>
          {summaryItems.map((item, index) => (
            <React.Fragment key={item.label}>
              <ListItem 
                disableGutters
                sx={{ 
                  px: 0,
                  py: 1.5
                }}
              >
                <Box sx={{ width: '100%' }}>
                  <Typography 
                    variant="body2"
                    sx={{ 
                      color: 'text.secondary',
                      fontSize: '0.875rem',
                      mb: 0.5
                    }}
                  >
                    {item.label}
                  </Typography>
                  <Typography 
                    variant="body1"
                    sx={{ 
                      color: 'text.primary',
                      fontWeight: 600
                    }}
                  >
                    {item.value}
                  </Typography>
                </Box>
              </ListItem>
              {index < summaryItems.length - 1 && (
                <Divider sx={{ my: 0.5 }} />
              )}
            </React.Fragment>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default PropertySummary; 