import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  List,
  ListItem,
  ListItemText,
  Divider,
  Chip,
  alpha,
  useTheme
} from '@mui/material';
import { Build } from '@mui/icons-material';
import axios from '../../../utils/axios';

const MaintenanceRequests = ({ resident }) => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const response = await axios.get(`/api/maintenance/all`);
        // Filter requests for this resident
        const residentRequests = response.data.filter(
          request => request.resident._id === resident._id
        );
        setRequests(residentRequests);
        setLoading(false);
      } catch (err) {
        setError(err.response?.data?.message || 'Failed to fetch maintenance requests');
        setLoading(false);
      }
    };

    fetchRequests();
  }, [resident._id]);

  const getPriorityColor = (priority) => {
    switch (priority) {
      case 'Emergency':
        return 'error';
      case 'High':
        return 'warning';
      case 'Medium':
        return 'info';
      case 'Low':
        return 'success';
      default:
        return 'default';
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Completed':
        return 'success';
      case 'In Progress':
        return 'warning';
      case 'Pending':
        return 'info';
      default:
        return 'default';
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <Card
      elevation={0}
      sx={{ 
        height: '100%',
        background: `linear-gradient(45deg, ${alpha(theme.palette.primary.main, 0.02)}, ${alpha(theme.palette.primary.light, 0.05)})`,
        borderRadius: 1,
        border: '1px solid',
        borderColor: 'divider'
      }}
    >
      <Box
        sx={{
          background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
          p: 2,
          borderRadius: '4px 4px 0 0',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Build sx={{ color: 'white' }} />
          <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 600 }}>
            Maintenance Requests
          </Typography>
        </Box>
      </Box>

      <Box sx={{ p: 2 }}>
        {loading ? (
          <Typography color="text.secondary">Loading...</Typography>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : requests.length === 0 ? (
          <Typography color="text.secondary">No maintenance requests found</Typography>
        ) : (
          <List sx={{ width: '100%', bgcolor: 'transparent' }}>
            {requests.map((request, index) => (
              <React.Fragment key={request._id}>
                <ListItem
                  alignItems="flex-start"
                  sx={{ 
                    px: 0,
                    '&:hover': { 
                      bgcolor: alpha(theme.palette.info.main, 0.05),
                      borderRadius: 1
                    }
                  }}
                >
                  <ListItemText
                    primary={
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
                        <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                          {request.title}
                        </Typography>
                        <Chip 
                          label={request.priority} 
                          size="small" 
                          color={getPriorityColor(request.priority)}
                          sx={{ ml: 'auto' }}
                        />
                        <Chip 
                          label={request.status} 
                          size="small" 
                          color={getStatusColor(request.status)}
                        />
                      </Box>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          color="text.primary"
                          sx={{ display: 'block', mt: 0.5 }}
                        >
                          {request.description}
                        </Typography>
                        <Typography
                          component="span"
                          variant="caption"
                          color="text.secondary"
                          sx={{ display: 'block', mt: 0.5 }}
                        >
                          Submitted on {formatDate(request.createdAt)}
                          {request.completedAt && ` • Completed on ${formatDate(request.completedAt)}`}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                {index < requests.length - 1 && <Divider component="li" />}
              </React.Fragment>
            ))}
          </List>
        )}
      </Box>
    </Card>
  );
};

export default MaintenanceRequests; 