import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Stack,
  Paper,
  Divider,
  Alert,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListSubheader,
} from '@mui/material';
import {
  DragIndicator as DragIndicatorIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  ArrowBack as ArrowBackIcon,
} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from '../../utils/axios';

const fieldTypes = [
  { value: 'header', label: 'Header', category: 'layout' },
  { value: 'paragraph', label: 'Paragraph', category: 'layout' },
  { value: 'divider', label: 'Divider', category: 'layout' },
  { value: 'text', label: 'Text', category: 'input' },
  { value: 'textarea', label: 'Text Area', category: 'input' },
  { value: 'number', label: 'Number', category: 'input' },
  { value: 'email', label: 'Email', category: 'input' },
  { value: 'phone', label: 'Phone', category: 'input' },
  { value: 'date', label: 'Date', category: 'input' },
  { value: 'time', label: 'Time', category: 'input' },
  { value: 'select', label: 'Dropdown', category: 'input' },
  { value: 'radio', label: 'Radio Buttons', category: 'input' },
  { value: 'checkbox', label: 'Checkboxes', category: 'input' },
  { value: 'file', label: 'File Upload', category: 'input' },
  { value: 'signature', label: 'Signature', category: 'input' },
];

const FormBuilder = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form, setForm] = useState({
    title: '',
    description: '',
    fields: [],
    status: 'draft'
  });
  const [loading, setLoading] = useState(id ? true : false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [showValidationDialog, setShowValidationDialog] = useState(false);
  const [validationField, setValidationField] = useState(null);
  const [validationConfig, setValidationConfig] = useState({
    min: '',
    max: '',
    pattern: '',
    message: ''
  });
  const [showStyleDialog, setShowStyleDialog] = useState(false);
  const [styleField, setStyleField] = useState(null);
  const [styleConfig, setStyleConfig] = useState({
    align: 'left',
    size: 'medium',
    bold: false
  });

  useEffect(() => {
    if (id) {
      fetchForm();
    }
  }, [id]);

  const fetchForm = async () => {
    try {
      setError(null);
      const response = await axios.get(`/api/forms/${id}`);
      setForm(response.data);
    } catch (error) {
      setError('Failed to load form. Please try again.');
      console.error('Error fetching form:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFieldChange = (index, field) => {
    const newFields = [...form.fields];
    const currentField = newFields[index];
    
    // If changing field type, set appropriate defaults
    if (field.type && field.type !== currentField.type) {
      if (['header', 'paragraph', 'divider'].includes(field.type)) {
        // For layout elements, remove input-specific fields and add content
        newFields[index] = {
          type: field.type,
          content: '',
          style: {
            align: 'left',
            size: 'medium',
            bold: false
          }
        };
      } else {
        // For input fields, remove layout-specific fields and add input fields
        newFields[index] = {
          type: field.type,
          label: '',
          placeholder: '',
          required: false,
          options: [],
          validation: {}
        };
      }
    } else {
      // For other changes, just update the specified field
      newFields[index] = { ...currentField, ...field };
    }
    
    setForm({ ...form, fields: newFields });
  };

  const addField = () => {
    setForm({
      ...form,
      fields: [
        ...form.fields,
        {
          type: 'text',
          label: '',
          placeholder: '',
          required: false,
          options: [],
          validation: {},
          style: {
            align: 'left',
            size: 'medium',
            bold: false
          }
        }
      ]
    });
  };

  const removeField = (index) => {
    const newFields = [...form.fields];
    newFields.splice(index, 1);
    setForm({ ...form, fields: newFields });
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const newFields = Array.from(form.fields);
    const [reorderedField] = newFields.splice(result.source.index, 1);
    newFields.splice(result.destination.index, 0, reorderedField);

    setForm({ ...form, fields: newFields });
  };

  const handleOptionsChange = (index, options) => {
    const newFields = [...form.fields];
    newFields[index] = { ...newFields[index], options };
    setForm({ ...form, fields: newFields });
  };

  const addOption = (fieldIndex) => {
    const newFields = [...form.fields];
    const currentOptions = newFields[fieldIndex].options || [];
    newFields[fieldIndex].options = [...currentOptions, ''];
    setForm({ ...form, fields: newFields });
  };

  const updateOption = (fieldIndex, optionIndex, value) => {
    const newFields = [...form.fields];
    newFields[fieldIndex].options[optionIndex] = value;
    setForm({ ...form, fields: newFields });
  };

  const removeOption = (fieldIndex, optionIndex) => {
    const newFields = [...form.fields];
    newFields[fieldIndex].options = newFields[fieldIndex].options.filter((_, index) => index !== optionIndex);
    setForm({ ...form, fields: newFields });
  };

  const openValidationDialog = (field, index) => {
    setValidationField({ ...field, index });
    setValidationConfig(field.validation || { min: '', max: '', pattern: '', message: '' });
    setShowValidationDialog(true);
  };

  const saveValidation = () => {
    const newFields = [...form.fields];
    newFields[validationField.index] = {
      ...newFields[validationField.index],
      validation: validationConfig
    };
    setForm({ ...form, fields: newFields });
    setShowValidationDialog(false);
  };

  const openStyleDialog = (field, index) => {
    setStyleField({ ...field, index });
    setStyleConfig(field.style || { align: 'left', size: 'medium', bold: false });
    setShowStyleDialog(true);
  };

  const saveStyle = () => {
    const newFields = [...form.fields];
    newFields[styleField.index] = {
      ...newFields[styleField.index],
      style: styleConfig
    };
    setForm({ ...form, fields: newFields });
    setShowStyleDialog(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSaving(true);
      setError(null);

      if (id) {
        await axios.put(`/api/forms/${id}`, form);
      } else {
        await axios.post('/api/forms', form);
      }

      navigate('/forms');
    } catch (error) {
      setError('Failed to save form. Please try again.');
      console.error('Error saving form:', error);
      setSaving(false);
    }
  };

  const renderField = (field, index, provided) => {
    return (
      <Card
        ref={provided.innerRef}
        {...provided.draggableProps}
        elevation={0}
        sx={{
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 1
        }}
      >
        <CardContent>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Box
              {...provided.dragHandleProps}
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'text.secondary'
              }}
            >
              <DragIndicatorIcon />
            </Box>

            <Box sx={{ flex: 1 }}>
              <Stack spacing={2}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <FormControl fullWidth disabled={saving}>
                    <InputLabel>Field Type</InputLabel>
                    <Select
                      value={field.type}
                      onChange={(e) =>
                        handleFieldChange(index, { type: e.target.value })
                      }
                      label="Field Type"
                    >
                      <ListSubheader>Layout Elements</ListSubheader>
                      {fieldTypes.filter(t => t.category === 'layout').map((type) => (
                        <MenuItem key={type.value} value={type.value}>
                          {type.label}
                        </MenuItem>
                      ))}
                      <ListSubheader>Input Fields</ListSubheader>
                      {fieldTypes.filter(t => t.category === 'input').map((type) => (
                        <MenuItem key={type.value} value={type.value}>
                          {type.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {['header', 'paragraph'].includes(field.type) ? (
                    <TextField
                      fullWidth
                      label="Content"
                      value={field.content || ''}
                      onChange={(e) =>
                        handleFieldChange(index, { content: e.target.value })
                      }
                      multiline={field.type === 'paragraph'}
                      rows={field.type === 'paragraph' ? 4 : 1}
                      required
                      disabled={saving}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      label="Label"
                      value={field.label}
                      onChange={(e) =>
                        handleFieldChange(index, { label: e.target.value })
                      }
                      required
                      disabled={saving}
                    />
                  )}
                </Box>

                {['header', 'paragraph'].includes(field.type) && (
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => openStyleDialog(field, index)}
                      disabled={saving}
                      sx={{
                        borderRadius: 1,
                        textTransform: 'none',
                        fontWeight: 500,
                        borderWidth: 1.5
                      }}
                    >
                      Style Options
                    </Button>
                  </Box>
                )}

                <Box sx={{ display: 'flex', gap: 2 }}>
                  <TextField
                    fullWidth
                    label="Placeholder"
                    value={field.placeholder || ''}
                    onChange={(e) =>
                      handleFieldChange(index, {
                        placeholder: e.target.value
                      })
                    }
                    disabled={saving}
                  />

                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Button
                      variant="outlined"
                      onClick={() => openValidationDialog(field, index)}
                      disabled={saving}
                      sx={{
                        borderRadius: 1,
                        textTransform: 'none',
                        fontWeight: 500,
                        borderWidth: 1.5
                      }}
                    >
                      Validation
                    </Button>

                    <IconButton
                      onClick={() => removeField(index)}
                      disabled={saving}
                      sx={{ 
                        border: '1.5px solid',
                        borderColor: 'divider',
                        borderRadius: 1,
                        '&:hover': {
                          borderColor: 'error.main',
                          bgcolor: 'error.lighter',
                          '& .MuiSvgIcon-root': {
                            color: 'error.main'
                          }
                        }
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>

                {['select', 'radio', 'checkbox'].includes(field.type) && (
                  <Box sx={{ width: '100%' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                      <Typography variant="subtitle2">Options</Typography>
                      <Button
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={() => addOption(index)}
                        disabled={saving}
                        sx={{
                          borderRadius: 1,
                          textTransform: 'none',
                          fontWeight: 500
                        }}
                      >
                        Add Option
                      </Button>
                    </Box>
                    <Stack spacing={1}>
                      {(field.options || []).map((option, optionIndex) => (
                        <Box
                          key={optionIndex}
                          sx={{
                            display: 'flex',
                            gap: 1,
                            alignItems: 'center'
                          }}
                        >
                          <TextField
                            fullWidth
                            size="small"
                            value={option}
                            onChange={(e) => updateOption(index, optionIndex, e.target.value)}
                            placeholder={`Option ${optionIndex + 1}`}
                            disabled={saving}
                          />
                          <IconButton
                            size="small"
                            onClick={() => removeOption(index, optionIndex)}
                            disabled={saving}
                            sx={{ 
                              border: '1px solid',
                              borderColor: 'divider',
                              borderRadius: 1,
                              p: 1,
                              '&:hover': {
                                borderColor: 'error.main',
                                bgcolor: 'error.lighter',
                                '& .MuiSvgIcon-root': {
                                  color: 'error.main'
                                }
                              }
                            }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      ))}
                      {(field.options || []).length === 0 && (
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ 
                            textAlign: 'center',
                            py: 2,
                            bgcolor: '#f5f6f8',
                            borderRadius: 1
                          }}
                        >
                          No options added yet. Click "Add Option" to begin.
                        </Typography>
                      )}
                    </Stack>
                  </Box>
                )}

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={field.required || false}
                      onChange={(e) =>
                        handleFieldChange(index, {
                          required: e.target.checked
                        })
                      }
                      disabled={saving}
                    />
                  }
                  label="Required field"
                />
              </Stack>
            </Box>
          </Box>
        </CardContent>
      </Card>
    );
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      {error && (
        <Alert 
          severity="error" 
          sx={{ mb: 3 }}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => setError(null)}
            >
              Dismiss
            </Button>
          }
        >
          {error}
        </Alert>
      )}

      <Paper
        elevation={0}
        sx={{
          mb: 3,
          p: 2.5,
          backgroundColor: '#ffffff',
          borderRadius: 1,
          border: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'stretch', sm: 'center' },
          gap: 2,
          mb: 2
        }}>
          <Box sx={{ flex: 1 }}>
            <Typography variant="h5" sx={{ fontWeight: 600, color: 'primary.main', mb: 0.25 }}>
              {id ? 'Edit Form' : 'Create Form'}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
              {id ? 'Modify your form structure and fields' : 'Design a new form with custom fields'}
            </Typography>
          </Box>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate('/forms')}
              sx={{
                borderRadius: 1,
                textTransform: 'none',
                fontWeight: 500,
                borderWidth: 1.5
              }}
            >
              Back to Forms
            </Button>
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={handleSubmit}
              disabled={saving}
              sx={{
                borderRadius: 1,
                textTransform: 'none',
                fontWeight: 500
              }}
            >
              {saving ? <CircularProgress size={24} /> : 'Save Form'}
            </Button>
          </Stack>
        </Box>
      </Paper>

      <form onSubmit={handleSubmit}>
        <Paper
          elevation={0}
          sx={{
            mb: 3,
            p: 2.5,
            backgroundColor: '#ffffff',
            borderRadius: 1,
            border: '1px solid',
            borderColor: 'divider',
          }}
        >
          <Stack spacing={3}>
            <TextField
              fullWidth
              label="Form Title"
              value={form.title}
              onChange={(e) => setForm({ ...form, title: e.target.value })}
              required
              disabled={saving}
            />

            <TextField
              fullWidth
              label="Description"
              value={form.description}
              onChange={(e) => setForm({ ...form, description: e.target.value })}
              multiline
              rows={3}
              disabled={saving}
            />

            <FormControl fullWidth disabled={saving}>
              <InputLabel>Status</InputLabel>
              <Select
                value={form.status}
                onChange={(e) => setForm({ ...form, status: e.target.value })}
                label="Status"
              >
                <MenuItem value="draft">Draft</MenuItem>
                <MenuItem value="published">Published</MenuItem>
                <MenuItem value="archived">Archived</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </Paper>

        <Paper
          elevation={0}
          sx={{
            mb: 3,
            backgroundColor: '#ffffff',
            borderRadius: 1,
            border: '1px solid',
            borderColor: 'divider',
          }}
        >
          <Box sx={{ p: 2.5, borderBottom: '1px solid', borderColor: 'divider' }}>
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              Form Fields
            </Typography>
          </Box>

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="fields">
              {(provided) => (
                <Box
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  sx={{ p: 2.5 }}
                >
                  <Stack spacing={2}>
                    {form.fields.map((field, index) => (
                      <Draggable
                        key={index}
                        draggableId={`field-${index}`}
                        index={index}
                      >
                        {(provided) => renderField(field, index, provided)}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Stack>
                </Box>
              )}
            </Droppable>
          </DragDropContext>

          <Divider />

          <Box sx={{ p: 2.5 }}>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={addField}
              disabled={saving}
              sx={{
                borderRadius: 1,
                textTransform: 'none',
                fontWeight: 500,
                borderWidth: 1.5
              }}
            >
              Add Field
            </Button>
          </Box>
        </Paper>
      </form>

      <Dialog
        open={showValidationDialog}
        onClose={() => setShowValidationDialog(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 1,
            boxShadow: '0 8px 32px -4px rgba(0, 0, 0, 0.1)'
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid',
          borderColor: 'divider',
          bgcolor: '#f5f6f8',
          py: 2
        }}>
          Field Validation
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            {['number', 'date', 'time'].includes(validationField?.type) && (
              <>
                <TextField
                  fullWidth
                  label="Minimum Value"
                  value={validationConfig.min}
                  onChange={(e) =>
                    setValidationConfig({ ...validationConfig, min: e.target.value })
                  }
                  type="number"
                />
                <TextField
                  fullWidth
                  label="Maximum Value"
                  value={validationConfig.max}
                  onChange={(e) =>
                    setValidationConfig({ ...validationConfig, max: e.target.value })
                  }
                  type="number"
                />
              </>
            )}

            {['text', 'email', 'phone'].includes(validationField?.type) && (
              <TextField
                fullWidth
                label="Pattern (RegEx)"
                value={validationConfig.pattern}
                onChange={(e) =>
                  setValidationConfig({ ...validationConfig, pattern: e.target.value })
                }
              />
            )}

            <TextField
              fullWidth
              label="Validation Message"
              value={validationConfig.message}
              onChange={(e) =>
                setValidationConfig({ ...validationConfig, message: e.target.value })
              }
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 2.5, borderTop: '1px solid', borderColor: 'divider' }}>
          <Button
            onClick={() => setShowValidationDialog(false)}
            sx={{
              borderRadius: 1,
              textTransform: 'none',
              fontWeight: 500,
              color: 'text.secondary'
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={saveValidation}
            variant="contained"
            sx={{
              borderRadius: 1,
              textTransform: 'none',
              fontWeight: 500,
              px: 3
            }}
          >
            Save Validation
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showStyleDialog}
        onClose={() => setShowStyleDialog(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 1,
            boxShadow: '0 8px 32px -4px rgba(0, 0, 0, 0.1)'
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid',
          borderColor: 'divider',
          bgcolor: '#f5f6f8',
          py: 2
        }}>
          Style Options
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Alignment</InputLabel>
              <Select
                value={styleConfig.align}
                onChange={(e) => setStyleConfig({ ...styleConfig, align: e.target.value })}
                label="Alignment"
              >
                <MenuItem value="left">Left</MenuItem>
                <MenuItem value="center">Center</MenuItem>
                <MenuItem value="right">Right</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>Size</InputLabel>
              <Select
                value={styleConfig.size}
                onChange={(e) => setStyleConfig({ ...styleConfig, size: e.target.value })}
                label="Size"
              >
                <MenuItem value="small">Small</MenuItem>
                <MenuItem value="medium">Medium</MenuItem>
                <MenuItem value="large">Large</MenuItem>
              </Select>
            </FormControl>

            <FormControlLabel
              control={
                <Checkbox
                  checked={styleConfig.bold}
                  onChange={(e) => setStyleConfig({ ...styleConfig, bold: e.target.checked })}
                />
              }
              label="Bold Text"
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 2.5, borderTop: '1px solid', borderColor: 'divider' }}>
          <Button
            onClick={() => setShowStyleDialog(false)}
            sx={{
              borderRadius: 1,
              textTransform: 'none',
              fontWeight: 500,
              color: 'text.secondary'
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={saveStyle}
            variant="contained"
            sx={{
              borderRadius: 1,
              textTransform: 'none',
              fontWeight: 500,
              px: 3
            }}
          >
            Save Style
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default FormBuilder; 