import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  InputAdornment,
  Alert,
  CircularProgress,
  alpha,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  TableFooter
} from '@mui/material';
import { AttachMoney, CheckCircle, Cancel, Warning } from '@mui/icons-material';
import axios from '../../../utils/axios';
import EmailIcon from '@mui/icons-material/Email';
import SmsIcon from '@mui/icons-material/Sms';
import EmailDialog from '../list/dialogs/EmailDialog';
import SmsDialog from '../list/dialogs/SmsDialog';

const RentSettingsCard = ({ resident, setError: setParentError }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [rentSettings, setRentSettings] = useState({
    customAmount: null,
    customDueDate: null
  });
  const [defaultRentSettings, setDefaultRentSettings] = useState({
    defaultAmount: 0,
    defaultDueDate: 1
  });
  const [rentHistory, setRentHistory] = useState([]);
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [smsDialogOpen, setSmsDialogOpen] = useState(false);
  const [appName, setAppName] = useState('');

  useEffect(() => {
    // First fetch rent settings, then fetch rent history
    const loadData = async () => {
      await fetchRentSettings();
      await fetchRentHistory();
    };
    loadData();
  }, [resident._id]);

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount); // Amount is already in dollars
  };

  const fetchRentSettings = async () => {
    try {
      const [rentResponse, defaultResponse] = await Promise.all([
        axios.get(`/api/settings/rent/${resident._id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }),
        axios.get('/api/settings', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        })
      ]);

      const newRentSettings = {
        customAmount: rentResponse.data.isCustom ? rentResponse.data.rentAmount : null,
        customDueDate: rentResponse.data.isCustom ? rentResponse.data.dueDate : null
      };

      const newDefaultSettings = {
        defaultAmount: defaultResponse.data.rentSettings.defaultAmount,
        defaultDueDate: defaultResponse.data.rentSettings.defaultDueDate
      };

      setAppName(defaultResponse.data.appName);
      setRentSettings(newRentSettings);
      setDefaultRentSettings(newDefaultSettings);

      return { rentSettings: newRentSettings, defaultSettings: newDefaultSettings };
    } catch (error) {
      console.error('Error fetching rent settings:', error);
      setParentError('Failed to fetch rent settings');
      return null;
    }
  };

  const fetchRentHistory = async () => {
    try {
      // Get all payments for this resident using admin endpoint with filters
      const paymentsResponse = await axios.get('/api/payments/all', {
        params: {
          resident: resident._id,
          // Remove type filter to get all payment types
        },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });

      // Calculate months since move-in
      const moveInDate = new Date(resident.moveInDate);
      const currentDate = new Date();
      const months = [];
      
      // Start from the first day of move-in month
      let currentMonth = new Date(moveInDate.getFullYear(), moveInDate.getMonth(), 1);
      
      // Get the current rent settings
      const currentRentSettings = await fetchRentSettings();
      if (!currentRentSettings) return;

      const { rentSettings: currentCustomSettings, defaultSettings } = currentRentSettings;
      
      while (currentMonth <= currentDate) {
        // Filter payments for the current month, including all payment types
        const monthPayments = paymentsResponse.data.filter(payment => {
          const paymentDate = new Date(payment.createdAt);
          const isInMonth = paymentDate.getMonth() === currentMonth.getMonth() && 
                           paymentDate.getFullYear() === currentMonth.getFullYear();
          const isCompleted = payment.status === 'Completed';
          // Include all payment methods (stripe, cash, check, money_order)
          return isInMonth && isCompleted;
        });

        // Calculate total paid for the month from all payment types
        const totalPaid = monthPayments.reduce((sum, payment) => sum + payment.amount, 0);

        // Get the expected amount for this month
        const expectedAmount = currentCustomSettings.customAmount || defaultSettings.defaultAmount;

        // Add month to history if it's after or equal to move-in date
        if (currentMonth >= new Date(moveInDate.getFullYear(), moveInDate.getMonth(), 1)) {
          months.push({
            month: currentMonth.toLocaleString('default', { month: 'long' }),
            year: currentMonth.getFullYear(),
            paid: totalPaid >= expectedAmount,
            amountPaid: totalPaid,
            expectedAmount: expectedAmount,
            payments: monthPayments // Store the payments for reference if needed
          });
        }

        // Move to next month
        currentMonth.setMonth(currentMonth.getMonth() + 1);
      }

      setRentHistory(months.reverse()); // Most recent first
    } catch (error) {
      console.error('Error fetching rent history:', error);
      setParentError('Failed to fetch rent history');
    }
  };

  const handleRentSettingsChange = async () => {
    try {
      setLoading(true);
      setError('');
      setSuccess('');

      await axios.put(`/api/settings/rent/custom/${resident._id}`, rentSettings, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });

      // Refetch both settings and history to ensure everything is up to date
      await fetchRentSettings();
      await fetchRentHistory();

      setSuccess('Rent settings updated successfully');
    } catch (error) {
      setError(error.response?.data?.message || 'Error updating rent settings');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      elevation={0}
      sx={{
        height: '100%',
        background: `linear-gradient(45deg, ${alpha(theme.palette.primary.main, 0.02)}, ${alpha(theme.palette.primary.light, 0.05)})`,
        borderRadius: 1,
        border: '1px solid',
        borderColor: 'divider'
      }}
    >
      {/* Header */}
      <Box
        sx={{
          background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
          p: 2,
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}
      >
        <AttachMoney sx={{ color: 'white' }} />
        <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 600 }}>
          Rent Settings
        </Typography>
      </Box>

      <CardContent sx={{ p: 3 }}>
        <Grid container spacing={3}>
          {/* Default Settings Section */}
          <Grid item xs={12}>
            <Typography variant="subtitle2" sx={{ 
              mb: 1.5,
              color: 'text.secondary',
              fontWeight: 600,
              textTransform: 'uppercase',
              letterSpacing: '0.5px',
              fontSize: '0.75rem'
            }}>
              Default Settings
            </Typography>
            <Box sx={{ 
              p: 2, 
              bgcolor: 'background.paper', 
              borderRadius: 1,
              border: '1px solid',
              borderColor: 'divider'
            }}>
              <Typography variant="body2" color="text.secondary">
                Amount: <Typography component="span" color="primary.main" fontWeight={600}>
                  ${defaultRentSettings.defaultAmount}
                </Typography>
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Due Date: <Typography component="span" color="primary.main" fontWeight={600}>
                  Day {defaultRentSettings.defaultDueDate} of each month
                </Typography>
              </Typography>
            </Box>
          </Grid>

          {/* Custom Settings Section */}
          <Grid item xs={12}>
            <Typography variant="subtitle2" sx={{ 
              mb: 1.5,
              color: 'text.secondary',
              fontWeight: 600,
              textTransform: 'uppercase',
              letterSpacing: '0.5px',
              fontSize: '0.75rem'
            }}>
              Custom Settings
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Custom Rent Amount"
                  type="number"
                  value={rentSettings.customAmount || ''}
                  onChange={(e) => setRentSettings(prev => ({
                    ...prev,
                    customAmount: e.target.value ? Number(e.target.value) : null
                  }))}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  variant="outlined"
                  placeholder={`Default: $${defaultRentSettings.defaultAmount}`}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      bgcolor: 'background.paper'
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Custom Due Date"
                  type="number"
                  value={rentSettings.customDueDate || ''}
                  onChange={(e) => setRentSettings(prev => ({
                    ...prev,
                    customDueDate: e.target.value ? Number(e.target.value) : null
                  }))}
                  inputProps={{ min: 1, max: 31 }}
                  variant="outlined"
                  placeholder={`Default: Day ${defaultRentSettings.defaultDueDate}`}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      bgcolor: 'background.paper'
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                onClick={handleRentSettingsChange}
                disabled={loading}
                sx={{
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  '&:hover': {
                    boxShadow: '0 6px 16px rgba(0, 0, 0, 0.15)',
                  },
                }}
              >
                {loading ? <CircularProgress size={24} /> : 'Save Custom Settings'}
              </Button>
            </Box>
          </Grid>

          {/* Rent History Section */}
          <Grid item xs={12}>
            <Typography variant="subtitle2" sx={{ 
              mb: 1.5,
              color: 'text.secondary',
              fontWeight: 600,
              textTransform: 'uppercase',
              letterSpacing: '0.5px',
              fontSize: '0.75rem'
            }}>
              Rent History
            </Typography>
            <TableContainer component={Paper} sx={{ 
              borderRadius: 1,
              boxShadow: 'none',
              border: '1px solid',
              borderColor: 'divider'
            }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Month</TableCell>
                    <TableCell align="right">Expected Amount</TableCell>
                    <TableCell align="right">Amount Paid</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="right">Total Due</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rentHistory.map((month, index) => (
                    <TableRow key={`${month.month}-${month.year}`}>
                      <TableCell component="th" scope="row">
                        {month.month} {month.year}
                      </TableCell>
                      <TableCell align="right">{formatCurrency(month.expectedAmount)}</TableCell>
                      <TableCell align="right">{formatCurrency(month.amountPaid)}</TableCell>
                      <TableCell align="center">
                        {month.expectedAmount > 0 ? (
                          month.amountPaid >= month.expectedAmount ? (
                            <Chip
                              icon={<CheckCircle />}
                              label="Paid"
                              color="success"
                              size="small"
                              variant="outlined"
                            />
                          ) : month.amountPaid > 0 ? (
                            <Chip
                              icon={<Warning />}
                              label="Partial"
                              color="warning"
                              size="small"
                              variant="outlined"
                            />
                          ) : (
                            <Chip
                              icon={<Cancel />}
                              label="Unpaid"
                              color="error"
                              size="small"
                              variant="outlined"
                            />
                          )
                        ) : (
                          <Chip
                            label="No Rent Due"
                            color="default"
                            size="small"
                            variant="outlined"
                          />
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {formatCurrency(Math.max(0, month.expectedAmount - month.amountPaid))}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow sx={{ '& td': { fontWeight: 'bold', borderTop: '2px solid rgba(224, 224, 224, 1)' } }}>
                    <TableCell>Total</TableCell>
                    <TableCell align="right">
                      {formatCurrency(rentHistory.reduce((sum, month) => sum + month.expectedAmount, 0))}
                    </TableCell>
                    <TableCell align="right">
                      {formatCurrency(rentHistory.reduce((sum, month) => sum + month.amountPaid, 0))}
                    </TableCell>
                    <TableCell />
                    <TableCell align="right">
                      {formatCurrency(rentHistory.reduce((sum, month) => sum + Math.max(0, month.expectedAmount - month.amountPaid), 0))}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Grid>

          {/* Communication Buttons */}
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
              <Button
                variant="outlined"
                startIcon={<EmailIcon />}
                onClick={() => setEmailDialogOpen(true)}
                sx={{
                  borderRadius: 2,
                  textTransform: 'none',
                  fontWeight: 600,
                  py: 1,
                  px: 2,
                  borderWidth: 2,
                  '&:hover': {
                    borderWidth: 2,
                  }
                }}
              >
                Send Email Summary
              </Button>
              <Button
                variant="outlined"
                startIcon={<SmsIcon />}
                onClick={() => setSmsDialogOpen(true)}
                sx={{
                  borderRadius: 2,
                  textTransform: 'none',
                  fontWeight: 600,
                  py: 1,
                  px: 2,
                  borderWidth: 2,
                  '&:hover': {
                    borderWidth: 2,
                  }
                }}
              >
                Send SMS Summary
              </Button>
            </Box>
          </Grid>

          {success && (
            <Grid item xs={12}>
              <Alert 
                severity="success"
                sx={{ 
                  borderRadius: 1,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)'
                }}
              >
                {success}
              </Alert>
            </Grid>
          )}
        </Grid>
      </CardContent>

      {/* Email Dialog */}
      <EmailDialog
        open={emailDialogOpen}
        onClose={() => {
          setEmailDialogOpen(false);
          setError('');
        }}
        selectedResidents={[resident]}
        error={error}
        setError={setError}
        initialSubject={`${appName} - Rent Summary`}
        initialMessage={`Dear ${resident.firstName} ${resident.lastName},\n\n` +
          `This is your rent summary from ${appName}. Below are the details of your rent payments and balances:\n\n` +
          rentHistory.map(month => 
            `${month.month} ${month.year}:\n` +
            `Expected: ${formatCurrency(month.expectedAmount)}\n` +
            `Paid: ${formatCurrency(month.amountPaid)}\n` +
            `Due: ${formatCurrency(Math.max(0, month.expectedAmount - month.amountPaid))}\n`
          ).join('\n') +
          `\nSummary:\n` +
          `Total Expected: ${formatCurrency(rentHistory.reduce((sum, month) => sum + month.expectedAmount, 0))}\n` +
          `Total Paid: ${formatCurrency(rentHistory.reduce((sum, month) => sum + month.amountPaid, 0))}\n` +
          `Total Due: ${formatCurrency(rentHistory.reduce((sum, month) => sum + Math.max(0, month.expectedAmount - month.amountPaid), 0))}\n\n` +
          `If you have any questions about your rent summary, please contact staff.\n\n` +
          `Best regards,\n${appName} Team`
        }
        onEmailSent={() => {
          setSuccess('Email sent successfully');
          setEmailDialogOpen(false);
        }}
      />

      {/* SMS Dialog */}
      <SmsDialog
        open={smsDialogOpen}
        onClose={() => {
          setSmsDialogOpen(false);
          setError('');
        }}
        selectedResidents={[resident]}
        error={error}
        setError={setError}
        initialMessage={
          `${appName} Rent Summary for ${resident.firstName} ${resident.lastName}:\n\n` +
          `This is your current rent summary:\n` +
          `Total Expected: ${formatCurrency(rentHistory.reduce((sum, month) => sum + month.expectedAmount, 0))}\n` +
          `Total Paid: ${formatCurrency(rentHistory.reduce((sum, month) => sum + month.amountPaid, 0))}\n` +
          `Total Due: ${formatCurrency(rentHistory.reduce((sum, month) => sum + Math.max(0, month.expectedAmount - month.amountPaid), 0))}\n\n` +
          `Please check your email for a detailed breakdown or contact staff for questions.`
        }
        onSmsSent={() => {
          setSuccess('SMS sent successfully');
          setSmsDialogOpen(false);
        }}
      />
    </Card>
  );
};

export default RentSettingsCard; 