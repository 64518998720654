import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Stack,
  Divider,
  ToggleButtonGroup,
  ToggleButton,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  CircularProgress,
  Alert,
  Skeleton,
} from '@mui/material';
import {
  Check as CheckIcon,
  Close as CloseIcon,
  Person,
  Refresh,
  LocationOn,
  AccessTime,
  ViewModule as ViewModuleIcon,
  ViewList as ViewListIcon,
  Error as ErrorIcon,
  Event as EventIcon,
} from '@mui/icons-material';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://192.168.0.135:5001';
axios.defaults.baseURL = API_URL;

const OvernightList = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [note, setNote] = useState('');
  const [newStatus, setNewStatus] = useState('');
  const [rejectionReason, setRejectionReason] = useState('');
  const [viewMode, setViewMode] = useState('grid');
  const [currentTab, setCurrentTab] = useState('all');
  const [filterProperty, setFilterProperty] = useState('all');
  const [properties, setProperties] = useState([]);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [error, setError] = useState(null);

  const fetchRequests = async () => {
    try {
      setError(null);
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/overnight/all', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setRequests(response.data);
    } catch (error) {
      setError('Failed to fetch overnight requests. Please try again.');
      console.error('Error fetching overnight requests:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchProperties = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/properties', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setProperties(response.data);
    } catch (error) {
      console.error('Error fetching properties:', error);
    }
  };

  useEffect(() => {
    fetchRequests();
    fetchProperties();
  }, []);

  const handleStatusUpdate = async () => {
    try {
      setLoadingUpdate(true);
      setError(null);
      const token = localStorage.getItem('token');
      await axios.patch(`/api/overnight/${selectedRequest._id}/status`, {
        status: newStatus,
        note: note,
        rejectionReason: newStatus === 'Rejected' ? rejectionReason : undefined
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      await fetchRequests();
      setDialogOpen(false);
      setNote('');
      setNewStatus('');
      setRejectionReason('');
    } catch (error) {
      setError('Failed to update status. Please try again.');
      console.error('Error updating status:', error);
    } finally {
      setLoadingUpdate(false);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Pending':
        return 'warning';
      case 'Approved':
        return 'success';
      case 'Rejected':
        return 'error';
      default:
        return 'default';
    }
  };

  const getResidentName = (resident) => {
    if (!resident) return 'Unknown';
    return `${resident.firstName} ${resident.lastName}`;
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const filteredRequests = requests.filter(request => {
    if (currentTab !== 'all' && request.status.toLowerCase() !== currentTab) {
      return false;
    }
    if (filterProperty !== 'all' && request.property?._id !== filterProperty) {
      return false;
    }
    return true;
  });

  const OvernightCard = ({ request }) => (
    <Card
      elevation={0}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        backgroundColor: '#ffffff',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
          borderColor: 'primary.main',
        }
      }}
    >
      <CardContent sx={{ p: 2.5, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Person sx={{ fontSize: 18, color: 'text.secondary' }} />
            <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.main' }}>
              {getResidentName(request.resident)}
            </Typography>
          </Box>
          <Chip
            label={request.status}
            color={getStatusColor(request.status)}
            size="small"
            sx={{ fontWeight: 500, height: 24 }}
          />
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'text.secondary', mb: 2 }}>
          <LocationOn sx={{ fontSize: 18 }} />
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {request.property?.name} • {request.unit}
          </Typography>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
            Stay Duration:
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {formatDate(request.startDate)} - {formatDate(request.endDate)}
          </Typography>
        </Box>

        <Typography 
          variant="body2" 
          color="text.secondary" 
          sx={{ 
            mb: 2, 
            flex: 1,
            fontSize: '0.875rem',
            lineHeight: 1.6
          }}
        >
          Reason: {request.reason}
        </Typography>

        {request.status === 'Approved' && (
          <Box sx={{ mb: 2 }}>
            {request.departedAt?.at && (
              <Box sx={{ mb: 1 }}>
                <Typography variant="subtitle2" color="primary" gutterBottom>
                  Departed:
                </Typography>
                <Typography variant="body2">
                  {new Date(request.departedAt.at).toLocaleString()}
                </Typography>
              </Box>
            )}

            {request.returnedAt?.at && (
              <Box sx={{ mb: 1 }}>
                <Typography variant="subtitle2" color="success.main" gutterBottom>
                  Returned:
                </Typography>
                <Typography variant="body2">
                  {new Date(request.returnedAt.at).toLocaleString()}
                </Typography>
              </Box>
            )}

            {request.notes && request.notes.length > 0 && (
              <Box>
                <Typography variant="subtitle2" gutterBottom>
                  Latest Update:
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {request.notes[request.notes.length - 1].content}
                </Typography>
              </Box>
            )}
          </Box>
        )}

        {request.rejectionReason && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2" color="error" sx={{ fontWeight: 500 }}>
              Rejection Reason: {request.rejectionReason}
            </Typography>
          </Box>
        )}

        <Box sx={{ mt: 'auto' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'text.secondary', mb: 2 }}>
            <AccessTime sx={{ fontSize: 18 }} />
            <Typography variant="caption" sx={{ fontWeight: 500 }}>
              Requested: {formatDate(request.createdAt)}
            </Typography>
          </Box>

          <Button
            variant="outlined"
            fullWidth
            startIcon={request.status === 'Approved' ? <CheckIcon /> : <EventIcon />}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedRequest(request);
              setNewStatus(request.status);
              setDialogOpen(true);
            }}
            color={request.status === 'Approved' ? 'success' : 'primary'}
            sx={{
              borderRadius: 1,
              textTransform: 'none',
              fontWeight: 500,
              py: 0.75,
              borderWidth: 1.5
            }}
          >
            Update Status
          </Button>
        </Box>
      </CardContent>
    </Card>
  );

  const OvernightTable = ({ requests }) => (
    <TableContainer 
      component={Paper}
      elevation={0}
      sx={{ 
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        overflow: 'hidden',
        backgroundColor: '#ffffff'
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Resident</TableCell>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Property</TableCell>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Dates</TableCell>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Reason</TableCell>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Status</TableCell>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Departure/Return</TableCell>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {requests.map((request) => (
            <TableRow 
              key={request._id}
              sx={{ 
                '&:hover': {
                  bgcolor: '#f8f9fb'
                }
              }}
            >
              <TableCell sx={{ py: 1.5, color: 'primary.main', fontWeight: 500 }}>
                {getResidentName(request.resident)}
              </TableCell>
              <TableCell sx={{ py: 1.5 }}>{request.property?.name} • {request.unit}</TableCell>
              <TableCell sx={{ py: 1.5 }}>
                <Typography variant="body2">
                  {new Date(request.startDate).toLocaleDateString()} -
                </Typography>
                <Typography variant="body2">
                  {new Date(request.endDate).toLocaleDateString()}
                </Typography>
              </TableCell>
              <TableCell sx={{ py: 1.5 }}>
                <Typography variant="body2">
                  {request.reason}
                </Typography>
              </TableCell>
              <TableCell sx={{ py: 1.5 }}>
                <Chip
                  label={request.status}
                  color={getStatusColor(request.status)}
                  size="small"
                  sx={{ fontWeight: 500, height: 24 }}
                />
              </TableCell>
              <TableCell sx={{ py: 1.5 }}>
                {request.status === 'Approved' && (
                  <Box>
                    {request.departedAt?.at && (
                      <Box sx={{ mb: 0.5 }}>
                        <Typography variant="subtitle2" color="primary" gutterBottom>
                          Departed:
                        </Typography>
                        <Typography variant="body2">
                          {new Date(request.departedAt.at).toLocaleString()}
                        </Typography>
                      </Box>
                    )}

                    {request.returnedAt?.at && (
                      <Box sx={{ mb: 0.5 }}>
                        <Typography variant="subtitle2" color="success.main" gutterBottom>
                          Returned:
                        </Typography>
                        <Typography variant="body2">
                          {new Date(request.returnedAt.at).toLocaleString()}
                        </Typography>
                      </Box>
                    )}

                    {request.notes && request.notes.length > 0 && (
                      <Box>
                        <Typography variant="subtitle2" gutterBottom>
                          Latest Update:
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {request.notes[request.notes.length - 1].content}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                )}
              </TableCell>
              <TableCell sx={{ py: 1.5 }}>
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={request.status === 'Approved' ? <CheckIcon /> : <EventIcon />}
                  onClick={() => {
                    setSelectedRequest(request);
                    setNewStatus(request.status);
                    setDialogOpen(true);
                  }}
                  color={request.status === 'Approved' ? 'success' : 'primary'}
                  sx={{
                    borderRadius: 1,
                    textTransform: 'none',
                    fontWeight: 500,
                    borderWidth: 1.5,
                    py: 0.5
                  }}
                >
                  Update
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const CardSkeleton = () => (
    <Card
      elevation={0}
      sx={{
        height: '100%',
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
      }}
    >
      <CardContent sx={{ p: 2.5 }}>
        <Stack spacing={2}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Skeleton width="60%" height={24} />
            <Skeleton width={80} height={24} />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Skeleton width={20} height={20} variant="circular" />
            <Skeleton width="40%" height={20} />
          </Box>
          <Skeleton width="100%" height={60} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Skeleton width={100} height={24} />
            <Skeleton width={120} height={24} />
          </Box>
          <Skeleton width="100%" height={36} />
        </Stack>
      </CardContent>
    </Card>
  );

  const TableSkeleton = () => (
    <TableContainer 
      component={Paper}
      elevation={0}
      sx={{ 
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        overflow: 'hidden'
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {[...Array(7)].map((_, index) => (
              <TableCell key={index} sx={{ bgcolor: '#f5f6f8' }}>
                <Skeleton width={100} height={24} />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(5)].map((_, index) => (
            <TableRow key={index}>
              {[...Array(7)].map((_, cellIndex) => (
                <TableCell key={cellIndex}>
                  <Skeleton width={cellIndex === 6 ? 120 : 100} height={24} />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Container maxWidth="lg" sx={{ py: 3 }}>
      {error && (
        <Alert 
          severity="error" 
          sx={{ 
            mb: 3,
            borderRadius: 1,
            '& .MuiAlert-icon': {
              color: 'error.main'
            }
          }}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => setError(null)}
              sx={{ 
                textTransform: 'none',
                fontWeight: 500
              }}
            >
              Dismiss
            </Button>
          }
        >
          {error}
        </Alert>
      )}

      <Paper
        elevation={0}
        sx={{
          mb: 3,
          p: { xs: 2, sm: 2.5 },
          backgroundColor: '#ffffff',
          borderRadius: 1,
          border: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: { xs: 'stretch', md: 'center' },
          gap: { xs: 2, md: 0 },
          mb: { xs: 2, md: 2.5 }
        }}>
          <Box sx={{ flex: 1 }}>
            <Typography 
              variant="h5" 
              component="h1" 
              sx={{ 
                fontWeight: 600, 
                color: 'primary.main',
                mb: { xs: 0.5, md: 0.25 }
              }}
            >
              Overnight Requests
            </Typography>
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{ 
                display: { xs: 'none', sm: 'block' },
                fontWeight: 500
              }}
            >
              Manage and track all overnight guest requests across properties
            </Typography>
          </Box>
          <Stack 
            direction={{ xs: 'column', sm: 'row' }} 
            spacing={2}
            sx={{ 
              width: { xs: '100%', md: 'auto' },
              alignItems: { xs: 'stretch', sm: 'center' }
            }}
          >
            <Button
              startIcon={<Refresh />}
              onClick={fetchRequests}
              variant="outlined"
              sx={{
                borderRadius: 1,
                textTransform: 'none',
                fontWeight: 500,
                borderWidth: 1.5,
                minWidth: { sm: 120 },
                order: { xs: 2, sm: 1 },
                py: 1
              }}
            >
              Refresh
            </Button>
            <ToggleButtonGroup
              value={viewMode}
              exclusive
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  setViewMode(newValue);
                }
              }}
              size="small"
              aria-label="view mode"
              sx={{
                display: 'flex',
                order: { xs: 1, sm: 2 },
                '& .MuiToggleButtonGroup-grouped': {
                  flex: { xs: 1, sm: 'none' },
                  px: { xs: 3, sm: 2 },
                  py: 1,
                  borderRadius: '4px !important',
                  borderColor: 'divider',
                  '&.Mui-selected': {
                    bgcolor: 'primary.main',
                    color: 'white',
                    '&:hover': {
                      bgcolor: 'primary.dark',
                    },
                  },
                },
              }}
            >
              <ToggleButton value="grid" aria-label="grid view">
                <ViewModuleIcon sx={{ mr: 1 }} />
                Grid
              </ToggleButton>
              <ToggleButton value="list" aria-label="list view">
                <ViewListIcon sx={{ mr: 1 }} />
                List
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Box>

        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}>
          <Box sx={{ 
            borderRadius: 1,
            bgcolor: '#f5f6f8',
            border: '1px solid',
            borderColor: 'divider',
            overflow: 'hidden'
          }}>
            <Tabs
              value={currentTab}
              onChange={(e, newValue) => setCurrentTab(newValue)}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              sx={{
                minHeight: 44,
                px: { xs: 1, sm: 2 },
                '& .MuiTab-root': {
                  textTransform: 'none',
                  fontWeight: 500,
                  fontSize: '0.875rem',
                  minHeight: 44,
                  minWidth: { xs: 'auto', sm: 100 },
                  px: { xs: 2, sm: 3 }
                }
              }}
            >
              <Tab label="All Requests" value="all" />
              <Tab 
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    Pending
                    <Chip 
                      label={requests.filter(r => r.status.toLowerCase() === 'pending').length} 
                      size="small"
                      color="warning"
                      sx={{ 
                        height: 20,
                        '& .MuiChip-label': { px: 1, fontSize: '0.75rem' }
                      }}
                    />
                  </Box>
                } 
                value="pending" 
              />
              <Tab 
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    Approved
                    <Chip 
                      label={requests.filter(r => r.status.toLowerCase() === 'approved').length}
                      size="small"
                      color="success"
                      sx={{ 
                        height: 20,
                        '& .MuiChip-label': { px: 1, fontSize: '0.75rem' }
                      }}
                    />
                  </Box>
                } 
                value="approved" 
              />
              <Tab 
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    Rejected
                    <Chip 
                      label={requests.filter(r => r.status.toLowerCase() === 'rejected').length}
                      size="small"
                      color="error"
                      sx={{ 
                        height: 20,
                        '& .MuiChip-label': { px: 1, fontSize: '0.75rem' }
                      }}
                    />
                  </Box>
                } 
                value="rejected" 
              />
            </Tabs>
          </Box>

          <FormControl 
            size="small" 
            sx={{ 
              minWidth: { xs: '100%', sm: 200 },
              alignSelf: 'flex-start'
            }}
          >
            <InputLabel>Property Filter</InputLabel>
            <Select
              value={filterProperty}
              onChange={(e) => setFilterProperty(e.target.value)}
              label="Property Filter"
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'divider',
                },
              }}
            >
              <MenuItem value="all">All Properties</MenuItem>
              {properties.map((property) => (
                <MenuItem key={property._id} value={property._id}>
                  {property.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Paper>

      {loading ? (
        viewMode === 'grid' ? (
          <Grid container spacing={2}>
            {[...Array(6)].map((_, index) => (
              <Grid item key={index} xs={12} sm={6} lg={4}>
                <CardSkeleton />
              </Grid>
            ))}
          </Grid>
        ) : (
          <TableSkeleton />
        )
      ) : filteredRequests.length === 0 ? (
        <Paper
          elevation={0}
          sx={{
            p: 4,
            textAlign: 'center',
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 1,
            bgcolor: '#ffffff'
          }}
        >
          <ErrorIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
          <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
            No Overnight Requests Found
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            {currentTab === 'all' && filterProperty === 'all'
              ? 'There are no overnight requests in the system.'
              : 'No requests match your current filters.'}
          </Typography>
          {(currentTab !== 'all' || filterProperty !== 'all') && (
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                setCurrentTab('all');
                setFilterProperty('all');
              }}
              sx={{
                borderRadius: 1,
                textTransform: 'none',
                fontWeight: 500,
                borderWidth: 1.5
              }}
            >
              Clear All Filters
            </Button>
          )}
        </Paper>
      ) : (
        viewMode === 'grid' ? (
          <Grid container spacing={2}>
            {filteredRequests.map((request) => (
              <Grid item key={request._id} xs={12} sm={6} lg={4}>
                <OvernightCard request={request} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <OvernightTable requests={filteredRequests} />
        )
      )}

      {/* Status Update Dialog */}
      <Dialog 
        open={dialogOpen} 
        onClose={() => !loadingUpdate && setDialogOpen(false)} 
        maxWidth="sm" 
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 1,
            boxShadow: '0 8px 32px -4px rgba(0, 0, 0, 0.1)'
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid',
          borderColor: 'divider',
          bgcolor: '#f5f6f8',
          py: 2
        }}>
          Update Overnight Request Status
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            {error && (
              <Alert 
                severity="error"
                sx={{ 
                  borderRadius: 1,
                  '& .MuiAlert-icon': {
                    color: 'error.main'
                  }
                }}
              >
                {error}
              </Alert>
            )}
            <FormControl fullWidth disabled={loadingUpdate}>
              <InputLabel>Status</InputLabel>
              <Select
                value={newStatus}
                onChange={(e) => {
                  setNewStatus(e.target.value);
                  if (e.target.value !== 'Rejected') {
                    setRejectionReason('');
                  }
                }}
                label="Status"
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'divider',
                  },
                }}
              >
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Approved">Approved</MenuItem>
                <MenuItem value="Rejected">Rejected</MenuItem>
              </Select>
            </FormControl>

            {newStatus === 'Rejected' && (
              <TextField
                fullWidth
                label="Rejection Reason"
                value={rejectionReason}
                onChange={(e) => setRejectionReason(e.target.value)}
                required
                error={newStatus === 'Rejected' && !rejectionReason}
                helperText={newStatus === 'Rejected' && !rejectionReason ? 'Rejection reason is required' : ''}
                disabled={loadingUpdate}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'divider',
                  },
                }}
              />
            )}

            <TextField
              fullWidth
              label="Add a note"
              multiline
              rows={4}
              value={note}
              onChange={(e) => setNote(e.target.value)}
              disabled={loadingUpdate}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'divider',
                },
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 2.5, borderTop: '1px solid', borderColor: 'divider' }}>
          <Button 
            onClick={() => setDialogOpen(false)}
            disabled={loadingUpdate}
            sx={{
              borderRadius: 1,
              textTransform: 'none',
              fontWeight: 500,
              color: 'text.secondary'
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleStatusUpdate} 
            variant="contained" 
            color="primary"
            disabled={loadingUpdate || (newStatus === 'Rejected' && !rejectionReason)}
            sx={{
              borderRadius: 1,
              textTransform: 'none',
              fontWeight: 500,
              px: 3,
              minWidth: 100,
              position: 'relative'
            }}
          >
            {loadingUpdate ? (
              <CircularProgress
                size={24}
                sx={{
                  color: 'primary.light',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px'
                }}
              />
            ) : (
              'Update'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default OvernightList; 