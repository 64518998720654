import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Alert
} from '@mui/material';

const AssignmentDialog = ({
  open,
  onClose,
  onSubmit,
  properties,
  selectedProperty,
  selectedRoom,
  selectedBed,
  onPropertyChange,
  onRoomChange,
  onBedChange,
  error
}) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 1,
          boxShadow: '0 8px 32px -4px rgba(0, 0, 0, 0.1)'
        }
      }}
    >
      <DialogTitle sx={{ 
        borderBottom: '1px solid',
        borderColor: 'divider',
        bgcolor: '#f5f6f8',
        py: 2
      }}>
        Assign Housing
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3} sx={{ mt: 2 }}>
          {error && (
            <Alert 
              severity="error"
              sx={{ 
                borderRadius: 1,
                '& .MuiAlert-icon': {
                  color: 'error.main'
                }
              }}
            >
              {error}
            </Alert>
          )}
          <FormControl fullWidth>
            <InputLabel>Select Property</InputLabel>
            <Select
              value={selectedProperty}
              onChange={(e) => onPropertyChange(e.target.value)}
              label="Select Property"
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'divider',
                },
              }}
            >
              {properties.map((property) => (
                <MenuItem key={property._id} value={property._id}>
                  {property.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {selectedProperty && (
            <FormControl fullWidth>
              <InputLabel>Select Room</InputLabel>
              <Select
                value={selectedRoom}
                onChange={(e) => onRoomChange(e.target.value)}
                label="Select Room"
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'divider',
                  },
                }}
              >
                {properties
                  .find(p => p._id === selectedProperty)
                  ?.rooms.map((room) => (
                    <MenuItem key={room._id} value={room._id}>
                      {room.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}

          {selectedRoom && (
            <FormControl fullWidth>
              <InputLabel>Select Bed</InputLabel>
              <Select
                value={selectedBed}
                onChange={(e) => onBedChange(e.target.value)}
                label="Select Bed"
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'divider',
                  },
                }}
              >
                {properties
                  .find(p => p._id === selectedProperty)
                  ?.rooms.find(r => r._id === selectedRoom)
                  ?.beds.filter(bed => !bed.resident)
                  .map((bed) => (
                    <MenuItem key={bed._id} value={bed._id}>
                      {bed.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2, bgcolor: '#f8f9fb' }}>
        <Button 
          onClick={onClose}
          sx={{ 
            borderRadius: 1,
            textTransform: 'none',
            fontWeight: 500,
            px: 2
          }}
        >
          Cancel
        </Button>
        <Button 
          onClick={onSubmit} 
          variant="contained"
          sx={{ 
            borderRadius: 1,
            textTransform: 'none',
            fontWeight: 500,
            px: 2
          }}
        >
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignmentDialog; 