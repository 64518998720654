import React, { useState } from 'react';
import {
  Box,
  Typography,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Paper,
  Stack,
  Button,
  Checkbox,
  alpha
} from '@mui/material';
import { 
  Edit as EditIcon,
  Person,
  LocationOn,
  AccessTime,
  Male as MaleIcon,
  Female as FemaleIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  Refresh as RefreshIcon,
  Delete as DeleteIcon,
  Email as EmailIcon
} from '@mui/icons-material';
import { getInitials } from '../details/utils';
import ApprovalDialog from './dialogs/ApprovalDialog';
import UnrejectDialog from './dialogs/UnrejectDialog';
import DeleteDialog from './dialogs/DeleteDialog';
import EmailDialog from './dialogs/EmailDialog';
import axios from '../../../utils/axios';

const ResidentList = ({ residents, onResidentClick, showMoveOutColumn, onStatusChange }) => {
  const [selectedResidents, setSelectedResidents] = useState([]);
  const [selectedResident, setSelectedResident] = useState(null);
  const [approvalDialogOpen, setApprovalDialogOpen] = useState(false);
  const [unrejectDialogOpen, setUnrejectDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [unrejectData, setUnrejectData] = useState({ status: 'pending' });
  const [error, setError] = useState('');

  const getStatusColor = (status) => {
    switch (status) {
      case 'active':
        return {
          bg: 'success.lighter',
          color: 'success.dark',
          border: 'success.light'
        };
      case 'discharged':
      case 'rejected':
        return {
          bg: 'error.lighter',
          color: 'error.dark',
          border: 'error.light'
        };
      default:
        return {
          bg: 'warning.lighter',
          color: 'warning.dark',
          border: 'warning.light'
        };
    }
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedResidents(residents);
    } else {
      setSelectedResidents([]);
    }
  };

  const handleSelectOne = (event, resident) => {
    event.stopPropagation();
    const selectedIndex = selectedResidents.findIndex(r => r._id === resident._id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selectedResidents, resident];
    } else {
      newSelected = selectedResidents.filter(r => r._id !== resident._id);
    }

    setSelectedResidents(newSelected);
  };

  const isSelected = (id) => selectedResidents.findIndex(r => r._id === id) !== -1;

  const handleReject = async (residentId) => {
    try {
      await axios.put(`/api/auth/residents/${residentId}/approve`, {
        approved: false
      });
      onStatusChange();
    } catch (error) {
      console.error('Error rejecting resident:', error);
    }
  };

  const handleUnreject = async () => {
    try {
      await axios.put(`/api/auth/residents/${selectedResident._id}/unreject`, {
        status: unrejectData.status
      });
      setUnrejectDialogOpen(false);
      setSelectedResident(null);
      onStatusChange();
    } catch (error) {
      console.error('Error unrejecting resident:', error);
      setError(error.response?.data?.message || 'Failed to unreject resident');
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`/api/auth/residents/${selectedResident._id}`);
      setDeleteDialogOpen(false);
      setSelectedResident(null);
      onStatusChange();
    } catch (error) {
      console.error('Error deleting resident:', error);
      setError(error.response?.data?.message || 'Failed to delete resident');
    }
  };

  return (
    <>
      {selectedResidents.length > 0 && (
        <Box
          sx={{
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
            px: 2,
            py: 1,
            mb: 2,
            borderRadius: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
            {selectedResidents.length} selected
          </Typography>
          <Button
            variant="contained"
            startIcon={<EmailIcon />}
            onClick={() => setEmailDialogOpen(true)}
            sx={{
              borderRadius: 2,
              textTransform: 'none',
              fontWeight: 600,
              py: 1,
              px: 2,
              boxShadow: 'none',
              '&:hover': {
                boxShadow: 'none',
              }
            }}
          >
            Send Email
          </Button>
        </Box>
      )}

      <TableContainer 
        component={Paper}
        elevation={0}
        sx={{ 
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 2,
          overflow: 'hidden',
          backgroundColor: '#ffffff',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
        }}
      >
        <Table>
          <TableHead>
            <TableRow sx={{ 
              bgcolor: '#f8f9fb',
              '& th': { 
                fontWeight: 700,
                color: 'text.primary',
                fontSize: '0.875rem',
                letterSpacing: '0.01em',
                py: 2,
                borderBottom: '2px solid',
                borderColor: 'divider'
              }
            }}>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={selectedResidents.length > 0 && selectedResidents.length < residents.length}
                  checked={residents.length > 0 && selectedResidents.length === residents.length}
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell>Assignment</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Move-in Date</TableCell>
              {showMoveOutColumn && <TableCell>Move-out Date</TableCell>}
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {residents.map((resident) => {
              const statusColors = getStatusColor(resident.status);
              const isItemSelected = isSelected(resident._id);
              
              return (
                <TableRow 
                  key={resident._id}
                  hover
                  onClick={() => onResidentClick(resident._id)}
                  selected={isItemSelected}
                  sx={{ 
                    cursor: 'pointer',
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      bgcolor: '#f8f9fb'
                    },
                    '& td': {
                      py: 2,
                      borderColor: 'divider'
                    }
                  }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      onClick={(e) => handleSelectOne(e, resident)}
                    />
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Avatar 
                        src={resident.imageUrl ? `${process.env.REACT_APP_S3_URL}/${resident.imageUrl}` : undefined}
                        sx={{ 
                          width: 40,
                          height: 40,
                          bgcolor: 'primary.main',
                          fontWeight: 700,
                          fontSize: '1rem',
                          boxShadow: '0 4px 14px rgba(0, 0, 0, 0.12)'
                        }}
                      >
                        {getInitials(resident.firstName, resident.lastName)}
                      </Avatar>
                      <Typography sx={{ 
                        fontWeight: 600,
                        color: 'primary.main'
                      }}>
                        {resident.firstName} {resident.lastName}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Stack spacing={0.5}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Person sx={{ fontSize: 16, color: 'text.secondary' }} />
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>
                          {resident.email}
                        </Typography>
                      </Box>
                      {resident.phone && (
                        <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                          {resident.phone}
                        </Typography>
                      )}
                    </Stack>
                  </TableCell>
                  <TableCell>
                    {resident.assignment ? (
                      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                        <LocationOn sx={{ fontSize: 16, color: 'text.secondary', mt: 0.25 }} />
                        <Stack spacing={0.5}>
                          <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            {resident.assignment.property}
                          </Typography>
                          <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                            Room {resident.assignment.room} • Bed {resident.assignment.bed}
                          </Typography>
                        </Stack>
                      </Box>
                    ) : (
                      <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                        Not assigned
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {resident.gender ? (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {resident.gender === 'Male' ? (
                          <MaleIcon sx={{ fontSize: 18, color: '#1976D2' }} />
                        ) : (
                          <FemaleIcon sx={{ fontSize: 18, color: '#D81B60' }} />
                        )}
                        <Typography variant="body2" sx={{ 
                          fontWeight: 600,
                          color: resident.gender === 'Male' ? '#1976D2' : '#D81B60'
                        }}>
                          {resident.gender}
                        </Typography>
                      </Box>
                    ) : (
                      <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                        Not set
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <Chip 
                      label={resident.status.charAt(0).toUpperCase() + resident.status.slice(1)}
                      size="small"
                      sx={{ 
                        height: 24,
                        fontWeight: 600,
                        bgcolor: statusColors.bg,
                        color: statusColors.color,
                        border: '1px solid',
                        borderColor: statusColors.border,
                        '& .MuiChip-label': { 
                          px: 1.5,
                          fontSize: '0.75rem'
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <AccessTime sx={{ fontSize: 16, color: 'text.secondary' }} />
                      <Typography variant="body2" sx={{ fontWeight: 600 }}>
                        {resident.moveInDate ? formatDate(resident.moveInDate) : 'Not set'}
                      </Typography>
                    </Box>
                  </TableCell>
                  {showMoveOutColumn && (
                    <TableCell>
                      {resident.status === 'discharged' && resident.moveOutDate ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <AccessTime sx={{ fontSize: 16, color: 'text.secondary' }} />
                          <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            {formatDate(resident.moveOutDate)}
                          </Typography>
                        </Box>
                      ) : (
                        <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                          -
                        </Typography>
                      )}
                    </TableCell>
                  )}
                  <TableCell align="right">
                    {resident.status === 'pending' ? (
                      <Stack direction="row" spacing={1} justifyContent="flex-end">
                        <Button
                          variant="contained"
                          size="small"
                          color="success"
                          startIcon={<CheckIcon />}
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedResident(resident);
                            setApprovalDialogOpen(true);
                          }}
                          sx={{
                            borderRadius: 2,
                            textTransform: 'none',
                            fontWeight: 600,
                            py: 1,
                            px: 2,
                            boxShadow: 'none',
                            '&:hover': {
                              boxShadow: 'none',
                            }
                          }}
                        >
                          Approve
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          color="error"
                          startIcon={<CloseIcon />}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleReject(resident._id);
                          }}
                          sx={{
                            borderRadius: 2,
                            textTransform: 'none',
                            fontWeight: 600,
                            py: 1,
                            px: 2,
                            boxShadow: 'none',
                            '&:hover': {
                              boxShadow: 'none',
                            }
                          }}
                        >
                          Reject
                        </Button>
                      </Stack>
                    ) : resident.status === 'rejected' ? (
                      <Stack direction="row" spacing={1} justifyContent="flex-end">
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          startIcon={<RefreshIcon />}
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedResident(resident);
                            setUnrejectDialogOpen(true);
                          }}
                          sx={{
                            borderRadius: 2,
                            textTransform: 'none',
                            fontWeight: 600,
                            py: 1,
                            px: 2,
                            boxShadow: 'none',
                            '&:hover': {
                              boxShadow: 'none',
                            }
                          }}
                        >
                          Unreject
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          color="error"
                          startIcon={<DeleteIcon />}
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedResident(resident);
                            setDeleteDialogOpen(true);
                          }}
                          sx={{
                            borderRadius: 2,
                            textTransform: 'none',
                            fontWeight: 600,
                            py: 1,
                            px: 2,
                            boxShadow: 'none',
                            '&:hover': {
                              boxShadow: 'none',
                            }
                          }}
                        >
                          Delete
                        </Button>
                      </Stack>
                    ) : resident.status === 'discharged' ? (
                      <Stack direction="row" spacing={1} justifyContent="flex-end">
                        <Button
                          variant="outlined"
                          size="small"
                          startIcon={<EditIcon />}
                          onClick={(e) => {
                            e.stopPropagation();
                            onResidentClick(resident._id);
                          }}
                          sx={{
                            borderRadius: 2,
                            textTransform: 'none',
                            fontWeight: 600,
                            py: 1,
                            px: 2,
                            borderWidth: 2,
                            '&:hover': {
                              borderWidth: 2,
                            }
                          }}
                        >
                          View
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          color="error"
                          startIcon={<DeleteIcon />}
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedResident(resident);
                            setDeleteDialogOpen(true);
                          }}
                          sx={{
                            borderRadius: 2,
                            textTransform: 'none',
                            fontWeight: 600,
                            py: 1,
                            px: 2,
                            boxShadow: 'none',
                            '&:hover': {
                              boxShadow: 'none',
                            }
                          }}
                        >
                          Delete
                        </Button>
                      </Stack>
                    ) : (
                      <Button
                        variant="outlined"
                        size="small"
                        startIcon={<EditIcon />}
                        onClick={(e) => {
                          e.stopPropagation();
                          onResidentClick(resident._id);
                        }}
                        sx={{
                          borderRadius: 2,
                          textTransform: 'none',
                          fontWeight: 600,
                          py: 1,
                          px: 2,
                          borderWidth: 2,
                          '&:hover': {
                            borderWidth: 2,
                          }
                        }}
                      >
                        View
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <ApprovalDialog
        open={approvalDialogOpen}
        onClose={() => {
          setApprovalDialogOpen(false);
          setSelectedResident(null);
        }}
        resident={selectedResident}
        onApproved={onStatusChange}
      />

      <UnrejectDialog
        open={unrejectDialogOpen}
        onClose={() => {
          setUnrejectDialogOpen(false);
          setSelectedResident(null);
          setError('');
          setUnrejectData({ status: 'pending' });
        }}
        onSubmit={handleUnreject}
        data={unrejectData}
        onChange={setUnrejectData}
        error={error}
      />

      <DeleteDialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
          setSelectedResident(null);
          setError('');
        }}
        onSubmit={handleDelete}
        resident={selectedResident}
        error={error}
      />

      <EmailDialog
        open={emailDialogOpen}
        onClose={() => {
          setEmailDialogOpen(false);
          setError('');
        }}
        selectedResidents={selectedResidents}
        error={error}
        setError={setError}
      />
    </>
  );
};

export default ResidentList; 