import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  IconButton,
  Chip,
  CircularProgress,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Stack,
  Paper,
  Tabs,
  Tab,
  Skeleton,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as ViewIcon,
  Error as ErrorIcon,
  Refresh,
  ViewModule as ViewModuleIcon,
  ViewList as ViewListIcon,
  Description as DescriptionIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from '../../utils/axios';

const FormList = () => {
  const navigate = useNavigate();
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const [deleteReason, setDeleteReason] = useState('');
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [viewMode, setViewMode] = useState('grid');
  const [currentTab, setCurrentTab] = useState('all');

  const fetchForms = async () => {
    try {
      setError(null);
      setLoading(true);
      const response = await axios.get('/api/forms');
      setForms(response.data);
    } catch (error) {
      setError('Failed to fetch forms. Please try again.');
      console.error('Error fetching forms:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchForms();
  }, []);

  const handleDelete = async () => {
    try {
      setLoadingDelete(true);
      await axios.delete(`/api/forms/${selectedForm._id}`);
      await fetchForms();
      setDeleteDialogOpen(false);
      setSelectedForm(null);
      setDeleteReason('');
    } catch (error) {
      setError('Failed to delete form. Please try again.');
      console.error('Error deleting form:', error);
    } finally {
      setLoadingDelete(false);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'draft':
        return 'warning';
      case 'published':
        return 'success';
      case 'archived':
        return 'error';
      default:
        return 'default';
    }
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const filteredForms = forms.filter(form => {
    if (currentTab !== 'all' && form.status !== currentTab) {
      return false;
    }
    return true;
  });

  const FormCard = ({ form }) => (
    <Card
      elevation={0}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        backgroundColor: '#ffffff',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
          borderColor: 'primary.main',
        }
      }}
    >
      <CardContent sx={{ p: 2.5, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <DescriptionIcon sx={{ fontSize: 18, color: 'text.secondary' }} />
            <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.main' }}>
              {form.title}
            </Typography>
          </Box>
          <Chip
            label={form.status}
            color={getStatusColor(form.status)}
            size="small"
            sx={{ fontWeight: 500, height: 24 }}
          />
        </Box>

        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          {form.description || 'No description provided'}
        </Typography>

        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Fields: {form.fields.length}
          </Typography>
          <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ mt: 1 }}>
            {form.fields.slice(0, 3).map((field, index) => (
              <Chip
                key={index}
                label={field.label || field.type}
                size="small"
                sx={{ 
                  bgcolor: 'background.default',
                  fontWeight: 500
                }}
              />
            ))}
            {form.fields.length > 3 && (
              <Chip
                label={`+${form.fields.length - 3} more`}
                size="small"
                sx={{ 
                  bgcolor: 'background.default',
                  fontWeight: 500
                }}
              />
            )}
          </Stack>
        </Box>

        <Box sx={{ mt: 'auto' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'text.secondary', mb: 2 }}>
            <Typography variant="caption" sx={{ fontWeight: 500 }}>
              Last Updated: {formatDate(form.updatedAt || form.createdAt)}
            </Typography>
          </Box>

          <Stack 
            direction="row" 
            spacing={1}
          >
            <Button
              variant="outlined"
              size="small"
              startIcon={<ViewIcon />}
              onClick={() => navigate(`/forms/${form._id}/submissions`)}
              sx={{
                flex: 1,
                borderRadius: 1,
                textTransform: 'none',
                fontWeight: 500,
                borderWidth: 1.5
              }}
            >
              View Submissions
            </Button>
            <IconButton
              size="small"
              onClick={() => navigate(`/forms/edit/${form._id}`)}
              sx={{ 
                border: '1.5px solid',
                borderColor: 'divider',
                borderRadius: 1,
                '&:hover': {
                  borderColor: 'primary.main',
                  bgcolor: 'primary.lighter'
                }
              }}
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => {
                setSelectedForm(form);
                setDeleteDialogOpen(true);
              }}
              sx={{ 
                border: '1.5px solid',
                borderColor: 'divider',
                borderRadius: 1,
                '&:hover': {
                  borderColor: 'error.main',
                  bgcolor: 'error.lighter',
                  '& .MuiSvgIcon-root': {
                    color: 'error.main'
                  }
                }
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );

  const FormTable = ({ forms }) => (
    <TableContainer 
      component={Paper}
      elevation={0}
      sx={{ 
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        overflow: 'hidden',
        backgroundColor: '#ffffff'
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Title</TableCell>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Description</TableCell>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Fields</TableCell>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Status</TableCell>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Last Updated</TableCell>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {forms.map((form) => (
            <TableRow 
              key={form._id}
              sx={{ 
                '&:hover': {
                  bgcolor: '#f8f9fb'
                }
              }}
            >
              <TableCell sx={{ py: 1.5, color: 'primary.main', fontWeight: 500 }}>
                {form.title}
              </TableCell>
              <TableCell sx={{ py: 1.5 }}>
                {form.description || 'No description'}
              </TableCell>
              <TableCell sx={{ py: 1.5 }}>
                {form.fields.length} fields
              </TableCell>
              <TableCell sx={{ py: 1.5 }}>
                <Chip
                  label={form.status}
                  color={getStatusColor(form.status)}
                  size="small"
                  sx={{ fontWeight: 500, height: 24 }}
                />
              </TableCell>
              <TableCell sx={{ py: 1.5 }}>
                {formatDate(form.updatedAt || form.createdAt)}
              </TableCell>
              <TableCell sx={{ py: 1.5 }}>
                <Stack direction="row" spacing={1}>
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<ViewIcon />}
                    onClick={() => navigate(`/forms/${form._id}/submissions`)}
                    sx={{
                      borderRadius: 1,
                      textTransform: 'none',
                      fontWeight: 500,
                      borderWidth: 1.5,
                      py: 0.5
                    }}
                  >
                    View
                  </Button>
                  <IconButton
                    size="small"
                    onClick={() => navigate(`/forms/edit/${form._id}`)}
                    sx={{ 
                      border: '1px solid',
                      borderColor: 'divider',
                      borderRadius: 1,
                      p: 1,
                      '&:hover': {
                        borderColor: 'primary.main',
                        bgcolor: 'primary.lighter'
                      }
                    }}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSelectedForm(form);
                      setDeleteDialogOpen(true);
                    }}
                    sx={{ 
                      border: '1px solid',
                      borderColor: 'divider',
                      borderRadius: 1,
                      p: 1,
                      '&:hover': {
                        borderColor: 'error.main',
                        bgcolor: 'error.lighter',
                        '& .MuiSvgIcon-root': {
                          color: 'error.main'
                        }
                      }
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Container maxWidth="lg" sx={{ py: 3 }}>
      {error && (
        <Alert 
          severity="error" 
          sx={{ 
            mb: 3,
            borderRadius: 1,
            '& .MuiAlert-icon': {
              color: 'error.main'
            }
          }}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => setError(null)}
              sx={{ 
                textTransform: 'none',
                fontWeight: 500
              }}
            >
              Dismiss
            </Button>
          }
        >
          {error}
        </Alert>
      )}

      <Paper
        elevation={0}
        sx={{
          mb: 3,
          p: { xs: 2, sm: 2.5 },
          backgroundColor: '#ffffff',
          borderRadius: 1,
          border: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: { xs: 'stretch', md: 'center' },
          gap: { xs: 2, md: 0 },
          mb: { xs: 2, md: 2.5 }
        }}>
          <Box sx={{ flex: 1 }}>
            <Typography 
              variant="h5" 
              component="h1" 
              sx={{ 
                fontWeight: 600, 
                color: 'primary.main',
                mb: { xs: 0.5, md: 0.25 }
              }}
            >
              Forms
            </Typography>
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{ 
                display: { xs: 'none', sm: 'block' },
                fontWeight: 500
              }}
            >
              Create and manage forms for residents
            </Typography>
          </Box>
          <Stack 
            direction={{ xs: 'column', sm: 'row' }} 
            spacing={2}
            sx={{ 
              width: { xs: '100%', md: 'auto' },
              alignItems: { xs: 'stretch', sm: 'center' }
            }}
          >
            <Button
              startIcon={<Refresh />}
              onClick={fetchForms}
              variant="outlined"
              sx={{
                borderRadius: 1,
                textTransform: 'none',
                fontWeight: 500,
                borderWidth: 1.5,
                minWidth: { sm: 120 },
                order: { xs: 2, sm: 1 },
                py: 1
              }}
            >
              Refresh
            </Button>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => navigate('/forms/new')}
              sx={{
                borderRadius: 1,
                textTransform: 'none',
                fontWeight: 500,
                order: { xs: 1, sm: 2 },
                py: 1
              }}
            >
              Create Form
            </Button>
          </Stack>
        </Box>

        <Box sx={{ 
          borderRadius: 1,
          bgcolor: '#f5f6f8',
          border: '1px solid',
          borderColor: 'divider',
          overflow: 'hidden'
        }}>
          <Tabs
            value={currentTab}
            onChange={(e, newValue) => setCurrentTab(newValue)}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            sx={{
              minHeight: 44,
              px: { xs: 1, sm: 2 },
              '& .MuiTab-root': {
                textTransform: 'none',
                fontWeight: 500,
                fontSize: '0.875rem',
                minHeight: 44,
                minWidth: { xs: 'auto', sm: 100 },
                px: { xs: 2, sm: 3 }
              }
            }}
          >
            <Tab label="All Forms" value="all" />
            <Tab 
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  Draft
                  <Chip 
                    label={forms.filter(f => f.status === 'draft').length} 
                    size="small"
                    color="warning"
                    sx={{ 
                      height: 20,
                      '& .MuiChip-label': { px: 1, fontSize: '0.75rem' }
                    }}
                  />
                </Box>
              } 
              value="draft" 
            />
            <Tab 
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  Published
                  <Chip 
                    label={forms.filter(f => f.status === 'published').length}
                    size="small"
                    color="success"
                    sx={{ 
                      height: 20,
                      '& .MuiChip-label': { px: 1, fontSize: '0.75rem' }
                    }}
                  />
                </Box>
              } 
              value="published" 
            />
            <Tab 
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  Archived
                  <Chip 
                    label={forms.filter(f => f.status === 'archived').length}
                    size="small"
                    color="error"
                    sx={{ 
                      height: 20,
                      '& .MuiChip-label': { px: 1, fontSize: '0.75rem' }
                    }}
                  />
                </Box>
              } 
              value="archived" 
            />
          </Tabs>
        </Box>
      </Paper>

      {loading ? (
        viewMode === 'grid' ? (
          <Grid container spacing={2}>
            {[...Array(6)].map((_, index) => (
              <Grid item key={index} xs={12} sm={6} lg={4}>
                <Card
                  elevation={0}
                  sx={{
                    height: '100%',
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 1,
                  }}
                >
                  <CardContent sx={{ p: 2.5 }}>
                    <Stack spacing={2}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Skeleton width="60%" height={24} />
                        <Skeleton width={80} height={24} />
                      </Box>
                      <Skeleton width="100%" height={60} />
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Skeleton width={100} height={24} />
                        <Skeleton width={120} height={24} />
                      </Box>
                      <Skeleton width="100%" height={36} />
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <TableContainer 
            component={Paper}
            elevation={0}
            sx={{ 
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 1,
              overflow: 'hidden'
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {[...Array(6)].map((_, index) => (
                    <TableCell key={index} sx={{ bgcolor: '#f5f6f8' }}>
                      <Skeleton width={100} height={24} />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {[...Array(5)].map((_, index) => (
                  <TableRow key={index}>
                    {[...Array(6)].map((_, cellIndex) => (
                      <TableCell key={cellIndex}>
                        <Skeleton width={cellIndex === 5 ? 120 : 100} height={24} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )
      ) : filteredForms.length === 0 ? (
        <Paper
          elevation={0}
          sx={{
            p: 4,
            textAlign: 'center',
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 1,
            bgcolor: '#ffffff'
          }}
        >
          <ErrorIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
          <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
            No Forms Found
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            {currentTab === 'all'
              ? 'Get started by creating your first form.'
              : 'No forms match your current filter.'}
          </Typography>
          {currentTab !== 'all' ? (
            <Button
              variant="outlined"
              size="small"
              onClick={() => setCurrentTab('all')}
              sx={{
                borderRadius: 1,
                textTransform: 'none',
                fontWeight: 500,
                borderWidth: 1.5
              }}
            >
              Show All Forms
            </Button>
          ) : (
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => navigate('/forms/new')}
              sx={{
                borderRadius: 1,
                textTransform: 'none',
                fontWeight: 500
              }}
            >
              Create Form
            </Button>
          )}
        </Paper>
      ) : (
        viewMode === 'grid' ? (
          <Grid container spacing={2}>
            {filteredForms.map((form) => (
              <Grid item key={form._id} xs={12} sm={6} lg={4}>
                <FormCard form={form} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <FormTable forms={filteredForms} />
        )
      )}

      <Dialog 
        open={deleteDialogOpen} 
        onClose={() => !loadingDelete && setDeleteDialogOpen(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 1,
            boxShadow: '0 8px 32px -4px rgba(0, 0, 0, 0.1)'
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid',
          borderColor: 'divider',
          bgcolor: '#f5f6f8',
          py: 2
        }}>
          Delete Form
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            <Typography variant="body1" paragraph>
              Are you sure you want to delete "{selectedForm?.title}"? This action cannot be undone.
            </Typography>
            <Typography variant="body2" color="text.secondary" paragraph>
              All submissions associated with this form will also be deleted.
            </Typography>
            <TextField
              fullWidth
              label="Reason for deletion (optional)"
              multiline
              rows={3}
              value={deleteReason}
              onChange={(e) => setDeleteReason(e.target.value)}
              disabled={loadingDelete}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'divider',
                },
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 2.5, borderTop: '1px solid', borderColor: 'divider' }}>
          <Button
            onClick={() => {
              setDeleteDialogOpen(false);
              setSelectedForm(null);
              setDeleteReason('');
            }}
            disabled={loadingDelete}
            sx={{
              borderRadius: 1,
              textTransform: 'none',
              fontWeight: 500,
              color: 'text.secondary'
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            variant="contained"
            color="error"
            disabled={loadingDelete}
            sx={{
              borderRadius: 1,
              textTransform: 'none',
              fontWeight: 500,
              px: 3,
              minWidth: 100,
              position: 'relative'
            }}
          >
            {loadingDelete ? (
              <CircularProgress
                size={24}
                sx={{
                  color: 'error.light',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px'
                }}
              />
            ) : (
              'Delete'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default FormList; 