import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  Alert,
  Typography,
  Box,
  Paper
} from '@mui/material';
import { Warning as WarningIcon } from '@mui/icons-material';

const DeleteDialog = ({
  open,
  onClose,
  onSubmit,
  error,
  resident
}) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.12)'
        }
      }}
    >
      <DialogTitle sx={{ 
        borderBottom: '1px solid',
        borderColor: 'divider',
        bgcolor: '#f8f9fb',
        py: 2.5,
        px: 3
      }}>
        <Typography variant="h5" component="div" sx={{ fontWeight: 700, color: 'error.main' }}>
          Delete Resident
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mt: 0.5, fontWeight: 500 }}>
          This action cannot be undone
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ p: 3 }}>
        <Stack spacing={3}>
          {error && (
            <Alert 
              severity="error"
              sx={{ 
                borderRadius: 2,
                '& .MuiAlert-icon': {
                  color: 'error.main'
                }
              }}
            >
              {error}
            </Alert>
          )}

          <Paper
            variant="outlined"
            sx={{
              p: 2.5,
              bgcolor: 'error.lighter',
              borderColor: 'error.light',
              borderRadius: 2,
              display: 'flex',
              alignItems: 'flex-start',
              gap: 1.5
            }}
          >
            <WarningIcon sx={{ color: 'error.main', mt: 0.25 }} />
            <Typography variant="body2" sx={{ color: 'error.main', fontWeight: 600 }}>
              Are you sure you want to delete {resident?.firstName} {resident?.lastName}? This will permanently remove all their data and cannot be reversed.
            </Typography>
          </Paper>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ 
        px: 3, 
        py: 2.5, 
        bgcolor: '#f8f9fb',
        borderTop: '1px solid',
        borderColor: 'divider'
      }}>
        <Button 
          onClick={onClose}
          sx={{ 
            borderRadius: 2,
            textTransform: 'none',
            fontWeight: 600,
            px: 3,
            py: 1
          }}
        >
          Cancel
        </Button>
        <Button 
          onClick={onSubmit}
          variant="contained"
          color="error"
          sx={{ 
            borderRadius: 2,
            textTransform: 'none',
            fontWeight: 600,
            px: 3,
            py: 1,
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            }
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteDialog; 