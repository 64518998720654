export const calculateLengthOfStay = (moveInDate, moveOutDate) => {
  if (!moveInDate || !moveOutDate) return null;
  
  const start = new Date(moveInDate);
  const end = new Date(moveOutDate);
  const diffTime = Math.abs(end - start);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  
  const years = Math.floor(diffDays / 365);
  const months = Math.floor((diffDays % 365) / 30);
  const days = diffDays % 30;
  
  let stay = [];
  if (years > 0) stay.push(`${years} year${years > 1 ? 's' : ''}`);
  if (months > 0) stay.push(`${months} month${months > 1 ? 's' : ''}`);
  if (days > 0) stay.push(`${days} day${days > 1 ? 's' : ''}`);
  
  return stay.join(', ');
};

export const formatUserName = (user) => {
  if (!user) return 'Unknown';
  return `${user.firstName || ''} ${user.lastName || ''}`.trim() || 'Unknown';
};

export const getInitials = (firstName, lastName) => {
  return `${firstName?.[0] || ''}${lastName?.[0] || ''}`;
}; 