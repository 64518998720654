import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  Typography
} from '@mui/material';

const PropertyDialogs = ({
  property,
  residents,
  openAssignDialog,
  openEditDialog,
  openDeleteDialog,
  openAddRoomDialog,
  openEditRoomDialog,
  openDeleteRoomDialog,
  openAddBedDialog,
  openEditBedDialog,
  openDeleteBedDialog,
  selectedAssignmentBed,
  selectedResident,
  selectedRoom,
  selectedBedToEdit,
  editedProperty,
  editedRoom,
  editedBed,
  assignmentError,
  editError,
  roomError,
  bedError,
  onAssignmentSubmit,
  onEditPropertySubmit,
  onDeletePropertyConfirm,
  onAddRoomSubmit,
  onEditRoomSubmit,
  onDeleteRoomConfirm,
  onAddBedSubmit,
  onEditBedSubmit,
  onDeleteBedConfirm,
  setOpenAssignDialog,
  setOpenEditDialog,
  setOpenDeleteDialog,
  setOpenAddRoomDialog,
  setOpenEditRoomDialog,
  setOpenDeleteRoomDialog,
  setOpenAddBedDialog,
  setOpenEditBedDialog,
  setOpenDeleteBedDialog,
  setSelectedResident,
  setEditedProperty,
  setEditedRoom,
  setEditedBed
}) => {
  const dialogStyle = {
    elevation: 0,
    sx: {
      borderRadius: 2,
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
      backgroundImage: 'linear-gradient(145deg, #ffffff 0%, #f8f9ff 100%)',
    }
  };

  const textFieldStyle = {
    '& .MuiOutlinedInput-root': {
      bgcolor: 'background.paper',
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'primary.main',
      },
    },
  };

  return (
    <>
      {/* Assign Resident Dialog */}
      <Dialog
        open={openAssignDialog}
        onClose={() => setOpenAssignDialog(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={dialogStyle}
      >
        <DialogTitle 
          sx={{ 
            pb: 2,
            fontWeight: 600,
            color: 'text.primary',
            letterSpacing: '-0.02em'
          }}
        >
          Assign Resident to Bed
        </DialogTitle>
        <DialogContent dividers>
          {assignmentError && (
            <Alert 
              severity="error" 
              sx={{ 
                mb: 2,
                borderRadius: 1,
                boxShadow: '0 4px 12px rgba(211, 47, 47, 0.1)'
              }}
            >
              {assignmentError}
            </Alert>
          )}
          <FormControl 
            fullWidth 
            sx={{ 
              mt: 2,
              ...textFieldStyle
            }}
          >
            <InputLabel>Select Resident</InputLabel>
            <Select
              value={selectedResident}
              onChange={(e) => setSelectedResident(e.target.value)}
              label="Select Resident"
            >
              {residents.map((resident) => (
                <MenuItem 
                  key={resident._id} 
                  value={resident._id}
                  sx={{
                    '&:hover': {
                      bgcolor: 'primary.lighter',
                    },
                  }}
                >
                  {resident.firstName} {resident.lastName} ({resident.email})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ p: 2.5 }}>
          <Button 
            onClick={() => setOpenAssignDialog(false)}
            sx={{
              color: 'text.secondary',
              '&:hover': {
                bgcolor: 'action.hover',
              },
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={onAssignmentSubmit}
            variant="contained"
            sx={{
              px: 3,
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              '&:hover': {
                boxShadow: '0 6px 16px rgba(0, 0, 0, 0.15)',
              },
            }}
          >
            Assign
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Property Dialog */}
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={dialogStyle}
      >
        <DialogTitle 
          sx={{ 
            pb: 2,
            fontWeight: 600,
            color: 'text.primary',
            letterSpacing: '-0.02em'
          }}
        >
          Edit Property
        </DialogTitle>
        <DialogContent dividers>
          {editError && (
            <Alert 
              severity="error" 
              sx={{ 
                mb: 2,
                borderRadius: 1,
                boxShadow: '0 4px 12px rgba(211, 47, 47, 0.1)'
              }}
            >
              {editError}
            </Alert>
          )}
          <TextField
            fullWidth
            label="Property Name"
            value={editedProperty.name}
            onChange={(e) => setEditedProperty(prev => ({ ...prev, name: e.target.value }))}
            required
            sx={{ mt: 2, ...textFieldStyle }}
          />
          <TextField
            fullWidth
            label="Address"
            value={editedProperty.address}
            onChange={(e) => setEditedProperty(prev => ({ ...prev, address: e.target.value }))}
            required
            sx={{ mt: 2, ...textFieldStyle }}
          />
          <TextField
            fullWidth
            label="Description"
            value={editedProperty.description}
            onChange={(e) => setEditedProperty(prev => ({ ...prev, description: e.target.value }))}
            multiline
            rows={4}
            sx={{ mt: 2, ...textFieldStyle }}
          />
        </DialogContent>
        <DialogActions sx={{ p: 2.5 }}>
          <Button 
            onClick={() => setOpenEditDialog(false)}
            sx={{
              color: 'text.secondary',
              '&:hover': {
                bgcolor: 'action.hover',
              },
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={onEditPropertySubmit}
            variant="contained"
            sx={{
              px: 3,
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              '&:hover': {
                boxShadow: '0 6px 16px rgba(0, 0, 0, 0.15)',
              },
            }}
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Property Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={dialogStyle}
      >
        <DialogTitle 
          sx={{ 
            pb: 2,
            fontWeight: 600,
            color: 'text.primary',
            letterSpacing: '-0.02em'
          }}
        >
          Delete Property
        </DialogTitle>
        <DialogContent dividers>
          <Typography sx={{ color: 'text.secondary' }}>
            Are you sure you want to delete this property? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2.5 }}>
          <Button 
            onClick={() => setOpenDeleteDialog(false)}
            sx={{
              color: 'text.secondary',
              '&:hover': {
                bgcolor: 'action.hover',
              },
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={onDeletePropertyConfirm}
            variant="contained"
            color="error"
            sx={{
              px: 3,
              boxShadow: '0 4px 12px rgba(211, 47, 47, 0.1)',
              '&:hover': {
                boxShadow: '0 6px 16px rgba(211, 47, 47, 0.15)',
              },
            }}
          >
            Delete Property
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Room Dialog */}
      <Dialog
        open={openAddRoomDialog}
        onClose={() => setOpenAddRoomDialog(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={dialogStyle}
      >
        <DialogTitle 
          sx={{ 
            pb: 2,
            fontWeight: 600,
            color: 'text.primary',
            letterSpacing: '-0.02em'
          }}
        >
          Add New Room
        </DialogTitle>
        <DialogContent dividers>
          {roomError && (
            <Alert 
              severity="error" 
              sx={{ 
                mb: 2,
                borderRadius: 1,
                boxShadow: '0 4px 12px rgba(211, 47, 47, 0.1)'
              }}
            >
              {roomError}
            </Alert>
          )}
          <TextField
            fullWidth
            label="Room Name"
            value={editedRoom.name}
            onChange={(e) => setEditedRoom(prev => ({ ...prev, name: e.target.value }))}
            required
            sx={{ mt: 2, ...textFieldStyle }}
          />
          <TextField
            fullWidth
            label="Square Footage"
            type="number"
            value={editedRoom.squareFootage}
            onChange={(e) => setEditedRoom(prev => ({ ...prev, squareFootage: e.target.value }))}
            required
            sx={{ mt: 2, ...textFieldStyle }}
          />
        </DialogContent>
        <DialogActions sx={{ p: 2.5 }}>
          <Button 
            onClick={() => setOpenAddRoomDialog(false)}
            sx={{
              color: 'text.secondary',
              '&:hover': {
                bgcolor: 'action.hover',
              },
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={onAddRoomSubmit}
            variant="contained"
            sx={{
              px: 3,
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              '&:hover': {
                boxShadow: '0 6px 16px rgba(0, 0, 0, 0.15)',
              },
            }}
          >
            Add Room
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Room Dialog */}
      <Dialog
        open={openEditRoomDialog}
        onClose={() => setOpenEditRoomDialog(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={dialogStyle}
      >
        <DialogTitle 
          sx={{ 
            pb: 2,
            fontWeight: 600,
            color: 'text.primary',
            letterSpacing: '-0.02em'
          }}
        >
          Edit Room
        </DialogTitle>
        <DialogContent dividers>
          {roomError && (
            <Alert 
              severity="error" 
              sx={{ 
                mb: 2,
                borderRadius: 1,
                boxShadow: '0 4px 12px rgba(211, 47, 47, 0.1)'
              }}
            >
              {roomError}
            </Alert>
          )}
          <TextField
            fullWidth
            label="Room Name"
            value={editedRoom.name}
            onChange={(e) => setEditedRoom(prev => ({ ...prev, name: e.target.value }))}
            required
            sx={{ mt: 2, ...textFieldStyle }}
          />
          <TextField
            fullWidth
            label="Square Footage"
            type="number"
            value={editedRoom.squareFootage}
            onChange={(e) => setEditedRoom(prev => ({ ...prev, squareFootage: e.target.value }))}
            required
            sx={{ mt: 2, ...textFieldStyle }}
          />
        </DialogContent>
        <DialogActions sx={{ p: 2.5 }}>
          <Button 
            onClick={() => setOpenEditRoomDialog(false)}
            sx={{
              color: 'text.secondary',
              '&:hover': {
                bgcolor: 'action.hover',
              },
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={onEditRoomSubmit}
            variant="contained"
            sx={{
              px: 3,
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              '&:hover': {
                boxShadow: '0 6px 16px rgba(0, 0, 0, 0.15)',
              },
            }}
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Room Dialog */}
      <Dialog
        open={openDeleteRoomDialog}
        onClose={() => setOpenDeleteRoomDialog(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={dialogStyle}
      >
        <DialogTitle 
          sx={{ 
            pb: 2,
            fontWeight: 600,
            color: 'text.primary',
            letterSpacing: '-0.02em'
          }}
        >
          Delete Room
        </DialogTitle>
        <DialogContent dividers>
          <Typography sx={{ color: 'text.secondary' }}>
            Are you sure you want to delete this room? This action cannot be undone.
            {selectedRoom?.beds.some(bed => bed.resident) && (
              <Typography color="error" sx={{ mt: 1 }}>
                Warning: This room has assigned residents. You must unassign all residents before deleting the room.
              </Typography>
            )}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2.5 }}>
          <Button 
            onClick={() => setOpenDeleteRoomDialog(false)}
            sx={{
              color: 'text.secondary',
              '&:hover': {
                bgcolor: 'action.hover',
              },
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={onDeleteRoomConfirm}
            variant="contained"
            color="error"
            disabled={selectedRoom?.beds.some(bed => bed.resident)}
            sx={{
              px: 3,
              boxShadow: '0 4px 12px rgba(211, 47, 47, 0.1)',
              '&:hover': {
                boxShadow: '0 6px 16px rgba(211, 47, 47, 0.15)',
              },
            }}
          >
            Delete Room
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Bed Dialog */}
      <Dialog
        open={openAddBedDialog}
        onClose={() => setOpenAddBedDialog(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={dialogStyle}
      >
        <DialogTitle 
          sx={{ 
            pb: 2,
            fontWeight: 600,
            color: 'text.primary',
            letterSpacing: '-0.02em'
          }}
        >
          Add New Bed
        </DialogTitle>
        <DialogContent dividers>
          {bedError && (
            <Alert 
              severity="error" 
              sx={{ 
                mb: 2,
                borderRadius: 1,
                boxShadow: '0 4px 12px rgba(211, 47, 47, 0.1)'
              }}
            >
              {bedError}
            </Alert>
          )}
          <TextField
            fullWidth
            label="Bed Name"
            value={editedBed.name}
            onChange={(e) => setEditedBed(prev => ({ ...prev, name: e.target.value }))}
            required
            sx={{ mt: 2, ...textFieldStyle }}
          />
        </DialogContent>
        <DialogActions sx={{ p: 2.5 }}>
          <Button 
            onClick={() => setOpenAddBedDialog(false)}
            sx={{
              color: 'text.secondary',
              '&:hover': {
                bgcolor: 'action.hover',
              },
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={onAddBedSubmit}
            variant="contained"
            sx={{
              px: 3,
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              '&:hover': {
                boxShadow: '0 6px 16px rgba(0, 0, 0, 0.15)',
              },
            }}
          >
            Add Bed
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Bed Dialog */}
      <Dialog
        open={openEditBedDialog}
        onClose={() => setOpenEditBedDialog(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={dialogStyle}
      >
        <DialogTitle 
          sx={{ 
            pb: 2,
            fontWeight: 600,
            color: 'text.primary',
            letterSpacing: '-0.02em'
          }}
        >
          Edit Bed
        </DialogTitle>
        <DialogContent dividers>
          {bedError && (
            <Alert 
              severity="error" 
              sx={{ 
                mb: 2,
                borderRadius: 1,
                boxShadow: '0 4px 12px rgba(211, 47, 47, 0.1)'
              }}
            >
              {bedError}
            </Alert>
          )}
          <TextField
            fullWidth
            label="Bed Name"
            value={editedBed.name}
            onChange={(e) => setEditedBed(prev => ({ ...prev, name: e.target.value }))}
            required
            sx={{ mt: 2, ...textFieldStyle }}
          />
        </DialogContent>
        <DialogActions sx={{ p: 2.5 }}>
          <Button 
            onClick={() => setOpenEditBedDialog(false)}
            sx={{
              color: 'text.secondary',
              '&:hover': {
                bgcolor: 'action.hover',
              },
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={onEditBedSubmit}
            variant="contained"
            sx={{
              px: 3,
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              '&:hover': {
                boxShadow: '0 6px 16px rgba(0, 0, 0, 0.15)',
              },
            }}
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Bed Dialog */}
      <Dialog
        open={openDeleteBedDialog}
        onClose={() => setOpenDeleteBedDialog(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={dialogStyle}
      >
        <DialogTitle 
          sx={{ 
            pb: 2,
            fontWeight: 600,
            color: 'text.primary',
            letterSpacing: '-0.02em'
          }}
        >
          Delete Bed
        </DialogTitle>
        <DialogContent dividers>
          <Typography sx={{ color: 'text.secondary' }}>
            Are you sure you want to delete this bed? This action cannot be undone.
            {selectedBedToEdit?.resident && (
              <Typography color="error" sx={{ mt: 1 }}>
                Warning: This bed has an assigned resident. You must unassign the resident before deleting the bed.
              </Typography>
            )}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2.5 }}>
          <Button 
            onClick={() => setOpenDeleteBedDialog(false)}
            sx={{
              color: 'text.secondary',
              '&:hover': {
                bgcolor: 'action.hover',
              },
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={onDeleteBedConfirm}
            variant="contained"
            color="error"
            disabled={selectedBedToEdit?.resident}
            sx={{
              px: 3,
              boxShadow: '0 4px 12px rgba(211, 47, 47, 0.1)',
              '&:hover': {
                boxShadow: '0 6px 16px rgba(211, 47, 47, 0.15)',
              },
            }}
          >
            Delete Bed
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PropertyDialogs; 