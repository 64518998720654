import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Chip,
  Button,
  Stack,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  alpha,
  useTheme,
  TextField,
  Grid,
  InputAdornment,
  Alert,
  CircularProgress
} from '@mui/material';
import {
  Male as MaleIcon,
  Female as FemaleIcon,
  PhotoCamera as PhotoCameraIcon,
  Person as PersonIcon,
  Delete as DeleteIcon
} from '@mui/icons-material';
import ImageUpload from './ImageUpload';
import axios from '../../../utils/axios';

const ResidentCard = ({ 
  resident, 
  onImageUpdate, 
  setError, 
  setOpenDischargeDialog, 
  setOpenReactivateDialog,
  fetchData 
}) => {
  const theme = useTheme();
  const [editMode, setEditMode] = useState({
    dateOfBirth: false
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');

  const handleEditModeChange = (field, index, value) => {
    setEditMode(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSaveField = async (field, value) => {
    try {
      await axios.put(`/api/auth/residents/${resident._id}/details`, {
        [field]: value
      });
      await fetchData();
      handleEditModeChange(field, null, false);
      setSuccess('Successfully updated field');
      setTimeout(() => setSuccess(''), 3000);
    } catch (error) {
      console.error(`Error updating ${field}:`, error);
      setError(error.response?.data?.message || `Failed to update ${field}`);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    return new Date(dateString).toLocaleDateString();
  };

  const getDateInputValue = (dateString) => {
    if (!dateString) return '';
    return new Date(dateString).toISOString().split('T')[0];
  };

  const formatUserName = (user) => {
    return `${user.firstName} ${user.lastName}`;
  };

  const getInitials = (user) => {
    return `${user.firstName?.[0] || ''}${user.lastName?.[0] || ''}`;
  };

  const getStatusConfig = (status) => {
    switch (status) {
      case 'active':
        return {
          color: 'success',
          bgColor: '#E8F5E9',
          borderColor: '#66BB6A'
        };
      case 'discharged':
      case 'rejected':
        return {
          color: 'error',
          bgColor: '#FEEBE9',
          borderColor: '#EF5350'
        };
      default:
        return {
          color: 'warning',
          bgColor: '#FFF3E0',
          borderColor: '#FFA726'
        };
    }
  };

  const statusConfig = getStatusConfig(resident.status);

  return (
    <Card
      elevation={0}
      sx={{
        borderRadius: 3,
        border: '1px solid',
        borderColor: 'divider',
        position: 'relative',
        overflow: 'hidden',
        bgcolor: '#ffffff',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          borderColor: 'primary.main',
          boxShadow: '0 8px 24px rgba(0, 0, 0, 0.12)',
        }
      }}
    >
      {/* Header */}
      <Box
        sx={{
          background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
          p: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <PersonIcon sx={{ color: 'white' }} />
          <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 600 }}>
            Resident Profile
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
          <ImageUpload 
            resident={resident} 
            onImageUpdate={onImageUpdate}
          />
        </Box>
      </Box>

      {/* Image Section with Overlay */}
      <Box sx={{ position: 'relative' }}>
        <CardMedia
          component="img"
          height="300"
          image={resident.imageUrl ? `${process.env.REACT_APP_S3_URL}/${resident.imageUrl}` : '/default-profile.jpg'}
          alt={formatUserName(resident)}
          sx={{
            objectFit: 'cover',
            objectPosition: 'center',
            backgroundColor: 'grey.100'
          }}
        />
        
        {/* Name and Status Overlay */}
        <Box
          sx={{ 
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            background: 'linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,0) 100%)',
            pt: 8,
            pb: 2,
            px: 3,
          }}
        >
          <Stack spacing={1}>
            <Typography variant="h5" sx={{ 
              color: '#fff',
              fontWeight: 700,
              textShadow: '0 2px 4px rgba(0,0,0,0.2)'
            }}>
              {formatUserName(resident)}
            </Typography>
            
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Chip
                label={resident.status.charAt(0).toUpperCase() + resident.status.slice(1)}
                sx={{
                  height: 28,
                  borderRadius: 2,
                  backgroundColor: 'rgba(255,255,255,0.9)',
                  color: `${statusConfig.color}.dark`,
                  border: '1px solid',
                  borderColor: statusConfig.borderColor,
                  fontWeight: 600,
                  '& .MuiChip-label': { px: 1.5 }
                }}
              />
              
              {resident.status === 'active' ? (
                <Button
                  size="small"
                  onClick={() => setOpenDischargeDialog(true)}
                  sx={{ 
                    height: 28,
                    borderRadius: 2,
                    textTransform: 'none',
                    fontWeight: 600,
                    px: 2,
                    color: '#fff',
                    borderColor: 'rgba(255,255,255,0.5)',
                    backgroundColor: 'rgba(211,47,47,0.2)',
                    '&:hover': {
                      borderColor: '#fff',
                      backgroundColor: 'rgba(211,47,47,0.3)',
                    }
                  }}
                  variant="outlined"
                >
                  Discharge
                </Button>
              ) : resident.status === 'discharged' && (
                <Button
                  size="small"
                  onClick={() => setOpenReactivateDialog(true)}
                  sx={{
                    height: 28,
                    borderRadius: 2,
                    textTransform: 'none',
                    fontWeight: 600,
                    px: 2,
                    color: '#fff',
                    borderColor: 'rgba(255,255,255,0.5)',
                    backgroundColor: 'rgba(25,118,210,0.2)',
                    '&:hover': {
                      borderColor: '#fff',
                      backgroundColor: 'rgba(25,118,210,0.3)',
                    }
                  }}
                  variant="outlined"
                >
                  Reactivate
                </Button>
              )}
            </Box>
          </Stack>
        </Box>
      </Box>

      {/* Bottom Section */}
      <CardContent sx={{ p: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" sx={{ 
              mb: 1.5,
              color: 'text.secondary',
              fontWeight: 600,
              textTransform: 'uppercase',
              letterSpacing: '0.5px',
              fontSize: '0.75rem'
            }}>
              Date of Birth
            </Typography>
            {editMode.dateOfBirth ? (
              <TextField
                type="date"
                size="small"
                defaultValue={getDateInputValue(resident.dateOfBirth)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSaveField('dateOfBirth', e.target.value);
                  } else if (e.key === 'Escape') {
                    handleEditModeChange('dateOfBirth', null, false);
                  }
                }}
                onBlur={(e) => handleSaveField('dateOfBirth', e.target.value)}
                autoFocus
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    bgcolor: 'white',
                    borderRadius: 2,
                    '& fieldset': {
                      borderWidth: 2,
                      borderColor: 'divider'
                    }
                  }
                }}
              />
            ) : (
              <Box 
                onClick={() => handleEditModeChange('dateOfBirth', null, true)}
                sx={{ 
                  cursor: 'pointer', 
                  '&:hover': { bgcolor: alpha(theme.palette.info.main, 0.1) },
                  borderRadius: 1,
                  py: 0.5
                }}
              >
                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                  {resident.dateOfBirth ? formatDate(resident.dateOfBirth) : 'Click to set'}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" sx={{ 
              mb: 1.5,
              color: 'text.secondary',
              fontWeight: 600,
              textTransform: 'uppercase',
              letterSpacing: '0.5px',
              fontSize: '0.75rem'
            }}>
              Gender
            </Typography>
            <ToggleButtonGroup
              value={resident.gender}
              exclusive
              fullWidth
              sx={{
                '& .MuiToggleButton-root': {
                  py: 1.5,
                  border: '2px solid',
                  borderColor: 'divider',
                  '&:hover': {
                    borderColor: 'primary.main',
                    bgcolor: alpha(theme.palette.primary.main, 0.08)
                  }
                }
              }}
            >
              <ToggleButton
                value="Male"
                sx={{
                  borderRadius: '12px 0 0 12px',
                  borderColor: resident.gender === 'Male' ? '#1976D2' : 'divider',
                  bgcolor: resident.gender === 'Male' ? '#1976D2' : 'transparent',
                  color: resident.gender === 'Male' ? 'white' : 'text.secondary',
                  '&:hover': {
                    bgcolor: resident.gender === 'Male' ? '#1565C0' : alpha('#1976D2', 0.08),
                    borderColor: '#1976D2'
                  },
                  '&.Mui-selected': {
                    bgcolor: '#1976D2',
                    color: 'white',
                    '&:hover': {
                      bgcolor: '#1565C0'
                    }
                  }
                }}
              >
                <MaleIcon sx={{ fontSize: 24, mr: 1 }} />
                Male
              </ToggleButton>
              <ToggleButton
                value="Female"
                sx={{
                  borderRadius: '0 12px 12px 0',
                  borderColor: resident.gender === 'Female' ? '#D81B60' : 'divider',
                  bgcolor: resident.gender === 'Female' ? '#D81B60' : 'transparent',
                  color: resident.gender === 'Female' ? 'white' : 'text.secondary',
                  '&:hover': {
                    bgcolor: resident.gender === 'Female' ? '#C2185B' : alpha('#D81B60', 0.08),
                    borderColor: '#D81B60'
                  },
                  '&.Mui-selected': {
                    bgcolor: '#D81B60',
                    color: 'white',
                    '&:hover': {
                      bgcolor: '#C2185B'
                    }
                  }
                }}
              >
                <FemaleIcon sx={{ fontSize: 24, mr: 1 }} />
                Female
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>

          {success && (
            <Grid item xs={12}>
              <Alert severity="success">{success}</Alert>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ResidentCard; 