import React from 'react';
import {
  Box,
  Typography,
  Card,
  TextField,
  alpha,
  useTheme
} from '@mui/material';
import { Notes } from '@mui/icons-material';

const ResidentNotes = ({ 
  resident, 
  editMode, 
  onEditModeChange, 
  onSaveField 
}) => {
  const theme = useTheme();

  return (
    <Card
      elevation={0}
      sx={{ 
        height: '100%',
        background: `linear-gradient(45deg, ${alpha(theme.palette.primary.main, 0.02)}, ${alpha(theme.palette.primary.light, 0.05)})`,
        borderRadius: 1,
        border: '1px solid',
        borderColor: 'divider'
      }}
    >
      <Box
        sx={{
          background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
          p: 2,
          borderRadius: '4px 4px 0 0',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Notes sx={{ color: 'white' }} />
          <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 600 }}>
            Notes
          </Typography>
        </Box>
      </Box>

      <Box sx={{ p: 2 }}>
        {editMode.notes ? (
          <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
            <TextField
              multiline
              rows={4}
              size="small"
              defaultValue={resident.notes || ''}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && e.ctrlKey) {
                  onSaveField('notes', null, e.target.value);
                  onEditModeChange('notes', null, false);
                } else if (e.key === 'Escape') {
                  onEditModeChange('notes', null, false);
                }
              }}
              onBlur={(e) => {
                onSaveField('notes', null, e.target.value);
                onEditModeChange('notes', null, false);
              }}
              autoFocus
              fullWidth
              sx={{ 
                '& .MuiOutlinedInput-root': {
                  borderRadius: 1,
                  borderColor: 'divider',
                  fontSize: '0.875rem',
                  bgcolor: 'white'
                }
              }}
            />
            <Typography 
              variant="caption" 
              color="text.secondary"
              sx={{ 
                fontWeight: 500,
                whiteSpace: 'nowrap'
              }}
            >
              Press Ctrl+Enter to save, Esc to cancel
            </Typography>
          </Box>
        ) : (
          <Box 
            onClick={() => onEditModeChange('notes', null, true)}
            sx={{ 
              cursor: 'pointer', 
              '&:hover': { 
                bgcolor: alpha(theme.palette.info.main, 0.05),
                borderRadius: 1
              },
              p: 1,
              mx: -1,
              minHeight: '60px'
            }}
          >
            <Typography 
              variant="body2" 
              sx={{ 
                whiteSpace: 'pre-wrap',
                fontWeight: 500,
                color: resident.notes ? 'text.primary' : 'text.secondary'
              }}
            >
              {resident.notes || 'Click to add notes'}
            </Typography>
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default ResidentNotes; 