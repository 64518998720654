import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../../utils/axios';
import {
  Container,
  Box,
  Alert,
  Typography,
  Grid,
  Stack,
  Paper,
  Button
} from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';

// Component Imports
import PropertyHeader from './details/PropertyHeader';
import PropertySummary from './details/PropertySummary';
import RoomsAndAssignments from './details/RoomsAndAssignments';
import PropertyDialogs from './details/PropertyDialogs';

const PropertyDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [residents, setResidents] = useState([]);

  // Dialog states
  const [openAssignDialog, setOpenAssignDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openAddRoomDialog, setOpenAddRoomDialog] = useState(false);
  const [openEditRoomDialog, setOpenEditRoomDialog] = useState(false);
  const [openDeleteRoomDialog, setOpenDeleteRoomDialog] = useState(false);
  const [openAddBedDialog, setOpenAddBedDialog] = useState(false);
  const [openEditBedDialog, setOpenEditBedDialog] = useState(false);
  const [openDeleteBedDialog, setOpenDeleteBedDialog] = useState(false);

  // Selected items and edited states
  const [selectedAssignmentBed, setSelectedAssignmentBed] = useState(null);
  const [selectedResident, setSelectedResident] = useState('');
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedBedToEdit, setSelectedBedToEdit] = useState(null);
  const [editedProperty, setEditedProperty] = useState({
    name: '',
    address: '',
    description: ''
  });
  const [editedRoom, setEditedRoom] = useState({
    name: '',
    squareFootage: ''
  });
  const [editedBed, setEditedBed] = useState({
    name: ''
  });

  // Error states
  const [assignmentError, setAssignmentError] = useState('');
  const [editError, setEditError] = useState('');
  const [roomError, setRoomError] = useState('');
  const [bedError, setBedError] = useState('');

  const updateAvailableResidents = async () => {
    try {
      const [residentsRes, allPropertiesRes] = await Promise.all([
        axios.get('/api/auth/residents'),
        axios.get('/api/properties')
      ]);

      const assignedResidentIds = new Set();
      allPropertiesRes.data.forEach(property => {
        property.rooms.forEach(room => {
          room.beds.forEach(bed => {
            if (bed.resident) {
              assignedResidentIds.add(bed.resident._id);
            }
          });
        });
      });

      const availableResidents = residentsRes.data.filter(
        resident => resident.isApproved && !assignedResidentIds.has(resident._id)
      );

      setResidents(availableResidents);
    } catch (error) {
      console.error('Error updating available residents:', error);
    }
  };

  const handleAssignBed = (roomId, bedId) => {
    setSelectedAssignmentBed({ roomId, bedId });
    setSelectedResident('');
    setAssignmentError('');
    setOpenAssignDialog(true);
  };

  const handleUnassignBed = async (roomId, bedId) => {
    try {
      await axios.put(
        `/api/properties/${id}/rooms/${roomId}/beds/${bedId}/unassign`
      );
      
      const propertyRes = await axios.get(`/api/properties/${id}`);
      setProperty(propertyRes.data);
      
      await updateAvailableResidents();
    } catch (error) {
      console.error('Error unassigning bed:', error);
      setError(error.response?.data?.message || 'Failed to unassign bed');
    }
  };

  const handleAssignmentSubmit = async () => {
    if (!selectedResident) {
      setAssignmentError('Please select a resident');
      return;
    }

    try {
      const response = await axios.put(
        `/api/properties/${id}/rooms/${selectedAssignmentBed.roomId}/beds/${selectedAssignmentBed.bedId}/assign`,
        { residentId: selectedResident }
      );
      setProperty(response.data);
      setOpenAssignDialog(false);
      await updateAvailableResidents();
    } catch (error) {
      console.error('Error assigning bed:', error);
      setAssignmentError(error.response?.data?.message || 'Failed to assign bed');
    }
  };

  // Room management handlers
  const handleAddRoom = async () => {
    if (!editedRoom.name || !editedRoom.squareFootage) {
      setRoomError('Name and square footage are required');
      return;
    }

    try {
      const response = await axios.post(`/api/properties/${id}/rooms`, {
        name: editedRoom.name,
        squareFootage: parseInt(editedRoom.squareFootage),
        beds: []
      });
      setProperty(response.data);
      setOpenAddRoomDialog(false);
      setEditedRoom({ name: '', squareFootage: '' });
      setRoomError('');
    } catch (error) {
      console.error('Error adding room:', error);
      setRoomError(error.response?.data?.message || 'Failed to add room');
    }
  };

  const handleEditRoom = async () => {
    if (!editedRoom.name || !editedRoom.squareFootage) {
      setRoomError('Name and square footage are required');
      return;
    }

    try {
      const response = await axios.put(`/api/properties/${id}/rooms/${selectedRoom._id}`, {
        name: editedRoom.name,
        squareFootage: parseInt(editedRoom.squareFootage)
      });
      setProperty(response.data);
      setOpenEditRoomDialog(false);
      setEditedRoom({ name: '', squareFootage: '' });
      setSelectedRoom(null);
      setRoomError('');
    } catch (error) {
      console.error('Error updating room:', error);
      setRoomError(error.response?.data?.message || 'Failed to update room');
    }
  };

  const handleDeleteRoom = async () => {
    try {
      const response = await axios.delete(`/api/properties/${id}/rooms/${selectedRoom._id}`);
      setProperty(response.data);
      setOpenDeleteRoomDialog(false);
      setSelectedRoom(null);
    } catch (error) {
      console.error('Error deleting room:', error);
      setRoomError(error.response?.data?.message || 'Failed to delete room');
    }
  };

  // Bed management handlers
  const handleAddBed = async () => {
    if (!editedBed.name) {
      setBedError('Bed name is required');
      return;
    }

    try {
      const response = await axios.post(`/api/properties/${id}/rooms/${selectedRoom._id}/beds`, {
        name: editedBed.name
      });
      setProperty(response.data);
      setOpenAddBedDialog(false);
      setEditedBed({ name: '' });
      setBedError('');
    } catch (error) {
      console.error('Error adding bed:', error);
      setBedError(error.response?.data?.message || 'Failed to add bed');
    }
  };

  const handleEditBed = async () => {
    if (!editedBed.name) {
      setBedError('Bed name is required');
      return;
    }

    try {
      const response = await axios.put(
        `/api/properties/${id}/rooms/${selectedRoom._id}/beds/${selectedBedToEdit._id}`,
        { name: editedBed.name }
      );
      setProperty(response.data);
      setOpenEditBedDialog(false);
      setEditedBed({ name: '' });
      setSelectedBedToEdit(null);
      setBedError('');
    } catch (error) {
      console.error('Error updating bed:', error);
      setBedError(error.response?.data?.message || 'Failed to update bed');
    }
  };

  const handleDeleteBed = async () => {
    try {
      const response = await axios.delete(
        `/api/properties/${id}/rooms/${selectedRoom._id}/beds/${selectedBedToEdit._id}`
      );
      setProperty(response.data);
      setOpenDeleteBedDialog(false);
      setSelectedBedToEdit(null);
    } catch (error) {
      console.error('Error deleting bed:', error);
      setBedError(error.response?.data?.message || 'Failed to delete bed');
    }
  };

  const handleEditProperty = async () => {
    if (!editedProperty.name || !editedProperty.address) {
      setEditError('Name and address are required');
      return;
    }

    try {
      const response = await axios.put(`/api/properties/${id}`, editedProperty);
      setProperty(response.data);
      setOpenEditDialog(false);
    } catch (error) {
      console.error('Error updating property:', error);
      setEditError(error.response?.data?.message || 'Failed to update property');
    }
  };

  const handleDeleteProperty = async () => {
    try {
      await axios.delete(`/api/properties/${id}`);
      navigate('/properties');
    } catch (error) {
      console.error('Error deleting property:', error);
      setError(error.response?.data?.message || 'Failed to delete property');
    }
  };

  const handleImageUpdate = async (imageUrl) => {
    setProperty(prev => ({
      ...prev,
      imageUrl
    }));
  };

  const fetchData = async () => {
    try {
      const [propertyRes, residentsRes] = await Promise.all([
        axios.get(`/api/properties/${id}`),
        axios.get('/api/auth/residents')
      ]);

      setProperty(propertyRes.data);

      const allPropertiesRes = await axios.get('/api/properties');
      const allProperties = allPropertiesRes.data;

      const assignedResidentIds = new Set();
      allProperties.forEach(property => {
        property.rooms.forEach(room => {
          room.beds.forEach(bed => {
            if (bed.resident) {
              assignedResidentIds.add(bed.resident._id);
            }
          });
        });
      });

      const availableResidents = residentsRes.data.filter(
        resident => resident.isApproved && !assignedResidentIds.has(resident._id)
      );

      setResidents(availableResidents);
      setError(null);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.response?.data?.message || 'Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  if (loading) {
    return (
      <Container maxWidth="lg">
        <Box sx={{ mt: 4, mb: 4 }}>
          <Typography 
            sx={{ 
              textAlign: 'center',
              color: 'text.secondary',
              py: 4
            }}
          >
            Loading...
          </Typography>
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg">
        <Box sx={{ mt: 4, mb: 4 }}>
          <Alert 
            severity="error"
            sx={{ 
              borderRadius: 2,
              boxShadow: '0 4px 12px rgba(211, 47, 47, 0.1)'
            }}
          >
            {error}
          </Alert>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/properties')}
          sx={{ 
            mb: 3,
            borderRadius: 1,
            textTransform: 'none',
            fontWeight: 500,
            py: 1,
            px: 2
          }}
        >
          Back to Properties
        </Button>

        <Box sx={{ 
          display: 'flex', 
          gap: 3,
          flexDirection: { xs: 'column', md: 'row' }
        }}>
          {/* Left Sidebar - Sticky on desktop, full width on mobile */}
          <Box
            sx={{
              width: { xs: '100%', md: '380px' },
              flexShrink: 0,
              position: { xs: 'relative', md: 'sticky' },
              top: { xs: 0, md: '24px' },
              alignSelf: 'flex-start'
            }}
          >
            <Stack spacing={3}>
              <PropertySummary 
                property={property}
                onImageUpdate={handleImageUpdate}
                setError={setError}
                onEdit={() => {
                  setEditedProperty({
                    name: property.name,
                    address: property.address,
                    description: property.description || ''
                  });
                  setEditError('');
                  setOpenEditDialog(true);
                }}
                onDelete={() => setOpenDeleteDialog(true)}
                fetchData={fetchData}
              />
              
              <Paper 
                elevation={0} 
                sx={{ 
                  p: 3,
                  borderRadius: 1,
                  border: '1px solid',
                  borderColor: 'divider',
                  bgcolor: '#ffffff'
                }}
              >
                <Typography 
                  variant="h6" 
                  sx={{ 
                    fontWeight: 600,
                    mb: 2
                  }}
                >
                  Property Description
                </Typography>
                <Typography 
                  sx={{ 
                    color: 'text.secondary',
                    fontSize: '0.875rem'
                  }}
                >
                  {property.description || 'No description available'}
                </Typography>
              </Paper>
            </Stack>
          </Box>

          {/* Right Content - Scrollable */}
          <Box sx={{ 
            flex: 1,
            width: { xs: '100%', md: 'auto' }
          }}>
            <RoomsAndAssignments
              property={property}
              onAssignBed={handleAssignBed}
              onUnassignBed={handleUnassignBed}
              onAddRoom={() => {
                setEditedRoom({ name: '', squareFootage: '' });
                setRoomError('');
                setOpenAddRoomDialog(true);
              }}
              onEditRoom={(room) => {
                setSelectedRoom(room);
                setEditedRoom({
                  name: room.name,
                  squareFootage: room.squareFootage.toString()
                });
                setRoomError('');
                setOpenEditRoomDialog(true);
              }}
              onDeleteRoom={(room) => {
                setSelectedRoom(room);
                setOpenDeleteRoomDialog(true);
              }}
              onAddBed={(room) => {
                setSelectedRoom(room);
                setEditedBed({ name: '' });
                setBedError('');
                setOpenAddBedDialog(true);
              }}
              onEditBed={(room, bed) => {
                setSelectedRoom(room);
                setSelectedBedToEdit(bed);
                setEditedBed({ name: bed.name });
                setBedError('');
                setOpenEditBedDialog(true);
              }}
              onDeleteBed={(room, bed) => {
                setSelectedRoom(room);
                setSelectedBedToEdit(bed);
                setOpenDeleteBedDialog(true);
              }}
            />
          </Box>
        </Box>

        <PropertyDialogs
          property={property}
          residents={residents}
          openAssignDialog={openAssignDialog}
          openEditDialog={openEditDialog}
          openDeleteDialog={openDeleteDialog}
          openAddRoomDialog={openAddRoomDialog}
          openEditRoomDialog={openEditRoomDialog}
          openDeleteRoomDialog={openDeleteRoomDialog}
          openAddBedDialog={openAddBedDialog}
          openEditBedDialog={openEditBedDialog}
          openDeleteBedDialog={openDeleteBedDialog}
          selectedAssignmentBed={selectedAssignmentBed}
          selectedResident={selectedResident}
          selectedRoom={selectedRoom}
          selectedBedToEdit={selectedBedToEdit}
          editedProperty={editedProperty}
          editedRoom={editedRoom}
          editedBed={editedBed}
          assignmentError={assignmentError}
          editError={editError}
          roomError={roomError}
          bedError={bedError}
          onAssignmentSubmit={handleAssignmentSubmit}
          onEditPropertySubmit={handleEditProperty}
          onDeletePropertyConfirm={handleDeleteProperty}
          onAddRoomSubmit={handleAddRoom}
          onEditRoomSubmit={handleEditRoom}
          onDeleteRoomConfirm={handleDeleteRoom}
          onAddBedSubmit={handleAddBed}
          onEditBedSubmit={handleEditBed}
          onDeleteBedConfirm={handleDeleteBed}
          setOpenAssignDialog={setOpenAssignDialog}
          setOpenEditDialog={setOpenEditDialog}
          setOpenDeleteDialog={setOpenDeleteDialog}
          setOpenAddRoomDialog={setOpenAddRoomDialog}
          setOpenEditRoomDialog={setOpenEditRoomDialog}
          setOpenDeleteRoomDialog={setOpenDeleteRoomDialog}
          setOpenAddBedDialog={setOpenAddBedDialog}
          setOpenEditBedDialog={setOpenEditBedDialog}
          setOpenDeleteBedDialog={setOpenDeleteBedDialog}
          setSelectedResident={setSelectedResident}
          setEditedProperty={setEditedProperty}
          setEditedRoom={setEditedRoom}
          setEditedBed={setEditedBed}
        />
      </Box>
    </Container>
  );
};

export default PropertyDetailsPage;