import React, { useState } from 'react';
import { 
  Typography, 
  Box,
  Grid,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PaymentsIcon from '@mui/icons-material/Payments';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import BlockIcon from '@mui/icons-material/Block';
import RefreshIcon from '@mui/icons-material/Refresh';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: '#ffffff',
  border: '1px solid',
  borderColor: theme.palette.divider,
  borderRadius: `${theme.spacing(2)} !important`,
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
  transition: 'all 0.3s ease-in-out',
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    boxShadow: '0 12px 48px rgba(0, 0, 0, 0.12)',
    transform: 'scale(1.02)',
    borderColor: 'success.main'
  },
  '& .MuiAccordionSummary-root': {
    padding: theme.spacing(2),
    minHeight: 'auto',
    '&.Mui-expanded': {
      minHeight: 'auto',
    }
  },
  '& .MuiAccordionSummary-content': {
    margin: '0 !important'
  },
  '& .MuiAccordionDetails-root': {
    padding: theme.spacing(0, 2, 2, 2),
    borderTop: '1px solid',
    borderTopColor: theme.palette.divider
  }
}));

const StatBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1.5),
  backgroundColor: 'rgba(76, 175, 80, 0.08)',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: 'rgba(76, 175, 80, 0.12)',
    transform: 'translateY(-2px)'
  }
}));

const PaymentsCard = ({ payments = [] }) => {
  const [expanded, setExpanded] = useState(false);

  // Filter payments for current month
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  
  const currentMonthPayments = payments.filter(payment => {
    const paymentDate = new Date(payment.completedAt || payment.createdAt);
    return paymentDate.getMonth() === currentMonth && 
           paymentDate.getFullYear() === currentYear;
  });

  // Calculate payment statistics for current month
  const totalPayments = currentMonthPayments.length;
  const completedPayments = currentMonthPayments.filter(p => p.status === 'Completed').length;
  const pendingPayments = currentMonthPayments.filter(p => p.status === 'Pending').length;
  const failedPayments = currentMonthPayments.filter(p => p.status === 'Failed').length;
  const refundedPayments = currentMonthPayments.filter(p => p.status === 'Refunded').length;

  // Calculate total amount (excluding refunded payments)
  const totalAmount = currentMonthPayments
    .filter(p => p.status === 'Completed')
    .reduce((sum, payment) => sum + payment.amount, 0);

  // Calculate success rate (completed vs total non-refunded payments)
  const nonRefundedTotal = totalPayments - refundedPayments;
  const successRate = nonRefundedTotal > 0 ? ((completedPayments / nonRefundedTotal) * 100).toFixed(0) : 0;

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  return (
    <StyledAccordion 
      expanded={expanded} 
      onChange={handleAccordionChange}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: 'success.main' }} />}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '100%' }}>
          <Box
            sx={{
              width: 48,
              height: 48,
              borderRadius: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'rgba(76, 175, 80, 0.08)'
            }}
          >
            <PaymentsIcon sx={{ color: 'success.main', fontSize: 28 }} />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography variant="h5" sx={{ fontWeight: 700, color: 'text.primary', mb: 0.5 }}>
              ${totalAmount.toLocaleString()}
            </Typography>
            <Typography variant="body1" sx={{ color: 'text.secondary', fontWeight: 500 }}>
              Current Month Payments
            </Typography>
          </Box>
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <StatBox>
              <CheckCircleIcon sx={{ color: 'success.main', fontSize: 24 }} />
              <Box>
                <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                  Completed
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                  {completedPayments}
                </Typography>
              </Box>
            </StatBox>
          </Grid>

          <Grid item xs={6}>
            <StatBox>
              <PendingIcon sx={{ color: 'warning.main', fontSize: 24 }} />
              <Box>
                <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                  Pending
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                  {pendingPayments}
                </Typography>
              </Box>
            </StatBox>
          </Grid>

          <Grid item xs={6}>
            <StatBox>
              <BlockIcon sx={{ color: 'error.main', fontSize: 24 }} />
              <Box>
                <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                  Failed
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                  {failedPayments}
                </Typography>
              </Box>
            </StatBox>
          </Grid>

          <Grid item xs={6}>
            <StatBox>
              <RefreshIcon sx={{ color: 'info.main', fontSize: 24 }} />
              <Box>
                <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                  Refunded
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                  {refundedPayments}
                </Typography>
              </Box>
            </StatBox>
          </Grid>
        </Grid>

        <Box sx={{ 
          mt: 3, 
          p: 2.5, 
          borderRadius: 2,
          bgcolor: 'rgba(76, 175, 80, 0.04)',
          border: '1px solid',
          borderColor: 'success.light',
        }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                Success Rate
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 700, color: 'success.main' }}>
                {successRate}%
              </Typography>
            </Box>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                Active Amount
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 700 }}>
                ${totalAmount.toLocaleString()}
              </Typography>
            </Box>
          </Stack>
        </Box>
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default PaymentsCard; 