import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Chip,
  Button,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  CircularProgress,
  Alert,
  IconButton,
  Tooltip,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  Collapse,
} from '@mui/material';
import {
  Refresh as RefreshIcon,
  CheckCircle as CheckCircleIcon,
  Warning as WarningIcon,
  Error as ErrorIcon,
  AttachMoney as MoneyIcon,
  CalendarMonth as CalendarIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  Add as AddIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Email as EmailIcon,
  Sms as SmsIcon
} from '@mui/icons-material';
import axios from '../../utils/axios';
import EmailDialog from '../residents/list/dialogs/EmailDialog';
import SmsDialog from '../residents/list/dialogs/SmsDialog';

const RentTracker = () => {
  const [residents, setResidents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterProperty, setFilterProperty] = useState('all');
  const [filterStatus, setFilterStatus] = useState('all');
  const [properties, setProperties] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'asc'
  });
  const [openManualPayment, setOpenManualPayment] = useState(false);
  const [manualPayment, setManualPayment] = useState({
    residentId: '',
    amount: '',
    paymentMethod: 'cash',
    referenceNumber: '',
    notes: ''
  });
  const [manualPaymentError, setManualPaymentError] = useState('');
  const [expandedRow, setExpandedRow] = useState(null);
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [smsDialogOpen, setSmsDialogOpen] = useState(false);
  const [selectedResident, setSelectedResident] = useState(null);
  const [appName, setAppName] = useState('');
  const navigate = useNavigate();

  const months = [
    { value: 0, label: 'January' },
    { value: 1, label: 'February' },
    { value: 2, label: 'March' },
    { value: 3, label: 'April' },
    { value: 4, label: 'May' },
    { value: 5, label: 'June' },
    { value: 6, label: 'July' },
    { value: 7, label: 'August' },
    { value: 8, label: 'September' },
    { value: 9, label: 'October' },
    { value: 10, label: 'November' },
    { value: 11, label: 'December' }
  ];

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null);
      
      // Get all residents, properties, and settings
      const [residentsRes, propertiesRes, settingsRes] = await Promise.all([
        axios.get('/api/auth/residents'),
        axios.get('/api/properties'),
        axios.get('/api/settings')
      ]);

      setAppName(settingsRes.data.appName);
      
      // Get rent payments for the current month
      const startDate = new Date(currentYear, currentMonth, 1);
      const endDate = new Date(currentYear, currentMonth + 1, 0);
      
      const paymentsRes = await axios.get('/api/payments/all', {
        params: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString()
        }
      });

      // Create a map of resident IDs to their property assignments
      const residentPropertyMap = new Map();
      propertiesRes.data.forEach(property => {
        property.rooms.forEach(room => {
          room.beds.forEach(bed => {
            if (bed.resident) {
              // Convert the resident ID to string for consistent comparison
              const residentId = typeof bed.resident === 'object' ? bed.resident._id : bed.resident;
              residentPropertyMap.set(residentId, {
                property: property,
                room: room.name,
                bed: bed.name
              });
            }
          });
        });
      });

      // Map payments to residents, considering both manual and Stripe payments
      const residentsWithRent = residentsRes.data.map(resident => {
        // Find all completed payments for this resident in the current month
        const residentPayments = paymentsRes.data
          .filter(p => 
            p.resident._id === resident._id && 
            p.status === 'Completed'
          )
          .sort((a, b) => new Date(b.completedAt || b.createdAt) - new Date(a.completedAt || a.createdAt));

        // Calculate total amount paid from all payments
        const totalPaid = residentPayments.reduce((sum, payment) => sum + payment.amount, 0);
        
        // Get the most recent payment date
        const latestPayment = residentPayments[0];
        const paymentDate = latestPayment ? (latestPayment.completedAt || latestPayment.createdAt) : null;

        // Get the resident's property assignment
        const assignment = residentPropertyMap.get(resident._id);

        const customSettings = resident.rentSettings || {};
        const defaultSettings = settingsRes.data.rentSettings || {};

        return {
          ...resident,
          property: assignment?.property?.name || 'Not Assigned',
          room: assignment?.room || '',
          bed: assignment?.bed || '',
          rentAmount: customSettings.customAmount || defaultSettings.defaultAmount || 0,
          dueDate: customSettings.customDueDate || defaultSettings.defaultDueDate || 1,
          rentPaid: totalPaid,
          paymentStatus: totalPaid > 0 ? 'paid' : 'unpaid',
          paymentDate: paymentDate,
          payments: residentPayments
        };
      });

      setResidents(residentsWithRent);
      setProperties(propertiesRes.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.response?.data?.message || 'Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentMonth, currentYear]);

  const getStatusColor = (status, dueDate, amountDue, amountPaid, resident, selectedDate) => {
    // Check if resident is active and the selected date is within their residency period
    const isActiveInMonth = isResidentActiveInMonth(resident, selectedDate);
    if (!isActiveInMonth) {
      return 'default';
    }

    const today = new Date();
    const paymentDue = new Date(currentYear, currentMonth, dueDate);
    
    if (amountPaid > 0) {
      if (amountPaid >= amountDue) {
        return 'success';
      }
      return today > paymentDue ? 'error' : 'warning';
    }
    
    return today > paymentDue ? 'error' : 'warning';
  };

  const getStatusLabel = (status, dueDate, amountDue, amountPaid, resident, selectedDate) => {
    // Check if resident is active and the selected date is within their residency period
    const isActiveInMonth = isResidentActiveInMonth(resident, selectedDate);
    if (!isActiveInMonth) {
      return 'Not Applicable';
    }

    const today = new Date();
    const paymentDue = new Date(currentYear, currentMonth, dueDate);
    
    if (amountPaid > 0) {
      if (amountPaid >= amountDue) {
        return resident.status === 'active' ? 'Paid' : 'Paid (Inactive)';
      }
      return resident.status === 'active' 
        ? (today > paymentDue ? 'Partial - Overdue' : 'Partial')
        : 'Partial (Inactive)';
    }
    
    return today > paymentDue ? 'Overdue' : 'Pending';
  };

  // Helper function to check if a resident is active in the selected month
  const isResidentActiveInMonth = (resident, selectedDate) => {
    const moveInDate = resident.moveInDate ? new Date(resident.moveInDate) : null;
    const moveOutDate = resident.moveOutDate ? new Date(resident.moveOutDate) : null;
    const startOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
    const endOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0);

    // If resident has a payment in this month, include them regardless of status
    if (resident.paymentDate) {
      const paymentDate = new Date(resident.paymentDate);
      if (paymentDate >= startOfMonth && paymentDate <= endOfMonth) {
        return true;
      }
    }

    // For active residents, check their residency period
    if (resident.status === 'active') {
      // Return false if no move-in date
      if (!moveInDate) {
        return false;
      }

      // Check if the selected month is after move-in and before move-out (if applicable)
      return (
        moveInDate <= endOfMonth && 
        (!moveOutDate || moveOutDate >= startOfMonth)
      );
    }

    return false;
  };

  const filteredResidents = residents.filter(resident => {
    const selectedDate = new Date(currentYear, currentMonth, 1);
    
    // First check if resident is active in the selected month
    if (!isResidentActiveInMonth(resident, selectedDate)) {
      return false;
    }

    // Then apply property filter
    if (filterProperty !== 'all' && resident.property !== filterProperty) {
      return false;
    }

    // Apply status filter
    if (filterStatus !== 'all') {
      const status = getStatusLabel(resident.paymentStatus, resident.dueDate, resident.rentAmount, resident.rentPaid, resident, selectedDate);
      
      switch (filterStatus) {
        case 'paid':
          return status === 'Paid' || status === 'Paid (Inactive)';
        case 'partial':
          return status.includes('Partial');
        case 'pending':
          return status === 'Pending';
        case 'overdue':
          return status.includes('Overdue');
        default:
          return true;
      }
    }

    return true;
  });

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const formatAmount = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  // Calculate summary metrics
  const calculateSummary = (residents) => {
    const summary = {
      totalResidents: residents.length,
      totalExpected: 0,
      totalPaid: 0,
      fullyPaid: 0,
      partialPaid: 0,
      unpaid: 0,
      overdue: 0
    };

    residents.forEach(resident => {
      summary.totalExpected += resident.rentAmount;
      summary.totalPaid += resident.rentPaid;
      
      if (resident.rentPaid >= resident.rentAmount) {
        summary.fullyPaid++;
      } else if (resident.rentPaid > 0) {
        summary.partialPaid++;
      } else {
        summary.unpaid++;
      }

      const today = new Date();
      const dueDate = new Date(currentYear, currentMonth, resident.dueDate);
      if (today > dueDate && resident.rentPaid < resident.rentAmount) {
        summary.overdue++;
      }
    });

    return summary;
  };

  // Sorting function
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const getSortedResidents = (residents) => {
    if (!sortConfig.key) return residents;

    return [...residents].sort((a, b) => {
      if (sortConfig.key === 'name') {
        const nameA = `${a.firstName} ${a.lastName}`;
        const nameB = `${b.firstName} ${b.lastName}`;
        return sortConfig.direction === 'asc' 
          ? nameA.localeCompare(nameB)
          : nameB.localeCompare(nameA);
      }
      
      if (sortConfig.key === 'balance') {
        const balanceA = a.rentAmount - a.rentPaid;
        const balanceB = b.rentAmount - b.rentPaid;
        return sortConfig.direction === 'asc'
          ? balanceA - balanceB
          : balanceB - balanceA;
      }

      if (['rentAmount', 'rentPaid', 'dueDate'].includes(sortConfig.key)) {
        return sortConfig.direction === 'asc'
          ? a[sortConfig.key] - b[sortConfig.key]
          : b[sortConfig.key] - a[sortConfig.key];
      }

      return 0;
    });
  };

  const summary = calculateSummary(filteredResidents);

  const handleManualPayment = async () => {
    try {
      setManualPaymentError('');
      
      if (!manualPayment.residentId || !manualPayment.amount) {
        setManualPaymentError('Please fill in all required fields');
        return;
      }

      const response = await axios.post('/api/payments/manual', {
        resident: manualPayment.residentId,
        amount: parseFloat(manualPayment.amount),
        description: `Manual ${manualPayment.paymentMethod.toUpperCase()} payment${manualPayment.referenceNumber ? ` - Ref#${manualPayment.referenceNumber}` : ''}`,
        paymentMethod: manualPayment.paymentMethod,
        referenceNumber: manualPayment.referenceNumber,
        notes: manualPayment.notes
      });

      await fetchData();
      setOpenManualPayment(false);
      setManualPayment({
        residentId: '',
        amount: '',
        paymentMethod: 'cash',
        referenceNumber: '',
        notes: ''
      });
    } catch (error) {
      console.error('Error submitting manual payment:', error);
      setManualPaymentError(error.response?.data?.message || 'Failed to submit payment');
    }
  };

  const handleResidentClick = (residentId) => {
    navigate(`/residents/${residentId}`);
  };

  const handleRowClick = (residentId) => {
    setExpandedRow(expandedRow === residentId ? null : residentId);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 3 }}>
      {error && (
        <Alert 
          severity="error" 
          sx={{ mb: 3 }}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => setError(null)}
            >
              Dismiss
            </Button>
          }
        >
          {error}
        </Alert>
      )}

      <Paper
        elevation={0}
        sx={{
          mb: 3,
          p: { xs: 2, sm: 2.5 },
          backgroundColor: '#ffffff',
          borderRadius: 1,
          border: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: { xs: 'stretch', md: 'center' },
          gap: { xs: 2, md: 0 },
          mb: { xs: 2, md: 2.5 }
        }}>
          <Box sx={{ flex: 1 }}>
            <Typography 
              variant="h5" 
              component="h1" 
              sx={{ 
                fontWeight: 600, 
                color: 'primary.main',
                mb: { xs: 0.5, md: 0.25 }
              }}
            >
              Rent Tracker
            </Typography>
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{ 
                display: { xs: 'none', sm: 'block' },
                fontWeight: 500
              }}
            >
              Track and manage resident rent payments
            </Typography>
          </Box>
          <Stack 
            direction={{ xs: 'column', sm: 'row' }} 
            spacing={2}
            sx={{ 
              width: { xs: '100%', md: 'auto' },
              alignItems: { xs: 'stretch', sm: 'center' }
            }}
          >
            <Button
              startIcon={<RefreshIcon />}
              onClick={fetchData}
              variant="outlined"
              sx={{
                borderRadius: 1,
                textTransform: 'none',
                fontWeight: 500,
                borderWidth: 1.5,
                minWidth: { sm: 120 },
                py: 1
              }}
            >
              Refresh
            </Button>
            <Button
              startIcon={<AddIcon />}
              onClick={() => setOpenManualPayment(true)}
              variant="contained"
              sx={{
                borderRadius: 1,
                textTransform: 'none',
                fontWeight: 500,
                minWidth: { sm: 160 },
                py: 1
              }}
            >
              Manual Payment
            </Button>
          </Stack>
        </Box>

        <Box sx={{ 
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          gap: 2,
          mb: 3
        }}>
          <FormControl 
            size="small" 
            sx={{ minWidth: { xs: '100%', sm: 200 } }}
          >
            <InputLabel>Property</InputLabel>
            <Select
              value={filterProperty}
              onChange={(e) => setFilterProperty(e.target.value)}
              label="Property"
            >
              <MenuItem value="all">All Properties</MenuItem>
              {properties.map((property) => (
                <MenuItem key={property._id} value={property.name}>
                  {property.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl 
            size="small" 
            sx={{ minWidth: { xs: '100%', sm: 200 } }}
          >
            <InputLabel>Payment Status</InputLabel>
            <Select
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
              label="Payment Status"
            >
              <MenuItem value="all">All Statuses</MenuItem>
              <MenuItem value="paid">Fully Paid</MenuItem>
              <MenuItem value="partial">Partial Payment</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="overdue">Overdue</MenuItem>
            </Select>
          </FormControl>

          <FormControl 
            size="small" 
            sx={{ 
              minWidth: { xs: '100%', sm: 200 }
            }}
          >
            <InputLabel>Month</InputLabel>
            <Select
              value={currentMonth}
              onChange={(e) => setCurrentMonth(e.target.value)}
              label="Month"
            >
              {months.map((month) => (
                <MenuItem key={month.value} value={month.value}>
                  {month.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl 
            size="small" 
            sx={{ 
              minWidth: { xs: '100%', sm: 200 }
            }}
          >
            <InputLabel>Year</InputLabel>
            <Select
              value={currentYear}
              onChange={(e) => setCurrentYear(e.target.value)}
              label="Year"
            >
              {[...Array(5)].map((_, i) => {
                const year = new Date().getFullYear() - i;
                return (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      </Paper>

      <TableContainer 
        component={Paper}
        elevation={0}
        sx={{ 
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 1,
          overflow: 'hidden',
          mb: 3
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" sx={{ fontWeight: 600, bgcolor: '#f5f6f8' }}></TableCell>
              <TableCell sx={{ fontWeight: 600, bgcolor: '#f5f6f8' }}>
                <TableSortLabel
                  active={sortConfig.key === 'name'}
                  direction={sortConfig.direction}
                  onClick={() => handleSort('name')}
                >
                  Resident
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ fontWeight: 600, bgcolor: '#f5f6f8' }}>Property</TableCell>
              <TableCell sx={{ fontWeight: 600, bgcolor: '#f5f6f8' }}>
                <TableSortLabel
                  active={sortConfig.key === 'dueDate'}
                  direction={sortConfig.direction}
                  onClick={() => handleSort('dueDate')}
                >
                  Due Date
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ fontWeight: 600, bgcolor: '#f5f6f8' }}>
                <TableSortLabel
                  active={sortConfig.key === 'rentAmount'}
                  direction={sortConfig.direction}
                  onClick={() => handleSort('rentAmount')}
                >
                  Amount Due
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ fontWeight: 600, bgcolor: '#f5f6f8' }}>
                <TableSortLabel
                  active={sortConfig.key === 'rentPaid'}
                  direction={sortConfig.direction}
                  onClick={() => handleSort('rentPaid')}
                >
                  Amount Paid
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ fontWeight: 600, bgcolor: '#f5f6f8' }}>
                <TableSortLabel
                  active={sortConfig.key === 'balance'}
                  direction={sortConfig.direction}
                  onClick={() => handleSort('balance')}
                >
                  Balance
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ fontWeight: 600, bgcolor: '#f5f6f8' }}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={8} align="center" sx={{ py: 3 }}>
                  <CircularProgress size={32} />
                </TableCell>
              </TableRow>
            ) : filteredResidents.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8} align="center" sx={{ py: 3 }}>
                  <Typography color="text.secondary">
                    No residents found matching the current filters.
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              getSortedResidents(filteredResidents).map((resident) => {
                const selectedDate = new Date(currentYear, currentMonth, 1);
                const balance = resident.rentAmount - resident.rentPaid;
                const status = getStatusLabel(resident.paymentStatus, resident.dueDate, resident.rentAmount, resident.rentPaid, resident, selectedDate);
                const isExpanded = expandedRow === resident._id;
                const isOverdue = status.includes('Overdue');
                
                return (
                  <React.Fragment key={resident._id}>
                    <TableRow 
                      sx={{ 
                        '&:hover': { bgcolor: '#f8f9fb' },
                        bgcolor: status.includes('Overdue') ? '#fff8f8' : 
                                status.includes('Partial') ? '#fff9c4' :
                                status === 'Paid' ? '#f1f8e9' : 'inherit',
                        cursor: 'pointer'
                      }}
                      onClick={() => handleRowClick(resident._id)}
                    >
                      <TableCell padding="checkbox">
                        <IconButton size="small">
                          {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <Typography 
                          variant="body2"
                          sx={{ 
                            fontWeight: 500,
                            cursor: 'pointer',
                            display: 'inline',
                            '&:hover': {
                              color: 'primary.main',
                              textDecoration: 'underline'
                            }
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleResidentClick(resident._id);
                          }}
                        >
                          {resident.firstName} {resident.lastName}
                        </Typography>
                      </TableCell>
                      <TableCell>{resident.property}</TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <CalendarIcon sx={{ fontSize: 20, color: 'text.secondary' }} />
                          Day {resident.dueDate}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <MoneyIcon sx={{ fontSize: 20, color: 'text.secondary' }} />
                          {formatAmount(resident.rentAmount)}
                        </Box>
                      </TableCell>
                      <TableCell>{formatAmount(resident.rentPaid)}</TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <MoneyIcon sx={{ fontSize: 20, color: balance > 0 ? 'error.main' : 'success.main' }} />
                          {formatAmount(balance)}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Chip
                            label={status}
                            color={getStatusColor(resident.paymentStatus, resident.dueDate, resident.rentAmount, resident.rentPaid, resident, selectedDate)}
                            size="small"
                            sx={{ fontWeight: 500 }}
                          />
                          {isOverdue && (
                            <>
                              <Tooltip title="Send Email Reminder">
                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedResident(resident);
                                    setEmailDialogOpen(true);
                                  }}
                                >
                                  <EmailIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Send SMS Reminder">
                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedResident(resident);
                                    setSmsDialogOpen(true);
                                  }}
                                >
                                  <SmsIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                          <Box sx={{ margin: 2 }}>
                            <Typography variant="h6" gutterBottom>
                              Payment History
                            </Typography>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Date</TableCell>
                                  <TableCell>Amount</TableCell>
                                  <TableCell>Method</TableCell>
                                  <TableCell>Reference</TableCell>
                                  <TableCell>Description</TableCell>
                                  <TableCell>Processed By</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {resident.payments.map((payment) => (
                                  <TableRow key={payment._id}>
                                    <TableCell>
                                      {formatDate(payment.completedAt || payment.createdAt)}
                                    </TableCell>
                                    <TableCell>
                                      {formatAmount(payment.amount)}
                                    </TableCell>
                                    <TableCell>
                                      {payment.paymentMethod === 'stripe' ? 'Online Payment' : 
                                        payment.paymentMethod.charAt(0).toUpperCase() + 
                                        payment.paymentMethod.slice(1).replace('_', ' ')
                                      }
                                    </TableCell>
                                    <TableCell>
                                      {payment.referenceNumber || '-'}
                                    </TableCell>
                                    <TableCell>
                                      {payment.description}
                                    </TableCell>
                                    <TableCell>
                                      {payment.paymentMethod === 'stripe' ? 'Online Payment' : 
                                        payment.completedBy ? `${payment.completedBy.firstName} ${payment.completedBy.lastName}` : 'System'
                                      }
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Summary Section moved to bottom */}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} md={4}>
          <Paper
            elevation={0}
            sx={{
              p: 2,
              height: '100%',
              background: 'linear-gradient(145deg, #e3f2fd 0%, #bbdefb 100%)',
              borderRadius: 1
            }}
          >
            <Typography variant="h6" gutterBottom>Financial Summary</Typography>
            <Stack spacing={1}>
              <Typography variant="body2">
                Expected: {formatAmount(summary.totalExpected)}
              </Typography>
              <Typography variant="body2">
                Collected: {formatAmount(summary.totalPaid)}
              </Typography>
              <Typography variant="body2">
                Outstanding: {formatAmount(summary.totalExpected - summary.totalPaid)}
              </Typography>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            elevation={0}
            sx={{
              p: 2,
              height: '100%',
              background: 'linear-gradient(145deg, #f5f5f5 0%, #eeeeee 100%)',
              borderRadius: 1
            }}
          >
            <Typography variant="h6" gutterBottom>Payment Status</Typography>
            <Stack spacing={1}>
              <Typography variant="body2">
                Fully Paid: {summary.fullyPaid} residents
              </Typography>
              <Typography variant="body2">
                Partial Payments: {summary.partialPaid} residents
              </Typography>
              <Typography variant="body2">
                No Payments: {summary.unpaid} residents
              </Typography>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            elevation={0}
            sx={{
              p: 2,
              height: '100%',
              background: 'linear-gradient(145deg, #ffebee 0%, #ffcdd2 100%)',
              borderRadius: 1
            }}
          >
            <Typography variant="h6" gutterBottom>Attention Needed</Typography>
            <Stack spacing={1}>
              <Typography variant="body2">
                Overdue Payments: {summary.overdue} residents
              </Typography>
              <Typography variant="body2">
                Collection Rate: {((summary.totalPaid / summary.totalExpected) * 100).toFixed(1)}%
              </Typography>
            </Stack>
          </Paper>
        </Grid>
      </Grid>

      <Dialog 
        open={openManualPayment} 
        onClose={() => setOpenManualPayment(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Enter Manual Payment</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Resident</InputLabel>
              <Select
                value={manualPayment.residentId}
                onChange={(e) => {
                  setManualPayment(prev => ({ 
                    ...prev, 
                    residentId: e.target.value
                  }));
                }}
                label="Resident"
              >
                {residents
                  .filter(r => r.status === 'active')
                  .map((resident) => (
                    <MenuItem key={resident._id} value={resident._id}>
                      {resident.firstName} {resident.lastName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <TextField
              fullWidth
              label="Amount"
              type="number"
              value={manualPayment.amount}
              onChange={(e) => setManualPayment(prev => ({ ...prev, amount: e.target.value }))}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />

            <FormControl component="fieldset">
              <FormLabel component="legend">Payment Method</FormLabel>
              <RadioGroup
                row
                value={manualPayment.paymentMethod}
                onChange={(e) => setManualPayment(prev => ({ ...prev, paymentMethod: e.target.value }))}
              >
                <FormControlLabel value="cash" control={<Radio />} label="Cash" />
                <FormControlLabel value="check" control={<Radio />} label="Check" />
                <FormControlLabel value="money_order" control={<Radio />} label="Money Order" />
              </RadioGroup>
            </FormControl>

            <TextField
              fullWidth
              label="Reference Number"
              value={manualPayment.referenceNumber}
              onChange={(e) => setManualPayment(prev => ({ ...prev, referenceNumber: e.target.value }))}
              helperText="Check number or money order number if applicable"
            />

            <TextField
              fullWidth
              label="Notes"
              multiline
              rows={3}
              value={manualPayment.notes}
              onChange={(e) => setManualPayment(prev => ({ ...prev, notes: e.target.value }))}
            />

            {manualPaymentError && (
              <Alert severity="error">{manualPaymentError}</Alert>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 2.5 }}>
          <Button 
            onClick={() => setOpenManualPayment(false)}
            sx={{ textTransform: 'none' }}
          >
            Cancel
          </Button>
          <Button 
            variant="contained"
            onClick={handleManualPayment}
            sx={{ textTransform: 'none' }}
          >
            Submit Payment
          </Button>
        </DialogActions>
      </Dialog>

      {/* Email Dialog */}
      <EmailDialog
        open={emailDialogOpen}
        onClose={() => {
          setEmailDialogOpen(false);
          setSelectedResident(null);
        }}
        selectedResidents={selectedResident ? [selectedResident] : []}
        error={error}
        setError={setError}
        initialSubject={`${appName} - Overdue Rent Notice`}
        initialMessage={selectedResident ? 
          `Dear ${selectedResident.firstName} ${selectedResident.lastName},\n\n` +
          `This is a reminder from ${appName} regarding your overdue rent payment.\n\n` +
          `Current Balance Details:\n` +
          `Expected Amount: ${formatAmount(selectedResident.rentAmount)}\n` +
          `Amount Paid: ${formatAmount(selectedResident.rentPaid)}\n` +
          `Balance Due: ${formatAmount(selectedResident.rentAmount - selectedResident.rentPaid)}\n\n` +
          `You can make a payment by logging into the housing portal at ${process.env.REACT_APP_API_URL} or contact staff to make payment arrangements.\n\n` +
          `Best regards,\n${appName} Team`
          : ''
        }
      />

      {/* SMS Dialog */}
      <SmsDialog
        open={smsDialogOpen}
        onClose={() => {
          setSmsDialogOpen(false);
          setSelectedResident(null);
        }}
        selectedResidents={selectedResident ? [selectedResident] : []}
        error={error}
        setError={setError}
        initialMessage={selectedResident ?
          `${appName} Rent Payment Reminder:\n\n` +
          `Hi ${selectedResident.firstName}, your rent payment of ${formatAmount(selectedResident.rentAmount - selectedResident.rentPaid)} is overdue. Login to ${process.env.REACT_APP_API_URL} to make a payment or contact staff for payment arrangements.`
          : ''
        }
      />
    </Container>
  );
};

export default RentTracker; 