import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Chip,
  CircularProgress,
  Alert,
  Stack,
  Paper,
} from '@mui/material';
import {
  Description as DescriptionIcon,
  Error as ErrorIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from '../../utils/axios';

const ResidentForms = () => {
  const navigate = useNavigate();
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchForms = async () => {
    try {
      setError(null);
      setLoading(true);
      const response = await axios.get('/api/forms/published');
      setForms(response.data);
    } catch (error) {
      setError('Failed to fetch forms. Please try again.');
      console.error('Error fetching forms:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchForms();
  }, []);

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      {error && (
        <Alert 
          severity="error" 
          sx={{ mb: 3 }}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => setError(null)}
            >
              Dismiss
            </Button>
          }
        >
          {error}
        </Alert>
      )}

      <Paper
        elevation={0}
        sx={{
          mb: 3,
          p: 2.5,
          backgroundColor: '#ffffff',
          borderRadius: 1,
          border: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'stretch', sm: 'center' },
          gap: 2,
          mb: 2
        }}>
          <Box sx={{ flex: 1 }}>
            <Typography variant="h5" sx={{ fontWeight: 600, color: 'primary.main', mb: 0.25 }}>
              Available Forms
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
              View and fill out forms
            </Typography>
          </Box>
          <Button
            variant="outlined"
            onClick={() => navigate('/resident/form-submissions')}
            sx={{
              borderRadius: 1,
              textTransform: 'none',
              fontWeight: 500,
              py: 1,
              px: 3,
              borderWidth: 1.5
            }}
          >
            View My Submissions
          </Button>
        </Box>
      </Paper>

      {forms.length === 0 ? (
        <Paper
          elevation={0}
          sx={{
            p: 4,
            textAlign: 'center',
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 1,
            bgcolor: '#ffffff'
          }}
        >
          <ErrorIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
          <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
            No Forms Available
          </Typography>
          <Typography variant="body2" color="text.secondary">
            There are no forms available for submission at this time.
          </Typography>
        </Paper>
      ) : (
        <Grid container spacing={2}>
          {forms.map((form) => (
            <Grid item key={form._id} xs={12} sm={6} lg={4}>
              <Card
                elevation={0}
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  border: '1px solid',
                  borderColor: 'divider',
                  borderRadius: 1,
                  backgroundColor: '#ffffff',
                  transition: 'all 0.2s ease-in-out',
                  '&:hover': {
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
                    borderColor: 'primary.main',
                  }
                }}
              >
                <CardContent sx={{ p: 2.5, display: 'flex', flexDirection: 'column', height: '100%' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <DescriptionIcon sx={{ color: 'primary.main', mr: 1 }} />
                    <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.main' }}>
                      {form.title}
                    </Typography>
                  </Box>

                  <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    {form.description || 'No description provided'}
                  </Typography>

                  <Box sx={{ mb: 2 }}>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      Fields: {form.fields.length}
                    </Typography>
                    <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ mt: 1 }}>
                      {form.fields.slice(0, 3).map((field, index) => (
                        <Chip
                          key={index}
                          label={field.label}
                          size="small"
                          sx={{ 
                            bgcolor: 'background.default',
                            fontWeight: 500
                          }}
                        />
                      ))}
                      {form.fields.length > 3 && (
                        <Chip
                          label={`+${form.fields.length - 3} more`}
                          size="small"
                          sx={{ 
                            bgcolor: 'background.default',
                            fontWeight: 500
                          }}
                        />
                      )}
                    </Stack>
                  </Box>

                  <Box sx={{ mt: 'auto' }}>
                    <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mb: 2 }}>
                      Last Updated: {formatDate(form.updatedAt || form.createdAt)}
                    </Typography>

                    <Button
                      variant="contained"
                      fullWidth
                      onClick={() => navigate(`/forms/${form._id}/fill`)}
                      sx={{
                        borderRadius: 1,
                        textTransform: 'none',
                        fontWeight: 500,
                        py: 1
                      }}
                    >
                      Fill Out Form
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default ResidentForms; 