import React, { useState, useEffect } from 'react';
import axios from '../../utils/axios';
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Container,
  Paper,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Stack,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tabs,
  Tab,
  CardMedia,
  useTheme,
  alpha,
  Skeleton
} from '@mui/material';
import { ViewModule as ViewModuleIcon, ViewList as ViewListIcon, Add as AddIcon, Delete as DeleteIcon, LocationOn, ExpandMore as ExpandMoreIcon, Home as HomeIcon, Error as ErrorIcon, Edit as EditIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const PropertiesPage = () => {
  const navigate = useNavigate();
  const [properties, setProperties] = useState([]);
  const [viewMode, setViewMode] = useState('card');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogError, setDialogError] = useState('');
  const [newProperty, setNewProperty] = useState({
    name: '',
    address: '',
    rooms: [],
    description: '',
  });
  const [newRoom, setNewRoom] = useState({
    name: '',
    beds: [],
    squareFootage: ''
  });
  const [newBedName, setNewBedName] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [occupancyFilter, setOccupancyFilter] = useState('all');

  useEffect(() => {
    fetchProperties();
  }, []);

  const fetchProperties = async () => {
    try {
      const response = await axios.get('/api/properties');
      setProperties(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching properties:', error);
      setError(error.response?.data?.message || 'Failed to fetch properties');
    } finally {
      setLoading(false);
    }
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
    setDialogError('');
    setNewProperty({
      name: '',
      address: '',
      rooms: [],
      description: '',
    });
    setNewRoom({
      name: '',
      beds: [],
      squareFootage: ''
    });
    setNewBedName('');
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setDialogError('');
    setNewProperty({
      name: '',
      address: '',
      rooms: [],
      description: '',
    });
    setNewRoom({
      name: '',
      beds: [],
      squareFootage: ''
    });
    setNewBedName('');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProperty((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleRoomInputChange = (e) => {
    const { name, value } = e.target;
    setNewRoom((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleAddBed = () => {
    if (!newBedName) {
      setDialogError('Please enter a bed name');
      return;
    }

    setNewRoom((prev) => ({
      ...prev,
      beds: [...prev.beds, { name: newBedName }]
    }));
    setNewBedName('');
    setDialogError('');
  };

  const handleRemoveBed = (index) => {
    setNewRoom((prev) => ({
      ...prev,
      beds: prev.beds.filter((_, i) => i !== index)
    }));
  };

  const handleAddRoom = () => {
    if (!newRoom.name || !newRoom.squareFootage || newRoom.beds.length === 0) {
      setDialogError('Please fill in all room fields and add at least one bed');
      return;
    }

    setNewProperty((prev) => ({
      ...prev,
      rooms: [...prev.rooms, { ...newRoom }]
    }));

    setNewRoom({
      name: '',
      beds: [],
      squareFootage: ''
    });
    setNewBedName('');
    setDialogError('');
  };

  const handleRemoveRoom = (index) => {
    setNewProperty((prev) => ({
      ...prev,
      rooms: prev.rooms.filter((_, i) => i !== index)
    }));
  };

  const handleAddProperty = async () => {
    if (!newProperty.name || !newProperty.address) {
      setDialogError('Please fill in all required fields');
      return;
    }

    if (newProperty.rooms.length === 0) {
      setDialogError('Please add at least one room');
      return;
    }

    try {
      const response = await axios.post('/api/properties', newProperty);
      handleDialogClose();
      await fetchProperties();
    } catch (error) {
      console.error('Error adding property:', error);
      setDialogError(error.response?.data?.message || 'Failed to add property');
    }
  };

  const handlePropertyClick = (propertyId) => {
    navigate(`/properties/${propertyId}`);
  };

  const theme = useTheme();

  const PropertyCard = ({ property, onPropertyClick }) => {
    const [expanded, setExpanded] = useState(false);

    const handleAccordionClick = (event) => {
      event.preventDefault();
      event.stopPropagation();
      setExpanded((prev) => !prev);
    };

    const handleCardClick = (event) => {
      if (!event.target.closest('.MuiAccordionSummary-root')) {
        onPropertyClick(property._id);
      }
    };

    const totalBeds = property.rooms.reduce((total, room) => total + room.beds.length, 0);
    const availableBeds = property.rooms.reduce((available, room) => 
      available + room.beds.filter(bed => !bed.resident).length, 0);

    const getAvailabilityColors = () => {
      if (availableBeds === 0) {
        return {
          bg: 'error.lighter',
          color: 'error.dark',
          border: 'error.light'
        };
      } else if (availableBeds < totalBeds * 0.2) {
        return {
          bg: 'warning.lighter',
          color: 'warning.dark',
          border: 'warning.light'
        };
      } else {
        return {
          bg: 'success.lighter',
          color: 'success.dark',
          border: 'success.light'
        };
      }
    };

    const availabilityColors = getAvailabilityColors();

    return (
      <Card
        elevation={0}
        sx={{
          height: '100%',
          borderRadius: 3,
          border: '1px solid',
          borderColor: 'divider',
          position: 'relative',
          overflow: 'hidden',
          bgcolor: '#ffffff',
          transition: 'all 0.2s ease-in-out',
          cursor: 'pointer',
          '&:hover': {
            transform: 'translateY(-4px)',
            borderColor: 'primary.main',
            boxShadow: '0 12px 48px rgba(0, 0, 0, 0.12)',
          }
        }}
        onClick={handleCardClick}
      >
        <Box sx={{ position: 'relative' }}>
          <CardMedia
            component="img"
            height="200"
            image={property.imageUrl ? `${process.env.REACT_APP_S3_URL}/${property.imageUrl}` : '/default-property.jpg'}
            alt={property.name}
            sx={{
              objectFit: 'cover',
              objectPosition: 'center',
              backgroundColor: 'grey.100'
            }}
          />
          <Box
            sx={{ 
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              background: 'linear-gradient(to top, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.6) 50%, rgba(0,0,0,0) 100%)',
              pt: 4,
              pb: 2,
              px: 2,
            }}
          >
            <Stack spacing={1}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h6" sx={{ 
                  color: '#fff',
                  fontWeight: 700,
                  textShadow: '0 2px 4px rgba(0,0,0,0.2)'
                }}>
                  {property.name}
                </Typography>
                <Chip
                  label={`${availableBeds}/${totalBeds} beds`}
                  size="small"
                  sx={{ 
                    height: 24,
                    borderRadius: 1.5,
                    backgroundColor: 'rgba(255,255,255,0.9)',
                    color: availabilityColors.color,
                    border: '1px solid',
                    borderColor: availabilityColors.border,
                    fontWeight: 600,
                    '& .MuiChip-label': { px: 1.5, fontSize: '0.75rem' }
                  }}
                />
              </Box>
            </Stack>
          </Box>
        </Box>

        <CardContent sx={{ p: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
            <LocationOn sx={{ fontSize: 16, color: 'text.secondary' }} />
            <Typography variant="body2" sx={{ fontWeight: 500 }}>
              {property.address}
            </Typography>
          </Box>

          {property.description && (
            <Typography 
              variant="body2" 
              color="text.secondary" 
              sx={{ 
                mb: 2,
                fontSize: '0.875rem',
                lineHeight: 1.6
              }}
            >
              {property.description}
            </Typography>
          )}

          <Box sx={{ mt: 'auto' }}>
            <Accordion 
              expanded={expanded}
              onChange={handleAccordionClick}
              elevation={0}
              disableGutters
              sx={{
                '&:before': { display: 'none' },
                border: 'none',
                bgcolor: 'transparent',
                '& .MuiAccordionSummary-root': {
                  minHeight: 0,
                  p: 0,
                  '&.Mui-expanded': {
                    minHeight: 0,
                    mb: 1.5
                  }
                },
                '& .MuiAccordionSummary-content': {
                  m: 0,
                  '&.Mui-expanded': {
                    m: 0
                  }
                },
                '& .MuiAccordionDetails-root': {
                  p: 0
                }
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ fontSize: 20 }} />}
                sx={{ 
                  '&:hover': {
                    bgcolor: 'transparent'
                  }
                }}
              >
                <Typography 
                  variant="subtitle2" 
                  sx={{ 
                    fontWeight: 600, 
                    color: 'text.primary'
                  }}
                >
                  Rooms & Beds
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
                  <Stack spacing={1.5}>
                    {property.rooms.map((room, roomIndex) => (
                      <Paper
                        key={roomIndex}
                        elevation={0}
                        sx={{
                          p: 1.5,
                          borderRadius: 1,
                          border: '1px solid',
                          borderColor: 'divider',
                          bgcolor: alpha(theme.palette.primary.main, 0.04)
                        }}
                      >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <HomeIcon sx={{ fontSize: 16, color: theme.palette.primary.main }} />
                            <Typography variant="body2" sx={{ fontWeight: 600, color: theme.palette.primary.main }}>
                              {room.name}
                            </Typography>
                          </Box>
                          <Typography variant="caption" sx={{ 
                            fontWeight: 500, 
                            color: 'text.secondary',
                            bgcolor: 'background.paper',
                            px: 1,
                            py: 0.5,
                            borderRadius: 0.5,
                            border: '1px solid',
                            borderColor: 'divider'
                          }}>
                            {room.squareFootage} sq ft
                          </Typography>
                        </Box>
                        <Box 
                          sx={{ 
                            display: 'grid', 
                            gridTemplateColumns: 'repeat(auto-fill, minmax(80px, 1fr))',
                            gap: 0.75
                          }}
                        >
                          {room.beds.map((bed, bedIndex) => {
                            const colors = bed.resident ? {
                              bg: 'error.lighter',
                              color: 'error.dark',
                              border: 'error.light'
                            } : {
                              bg: 'success.lighter',
                              color: 'success.dark',
                              border: 'success.light'
                            };
                            
                            return (
                              <Chip
                                key={bedIndex}
                                label={bed.name}
                                size="small"
                                sx={{ 
                                  height: 24,
                                  width: '100%',
                                  fontWeight: 500,
                                  bgcolor: colors.bg,
                                  color: colors.color,
                                  border: '1px solid',
                                  borderColor: colors.border,
                                  '& .MuiChip-label': { 
                                    px: 1, 
                                    fontSize: '0.75rem',
                                    width: '100%',
                                    textAlign: 'center'
                                  }
                                }}
                              />
                            );
                          })}
                        </Box>
                      </Paper>
                    ))}
                  </Stack>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </CardContent>
      </Card>
    );
  };

  const PropertyList = ({ properties, onPropertyClick }) => (
    <TableContainer 
      component={Paper}
      elevation={0}
      sx={{ 
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 2,
        overflow: 'hidden',
        backgroundColor: '#ffffff',
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
      }}
    >
      <Table>
        <TableHead>
          <TableRow sx={{ 
            bgcolor: '#f8f9fb',
            '& th': { 
              fontWeight: 700,
              color: 'text.primary',
              fontSize: '0.875rem',
              letterSpacing: '0.01em',
              py: 2,
              borderBottom: '2px solid',
              borderColor: 'divider'
            }
          }}>
            <TableCell>Name</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>Availability</TableCell>
            <TableCell>Rooms</TableCell>
            <TableCell>Description</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {properties.map((property) => {
            const totalBeds = property.rooms.reduce((total, room) => total + room.beds.length, 0);
            const availableBeds = property.rooms.reduce((available, room) => 
              available + room.beds.filter(bed => !bed.resident).length, 0);

            const getAvailabilityColors = () => {
              if (availableBeds === 0) {
                return {
                  bg: 'error.lighter',
                  color: 'error.dark',
                  border: 'error.light'
                };
              } else if (availableBeds < totalBeds * 0.2) {
                return {
                  bg: 'warning.lighter',
                  color: 'warning.dark',
                  border: 'warning.light'
                };
              } else {
                return {
                  bg: 'success.lighter',
                  color: 'success.dark',
                  border: 'success.light'
                };
              }
            };

            const availabilityColors = getAvailabilityColors();
            
            return (
              <TableRow 
                key={property._id}
                sx={{ 
                  cursor: 'pointer',
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    bgcolor: '#f8f9fb'
                  },
                  '& td': {
                    py: 2,
                    borderColor: 'divider'
                  }
                }}
              >
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Box
                      component="img"
                      src={property.imageUrl ? `${process.env.REACT_APP_S3_URL}/${property.imageUrl}` : '/default-property.jpg'}
                      alt={property.name}
                      sx={{
                        width: 40,
                        height: 40,
                        borderRadius: 1,
                        objectFit: 'cover',
                        boxShadow: '0 4px 14px rgba(0, 0, 0, 0.12)'
                      }}
                    />
                    <Typography sx={{ 
                      fontWeight: 600,
                      color: 'primary.main'
                    }}>
                      {property.name}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <LocationOn sx={{ fontSize: 16, color: 'text.secondary' }} />
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      {property.address}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Chip
                    label={`${availableBeds}/${totalBeds} beds`}
                    size="small"
                    sx={{ 
                      height: 24,
                      fontWeight: 600,
                      bgcolor: availabilityColors.bg,
                      color: availabilityColors.color,
                      border: '1px solid',
                      borderColor: availabilityColors.border,
                      '& .MuiChip-label': { 
                        px: 1.5,
                        fontSize: '0.75rem'
                      }
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Stack spacing={1}>
                    {property.rooms.map((room, index) => (
                      <Box key={index}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
                          <HomeIcon sx={{ fontSize: 16, color: 'text.secondary' }} />
                          <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            {room.name} ({room.squareFootage} sq ft)
                          </Typography>
                        </Box>
                        <Box 
                          sx={{ 
                            display: 'flex',
                            gap: 0.5,
                            flexWrap: 'wrap'
                          }}
                        >
                          {room.beds.map((bed, bedIndex) => {
                            const colors = bed.resident ? {
                              bg: 'error.lighter',
                              color: 'error.dark',
                              border: 'error.light'
                            } : {
                              bg: 'success.lighter',
                              color: 'success.dark',
                              border: 'success.light'
                            };
                            
                            return (
                              <Chip
                                key={bedIndex}
                                label={bed.name}
                                size="small"
                                sx={{ 
                                  height: 24,
                                  fontWeight: 500,
                                  bgcolor: colors.bg,
                                  color: colors.color,
                                  border: '1px solid',
                                  borderColor: colors.border,
                                  '& .MuiChip-label': { 
                                    px: 1, 
                                    fontSize: '0.75rem'
                                  }
                                }}
                              />
                            );
                          })}
                        </Box>
                      </Box>
                    ))}
                  </Stack>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                    {property.description || 'No description available'}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={(e) => {
                      e.stopPropagation();
                      onPropertyClick(property._id);
                    }}
                    sx={{
                      borderRadius: 2,
                      textTransform: 'none',
                      fontWeight: 600,
                      py: 1,
                      px: 2,
                      borderWidth: 2,
                      '&:hover': {
                        borderWidth: 2,
                      }
                    }}
                  >
                    View
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const getPropertyStatus = (property) => {
    const totalBeds = property.rooms.reduce((total, room) => total + room.beds.length, 0);
    const availableBeds = property.rooms.reduce((available, room) => 
      available + room.beds.filter(bed => !bed.resident).length, 0);

    if (availableBeds === 0) return 'full';
    if (availableBeds === totalBeds) return 'empty';
    return 'partial';
  };

  const filteredProperties = properties.filter(property => {
    if (occupancyFilter !== 'all' && getPropertyStatus(property) !== occupancyFilter) {
      return false;
    }
    return true;
  });

  return (
    <Container maxWidth="lg" sx={{ py: 3 }}>
      {error && (
        <Alert 
          severity="error" 
          sx={{ 
            mb: 3,
            borderRadius: 1,
            '& .MuiAlert-icon': {
              color: 'error.main'
            }
          }}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => setError(null)}
              sx={{ 
                textTransform: 'none',
                fontWeight: 500
              }}
            >
              Dismiss
            </Button>
          }
        >
          {error}
        </Alert>
      )}

      <Paper
        elevation={0}
        sx={{
          mb: 3,
          p: { xs: 2, sm: 2.5 },
          backgroundColor: '#ffffff',
          borderRadius: 1,
          border: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: { xs: 'stretch', md: 'center' },
          gap: { xs: 2, md: 0 },
          mb: { xs: 2, md: 2.5 }
        }}>
          <Box sx={{ flex: 1 }}>
            <Typography 
              variant="h5" 
              component="h1" 
              sx={{ 
                fontWeight: 600, 
                color: 'primary.main',
                mb: { xs: 0.5, md: 0.25 }
              }}
            >
              Properties
            </Typography>
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{ 
                display: { xs: 'none', sm: 'block' },
                fontWeight: 500
              }}
            >
              Manage and track all properties in the system
            </Typography>
          </Box>
          <Stack 
            direction={{ xs: 'column', sm: 'row' }} 
            spacing={2}
            sx={{ 
              width: { xs: '100%', md: 'auto' },
              alignItems: { xs: 'stretch', sm: 'center' }
            }}
          >
            <Button
              startIcon={<AddIcon />}
              onClick={handleDialogOpen}
              variant="contained"
              sx={{
                borderRadius: 1,
                textTransform: 'none',
                fontWeight: 500,
                py: 1,
                order: { xs: 1, sm: 1 }
              }}
            >
              Add Property
            </Button>
            <ToggleButtonGroup
              value={viewMode}
              exclusive
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  setViewMode(newValue);
                }
              }}
              size="small"
              aria-label="view mode"
              sx={{
                display: 'flex',
                order: { xs: 2, sm: 2 },
                '& .MuiToggleButtonGroup-grouped': {
                  flex: { xs: 1, sm: 'none' },
                  px: { xs: 3, sm: 2 },
                  py: 1,
                  borderRadius: '4px !important',
                  borderColor: 'divider',
                  '&.Mui-selected': {
                    bgcolor: 'primary.main',
                    color: 'white',
                    '&:hover': {
                      bgcolor: 'primary.dark',
                    },
                  },
                },
              }}
            >
              <ToggleButton value="card" aria-label="card view">
                <ViewModuleIcon sx={{ mr: 1 }} />
                Grid
              </ToggleButton>
              <ToggleButton value="list" aria-label="list view">
                <ViewListIcon sx={{ mr: 1 }} />
                List
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Box>

        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}>
          <Box sx={{ 
            borderRadius: 1,
            bgcolor: '#f5f6f8',
            border: '1px solid',
            borderColor: 'divider',
            overflow: 'hidden'
          }}>
            <Tabs
              value={occupancyFilter}
              onChange={(e, newValue) => setOccupancyFilter(newValue)}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              sx={{
                minHeight: 44,
                px: { xs: 1, sm: 2 },
                '& .MuiTab-root': {
                  textTransform: 'none',
                  fontWeight: 500,
                  fontSize: '0.875rem',
                  minHeight: 44,
                  minWidth: { xs: 'auto', sm: 100 },
                  px: { xs: 2, sm: 3 }
                }
              }}
            >
              <Tab label="All Properties" value="all" />
              <Tab 
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    Full
                    <Chip 
                      label={properties.filter(p => getPropertyStatus(p) === 'full').length} 
                      size="small"
                      color="error"
                      sx={{ 
                        height: 20,
                        '& .MuiChip-label': { px: 1, fontSize: '0.75rem' }
                      }}
                    />
                  </Box>
                } 
                value="full" 
              />
              <Tab 
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    Partial
                    <Chip 
                      label={properties.filter(p => getPropertyStatus(p) === 'partial').length}
                      size="small"
                      color="warning"
                      sx={{ 
                        height: 20,
                        '& .MuiChip-label': { px: 1, fontSize: '0.75rem' }
                      }}
                    />
                  </Box>
                } 
                value="partial" 
              />
              <Tab 
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    Empty
                    <Chip 
                      label={properties.filter(p => getPropertyStatus(p) === 'empty').length}
                      size="small"
                      color="success"
                      sx={{ 
                        height: 20,
                        '& .MuiChip-label': { px: 1, fontSize: '0.75rem' }
                      }}
                    />
                  </Box>
                } 
                value="empty" 
              />
            </Tabs>
          </Box>

          {occupancyFilter !== 'all' && (
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                setOccupancyFilter('all');
              }}
              sx={{
                alignSelf: 'flex-start',
                borderRadius: 1,
                textTransform: 'none',
                fontWeight: 500,
                borderWidth: 1.5,
                py: 0.5
              }}
            >
              Clear Filters
            </Button>
          )}
        </Box>
      </Paper>

      {loading ? (
        viewMode === 'card' ? (
          <Grid container spacing={3}>
            {[...Array(6)].map((_, index) => (
              <Grid item key={index} xs={12} sm={6} lg={4}>
                <Paper
                  elevation={0}
                  sx={{
                    height: '100%',
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 3,
                    p: 0,
                    bgcolor: '#ffffff',
                    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
                    overflow: 'hidden'
                  }}
                >
                  <Stack spacing={0}>
                    <Skeleton 
                      variant="rectangular" 
                      height={200}
                      sx={{ bgcolor: 'grey.100' }}
                    />
                    <Box sx={{ p: 2 }}>
                      <Stack spacing={2}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Skeleton variant="circular" width={16} height={16} />
                          <Skeleton width="80%" height={24} />
                        </Box>
                        <Skeleton width="100%" height={60} />
                        <Skeleton width="100%" height={60} />
                        <Skeleton width="100%" height={60} />
                      </Stack>
                    </Box>
                  </Stack>
                </Paper>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Paper
            elevation={0}
            sx={{ 
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 2,
              overflow: 'hidden',
              bgcolor: '#ffffff',
              boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
            }}
          >
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {[...Array(6)].map((_, index) => (
                      <TableCell key={index} sx={{ bgcolor: '#f8f9fb' }}>
                        <Skeleton width={100} height={24} />
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[...Array(5)].map((_, index) => (
                    <TableRow key={index}>
                      {[...Array(6)].map((_, cellIndex) => (
                        <TableCell key={cellIndex}>
                          <Skeleton width={cellIndex === 5 ? 120 : 100} height={24} />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )
      ) : filteredProperties.length === 0 ? (
        <Paper
          elevation={0}
          sx={{
            p: 4,
            textAlign: 'center',
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 1,
            bgcolor: '#ffffff'
          }}
        >
          <ErrorIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
          <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
            No Properties Found
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            {occupancyFilter === 'all'
              ? 'There are no properties in the system.'
              : 'No properties match your current filters.'}
          </Typography>
          {occupancyFilter !== 'all' && (
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                setOccupancyFilter('all');
              }}
              sx={{
                borderRadius: 1,
                textTransform: 'none',
                fontWeight: 500,
                borderWidth: 1.5
              }}
            >
              Clear All Filters
            </Button>
          )}
        </Paper>
      ) : viewMode === 'card' ? (
        <Grid 
          container 
          spacing={2}
          alignItems="flex-start"
          sx={{
            '& .MuiGrid-item': {
              display: 'flex',
              '& > *': {
                width: '100%',
                height: 'auto !important'
              }
            }
          }}
        >
          {filteredProperties.map((property) => (
            <Grid 
              item 
              key={property._id} 
              xs={12} 
              sm={6} 
              lg={4}
            >
              <PropertyCard property={property} onPropertyClick={handlePropertyClick} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <PropertyList properties={filteredProperties} onPropertyClick={handlePropertyClick} />
      )}

      <Dialog 
        open={openDialog} 
        onClose={handleDialogClose} 
        maxWidth="sm" 
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 1,
            boxShadow: '0 8px 32px -4px rgba(0, 0, 0, 0.1)'
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid',
          borderColor: 'divider',
          bgcolor: '#f5f6f8',
          py: 2
        }}>
          Add New Property
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            {dialogError && (
              <Alert 
                severity="error"
                sx={{ 
                  borderRadius: 1,
                  '& .MuiAlert-icon': {
                    color: 'error.main'
                  }
                }}
              >
                {dialogError}
              </Alert>
            )}
            <TextField
              required
              fullWidth
              id="name"
              name="name"
              label="Property Name"
              value={newProperty.name}
              onChange={handleInputChange}
              autoFocus
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'divider',
                },
              }}
            />
            <TextField
              required
              fullWidth
              id="address"
              name="address"
              label="Address"
              value={newProperty.address}
              onChange={handleInputChange}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'divider',
                },
              }}
            />
            <TextField
              fullWidth
              id="description"
              name="description"
              label="Description"
              value={newProperty.description}
              onChange={handleInputChange}
              multiline
              rows={4}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'divider',
                },
              }}
            />
            
            <Box sx={{ mt: 2 }}>
              <Typography 
                variant="h6"
                sx={{ 
                  mb: 2,
                  fontWeight: 600,
                  color: 'text.primary',
                  letterSpacing: '-0.02em'
                }}
              >
                Add Rooms
              </Typography>
              <Stack spacing={2}>
                <Stack direction="row" spacing={2}>
                  <TextField
                    size="small"
                    required
                    id="room-name"
                    name="name"
                    label="Room Name"
                    value={newRoom.name}
                    onChange={handleRoomInputChange}
                    sx={{
                      flexGrow: 1,
                      '& .MuiOutlinedInput-root': {
                        bgcolor: 'background.paper',
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'primary.main',
                        },
                      },
                    }}
                  />
                  <TextField
                    size="small"
                    required
                    id="room-square-footage"
                    name="squareFootage"
                    label="Square Footage"
                    type="number"
                    value={newRoom.squareFootage}
                    onChange={handleRoomInputChange}
                    inputProps={{ min: 1 }}
                    sx={{
                      width: '150px',
                      '& .MuiOutlinedInput-root': {
                        bgcolor: 'background.paper',
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'primary.main',
                        },
                      },
                    }}
                  />
                </Stack>
                <Stack direction="row" spacing={2}>
                  <TextField
                    size="small"
                    required
                    id="bed-name"
                    label="Bed Name"
                    value={newBedName}
                    onChange={(e) => setNewBedName(e.target.value)}
                    sx={{
                      flexGrow: 1,
                      '& .MuiOutlinedInput-root': {
                        bgcolor: 'background.paper',
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'primary.main',
                        },
                      },
                    }}
                  />
                  <Button
                    variant="outlined"
                    onClick={handleAddBed}
                    sx={{
                      borderColor: 'divider',
                      '&:hover': {
                        borderColor: 'primary.main',
                        bgcolor: 'background.paper',
                      },
                    }}
                  >
                    Add Bed
                  </Button>
                </Stack>
              </Stack>

              {newRoom.beds.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  <Typography 
                    variant="subtitle2" 
                    gutterBottom
                    sx={{ 
                      fontWeight: 600,
                      color: 'text.primary'
                    }}
                  >
                    Beds in this room:
                  </Typography>
                  <Stack direction="row" spacing={1} flexWrap="wrap">
                    {newRoom.beds.map((bed, index) => (
                      <Chip
                        key={index}
                        label={bed.name}
                        onDelete={() => handleRemoveBed(index)}
                        size="small"
                        sx={{ 
                          mb: 1,
                          bgcolor: 'background.paper',
                          border: '1px solid',
                          borderColor: 'divider',
                          '& .MuiChip-label': {
                            fontWeight: 500
                          }
                        }}
                      />
                    ))}
                  </Stack>
                </Box>
              )}

              <Button
                variant="contained"
                onClick={handleAddRoom}
                disabled={!newRoom.name || !newRoom.squareFootage || newRoom.beds.length === 0}
                sx={{
                  mt: 2,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  '&:hover': {
                    boxShadow: '0 6px 16px rgba(0, 0, 0, 0.15)',
                  },
                }}
              >
                Add Room
              </Button>
            </Box>

            {newProperty.rooms.length > 0 && (
              <List>
                {newProperty.rooms.map((room, index) => (
                  <React.Fragment key={index}>
                    <ListItem
                      sx={{
                        bgcolor: 'background.paper',
                        borderRadius: 1,
                        mb: 1,
                        border: '1px solid',
                        borderColor: 'divider',
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography 
                            variant="subtitle2"
                            sx={{ 
                              fontWeight: 600,
                              color: 'text.primary'
                            }}
                          >
                            {room.name}
                          </Typography>
                        }
                        secondary={
                          <>
                            <Typography 
                              variant="body2" 
                              component="span"
                              sx={{ 
                                color: 'text.secondary',
                                fontSize: '0.875rem'
                              }}
                            >
                              {room.squareFootage} sq ft
                            </Typography>
                            <Box sx={{ mt: 1 }}>
                              <Stack direction="row" spacing={1} flexWrap="wrap">
                                {room.beds.map((bed, bedIndex) => (
                                  <Chip
                                    key={bedIndex}
                                    label={bed.name}
                                    size="small"
                                    sx={{ 
                                      mb: 1,
                                      bgcolor: 'background.paper',
                                      border: '1px solid',
                                      borderColor: 'divider',
                                      '& .MuiChip-label': {
                                        fontWeight: 500
                                      }
                                    }}
                                  />
                                ))}
                              </Stack>
                            </Box>
                          </>
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleRemoveRoom(index)}
                          sx={{
                            color: 'error.main',
                            '&:hover': {
                              bgcolor: 'error.lighter',
                            },
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </React.Fragment>
                ))}
              </List>
            )}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 2.5, borderTop: '1px solid', borderColor: 'divider' }}>
          <Button 
            onClick={handleDialogClose}
            sx={{
              borderRadius: 1,
              textTransform: 'none',
              fontWeight: 500,
              color: 'text.secondary'
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleAddProperty}
            variant="contained" 
            sx={{
              borderRadius: 1,
              textTransform: 'none',
              fontWeight: 500,
              px: 3
            }}
          >
            Add Property
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default PropertiesPage; 