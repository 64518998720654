import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  Image as ImageIcon,
  Add as AddIcon,
  Refresh,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Update API URL to include full path
const API_URL = process.env.REACT_APP_API_URL || 'http://192.168.0.135:5001';
axios.defaults.baseURL = API_URL;

const MyMaintenanceRequests = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const navigate = useNavigate();

  const fetchRequests = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/maintenance/my-requests', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setRequests(response.data);
    } catch (error) {
      console.error('Error fetching maintenance requests:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRequests();
  }, []);

  const getStatusColor = (status) => {
    switch (status) {
      case 'Pending':
        return 'warning';
      case 'In Progress':
        return 'info';
      case 'Completed':
        return 'success';
      default:
        return 'default';
    }
  };

  const getPriorityColor = (priority) => {
    switch (priority) {
      case 'Low':
        return 'success';
      case 'Medium':
        return 'info';
      case 'High':
        return 'warning';
      case 'Emergency':
        return 'error';
      default:
        return 'default';
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h5" component="h2">
          My Maintenance Requests
        </Typography>
        <Box>
          <Button
            startIcon={<Refresh />}
            onClick={fetchRequests}
            variant="outlined"
            sx={{ mr: 2 }}
          >
            Refresh
          </Button>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            onClick={() => navigate('/maintenance-requests/new')}
          >
            New Request
          </Button>
        </Box>
      </Box>

      <Grid container spacing={3}>
        {requests.map((request) => (
          <Grid item xs={12} md={6} lg={4} key={request._id}>
            <Card elevation={3}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                  <Typography variant="h6" component="h3">
                    {request.title}
                  </Typography>
                  <Chip
                    label={request.status}
                    color={getStatusColor(request.status)}
                    size="small"
                  />
                </Box>

                <Box sx={{ mb: 2 }}>
                  <Chip
                    label={`Priority: ${request.priority}`}
                    color={getPriorityColor(request.priority)}
                    size="small"
                    sx={{ mr: 1 }}
                  />
                  {request.images?.length > 0 && (
                    <Tooltip title="View Images">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setSelectedRequest(request);
                          setImageDialogOpen(true);
                        }}
                      >
                        <ImageIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>

                <Typography variant="body2" sx={{ mb: 2 }}>
                  {request.description}
                </Typography>

                {request.notes && request.notes.length > 0 && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle2" gutterBottom>
                      Latest Update:
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {request.notes[request.notes.length - 1].content}
                    </Typography>
                  </Box>
                )}

                <Box sx={{ mt: 2 }}>
                  <Typography variant="caption" color="textSecondary">
                    Submitted: {new Date(request.createdAt).toLocaleDateString()}
                  </Typography>
                  {request.status === 'Completed' && request.completedAt && (
                    <Typography variant="caption" color="textSecondary" sx={{ display: 'block' }}>
                      Completed: {new Date(request.completedAt).toLocaleDateString()}
                    </Typography>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}

        {requests.length === 0 && !loading && (
          <Grid item xs={12}>
            <Box sx={{ textAlign: 'center', py: 4 }}>
              <Typography variant="body1" color="textSecondary">
                You haven't submitted any maintenance requests yet.
              </Typography>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate('/maintenance-requests/new')}
                sx={{ mt: 2 }}
              >
                Submit Your First Request
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>

      {/* Image View Dialog */}
      <Dialog
        open={imageDialogOpen}
        onClose={() => setImageDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Request Images</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {selectedRequest?.images?.map((image, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <img
                  src={`${API_URL}/${image}`}
                  alt={`Maintenance request ${index + 1}`}
                  style={{
                    width: '100%',
                    height: 'auto',
                    cursor: 'pointer'
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setImageDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MyMaintenanceRequests; 