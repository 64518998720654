import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Chip,
  Button,
  IconButton,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import {
  Add as AddIcon,
  Refresh,
  Gavel as GavelIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://192.168.0.135:5001';
axios.defaults.baseURL = API_URL;

const MyGrievances = () => {
  const [grievances, setGrievances] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchGrievances = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/grievances/my-grievances', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setGrievances(response.data);
    } catch (error) {
      console.error('Error fetching grievances:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGrievances();
  }, []);

  const getStatusColor = (status) => {
    switch (status) {
      case 'Pending':
        return 'warning';
      case 'In Progress':
        return 'info';
      case 'Resolved':
        return 'success';
      case 'Rejected':
        return 'error';
      default:
        return 'default';
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h5" component="h2">
          My Grievances
        </Typography>
        <Box>
          <Button
            startIcon={<Refresh />}
            onClick={fetchGrievances}
            variant="outlined"
            sx={{ mr: 2 }}
          >
            Refresh
          </Button>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            onClick={() => navigate('/my-grievances/new')}
          >
            New Grievance
          </Button>
        </Box>
      </Box>

      <Grid container spacing={3}>
        {grievances.map((grievance) => (
          <Grid item xs={12} md={6} lg={4} key={grievance._id}>
            <Card elevation={3}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <GavelIcon sx={{ mr: 1 }} />
                    <Typography variant="h6" component="h3">
                      Grievance
                    </Typography>
                  </Box>
                  <Chip
                    label={grievance.status}
                    color={getStatusColor(grievance.status)}
                    size="small"
                  />
                </Box>

                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Right Violated:
                  </Typography>
                  <Typography variant="body2">
                    {grievance.violatedRight}
                  </Typography>
                </Box>

                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Statement:
                  </Typography>
                  <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                    {grievance.statement}
                  </Typography>
                </Box>

                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Signature:
                  </Typography>
                  <Box 
                    component="img"
                    src={grievance.signature}
                    alt="Resident's Signature"
                    sx={{ 
                      maxWidth: '100%',
                      height: 'auto',
                      border: '1px solid',
                      borderColor: 'divider',
                      borderRadius: 1,
                      bgcolor: '#fff'
                    }}
                  />
                </Box>

                {grievance.notes && grievance.notes.length > 0 && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle2" gutterBottom>
                      Latest Update:
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {grievance.notes[grievance.notes.length - 1].content}
                    </Typography>
                  </Box>
                )}

                {grievance.status === 'Rejected' && grievance.rejectionReason && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle2" color="error" gutterBottom>
                      Rejection Reason:
                    </Typography>
                    <Typography variant="body2" color="error">
                      {grievance.rejectionReason}
                    </Typography>
                  </Box>
                )}

                <Box sx={{ mt: 2 }}>
                  <Typography variant="caption" color="textSecondary">
                    Submitted: {new Date(grievance.createdAt).toLocaleDateString()}
                  </Typography>
                  {grievance.status === 'Resolved' && grievance.resolvedAt && (
                    <Typography variant="caption" color="textSecondary" sx={{ display: 'block' }}>
                      Resolved: {new Date(grievance.resolvedAt).toLocaleDateString()}
                    </Typography>
                  )}
                  {grievance.status === 'Rejected' && grievance.rejectedAt && (
                    <Typography variant="caption" color="error" sx={{ display: 'block' }}>
                      Rejected: {new Date(grievance.rejectedAt).toLocaleDateString()}
                    </Typography>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}

        {grievances.length === 0 && !loading && (
          <Grid item xs={12}>
            <Box sx={{ textAlign: 'center', py: 4 }}>
              <Typography variant="body1" color="textSecondary">
                You haven't submitted any grievances yet.
              </Typography>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate('/my-grievances/new')}
                sx={{ mt: 2 }}
              >
                Submit Your First Grievance
              </Button>
            </Box>
          </Grid>
        )}

        {loading && (
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
              <CircularProgress />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default MyGrievances; 