import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  List,
  ListItem,
  ListItemText,
  Divider,
  Chip,
  alpha,
  useTheme
} from '@mui/material';
import { Payments as PaymentsIcon } from '@mui/icons-material';
import axios from '../../../utils/axios';

const Payments = ({ resident }) => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await axios.get(`/api/payments/all`, {
          params: { resident: resident._id }
        });
        setPayments(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.response?.data?.message || 'Failed to fetch payments');
        setLoading(false);
      }
    };

    fetchPayments();
  }, [resident._id]);

  const getStatusColor = (status) => {
    switch (status) {
      case 'completed':
        return 'success';
      case 'refunded':
        return 'warning';
      case 'failed':
        return 'error';
      default:
        return 'default';
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const formatAmount = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount); // Amount is already in dollars, no need to divide by 100
  };

  return (
    <Card
      elevation={0}
      sx={{ 
        height: '100%',
        background: `linear-gradient(45deg, ${alpha(theme.palette.primary.main, 0.02)}, ${alpha(theme.palette.primary.light, 0.05)})`,
        borderRadius: 1,
        border: '1px solid',
        borderColor: 'divider'
      }}
    >
      <Box
        sx={{
          background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
          p: 2,
          borderRadius: '4px 4px 0 0',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <PaymentsIcon sx={{ color: 'white' }} />
          <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 600 }}>
            Payment History
          </Typography>
        </Box>
      </Box>

      <Box sx={{ p: 2 }}>
        {loading ? (
          <Typography color="text.secondary">Loading...</Typography>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : payments.length === 0 ? (
          <Typography color="text.secondary">No payments found</Typography>
        ) : (
          <List sx={{ width: '100%', bgcolor: 'transparent' }}>
            {payments.map((payment, index) => (
              <React.Fragment key={payment._id}>
                <ListItem
                  alignItems="flex-start"
                  sx={{ 
                    px: 0,
                    '&:hover': { 
                      bgcolor: alpha(theme.palette.info.main, 0.05),
                      borderRadius: 1
                    }
                  }}
                >
                  <ListItemText
                    primary={
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
                        <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                          {formatAmount(payment.amount)}
                        </Typography>
                        <Chip 
                          label={payment.status} 
                          size="small" 
                          color={getStatusColor(payment.status)}
                          sx={{ ml: 'auto' }}
                        />
                      </Box>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          color="text.primary"
                          sx={{ display: 'block', mt: 0.5 }}
                        >
                          {payment.description}
                        </Typography>
                        {payment.refundReason && (
                          <Typography
                            component="span"
                            variant="body2"
                            color="warning.main"
                            sx={{ display: 'block', mt: 0.5 }}
                          >
                            Refund Reason: {payment.refundReason}
                          </Typography>
                        )}
                        <Typography
                          component="span"
                          variant="caption"
                          color="text.secondary"
                          sx={{ display: 'block', mt: 0.5 }}
                        >
                          Paid on {formatDate(payment.completedAt)}
                          {payment.property && ` • ${payment.property.name}`}
                          {payment.unit && ` • Unit ${payment.unit}`}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                {index < payments.length - 1 && <Divider component="li" />}
              </React.Fragment>
            ))}
          </List>
        )}
      </Box>
    </Card>
  );
};

export default Payments; 