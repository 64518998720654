import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';
import axios from '../../../../utils/axios';

const SmsDialog = ({ open, onClose, selectedResidents, error, setError, onSmsSent = () => {}, initialMessage = '' }) => {
  const [message, setMessage] = useState(initialMessage);
  const [sending, setSending] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (open) {
      setMessage(initialMessage);
    }
  }, [open, initialMessage]);

  const handleSend = async () => {
    if (!message.trim()) {
      setError('Please enter a message');
      return;
    }

    setSending(true);
    try {
      await axios.post('/api/auth/residents/sms', {
        residents: selectedResidents.map(r => r._id),
        message: message.trim()
      });

      setShowSuccess(true);
      setMessage('');
      onSmsSent();
      onClose();
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to send SMS messages');
    } finally {
      setSending(false);
    }
  };

  return (
    <>
      <Dialog 
        open={open} 
        onClose={onClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Send SMS to {selectedResidents.length} Resident{selectedResidents.length !== 1 ? 's' : ''}
        </DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              Recipients: {selectedResidents.map(r => r.name).join(', ')}
            </Typography>
          </Box>
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            error={Boolean(error)}
            sx={{ mb: 2 }}
          />
          <Typography variant="caption" color="text.secondary">
            Message will be sent to residents with valid phone numbers only
          </Typography>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="contained"
            onClick={handleSend}
            disabled={sending || !message.trim()}
            startIcon={sending ? <CircularProgress size={20} /> : null}
          >
            {sending ? 'Sending...' : 'Send SMS'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => setShowSuccess(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity="success">SMS messages sent successfully!</Alert>
      </Snackbar>
    </>
  );
};

export default SmsDialog; 