import React from 'react';
import { 
  Typography, 
  Box,
  Stack,
  Avatar,
  Card,
  CardContent
} from '@mui/material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { format } from 'date-fns';

function HeaderCard({ user }) {
  const currentDate = new Date();
  const formattedDate = format(currentDate, 'EEEE, MMMM do, yyyy');
  const formattedTime = format(currentDate, 'h:mm a');

  // Get greeting based on time of day
  const getGreeting = () => {
    const hour = currentDate.getHours();
    if (hour < 12) return 'Good Morning';
    if (hour < 17) return 'Good Afternoon';
    return 'Good Evening';
  };

  // Function to get initials from name
  const getInitials = (firstName, lastName) => {
    return `${firstName?.[0] || ''}${lastName?.[0] || ''}`.toUpperCase();
  };

  return (
    <Card
      elevation={0}
      sx={{
        mb: 3,
        backgroundColor: '#ffffff',
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 2,
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          boxShadow: '0 12px 48px rgba(0, 0, 0, 0.12)',
          transform: 'translateY(-4px)'
        }
      }}
    >
      <CardContent sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Avatar 
              src={user?.imageUrl ? `${process.env.REACT_APP_S3_URL}/${user?.imageUrl}` : undefined}
              sx={{ 
                width: 68,
                height: 68,
                bgcolor: user?.imageUrl ? 'transparent' : 'primary.light',
                fontSize: '1.75rem',
                fontWeight: 700,
                color: 'primary.main',
                boxShadow: '0 4px 14px rgba(0, 0, 0, 0.12)',
                border: '2px solid #fff'
              }}
            >
              {!user?.imageUrl && getInitials(user?.firstName, user?.lastName)}
            </Avatar>
            <Box>
              <Typography variant="h5" sx={{ fontWeight: 700, color: 'text.primary', mb: 0.5 }}>
                {getGreeting()}, {user?.firstName}
              </Typography>
              <Stack direction="row" spacing={2} alignItems="center">
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <AdminPanelSettingsIcon sx={{ color: 'primary.main', fontSize: 20 }} />
                  <Typography variant="body1" sx={{ color: 'text.secondary', fontWeight: 500 }}>
                    {user?.role === 'admin' && user?.jobTitle ? user.jobTitle : user?.role?.charAt(0).toUpperCase() + user?.role?.slice(1)}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CalendarTodayIcon sx={{ color: 'text.secondary', fontSize: 20 }} />
                  <Typography variant="body1" sx={{ color: 'text.secondary', fontWeight: 500 }}>
                    {formattedDate}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Box>

          <Box 
            sx={{ 
              display: { xs: 'none', md: 'flex' },
              alignItems: 'center',
              gap: 1,
              bgcolor: 'rgba(255, 255, 255, 0.9)',
              py: 1.5,
              px: 2.5,
              borderRadius: 2,
              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.06)',
              backdropFilter: 'blur(8px)'
            }}
          >
            <AccessTimeIcon sx={{ color: 'text.secondary', fontSize: 20 }} />
            <Typography variant="body1" sx={{ color: 'text.primary', fontWeight: 500 }}>
              {formattedTime}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default HeaderCard; 