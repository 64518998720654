import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Chip,
  alpha,
  useTheme
} from '@mui/material';
import {
  Home as HomeIcon,
  MeetingRoom as RoomIcon,
  Hotel as BedIcon,
  Event as EventIcon,
  SwapHoriz as SwapIcon,
  NavigateNext as NavigateNextIcon,
  LocationOn as LocationIcon
} from '@mui/icons-material';

const HousingAssignment = ({ 
  assignment, 
  onAssignHousing, 
  propertyId 
}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  if (!assignment) {
    return (
      <Box sx={{ margin: -3 }}>
        <Card 
          elevation={0}
          sx={{ 
            height: '100%',
            background: `linear-gradient(45deg, ${alpha(theme.palette.primary.main, 0.05)}, ${alpha(theme.palette.primary.light, 0.1)})`,
            border: `1px dashed ${alpha(theme.palette.primary.main, 0.2)}`,
            borderRadius: 1,
            transition: 'all 0.3s ease',
            '&:hover': {
              background: `linear-gradient(45deg, ${alpha(theme.palette.primary.main, 0.08)}, ${alpha(theme.palette.primary.light, 0.15)})`,
            }
          }}
        >
          <CardContent sx={{ 
            height: '100%', 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1.5,
            p: 2,
            '&:last-child': { pb: 2 }
          }}>
            <HomeIcon sx={{ fontSize: 36, color: 'primary.main', opacity: 0.7 }} />
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h6" color="primary.main" sx={{ fontWeight: 600, mb: 0.5 }}>
                No Housing Assigned
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ maxWidth: 200 }}>
                Assign housing to manage resident's accommodation details
              </Typography>
            </Box>
            <Button
              variant="contained"
              startIcon={<HomeIcon />}
              onClick={onAssignHousing}
              fullWidth
              sx={{
                mt: 1,
                borderRadius: 2,
                textTransform: 'none',
                fontWeight: 600,
                background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
                boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.3)}`,
                '&:hover': {
                  background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
                  boxShadow: `0 6px 16px ${alpha(theme.palette.primary.main, 0.4)}`,
                }
              }}
            >
              Assign Housing
            </Button>
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <Box sx={{ margin: -3 }}>
      <Box
        sx={{ 
          height: '100%',
          background: `linear-gradient(45deg, ${alpha(theme.palette.primary.main, 0.02)}, ${alpha(theme.palette.primary.light, 0.05)})`,
          borderRadius: 1,
          position: 'relative',
        }}
      >
        <Box
          sx={{
            background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
            p: 2,
            borderRadius: '4px 4px 0 0',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 600 }}>
              Housing Assignment
            </Typography>
            <Chip
              icon={<LocationIcon sx={{ color: 'white !important', fontSize: '16px !important' }} />}
              label="Active"
              size="small"
              sx={{
                height: 24,
                bgcolor: 'success.main',
                color: 'white',
                fontWeight: 600,
                '& .MuiChip-label': {
                  px: 1,
                  fontSize: '0.75rem'
                }
              }}
            />
          </Box>
        </Box>

        <Box sx={{ p: 2 }}>
          {/* Property Card */}
          <Card 
            elevation={1}
            sx={{ 
              mb: 2,
              borderRadius: 1.5,
              cursor: 'pointer',
              transition: 'all 0.2s ease',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: theme.shadows[2]
              }
            }}
            onClick={() => navigate(`/properties/${propertyId}`)}
          >
            <CardContent sx={{ p: '12px !important', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                <Box 
                  sx={{ 
                    width: 32,
                    height: 32,
                    borderRadius: 1.5,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'primary.lighter'
                  }}
                >
                  <HomeIcon sx={{ fontSize: 18, color: 'primary.main' }} />
                </Box>
                <Box>
                  <Typography variant="subtitle2" sx={{ fontWeight: 600, color: 'primary.main', lineHeight: 1.2 }}>
                    {assignment.property.name}
                  </Typography>
                  <Typography variant="caption" color="text.secondary" sx={{ fontSize: '0.7rem' }}>
                    Property Details
                  </Typography>
                </Box>
              </Box>
              <NavigateNextIcon sx={{ color: 'primary.main', fontSize: 20 }} />
            </CardContent>
          </Card>

          {/* Room & Bed Info */}
          <Box sx={{ display: 'flex', gap: 1.5, mb: 2 }}>
            <Card 
              elevation={0} 
              sx={{ 
                flex: 1,
                borderRadius: 1.5,
                bgcolor: alpha(theme.palette.secondary.main, 0.05),
                border: `1px solid ${alpha(theme.palette.secondary.main, 0.1)}`
              }}
            >
              <CardContent sx={{ p: '10px !important' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: 0.5 }}>
                  <RoomIcon sx={{ color: 'secondary.main', fontSize: 16 }} />
                  <Typography variant="caption" color="secondary.main" sx={{ fontWeight: 500, fontSize: '0.7rem' }}>
                    Room
                  </Typography>
                </Box>
                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                  {assignment.room.name}
                </Typography>
              </CardContent>
            </Card>

            <Card 
              elevation={0}
              sx={{ 
                flex: 1,
                borderRadius: 1.5,
                bgcolor: alpha(theme.palette.info.main, 0.05),
                border: `1px solid ${alpha(theme.palette.info.main, 0.1)}`
              }}
            >
              <CardContent sx={{ p: '10px !important' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: 0.5 }}>
                  <BedIcon sx={{ color: 'info.main', fontSize: 16 }} />
                  <Typography variant="caption" color="info.main" sx={{ fontWeight: 500, fontSize: '0.7rem' }}>
                    Bed
                  </Typography>
                </Box>
                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                  {assignment.bed.name}
                </Typography>
              </CardContent>
            </Card>
          </Box>

          {/* Assignment Date */}
          <Box sx={{ mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: 0.5 }}>
              <EventIcon sx={{ color: 'text.secondary', fontSize: 16 }} />
              <Typography variant="caption" color="text.secondary" sx={{ fontWeight: 500, fontSize: '0.7rem' }}>
                Assigned Date
              </Typography>
            </Box>
            <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
              {assignment.assignedAt}
            </Typography>
          </Box>

          {/* Action Button */}
          <Button
            variant="outlined"
            fullWidth
            startIcon={<SwapIcon sx={{ fontSize: 18 }} />}
            onClick={onAssignHousing}
            size="small"
            sx={{
              borderRadius: 1.5,
              textTransform: 'none',
              fontWeight: 600,
              borderWidth: 1.5,
              borderColor: alpha(theme.palette.primary.main, 0.5),
              color: theme.palette.primary.main,
              '&:hover': {
                borderWidth: 1.5,
                bgcolor: alpha(theme.palette.primary.main, 0.05),
              }
            }}
          >
            Reassign Housing
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default HousingAssignment; 