import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  Grid,
  Card,
  CardContent,
  alpha,
  useTheme
} from '@mui/material';
import {
  LocalHospital,
  Add as AddIcon,
  Delete as DeleteIcon,
  Medication as MedicationIcon,
  WarningAmber as AllergyIcon,
  MedicalInformation as ConditionsIcon
} from '@mui/icons-material';

const HealthInformation = ({ 
  resident, 
  editMode, 
  onEditModeChange, 
  onSaveField 
}) => {
  const theme = useTheme();
  const [newAllergy, setNewAllergy] = useState('');
  const [newCondition, setNewCondition] = useState('');
  const [newMedication, setNewMedication] = useState({
    name: '',
    dosage: '',
    frequency: '',
    prescribedBy: ''
  });

  const handleAddAllergy = () => {
    if (!newAllergy.trim()) return;
    const updatedAllergies = [...(resident.allergies || []), newAllergy.trim()];
    onSaveField('health', 'allergies', updatedAllergies);
    setNewAllergy('');
  };

  const handleRemoveAllergy = (index) => {
    const updatedAllergies = resident.allergies.filter((_, i) => i !== index);
    onSaveField('health', 'allergies', updatedAllergies);
  };

  const handleAddCondition = () => {
    if (!newCondition.trim()) return;
    const updatedConditions = [...(resident.preexistingConditions || []), newCondition.trim()];
    onSaveField('health', 'preexistingConditions', updatedConditions);
    setNewCondition('');
  };

  const handleRemoveCondition = (index) => {
    const updatedConditions = resident.preexistingConditions.filter((_, i) => i !== index);
    onSaveField('health', 'preexistingConditions', updatedConditions);
  };

  const handleAddMedication = () => {
    if (!newMedication.name || !newMedication.dosage || !newMedication.frequency || !newMedication.prescribedBy) return;
    const updatedMedications = [...(resident.medications || []), newMedication];
    onSaveField('health', 'medications', updatedMedications);
    setNewMedication({
      name: '',
      dosage: '',
      frequency: '',
      prescribedBy: ''
    });
  };

  const handleRemoveMedication = (index) => {
    const updatedMedications = resident.medications.filter((_, i) => i !== index);
    onSaveField('health', 'medications', updatedMedications);
  };

  return (
    <Card
      elevation={0}
      sx={{ 
        height: '100%',
        background: `linear-gradient(45deg, ${alpha(theme.palette.primary.main, 0.02)}, ${alpha(theme.palette.primary.light, 0.05)})`,
        borderRadius: 1,
        border: '1px solid',
        borderColor: 'divider'
      }}
    >
      <Box
        sx={{
          background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
          p: 2,
          borderRadius: '4px 4px 0 0',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <LocalHospital sx={{ color: 'white' }} />
          <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 600 }}>
            Health Information
          </Typography>
        </Box>
      </Box>

      <Box sx={{ p: 2 }}>
        {/* Allergies */}
        <Card 
          elevation={0}
          sx={{ 
            mb: 2,
            borderRadius: 1.5,
            bgcolor: alpha(theme.palette.info.main, 0.05),
            border: `1px solid ${alpha(theme.palette.info.main, 0.1)}`
          }}
        >
          <CardContent sx={{ p: '12px !important' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: 0.5 }}>
              <AllergyIcon sx={{ color: 'info.main', fontSize: 16 }} />
              <Typography variant="caption" color="info.main" sx={{ fontWeight: 500, fontSize: '0.7rem' }}>
                Allergies
              </Typography>
            </Box>
            <List dense sx={{ mt: 1 }}>
              {resident.allergies?.map((allergy, index) => (
                <ListItem 
                  key={index}
                  sx={{ 
                    px: 1,
                    borderRadius: 1,
                    '&:hover': { bgcolor: alpha(theme.palette.info.main, 0.1) }
                  }}
                >
                  <ListItemText 
                    primary={
                      <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                        {allergy}
                      </Typography>
                    } 
                  />
                  <ListItemSecondaryAction>
                    <IconButton 
                      edge="end" 
                      onClick={() => handleRemoveAllergy(index)} 
                      size="small"
                      sx={{ color: 'error.main' }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
            <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
              <TextField
                size="small"
                placeholder="Add allergy"
                value={newAllergy}
                onChange={(e) => setNewAllergy(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleAddAllergy();
                  }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    bgcolor: 'white'
                  }
                }}
              />
              <Button
                variant="outlined"
                size="small"
                onClick={handleAddAllergy}
                startIcon={<AddIcon />}
                sx={{ 
                  color: 'info.main',
                  borderColor: 'info.main',
                  '&:hover': {
                    borderColor: 'info.dark',
                    bgcolor: alpha(theme.palette.info.main, 0.1)
                  }
                }}
              >
                Add
              </Button>
            </Box>
          </CardContent>
        </Card>

        {/* Pre-existing Conditions */}
        <Card 
          elevation={0}
          sx={{ 
            mb: 2,
            borderRadius: 1.5,
            bgcolor: alpha(theme.palette.info.main, 0.05),
            border: `1px solid ${alpha(theme.palette.info.main, 0.1)}`
          }}
        >
          <CardContent sx={{ p: '12px !important' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: 0.5 }}>
              <ConditionsIcon sx={{ color: 'info.main', fontSize: 16 }} />
              <Typography variant="caption" color="info.main" sx={{ fontWeight: 500, fontSize: '0.7rem' }}>
                Pre-existing Conditions
              </Typography>
            </Box>
            <List dense sx={{ mt: 1 }}>
              {resident.preexistingConditions?.map((condition, index) => (
                <ListItem 
                  key={index}
                  sx={{ 
                    px: 1,
                    borderRadius: 1,
                    '&:hover': { bgcolor: alpha(theme.palette.info.main, 0.1) }
                  }}
                >
                  <ListItemText 
                    primary={
                      <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                        {condition}
                      </Typography>
                    } 
                  />
                  <ListItemSecondaryAction>
                    <IconButton 
                      edge="end" 
                      onClick={() => handleRemoveCondition(index)} 
                      size="small"
                      sx={{ color: 'error.main' }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
            <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
              <TextField
                size="small"
                placeholder="Add condition"
                value={newCondition}
                onChange={(e) => setNewCondition(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleAddCondition();
                  }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    bgcolor: 'white'
                  }
                }}
              />
              <Button
                variant="outlined"
                size="small"
                onClick={handleAddCondition}
                startIcon={<AddIcon />}
                sx={{ 
                  color: 'info.main',
                  borderColor: 'info.main',
                  '&:hover': {
                    borderColor: 'info.dark',
                    bgcolor: alpha(theme.palette.info.main, 0.1)
                  }
                }}
              >
                Add
              </Button>
            </Box>
          </CardContent>
        </Card>

        {/* Medications */}
        <Card 
          elevation={0}
          sx={{ 
            borderRadius: 1.5,
            bgcolor: alpha(theme.palette.info.main, 0.05),
            border: `1px solid ${alpha(theme.palette.info.main, 0.1)}`
          }}
        >
          <CardContent sx={{ p: '12px !important' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: 0.5 }}>
              <MedicationIcon sx={{ color: 'info.main', fontSize: 16 }} />
              <Typography variant="caption" color="info.main" sx={{ fontWeight: 500, fontSize: '0.7rem' }}>
                Medications
              </Typography>
            </Box>
            <List dense sx={{ mt: 1 }}>
              {resident.medications?.map((medication, index) => (
                <ListItem 
                  key={index}
                  sx={{ 
                    px: 1,
                    borderRadius: 1,
                    '&:hover': { bgcolor: alpha(theme.palette.info.main, 0.1) }
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                        {medication.name}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body2" color="text.secondary">
                        {`${medication.dosage} - ${medication.frequency} (Prescribed by: ${medication.prescribedBy})`}
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton 
                      edge="end" 
                      onClick={() => handleRemoveMedication(index)} 
                      size="small"
                      sx={{ color: 'error.main' }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
            <Box sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    fullWidth
                    label="Medication Name"
                    value={newMedication.name}
                    onChange={(e) => setNewMedication(prev => ({ ...prev, name: e.target.value }))}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        bgcolor: 'white'
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    fullWidth
                    label="Dosage"
                    value={newMedication.dosage}
                    onChange={(e) => setNewMedication(prev => ({ ...prev, dosage: e.target.value }))}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        bgcolor: 'white'
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    fullWidth
                    label="Frequency"
                    value={newMedication.frequency}
                    onChange={(e) => setNewMedication(prev => ({ ...prev, frequency: e.target.value }))}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        bgcolor: 'white'
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    fullWidth
                    label="Prescribed By"
                    value={newMedication.prescribedBy}
                    onChange={(e) => setNewMedication(prev => ({ ...prev, prescribedBy: e.target.value }))}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        bgcolor: 'white'
                      }
                    }}
                  />
                </Grid>
              </Grid>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleAddMedication}
                  startIcon={<AddIcon />}
                  disabled={!newMedication.name || !newMedication.dosage || !newMedication.frequency || !newMedication.prescribedBy}
                  sx={{ 
                    color: 'info.main',
                    borderColor: 'info.main',
                    '&:hover': {
                      borderColor: 'info.dark',
                      bgcolor: alpha(theme.palette.info.main, 0.1)
                    },
                    '&.Mui-disabled': {
                      borderColor: 'info.main',
                      color: 'info.main',
                      opacity: 0.5
                    }
                  }}
                >
                  Add Medication
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Card>
  );
};

export default HealthInformation; 