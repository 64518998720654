import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Alert,
  Stack,
  Divider,
  Card,
  Pagination,
  useTheme,
  alpha,
  DialogContentText
} from '@mui/material';
import {
  CloudUpload as UploadIcon,
  Delete as DeleteIcon,
  Download as DownloadIcon,
  Description as FileIcon,
  Image as ImageIcon,
  PictureAsPdf as PdfIcon,
  InsertDriveFile as DefaultFileIcon,
  Folder as FolderIcon,
  Close as CloseIcon,
  OpenInNew as OpenInNewIcon,
  Edit as EditIcon
} from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import axios from '../../../utils/axios';
import { formatDistanceToNow } from 'date-fns';

const ITEMS_PER_PAGE = 5;

const getFileIcon = (fileType) => {
  if (fileType.startsWith('image/')) return ImageIcon;
  if (fileType === 'application/pdf') return PdfIcon;
  if (fileType.includes('document')) return FileIcon;
  return DefaultFileIcon;
};

const formatFileSize = (bytes) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

const ResidentFiles = ({ residentId }) => {
  const theme = useTheme();
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [description, setDescription] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [page, setPage] = useState(1);
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [fileToRename, setFileToRename] = useState(null);
  const [newFileName, setNewFileName] = useState('');

  const fetchFiles = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/api/files/${residentId}`);
      setFiles(response.data);
    } catch (err) {
      setError(err.response?.data?.message || 'Error fetching files');
    } finally {
      setLoading(false);
    }
  }, [residentId]);

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setSelectedFile(acceptedFiles[0]);
      setUploadDialogOpen(true);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize: 10 * 1024 * 1024 // 10MB
  });

  const handleUpload = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('description', description);

    try {
      await axios.post(`/api/files/upload/${residentId}`, formData, {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(progress);
        }
      });
      
      setUploadDialogOpen(false);
      setDescription('');
      setSelectedFile(null);
      setUploadProgress(0);
      fetchFiles();
    } catch (err) {
      setError(err.response?.data?.message || 'Error uploading file');
    }
  };

  const handleDownload = async (fileId) => {
    try {
      const response = await axios.get(`/api/files/download/${fileId}`);
      window.open(response.data.url, '_blank');
    } catch (err) {
      setError(err.response?.data?.message || 'Error downloading file');
    }
  };

  const handleDelete = async (fileId) => {
    if (!window.confirm('Are you sure you want to delete this file?')) return;

    try {
      await axios.delete(`/api/files/${fileId}`);
      fetchFiles();
    } catch (err) {
      setError(err.response?.data?.message || 'Error deleting file');
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const paginatedFiles = files.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE);
  const pageCount = Math.ceil(files.length / ITEMS_PER_PAGE);

  const handlePreview = async (file) => {
    try {
      const response = await axios.get(`/api/files/download/${file._id}`);
      setPreviewUrl(response.data.url);
      setPreviewFile(file);
      setPreviewDialogOpen(true);
    } catch (err) {
      setError(err.response?.data?.message || 'Error previewing file');
    }
  };

  const handleRename = async () => {
    try {
      await axios.put(`/api/files/${fileToRename._id}/rename`, {
        fileName: newFileName
      });
      setRenameDialogOpen(false);
      setFileToRename(null);
      setNewFileName('');
      fetchFiles();
    } catch (err) {
      setError(err.response?.data?.message || 'Error renaming file');
    }
  };

  const openRenameDialog = (e, file) => {
    e.stopPropagation();
    setFileToRename(file);
    setNewFileName(file.fileName);
    setRenameDialogOpen(true);
  };

  const renderPreviewContent = () => {
    if (!previewFile || !previewUrl) return null;

    if (previewFile.fileType.startsWith('image/')) {
      return (
        <Box sx={{ 
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center',
          bgcolor: 'black'
        }}>
          <img
            src={previewUrl}
            alt={previewFile.fileName}
            style={{ 
              maxWidth: '100%', 
              maxHeight: '100%',
              objectFit: 'contain'
            }}
          />
        </Box>
      );
    }

    if (previewFile.fileType === 'application/pdf') {
      return (
        <Box sx={{ 
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}>
          <iframe
            src={previewUrl}
            title={previewFile.fileName}
            style={{ 
              width: '100%',
              height: '100%',
              border: 'none'
            }}
          />
        </Box>
      );
    }

    return (
      <DialogContentText>
        This file type cannot be previewed directly. Please download the file to view it.
      </DialogContentText>
    );
  };

  return (
    <Card
      elevation={0}
      sx={{ 
        height: '100%',
        background: `linear-gradient(45deg, ${alpha(theme.palette.primary.main, 0.02)}, ${alpha(theme.palette.primary.light, 0.05)})`,
        borderRadius: 1,
        border: '1px solid',
        borderColor: 'divider'
      }}
    >
      <Box
        sx={{
          background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
          p: 2,
          borderRadius: '4px 4px 0 0',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <FolderIcon sx={{ color: 'white' }} />
          <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 600 }}>
            Files & Documents
          </Typography>
        </Box>
      </Box>

      <Box sx={{ p: 2 }}>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
            {error}
          </Alert>
        )}

        <Box
          {...getRootProps()}
          sx={{
            border: '2px dashed',
            borderColor: isDragActive ? 'primary.main' : 'divider',
            borderRadius: 1,
            p: 3,
            mb: 3,
            textAlign: 'center',
            bgcolor: isDragActive ? alpha(theme.palette.primary.main, 0.05) : 'background.paper',
            cursor: 'pointer',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              borderColor: 'primary.main',
              bgcolor: alpha(theme.palette.primary.main, 0.05)
            }
          }}
        >
          <input {...getInputProps()} />
          <UploadIcon sx={{ fontSize: 40, color: 'primary.main', mb: 1 }} />
          <Typography variant="subtitle1" gutterBottom>
            {isDragActive ? 'Drop the file here' : 'Drag & drop a file here, or click to select'}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Maximum file size: 10MB
          </Typography>
        </Box>

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        ) : files.length === 0 ? (
          <Typography variant="body1" color="text.secondary" align="center">
            No files uploaded yet
          </Typography>
        ) : (
          <>
            <List>
              {paginatedFiles.map((file, index) => {
                const FileIconComponent = getFileIcon(file.fileType);
                return (
                  <React.Fragment key={file._id}>
                    {index > 0 && <Divider />}
                    <ListItem
                      sx={{
                        py: 2,
                        px: 1,
                        '&:hover': {
                          bgcolor: alpha(theme.palette.primary.main, 0.05),
                          borderRadius: 1
                        },
                        cursor: 'pointer'
                      }}
                      onClick={() => handlePreview(file)}
                    >
                      <FileIconComponent sx={{ mr: 2, color: 'primary.main' }} />
                      <ListItemText
                        primary={file.fileName}
                        secondary={
                          <Stack direction="column" spacing={0.5}>
                            <Typography variant="body2" color="text.secondary">
                              {file.description}
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                              {formatFileSize(file.fileSize)} • Uploaded {formatDistanceToNow(new Date(file.createdAt))} ago
                              by {file.uploadedBy.firstName} {file.uploadedBy.lastName}
                            </Typography>
                          </Stack>
                        }
                      />
                      <Stack direction="row" spacing={1}>
                        <IconButton
                          size="small"
                          onClick={(e) => openRenameDialog(e, file)}
                          sx={{ color: 'primary.main' }}
                          title="Rename"
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDownload(file._id);
                          }}
                          sx={{ color: 'primary.main' }}
                        >
                          <DownloadIcon />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(file._id);
                          }}
                          sx={{ color: 'error.main' }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Stack>
                    </ListItem>
                  </React.Fragment>
                );
              })}
            </List>
            {pageCount > 1 && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Pagination
                  count={pageCount}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                  size="large"
                  sx={{
                    '& .MuiPaginationItem-root': {
                      fontWeight: 500
                    }
                  }}
                />
              </Box>
            )}
          </>
        )}
      </Box>

      <Dialog open={uploadDialogOpen} onClose={() => setUploadDialogOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Upload File</DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Selected file: {selectedFile?.name}
          </Typography>
          <TextField
            fullWidth
            label="Description"
            multiline
            rows={3}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            sx={{ mt: 2 }}
          />
          {uploadProgress > 0 && (
            <Box sx={{ mt: 2 }}>
              <CircularProgress variant="determinate" value={uploadProgress} />
              <Typography variant="body2" color="text.secondary">
                Uploading: {uploadProgress}%
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUploadDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleUpload}
            variant="contained"
            disabled={uploadProgress > 0}
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={previewDialogOpen} 
        onClose={() => setPreviewDialogOpen(false)}
        fullScreen
        PaperProps={{
          sx: {
            bgcolor: previewFile?.fileType.startsWith('image/') ? 'black' : 'background.paper'
          }
        }}
      >
        <DialogTitle 
          sx={{ 
            m: 0, 
            p: 2, 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between',
            bgcolor: 'background.paper',
            borderBottom: '1px solid',
            borderColor: 'divider',
            position: 'relative',
            zIndex: 1
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {previewFile && React.createElement(getFileIcon(previewFile.fileType), { sx: { color: 'primary.main' } })}
            <Typography variant="h6">{previewFile?.fileName}</Typography>
          </Box>
          <Stack direction="row" spacing={1}>
            <IconButton
              onClick={() => window.open(previewUrl, '_blank')}
              sx={{ color: 'primary.main' }}
              title="Open in new tab"
            >
              <OpenInNewIcon />
            </IconButton>
            <IconButton
              onClick={() => setPreviewDialogOpen(false)}
              sx={{ color: 'text.secondary' }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ 
          p: 0, 
          position: 'relative',
          height: 'calc(100vh - 130px)', // Full height minus header and footer
          overflow: 'hidden'
        }}>
          {renderPreviewContent()}
        </DialogContent>
        <DialogActions sx={{ 
          borderTop: '1px solid',
          borderColor: 'divider',
          bgcolor: 'background.paper',
          px: 2,
          position: 'relative',
          zIndex: 1
        }}>
          <Button 
            onClick={() => handleDownload(previewFile?._id)}
            startIcon={<DownloadIcon />}
          >
            Download
          </Button>
          <Button 
            onClick={() => setPreviewDialogOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Rename Dialog */}
      <Dialog 
        open={renameDialogOpen} 
        onClose={() => setRenameDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Rename File</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            label="File Name"
            value={newFileName}
            onChange={(e) => setNewFileName(e.target.value)}
            sx={{ mt: 2 }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && newFileName.trim()) {
                handleRename();
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRenameDialogOpen(false)}>
            Cancel
          </Button>
          <Button 
            onClick={handleRename}
            variant="contained"
            disabled={!newFileName.trim() || newFileName === fileToRename?.fileName}
          >
            Rename
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default ResidentFiles; 