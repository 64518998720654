import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  IconButton,
  Tooltip,
  Card,
  CardContent,
  Grid,
  Chip,
  Stack,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Block as BlockIcon,
  CheckCircle as CheckCircleIcon,
  Add as AddIcon,
} from '@mui/icons-material';
import axios from '../../utils/axios';
import PasswordInput from '../common/PasswordInput';

const isValidEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return emailRegex.test(email);
};

function ManageAdmins() {
  const [admins, setAdmins] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    jobTitle: '',
  });
  const [emailError, setEmailError] = useState('');
  const [dialogError, setDialogError] = useState('');

  useEffect(() => {
    fetchAdmins();
  }, []);

  const fetchAdmins = async () => {
    try {
      const response = await axios.get('/api/auth/admins');
      setAdmins(response.data);
    } catch (error) {
      console.error('Fetch admins error:', error);
      setError('Failed to fetch admins');
    }
  };

  const handleCreateDialogOpen = () => {
    setIsCreateDialogOpen(true);
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      jobTitle: '',
    });
    setDialogError('');
    setEmailError('');
  };

  const handleEditDialogOpen = (admin) => {
    setSelectedAdmin(admin);
    setFormData({
      firstName: admin.firstName,
      lastName: admin.lastName,
      email: admin.email,
      password: '',
      confirmPassword: '',
      jobTitle: admin.jobTitle || '',
    });
    setIsEditDialogOpen(true);
    setDialogError('');
    setEmailError('');
  };

  const handleDeleteDialogOpen = (admin) => {
    setSelectedAdmin(admin);
    setIsDeleteDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsCreateDialogOpen(false);
    setIsEditDialogOpen(false);
    setIsDeleteDialogOpen(false);
    setSelectedAdmin(null);
    setDialogError('');
    setEmailError('');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    
    if (name === 'email') {
      if (value && !isValidEmail(value)) {
        setEmailError('Please enter a valid email address');
      } else {
        setEmailError('');
      }
    }
  };

  const handleCreateAdmin = async () => {
    if (!isValidEmail(formData.email)) {
      setDialogError('Please enter a valid email address');
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setDialogError('Passwords do not match');
      return;
    }

    if (!formData.jobTitle.trim()) {
      setDialogError('Job Title is required');
      return;
    }

    try {
      const response = await axios.post('/api/auth/register-admin', {
        email: formData.email,
        password: formData.password,
        firstName: formData.firstName,
        lastName: formData.lastName,
        jobTitle: formData.jobTitle
      });

      if (response.status === 201) {
        setSuccess('Admin created successfully');
        handleDialogClose();
        fetchAdmins();
      }
    } catch (error) {
      console.error('Create admin error:', error);
      // Check for duplicate email error
      if (error.response?.data?.message?.includes('duplicate key error') && 
          error.response?.data?.message?.includes('email')) {
        setDialogError('An account with this email already exists');
      } else {
        setDialogError(error.response?.data?.message || 'Failed to create admin');
      }
    }
  };

  const handleUpdateAdmin = async () => {
    if (!isValidEmail(formData.email)) {
      setDialogError('Please enter a valid email address');
      return;
    }

    if (formData.password && formData.password !== formData.confirmPassword) {
      setDialogError('Passwords do not match');
      return;
    }

    if (!formData.jobTitle.trim()) {
      setDialogError('Job Title is required');
      return;
    }

    try {
      const updateData = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        jobTitle: formData.jobTitle
      };

      if (formData.password) {
        updateData.password = formData.password;
      }

      const response = await axios.put(`/api/auth/admins/${selectedAdmin._id}`, updateData);

      if (response.status === 200) {
        setSuccess('Admin updated successfully');
        handleDialogClose();
        fetchAdmins();
      }
    } catch (error) {
      console.error('Update admin error:', error);
      setDialogError(error.response?.data?.message || 'Failed to update admin');
    }
  };

  const handleDeleteAdmin = async () => {
    try {
      const response = await axios.delete(`/api/auth/admins/${selectedAdmin._id}`);

      if (response.status === 200) {
        setSuccess('Admin deleted successfully');
        handleDialogClose();
        fetchAdmins();
      }
    } catch (error) {
      console.error('Delete admin error:', error);
      setError(error.response?.data?.message || 'Failed to delete admin');
    }
  };

  const handleToggleAdminStatus = async (admin) => {
    try {
      const response = await axios.patch(`/api/auth/admins/${admin._id}/status`, {
        isActive: !admin.isActive
      });

      if (response.status === 200) {
        setSuccess(`Admin ${admin.isActive ? 'deactivated' : 'activated'} successfully`);
        
        // Get current user from localStorage
        const currentUser = JSON.parse(localStorage.getItem('user'));
        
        // If the deactivated admin is the current user, log them out
        if (currentUser && currentUser.id === admin._id && admin.isActive) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          window.location.href = '/login';
          return;
        }
        
        fetchAdmins();
      }
    } catch (error) {
      console.error('Toggle admin status error:', error);
      setError(error.response?.data?.message || 'Failed to update admin status');
    }
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card 
              elevation={0}
              sx={{ 
                mb: 3,
                backgroundImage: 'linear-gradient(145deg, #ffffff 0%, #f8f9ff 100%)',
                borderRadius: 2,
                border: '1px solid',
                borderColor: 'divider',
                boxShadow: '0 4px 20px -4px rgba(0, 0, 0, 0.1)'
              }}
            >
              <CardContent>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                  <Typography variant="h4" component="h1">
                    Manage Administrators
                  </Typography>
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={handleCreateDialogOpen}
                    sx={{
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                      '&:hover': {
                        boxShadow: '0 6px 16px rgba(0, 0, 0, 0.15)',
                      },
                    }}
                  >
                    Create New Admin
                  </Button>
                </Stack>
              </CardContent>
            </Card>

            {error && (
              <Alert 
                severity="error" 
                sx={{ 
                  mb: 3,
                  borderRadius: 2,
                  boxShadow: '0 4px 12px rgba(211, 47, 47, 0.1)'
                }}
                onClose={() => setError('')}
              >
                {error}
              </Alert>
            )}

            {success && (
              <Alert 
                severity="success" 
                sx={{ 
                  mb: 3,
                  borderRadius: 2,
                  boxShadow: '0 4px 12px rgba(46, 125, 50, 0.1)'
                }}
                onClose={() => setSuccess('')}
              >
                {success}
              </Alert>
            )}

            <TableContainer 
              component={Paper}
              elevation={0}
              sx={{ 
                borderRadius: 2,
                border: '1px solid',
                borderColor: 'divider',
                boxShadow: '0 4px 20px -4px rgba(0, 0, 0, 0.1)'
              }}
            >
              <Table>
                <TableHead>
                  <TableRow sx={{ bgcolor: 'background.default' }}>
                    <TableCell sx={{ fontWeight: 600 }}>Name</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Email</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Job Title</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Status</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Created At</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {admins.map((admin) => (
                    <TableRow key={admin._id}>
                      <TableCell>{`${admin.firstName} ${admin.lastName}`}</TableCell>
                      <TableCell>{admin.email}</TableCell>
                      <TableCell>{admin.jobTitle}</TableCell>
                      <TableCell>
                        <Chip
                          label={admin.isActive ? 'Active' : 'Inactive'}
                          color={admin.isActive ? 'success' : 'error'}
                          size="small"
                          sx={{ fontWeight: 500 }}
                        />
                      </TableCell>
                      <TableCell>{new Date(admin.createdAt).toLocaleDateString()}</TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={1}>
                          <Tooltip title="Edit Admin">
                            <IconButton
                              size="small"
                              onClick={() => handleEditDialogOpen(admin)}
                              sx={{ color: 'primary.main' }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={admin.isActive ? 'Deactivate Admin' : 'Activate Admin'}>
                            <IconButton
                              size="small"
                              onClick={() => handleToggleAdminStatus(admin)}
                              color={admin.isActive ? 'error' : 'success'}
                            >
                              {admin.isActive ? <BlockIcon /> : <CheckCircleIcon />}
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete Admin">
                            <IconButton
                              size="small"
                              onClick={() => handleDeleteDialogOpen(admin)}
                              sx={{ color: 'error.main' }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        {/* Create Admin Dialog */}
        <Dialog 
          open={isCreateDialogOpen} 
          onClose={handleDialogClose}
          PaperProps={{
            sx: {
              borderRadius: 2,
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)'
            }
          }}
        >
          <DialogTitle>Create New Admin</DialogTitle>
          <DialogContent>
            {dialogError && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {dialogError}
              </Alert>
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              name="firstName"
              label="First Name"
              value={formData.firstName}
              onChange={handleInputChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="lastName"
              label="Last Name"
              value={formData.lastName}
              onChange={handleInputChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="email"
              label="Email Address"
              type="email"
              value={formData.email}
              onChange={handleInputChange}
              error={!!emailError}
              helperText={emailError}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="jobTitle"
              label="Job Title"
              value={formData.jobTitle}
              onChange={handleInputChange}
            />
            <PasswordInput
              name="password"
              label="Password"
              value={formData.password}
              onChange={handleInputChange}
            />
            <PasswordInput
              name="confirmPassword"
              label="Confirm Password"
              value={formData.confirmPassword}
              onChange={handleInputChange}
              originalPassword={formData.password}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button onClick={handleCreateAdmin} variant="contained">
              Create
            </Button>
          </DialogActions>
        </Dialog>

        {/* Edit Admin Dialog */}
        <Dialog 
          open={isEditDialogOpen} 
          onClose={handleDialogClose}
          PaperProps={{
            sx: {
              borderRadius: 2,
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)'
            }
          }}
        >
          <DialogTitle>Edit Admin</DialogTitle>
          <DialogContent>
            {dialogError && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {dialogError}
              </Alert>
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              name="firstName"
              label="First Name"
              value={formData.firstName}
              onChange={handleInputChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="lastName"
              label="Last Name"
              value={formData.lastName}
              onChange={handleInputChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="email"
              label="Email Address"
              type="email"
              value={formData.email}
              onChange={handleInputChange}
              error={!!emailError}
              helperText={emailError}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="jobTitle"
              label="Job Title"
              value={formData.jobTitle}
              onChange={handleInputChange}
            />
            <Typography variant="subtitle2" color="text.secondary" sx={{ mt: 2, mb: 1 }}>
              Leave password fields empty to keep current password
            </Typography>
            <PasswordInput
              name="password"
              label="New Password (Optional)"
              value={formData.password}
              onChange={handleInputChange}
            />
            <PasswordInput
              name="confirmPassword"
              label="Confirm New Password"
              value={formData.confirmPassword}
              onChange={handleInputChange}
              originalPassword={formData.password}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button onClick={handleUpdateAdmin} variant="contained">
              Update
            </Button>
          </DialogActions>
        </Dialog>

        {/* Delete Admin Dialog */}
        <Dialog 
          open={isDeleteDialogOpen} 
          onClose={handleDialogClose}
          PaperProps={{
            sx: {
              borderRadius: 2,
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)'
            }
          }}
        >
          <DialogTitle>Delete Admin</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete the admin account for{' '}
              {selectedAdmin ? `${selectedAdmin.firstName} ${selectedAdmin.lastName}` : ''}?
              This action cannot be undone.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button onClick={handleDeleteAdmin} color="error" variant="contained">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Container>
  );
}

export default ManageAdmins; 