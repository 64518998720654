import React from 'react';
import {
  Box,
  Typography,
  TextField,
  Card,
  CardContent,
  alpha,
  useTheme
} from '@mui/material';
import {
  Assignment,
  Event as EventIcon,
  MedicalServices as MedicalIcon,
  Business as BusinessIcon
} from '@mui/icons-material';

const ProgramDetails = ({ 
  resident, 
  editMode, 
  onEditModeChange, 
  onSaveField 
}) => {
  const theme = useTheme();

  const formatDate = (dateString) => {
    if (!dateString) return '';
    return new Date(dateString).toLocaleDateString();
  };

  const getDateInputValue = (dateString) => {
    if (!dateString) return '';
    return new Date(dateString).toISOString().split('T')[0];
  };

  const handleDateSave = (field, index, value) => {
    onSaveField(field, index, value);
  };

  return (
    <Card
      elevation={0}
      sx={{ 
        height: '100%',
        background: `linear-gradient(45deg, ${alpha(theme.palette.primary.main, 0.02)}, ${alpha(theme.palette.primary.light, 0.05)})`,
        borderRadius: 1,
        border: '1px solid',
        borderColor: 'divider'
      }}
    >
      <Box
        sx={{
          background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
          p: 2,
          borderRadius: '4px 4px 0 0',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Assignment sx={{ color: 'white' }} />
          <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 600 }}>
            Program Details
          </Typography>
        </Box>
      </Box>

      <Box sx={{ p: 2 }}>
        {/* Move-in Date */}
        <Card 
          elevation={0}
          sx={{ 
            mb: 2,
            borderRadius: 1.5,
            bgcolor: alpha(theme.palette.info.main, 0.05),
            border: `1px solid ${alpha(theme.palette.info.main, 0.1)}`
          }}
        >
          <CardContent sx={{ p: '12px !important' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: 0.5 }}>
              <EventIcon sx={{ color: 'info.main', fontSize: 16 }} />
              <Typography variant="caption" color="info.main" sx={{ fontWeight: 500, fontSize: '0.7rem' }}>
                Move-in Date
              </Typography>
            </Box>
            {editMode.moveInDate ? (
              <TextField
                type="date"
                size="small"
                defaultValue={getDateInputValue(resident.moveInDate)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleDateSave('moveInDate', null, e.target.value);
                  } else if (e.key === 'Escape') {
                    onEditModeChange('moveInDate', null, false);
                  }
                }}
                onBlur={(e) => handleDateSave('moveInDate', null, e.target.value)}
                autoFocus
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    bgcolor: 'white'
                  }
                }}
              />
            ) : (
              <Box 
                onClick={() => onEditModeChange('moveInDate', null, true)}
                sx={{ 
                  cursor: 'pointer', 
                  '&:hover': { bgcolor: alpha(theme.palette.info.main, 0.1) },
                  borderRadius: 1,
                  py: 0.5
                }}
              >
                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                  {resident.moveInDate ? formatDate(resident.moveInDate) : 'Click to set'}
                </Typography>
              </Box>
            )}
          </CardContent>
        </Card>

        {/* Level of Care */}
        <Card 
          elevation={0} 
          sx={{ 
            mb: 2,
            borderRadius: 1.5,
            bgcolor: alpha(theme.palette.info.main, 0.05),
            border: `1px solid ${alpha(theme.palette.info.main, 0.1)}`
          }}
        >
          <CardContent sx={{ p: '12px !important' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: 0.5 }}>
              <MedicalIcon sx={{ color: 'info.main', fontSize: 16 }} />
              <Typography variant="caption" color="info.main" sx={{ fontWeight: 500, fontSize: '0.7rem' }}>
                Level of Care (LOC)
              </Typography>
            </Box>
            {editMode.levelOfCare ? (
              <TextField
                size="small"
                defaultValue={resident.levelOfCare || ''}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onSaveField('levelOfCare', null, e.target.value);
                  } else if (e.key === 'Escape') {
                    onEditModeChange('levelOfCare', null, false);
                  }
                }}
                onBlur={(e) => onSaveField('levelOfCare', null, e.target.value)}
                autoFocus
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    bgcolor: 'white'
                  }
                }}
              />
            ) : (
              <Box 
                onClick={() => onEditModeChange('levelOfCare', null, true)}
                sx={{ 
                  cursor: 'pointer', 
                  '&:hover': { bgcolor: alpha(theme.palette.info.main, 0.1) },
                  borderRadius: 1,
                  py: 0.5
                }}
              >
                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                  {resident.levelOfCare || 'Click to set'}
                </Typography>
              </Box>
            )}
          </CardContent>
        </Card>

        {/* Treatment Agency */}
        <Card 
          elevation={0}
          sx={{ 
            borderRadius: 1.5,
            bgcolor: alpha(theme.palette.info.main, 0.05),
            border: `1px solid ${alpha(theme.palette.info.main, 0.1)}`
          }}
        >
          <CardContent sx={{ p: '12px !important' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: 0.5 }}>
              <BusinessIcon sx={{ color: 'info.main', fontSize: 16 }} />
              <Typography variant="caption" color="info.main" sx={{ fontWeight: 500, fontSize: '0.7rem' }}>
                Treatment Agency
              </Typography>
            </Box>
            {editMode.treatmentAgency ? (
              <TextField
                size="small"
                defaultValue={resident.treatmentAgency || ''}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onSaveField('treatmentAgency', null, e.target.value);
                  } else if (e.key === 'Escape') {
                    onEditModeChange('treatmentAgency', null, false);
                  }
                }}
                onBlur={(e) => onSaveField('treatmentAgency', null, e.target.value)}
                autoFocus
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    bgcolor: 'white'
                  }
                }}
              />
            ) : (
              <Box 
                onClick={() => onEditModeChange('treatmentAgency', null, true)}
                sx={{ 
                  cursor: 'pointer', 
                  '&:hover': { bgcolor: alpha(theme.palette.info.main, 0.1) },
                  borderRadius: 1,
                  py: 0.5
                }}
              >
                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                  {resident.treatmentAgency || 'Click to set'}
                </Typography>
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>
    </Card>
  );
};

export default ProgramDetails; 