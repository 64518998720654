import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  IconButton,
  Tooltip,
  Paper,
  Stack,
  Divider,
  ToggleButtonGroup,
  ToggleButton,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  CircularProgress,
  Alert,
  Skeleton,
} from '@mui/material';
import {
  Assignment,
  Build,
  CheckCircle,
  Image as ImageIcon,
  Refresh,
  LocationOn,
  AccessTime,
  Person,
  ViewModule as ViewModuleIcon,
  ViewList as ViewListIcon,
  FilterList as FilterIcon,
  Error as ErrorIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  NavigateBefore as NavigateBeforeIcon,
  NavigateNext as NavigateNextIcon,
  Download as DownloadIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://192.168.0.135:5001';
axios.defaults.baseURL = API_URL;

const MaintenanceList = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [note, setNote] = useState('');
  const [newStatus, setNewStatus] = useState('');
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [viewMode, setViewMode] = useState('grid');
  const [currentTab, setCurrentTab] = useState('all');
  const [filterPriority, setFilterPriority] = useState('all');
  const [filterProperty, setFilterProperty] = useState('all');
  const [properties, setProperties] = useState([]);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [error, setError] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1);

  const fetchRequests = async () => {
    try {
      setError(null);
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/maintenance/all', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setRequests(response.data);
    } catch (error) {
      setError('Failed to fetch maintenance requests. Please try again.');
      console.error('Error fetching maintenance requests:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchProperties = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/properties', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setProperties(response.data);
    } catch (error) {
      console.error('Error fetching properties:', error);
    }
  };

  useEffect(() => {
    fetchRequests();
    fetchProperties();
  }, []);

  const handleStatusUpdate = async () => {
    try {
      setLoadingUpdate(true);
      setError(null);
      const token = localStorage.getItem('token');
      await axios.patch(`/api/maintenance/${selectedRequest._id}/status`, {
        status: newStatus,
        note: note
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      await fetchRequests();
      setDialogOpen(false);
      setNote('');
      setNewStatus('');
    } catch (error) {
      setError('Failed to update status. Please try again.');
      console.error('Error updating status:', error);
    } finally {
      setLoadingUpdate(false);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Pending':
        return 'warning';
      case 'In Progress':
        return 'info';
      case 'Completed':
        return 'success';
      default:
        return 'default';
    }
  };

  const getPriorityColor = (priority) => {
    switch (priority) {
      case 'Low':
        return 'success';
      case 'Medium':
        return 'info';
      case 'High':
        return 'warning';
      case 'Emergency':
        return 'error';
      default:
        return 'default';
    }
  };

  const getResidentName = (resident) => {
    if (!resident) return 'Unknown';
    return `${resident.firstName} ${resident.lastName}`;
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const filteredRequests = requests.filter(request => {
    if (currentTab !== 'all' && request.status.toLowerCase() !== currentTab) {
      return false;
    }
    if (filterPriority !== 'all' && request.priority !== filterPriority) {
      return false;
    }
    if (filterProperty !== 'all' && request.property?._id !== filterProperty) {
      return false;
    }
    return true;
  });

  const MaintenanceCard = ({ request }) => (
    <Card
      elevation={0}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 2,
        backgroundColor: '#ffffff',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
          borderColor: 'primary.main',
        }
      }}
      onClick={() => {
        setSelectedRequest(request);
      }}
    >
      <Box sx={{ position: 'relative', p: 2.5 }}>
        {/* Status Badge */}
        <Chip
          label={request.status}
          color={getStatusColor(request.status)}
          size="small"
          sx={{ 
            position: 'absolute',
            top: 16,
            right: 16,
            height: 24,
            fontWeight: 600,
            fontSize: '0.75rem'
          }}
        />

        {/* Title and Priority */}
        <Box sx={{ pr: 9, mb: 2 }}>
          <Typography variant="h6" sx={{ 
            fontWeight: 600,
            color: 'text.primary',
            mb: 1,
            fontSize: '1rem',
            lineHeight: 1.3
          }}>
            {request.title}
          </Typography>
          <Chip
            label={request.priority}
            color={getPriorityColor(request.priority)}
            size="small"
            sx={{ 
              height: 24,
              fontWeight: 600,
              fontSize: '0.75rem'
            }}
          />
        </Box>

        {/* Resident and Property Info */}
        <Stack spacing={1.5} sx={{ mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Person sx={{ fontSize: 18, color: 'text.secondary' }} />
            <Typography variant="body2" sx={{ 
              fontWeight: 500,
              color: 'text.primary'
            }}>
              {getResidentName(request.resident)}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <LocationOn sx={{ fontSize: 18, color: 'text.secondary' }} />
            <Typography variant="body2" sx={{ 
              fontWeight: 500,
              color: 'text.primary'
            }}>
              {request.property?.name} • {request.unit}
            </Typography>
          </Box>
        </Stack>

        {/* Description */}
        {request.description && (
          <Typography 
            variant="body2" 
            color="text.secondary" 
            sx={{ 
              mb: 2,
              fontSize: '0.875rem',
              lineHeight: 1.6,
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {request.description}
          </Typography>
        )}

        {/* Footer */}
        <Stack 
          direction="row" 
          spacing={2} 
          alignItems="center" 
          sx={{ 
            pt: 2,
            borderTop: '1px solid',
            borderColor: 'divider'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <AccessTime sx={{ fontSize: 16, color: 'text.secondary' }} />
            <Typography variant="caption" color="text.secondary" sx={{ fontWeight: 500 }}>
              {formatDate(request.createdAt)}
            </Typography>
          </Box>
          {request.images?.length > 0 && (
            <Tooltip title="Has Images">
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <ImageIcon sx={{ fontSize: 16, color: 'text.secondary' }} />
                <Typography variant="caption" color="text.secondary" sx={{ fontWeight: 500 }}>
                  {request.images.length}
                </Typography>
              </Box>
            </Tooltip>
          )}
        </Stack>
      </Box>
    </Card>
  );

  const MaintenanceTable = ({ requests }) => (
    <TableContainer 
      component={Paper}
      elevation={0}
      sx={{ 
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        overflow: 'hidden',
        backgroundColor: '#ffffff'
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Title</TableCell>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Resident</TableCell>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Property</TableCell>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Priority</TableCell>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Status</TableCell>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Date</TableCell>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {requests.map((request) => (
            <TableRow 
              key={request._id}
              sx={{ 
                '&:hover': {
                  bgcolor: '#f8f9fb'
                }
              }}
            >
              <TableCell sx={{ py: 1.5, color: 'primary.main', fontWeight: 500 }}>{request.title}</TableCell>
              <TableCell sx={{ py: 1.5 }}>{getResidentName(request.resident)}</TableCell>
              <TableCell sx={{ py: 1.5 }}>{request.property?.name} • {request.unit}</TableCell>
              <TableCell sx={{ py: 1.5 }}>
                <Chip
                  label={request.priority}
                  color={getPriorityColor(request.priority)}
                  size="small"
                  sx={{ fontWeight: 500, height: 24 }}
                />
              </TableCell>
              <TableCell sx={{ py: 1.5 }}>
                <Chip
                  label={request.status}
                  color={getStatusColor(request.status)}
                  size="small"
                  sx={{ fontWeight: 500, height: 24 }}
                />
              </TableCell>
              <TableCell sx={{ py: 1.5 }}>
                <Typography variant="caption" sx={{ fontWeight: 500, color: 'text.secondary' }}>
                  {formatDate(request.createdAt)}
                </Typography>
              </TableCell>
              <TableCell sx={{ py: 1.5 }}>
                <Stack direction="row" spacing={1}>
                  {request.images?.length > 0 && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        setSelectedRequest(request);
                        setImageDialogOpen(true);
                      }}
                    >
                      <ImageIcon fontSize="small" />
                    </IconButton>
                  )}
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={request.status === 'Completed' ? <CheckCircle /> : <Build />}
                    onClick={() => {
                      setSelectedRequest(request);
                    }}
                    color={request.status === 'Completed' ? 'success' : 'primary'}
                    sx={{
                      borderRadius: 1,
                      textTransform: 'none',
                      fontWeight: 500,
                      borderWidth: 1.5,
                      py: 0.5
                    }}
                  >
                    Update
                  </Button>
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const CardSkeleton = () => (
    <Card
      elevation={0}
      sx={{
        height: '100%',
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
      }}
    >
      <CardContent sx={{ p: 2.5 }}>
        <Stack spacing={2}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Skeleton width="60%" height={24} />
            <Skeleton width={80} height={24} />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Skeleton width={20} height={20} variant="circular" />
            <Skeleton width="40%" height={20} />
          </Box>
          <Skeleton width="100%" height={60} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Skeleton width={100} height={24} />
            <Skeleton width={120} height={24} />
          </Box>
          <Skeleton width="100%" height={36} />
        </Stack>
      </CardContent>
    </Card>
  );

  const TableSkeleton = () => (
    <TableContainer 
      component={Paper}
      elevation={0}
      sx={{ 
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        overflow: 'hidden'
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {[...Array(7)].map((_, index) => (
              <TableCell key={index} sx={{ bgcolor: '#f5f6f8' }}>
                <Skeleton width={100} height={24} />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(5)].map((_, index) => (
            <TableRow key={index}>
              {[...Array(7)].map((_, cellIndex) => (
                <TableCell key={cellIndex}>
                  <Skeleton width={cellIndex === 6 ? 120 : 100} height={24} />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const RequestDetailsDialog = ({ request, open, onClose }) => {
    const [localStatus, setLocalStatus] = useState(request?.status || '');
    const [localNote, setLocalNote] = useState('');
    const [isUpdating, setIsUpdating] = useState(false);
    
    useEffect(() => {
      if (request) {
        setLocalStatus(request.status);
        setLocalNote('');
      }
    }, [request]);

    if (!request) return null;

    const handleUpdate = async () => {
      try {
        setIsUpdating(true);
        setError(null);
        const token = localStorage.getItem('token');
        
        await axios.patch(`/api/maintenance/${request._id}/status`, {
          status: localStatus,
          note: localNote
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        // Update the local requests state first
        setRequests(prevRequests => 
          prevRequests.map(r => 
            r._id === request._id 
              ? { ...r, status: localStatus }
              : r
          )
        );
        
        // Close the dialog before fetching fresh data
        onClose();
        
        // Fetch fresh data in the background
        fetchRequests();
      } catch (error) {
        setError('Failed to update status. Please try again.');
        console.error('Error updating status:', error);
      } finally {
        setIsUpdating(false);
      }
    };
    
    return (
      <Dialog
        open={open}
        onClose={!isUpdating ? onClose : undefined}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)'
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid',
          borderColor: 'divider',
          bgcolor: '#f8f9fb',
          p: 3
        }}>
          <Stack spacing={2}>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', gap: 2 }}>
              <Typography variant="h5" sx={{ fontWeight: 600 }}>
                {request.title}
              </Typography>
              <Chip
                label={request.status}
                color={getStatusColor(request.status)}
                sx={{ fontWeight: 600 }}
              />
            </Box>
            <Stack direction="row" spacing={3}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Person sx={{ fontSize: 20, color: 'text.secondary' }} />
                <Typography sx={{ fontWeight: 500 }}>
                  {getResidentName(request.resident)}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <LocationOn sx={{ fontSize: 20, color: 'text.secondary' }} />
                <Typography sx={{ fontWeight: 500 }}>
                  {request.property?.name} • {request.unit}
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Stack spacing={3}>
            <Box>
              <Typography variant="subtitle2" sx={{ mb: 1, color: 'text.secondary' }}>
                Description
              </Typography>
              <Typography>
                {request.description}
              </Typography>
            </Box>

            <Box>
              <Typography variant="subtitle2" sx={{ mb: 1, color: 'text.secondary' }}>
                Details
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Paper variant="outlined" sx={{ p: 2, borderRadius: 1 }}>
                    <Stack spacing={1}>
                      <Typography variant="body2" color="text.secondary">Priority</Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Chip
                          label={request.priority}
                          color={getPriorityColor(request.priority)}
                          size="small"
                          sx={{ fontWeight: 600 }}
                        />
                      </Box>
                    </Stack>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper variant="outlined" sx={{ p: 2, borderRadius: 1 }}>
                    <Stack spacing={1}>
                      <Typography variant="body2" color="text.secondary">Created</Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <AccessTime sx={{ fontSize: 20, color: 'text.secondary' }} />
                        <Typography sx={{ fontWeight: 500 }}>
                          {formatDate(request.createdAt)}
                        </Typography>
                      </Box>
                    </Stack>
                  </Paper>
                </Grid>
              </Grid>
            </Box>

            <Box>
              <Typography variant="subtitle2" sx={{ mb: 1, color: 'text.secondary' }}>
                Update Status
              </Typography>
              <Paper variant="outlined" sx={{ p: 2, borderRadius: 1 }}>
                <Stack spacing={2}>
                  {error && (
                    <Alert 
                      severity="error"
                      sx={{ 
                        borderRadius: 1,
                        '& .MuiAlert-icon': {
                          color: 'error.main'
                        }
                      }}
                    >
                      {error}
                    </Alert>
                  )}
                  <FormControl fullWidth disabled={isUpdating}>
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={localStatus}
                      onChange={(e) => setLocalStatus(e.target.value)}
                      label="Status"
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'divider',
                        },
                      }}
                    >
                      <MenuItem value="Pending">Pending</MenuItem>
                      <MenuItem value="In Progress">In Progress</MenuItem>
                      <MenuItem value="Completed">Completed</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    fullWidth
                    label="Add a note"
                    multiline
                    rows={4}
                    value={localNote}
                    onChange={(e) => setLocalNote(e.target.value)}
                    disabled={isUpdating}
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'divider',
                      },
                    }}
                  />
                </Stack>
              </Paper>
            </Box>

            {request.images?.length > 0 && (
              <Box>
                <Typography variant="subtitle2" sx={{ mb: 2, color: 'text.secondary' }}>
                  Images
                </Typography>
                <Grid container spacing={2}>
                  {request.images.map((image, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Paper
                        variant="outlined"
                        sx={{
                          p: 1,
                          borderRadius: 1,
                          cursor: 'pointer',
                          transition: 'all 0.2s',
                          '&:hover': {
                            borderColor: 'primary.main',
                            transform: 'scale(1.02)'
                          }
                        }}
                        onClick={() => {
                          setSelectedImage(`${process.env.REACT_APP_S3_URL}/${image}`);
                          setImageDialogOpen(true);
                        }}
                      >
                        <Box
                          sx={{
                            position: 'relative',
                            paddingTop: '75%',
                            borderRadius: 0.5,
                            overflow: 'hidden'
                          }}
                        >
                          <img
                            src={`${process.env.REACT_APP_S3_URL}/${image}`}
                            alt={`Maintenance request ${index + 1}`}
                            style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover'
                            }}
                          />
                        </Box>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 3, borderTop: '1px solid', borderColor: 'divider' }}>
          <Button
            variant="outlined"
            onClick={onClose}
            disabled={isUpdating}
            sx={{
              borderRadius: 1,
              textTransform: 'none',
              fontWeight: 500
            }}
          >
            Close
          </Button>
          <Button
            variant="contained"
            onClick={handleUpdate}
            disabled={isUpdating || localStatus === request.status}
            sx={{
              borderRadius: 1,
              textTransform: 'none',
              fontWeight: 500,
              minWidth: 100,
              position: 'relative'
            }}
          >
            {isUpdating ? (
              <CircularProgress
                size={24}
                sx={{
                  color: 'primary.light',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px'
                }}
              />
            ) : (
              'Update Status'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleNextImage = () => {
    if (selectedRequest && selectedRequest.images) {
      setSelectedImageIndex((prev) => 
        prev === selectedRequest.images.length - 1 ? 0 : prev + 1
      );
      setZoomLevel(1);
    }
  };

  const handlePrevImage = () => {
    if (selectedRequest && selectedRequest.images) {
      setSelectedImageIndex((prev) => 
        prev === 0 ? selectedRequest.images.length - 1 : prev - 1
      );
      setZoomLevel(1);
    }
  };

  const handleZoomIn = () => {
    setZoomLevel(prev => Math.min(prev + 0.5, 3));
  };

  const handleZoomOut = () => {
    setZoomLevel(prev => Math.max(prev - 0.5, 0.5));
  };

  const ImageViewDialog = () => {
    if (!selectedRequest || !selectedRequest.images || selectedRequest.images.length === 0) return null;

    const currentImage = `${process.env.REACT_APP_S3_URL}/${selectedRequest.images[selectedImageIndex]}`;

    return (
      <Dialog
        open={imageDialogOpen}
        onClose={() => {
          setImageDialogOpen(false);
          setSelectedImageIndex(0);
          setZoomLevel(1);
        }}
        maxWidth="xl"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            bgcolor: 'black',
            maxHeight: '90vh',
            height: '90vh'
          }
        }}
      >
        <Box sx={{ 
          position: 'relative', 
          width: '100%', 
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}>
          {/* Header */}
          <Box sx={{
            p: 2,
            bgcolor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1
          }}>
            <Typography sx={{ color: 'white' }}>
              Image {selectedImageIndex + 1} of {selectedRequest.images.length}
            </Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <IconButton
                onClick={handleZoomOut}
                disabled={zoomLevel <= 0.5}
                sx={{ color: 'white' }}
              >
                <ZoomOutIcon />
              </IconButton>
              <IconButton
                onClick={handleZoomIn}
                disabled={zoomLevel >= 3}
                sx={{ color: 'white' }}
              >
                <ZoomInIcon />
              </IconButton>
              <IconButton
                onClick={() => window.open(currentImage, '_blank')}
                sx={{ color: 'white' }}
              >
                <DownloadIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  setImageDialogOpen(false);
                  setSelectedImageIndex(0);
                  setZoomLevel(1);
                }}
                sx={{ color: 'white' }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

          {/* Image Container */}
          <Box sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            overflow: 'auto'
          }}>
            {selectedRequest.images.length > 1 && (
              <>
                <IconButton
                  onClick={handlePrevImage}
                  sx={{
                    position: 'absolute',
                    left: 16,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    bgcolor: 'rgba(255, 255, 255, 0.1)',
                    color: 'white',
                    '&:hover': {
                      bgcolor: 'rgba(255, 255, 255, 0.2)'
                    }
                  }}
                >
                  <NavigateBeforeIcon />
                </IconButton>
                <IconButton
                  onClick={handleNextImage}
                  sx={{
                    position: 'absolute',
                    right: 16,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    bgcolor: 'rgba(255, 255, 255, 0.1)',
                    color: 'white',
                    '&:hover': {
                      bgcolor: 'rgba(255, 255, 255, 0.2)'
                    }
                  }}
                >
                  <NavigateNextIcon />
                </IconButton>
              </>
            )}
            <img
              src={currentImage}
              alt={`Maintenance request ${selectedImageIndex + 1}`}
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain',
                transform: `scale(${zoomLevel})`,
                transition: 'transform 0.2s ease-in-out'
              }}
            />
          </Box>

          {/* Thumbnail Navigation */}
          {selectedRequest.images.length > 1 && (
            <Box sx={{
              p: 2,
              bgcolor: 'rgba(0, 0, 0, 0.8)',
              display: 'flex',
              gap: 1,
              overflowX: 'auto',
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0
            }}>
              {selectedRequest.images.map((image, index) => (
                <Box
                  key={index}
                  onClick={() => {
                    setSelectedImageIndex(index);
                    setZoomLevel(1);
                  }}
                  sx={{
                    width: 80,
                    height: 60,
                    flexShrink: 0,
                    borderRadius: 1,
                    overflow: 'hidden',
                    border: '2px solid',
                    borderColor: index === selectedImageIndex ? 'primary.main' : 'transparent',
                    cursor: 'pointer',
                    '&:hover': {
                      borderColor: 'primary.main'
                    }
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_S3_URL}/${image}`}
                    alt={`Thumbnail ${index + 1}`}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                  />
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Dialog>
    );
  };

  return (
    <Container maxWidth="lg" sx={{ py: 3 }}>
      {error && (
        <Alert 
          severity="error" 
          sx={{ 
            mb: 3,
            borderRadius: 1,
            '& .MuiAlert-icon': {
              color: 'error.main'
            }
          }}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => setError(null)}
              sx={{ 
                textTransform: 'none',
                fontWeight: 500
              }}
            >
              Dismiss
            </Button>
          }
        >
          {error}
        </Alert>
      )}

      <Paper
        elevation={0}
        sx={{
          mb: 3,
          p: { xs: 2, sm: 2.5 },
          backgroundColor: '#ffffff',
          borderRadius: 1,
          border: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: { xs: 'stretch', md: 'center' },
          gap: { xs: 2, md: 0 },
          mb: { xs: 2, md: 2.5 }
        }}>
          <Box sx={{ flex: 1 }}>
            <Typography 
              variant="h5" 
              component="h1" 
              sx={{ 
                fontWeight: 600, 
                color: 'primary.main',
                mb: { xs: 0.5, md: 0.25 }
              }}
            >
              Maintenance Requests
            </Typography>
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{ 
                display: { xs: 'none', sm: 'block' },
                fontWeight: 500
              }}
            >
              Manage and track all maintenance requests across properties
            </Typography>
          </Box>
          <Stack 
            direction={{ xs: 'column', sm: 'row' }} 
            spacing={2}
            sx={{ 
              width: { xs: '100%', md: 'auto' },
              alignItems: { xs: 'stretch', sm: 'center' }
            }}
          >
            <Button
              startIcon={<Refresh />}
              onClick={fetchRequests}
              variant="outlined"
              sx={{
                borderRadius: 1,
                textTransform: 'none',
                fontWeight: 500,
                borderWidth: 1.5,
                minWidth: { sm: 120 },
                order: { xs: 2, sm: 1 },
                py: 1
              }}
            >
              Refresh
            </Button>
            <ToggleButtonGroup
              value={viewMode}
              exclusive
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  setViewMode(newValue);
                }
              }}
              size="small"
              aria-label="view mode"
              sx={{
                display: 'flex',
                order: { xs: 1, sm: 2 },
                '& .MuiToggleButtonGroup-grouped': {
                  flex: { xs: 1, sm: 'none' },
                  px: { xs: 3, sm: 2 },
                  py: 1,
                  borderRadius: '4px !important',
                  borderColor: 'divider',
                  '&.Mui-selected': {
                    bgcolor: 'primary.main',
                    color: 'white',
                    '&:hover': {
                      bgcolor: 'primary.dark',
                    },
                  },
                },
              }}
            >
              <ToggleButton value="grid" aria-label="grid view">
                <ViewModuleIcon sx={{ mr: 1 }} />
                Grid
              </ToggleButton>
              <ToggleButton value="list" aria-label="list view">
                <ViewListIcon sx={{ mr: 1 }} />
                List
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Box>

        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}>
          <Box sx={{ 
            borderRadius: 1,
            bgcolor: '#f5f6f8',
            border: '1px solid',
            borderColor: 'divider',
            overflow: 'hidden'
          }}>
            <Tabs
              value={currentTab}
              onChange={(e, newValue) => setCurrentTab(newValue)}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              sx={{
                minHeight: 44,
                px: { xs: 1, sm: 2 },
                '& .MuiTab-root': {
                  textTransform: 'none',
                  fontWeight: 500,
                  fontSize: '0.875rem',
                  minHeight: 44,
                  minWidth: { xs: 'auto', sm: 100 },
                  px: { xs: 2, sm: 3 }
                }
              }}
            >
              <Tab label="All Requests" value="all" />
              <Tab 
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    Pending
                    <Chip 
                      label={requests.filter(r => r.status.toLowerCase() === 'pending').length} 
                      size="small"
                      color="warning"
                      sx={{ 
                        height: 20,
                        '& .MuiChip-label': { px: 1, fontSize: '0.75rem' }
                      }}
                    />
                  </Box>
                } 
                value="pending" 
              />
              <Tab 
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    In Progress
                    <Chip 
                      label={requests.filter(r => r.status.toLowerCase() === 'in progress').length}
                      size="small"
                      color="info"
                      sx={{ 
                        height: 20,
                        '& .MuiChip-label': { px: 1, fontSize: '0.75rem' }
                      }}
                    />
                  </Box>
                } 
                value="in progress" 
              />
              <Tab 
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    Completed
                    <Chip 
                      label={requests.filter(r => r.status.toLowerCase() === 'completed').length}
                      size="small"
                      color="success"
                      sx={{ 
                        height: 20,
                        '& .MuiChip-label': { px: 1, fontSize: '0.75rem' }
                      }}
                    />
                  </Box>
                } 
                value="completed" 
              />
            </Tabs>
          </Box>

          <Stack 
            direction={{ xs: 'column', sm: 'row' }} 
            spacing={2}
            sx={{
              width: '100%',
              flexWrap: 'wrap'
            }}
          >
            <FormControl 
              size="small" 
              sx={{ 
                minWidth: { xs: '100%', sm: 200 },
                flex: { xs: '1 1 100%', sm: '0 1 auto' }
              }}
            >
              <InputLabel>Property Filter</InputLabel>
              <Select
                value={filterProperty}
                onChange={(e) => setFilterProperty(e.target.value)}
                label="Property Filter"
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'divider',
                  },
                }}
              >
                <MenuItem value="all">All Properties</MenuItem>
                {properties.map((property) => (
                  <MenuItem key={property._id} value={property._id}>
                    {property.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl 
              size="small" 
              sx={{ 
                minWidth: { xs: '100%', sm: 200 },
                flex: { xs: '1 1 100%', sm: '0 1 auto' }
              }}
            >
              <InputLabel>Priority Filter</InputLabel>
              <Select
                value={filterPriority}
                onChange={(e) => setFilterPriority(e.target.value)}
                label="Priority Filter"
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'divider',
                  },
                }}
              >
                <MenuItem value="all">All Priorities</MenuItem>
                <MenuItem value="Low">Low</MenuItem>
                <MenuItem value="Medium">Medium</MenuItem>
                <MenuItem value="High">High</MenuItem>
                <MenuItem value="Emergency">Emergency</MenuItem>
              </Select>
            </FormControl>

            {(filterProperty !== 'all' || filterPriority !== 'all') && (
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  setFilterProperty('all');
                  setFilterPriority('all');
                }}
                sx={{
                  borderRadius: 1,
                  textTransform: 'none',
                  fontWeight: 500,
                  borderWidth: 1.5,
                  minHeight: 40,
                  flex: { xs: '1 1 100%', sm: '0 0 auto' }
                }}
              >
                Clear Filters
              </Button>
            )}
          </Stack>
        </Box>
      </Paper>

      {loading ? (
        viewMode === 'grid' ? (
          <Grid container spacing={2}>
            {[...Array(6)].map((_, index) => (
              <Grid item key={index} xs={12} sm={6} lg={4}>
                <CardSkeleton />
              </Grid>
            ))}
          </Grid>
        ) : (
          <TableSkeleton />
        )
      ) : filteredRequests.length === 0 ? (
        <Paper
          elevation={0}
          sx={{
            p: 4,
            textAlign: 'center',
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 1,
            bgcolor: '#ffffff'
          }}
        >
          <ErrorIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
          <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
            No Maintenance Requests Found
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            {currentTab === 'all' && filterProperty === 'all' && filterPriority === 'all'
              ? 'There are no maintenance requests in the system.'
              : 'No requests match your current filters.'}
          </Typography>
          {(currentTab !== 'all' || filterProperty !== 'all' || filterPriority !== 'all') && (
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                setCurrentTab('all');
                setFilterProperty('all');
                setFilterPriority('all');
              }}
              sx={{
                borderRadius: 1,
                textTransform: 'none',
                fontWeight: 500,
                borderWidth: 1.5
              }}
            >
              Clear All Filters
            </Button>
          )}
        </Paper>
      ) : (
        viewMode === 'grid' ? (
          <Grid container spacing={2}>
            {filteredRequests.map((request) => (
              <Grid item key={request._id} xs={12} sm={6} lg={4}>
                <MaintenanceCard request={request} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <MaintenanceTable requests={filteredRequests} />
        )
      )}

      <RequestDetailsDialog
        request={selectedRequest}
        open={Boolean(selectedRequest)}
        onClose={() => {
          setSelectedRequest(null);
          setError(null);
        }}
      />

      <ImageViewDialog />
    </Container>
  );
};

export default MaintenanceList; 