import React, { useState } from 'react';
import { 
  Typography, 
  Box,
  Grid,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import BlockIcon from '@mui/icons-material/Block';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: '#ffffff',
  border: '1px solid',
  borderColor: theme.palette.divider,
  borderRadius: `${theme.spacing(2)} !important`,
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
  transition: 'all 0.3s ease-in-out',
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    boxShadow: '0 12px 48px rgba(0, 0, 0, 0.12)',
    transform: 'scale(1.02)',
    borderColor: 'error.main'
  },
  '& .MuiAccordionSummary-root': {
    padding: theme.spacing(2),
    minHeight: 'auto',
    '&.Mui-expanded': {
      minHeight: 'auto',
    }
  },
  '& .MuiAccordionSummary-content': {
    margin: '0 !important'
  },
  '& .MuiAccordionDetails-root': {
    padding: theme.spacing(0, 2, 2, 2),
    borderTop: '1px solid',
    borderTopColor: theme.palette.divider
  }
}));

const StatBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1.5),
  backgroundColor: 'rgba(244, 67, 54, 0.08)',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: 'rgba(244, 67, 54, 0.12)',
    transform: 'translateY(-2px)'
  }
}));

const GrievancesCard = ({ grievances = [] }) => {
  const [expanded, setExpanded] = useState(false);

  // Calculate grievance statistics by status
  const totalGrievances = grievances.length;
  const resolvedGrievances = grievances.filter(g => g.status === 'Resolved').length;
  const pendingGrievances = grievances.filter(g => g.status === 'Pending').length;
  const inProgressGrievances = grievances.filter(g => g.status === 'In Progress').length;
  const rejectedGrievances = grievances.filter(g => g.status === 'Rejected').length;

  // Calculate active grievances (pending + in progress)
  const activeGrievances = pendingGrievances + inProgressGrievances;

  // Get most common violated right (excluding 'Other')
  const violatedRightsCounts = grievances
    .filter(g => g.violatedRight !== 'Other' && (g.status === 'Pending' || g.status === 'In Progress'))
    .reduce((acc, g) => {
      acc[g.violatedRight] = (acc[g.violatedRight] || 0) + 1;
      return acc;
    }, {});

  const mostCommonViolation = Object.entries(violatedRightsCounts)
    .sort(([,a], [,b]) => b - a)[0]?.[0];

  const resolutionRate = totalGrievances > 0 ? ((resolvedGrievances / totalGrievances) * 100).toFixed(0) : 0;

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  return (
    <StyledAccordion 
      expanded={expanded} 
      onChange={handleAccordionChange}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: 'error.main' }} />}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '100%' }}>
          <Box
            sx={{
              width: 48,
              height: 48,
              borderRadius: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'rgba(244, 67, 54, 0.08)'
            }}
          >
            <ReportProblemIcon sx={{ color: 'error.main', fontSize: 28 }} />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography variant="h5" sx={{ fontWeight: 700, color: 'text.primary', mb: 0.5 }}>
              {pendingGrievances}
            </Typography>
            <Typography variant="body1" sx={{ color: 'text.secondary', fontWeight: 500 }}>
              Pending Grievances
            </Typography>
          </Box>
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <StatBox>
              <CheckCircleIcon sx={{ color: 'success.main', fontSize: 24 }} />
              <Box>
                <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                  Resolved
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                  {resolvedGrievances}
                </Typography>
              </Box>
            </StatBox>
          </Grid>

          <Grid item xs={6}>
            <StatBox>
              <PendingIcon sx={{ color: 'warning.main', fontSize: 24 }} />
              <Box>
                <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                  Pending
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                  {pendingGrievances}
                </Typography>
              </Box>
            </StatBox>
          </Grid>

          <Grid item xs={6}>
            <StatBox>
              <EngineeringIcon sx={{ color: 'info.main', fontSize: 24 }} />
              <Box>
                <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                  In Progress
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                  {inProgressGrievances}
                </Typography>
              </Box>
            </StatBox>
          </Grid>

          <Grid item xs={6}>
            <StatBox>
              <BlockIcon sx={{ color: 'error.main', fontSize: 24 }} />
              <Box>
                <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                  Rejected
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                  {rejectedGrievances}
                </Typography>
              </Box>
            </StatBox>
          </Grid>
        </Grid>

        <Box sx={{ 
          mt: 3, 
          p: 2.5, 
          borderRadius: 2,
          bgcolor: 'rgba(244, 67, 54, 0.04)',
          border: '1px solid',
          borderColor: 'error.light',
        }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                Resolution Rate
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 700, color: 'error.main' }}>
                {resolutionRate}%
              </Typography>
            </Box>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                Active Cases
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 700 }}>
                {activeGrievances}
              </Typography>
            </Box>
          </Stack>
        </Box>
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default GrievancesCard; 