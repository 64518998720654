import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Chip,
  Button,
  IconButton,
  Tooltip,
  ButtonGroup,
  CircularProgress,
} from '@mui/material';
import {
  Add as AddIcon,
  Refresh,
  Event as EventIcon,
  ExitToApp as DepartIcon,
  HomeWork as ReturnIcon,
  CheckCircle as CheckCircleIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://192.168.0.135:5001';
axios.defaults.baseURL = API_URL;

const MyOvernightRequests = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [activeRequestId, setActiveRequestId] = useState(null);
  const navigate = useNavigate();

  const fetchRequests = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/overnight/my-requests', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setRequests(response.data);
    } catch (error) {
      console.error('Error fetching overnight requests:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRequests();
  }, []);

  const getStatusColor = (status) => {
    switch (status) {
      case 'Pending':
        return 'warning';
      case 'Approved':
        return 'success';
      case 'Rejected':
        return 'error';
      default:
        return 'default';
    }
  };

  const handleDepart = async (requestId) => {
    try {
      setActionLoading(true);
      setActiveRequestId(requestId);
      const token = localStorage.getItem('token');
      await axios.patch(`/api/overnight/${requestId}/depart`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      await fetchRequests();
    } catch (error) {
      console.error('Error recording departure:', error);
    } finally {
      setActionLoading(false);
      setActiveRequestId(null);
    }
  };

  const handleReturn = async (requestId) => {
    try {
      setActionLoading(true);
      setActiveRequestId(requestId);
      const token = localStorage.getItem('token');
      await axios.patch(`/api/overnight/${requestId}/return`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      await fetchRequests();
    } catch (error) {
      console.error('Error recording return:', error);
    } finally {
      setActionLoading(false);
      setActiveRequestId(null);
    }
  };

  const getRequestActions = (request) => {
    if (request.status !== 'Approved') {
      return null;
    }

    if (request.returnedAt?.at) {
      return (
        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
          <CheckCircleIcon color="success" />
          <Typography variant="body2" color="success.main">
            Returned at {new Date(request.returnedAt.at).toLocaleString()}
          </Typography>
        </Box>
      );
    }

    if (request.departedAt?.at) {
      return (
        <Button
          variant="contained"
          color="primary"
          startIcon={<ReturnIcon />}
          onClick={() => handleReturn(request._id)}
          disabled={actionLoading && activeRequestId === request._id}
          fullWidth
          sx={{ mt: 2 }}
        >
          {actionLoading && activeRequestId === request._id ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            'Record Return'
          )}
        </Button>
      );
    }

    return (
      <Button
        variant="contained"
        color="primary"
        startIcon={<DepartIcon />}
        onClick={() => handleDepart(request._id)}
        disabled={actionLoading && activeRequestId === request._id}
        fullWidth
        sx={{ mt: 2 }}
      >
        {actionLoading && activeRequestId === request._id ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          'Record Departure'
        )}
      </Button>
    );
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h5" component="h2">
          My Overnight Requests
        </Typography>
        <Box>
          <Button
            startIcon={<Refresh />}
            onClick={fetchRequests}
            variant="outlined"
            sx={{ mr: 2 }}
          >
            Refresh
          </Button>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            onClick={() => navigate('/overnight-requests/new')}
          >
            New Request
          </Button>
        </Box>
      </Box>

      <Grid container spacing={3}>
        {requests.map((request) => (
          <Grid item xs={12} md={6} lg={4} key={request._id}>
            <Card elevation={3}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                  <Typography variant="h6" component="h3">
                    Overnight Stay Request
                  </Typography>
                  <Chip
                    label={request.status}
                    color={getStatusColor(request.status)}
                    size="small"
                  />
                </Box>

                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Stay Duration:
                  </Typography>
                  <Typography variant="body2">
                    From: {new Date(request.startDate).toLocaleDateString()}
                  </Typography>
                  <Typography variant="body2">
                    To: {new Date(request.endDate).toLocaleDateString()}
                  </Typography>
                </Box>

                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Reason for Stay:
                  </Typography>
                  <Typography variant="body2">
                    {request.reason}
                  </Typography>
                </Box>

                {request.departedAt?.at && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="primary" gutterBottom>
                      Departed:
                    </Typography>
                    <Typography variant="body2">
                      {new Date(request.departedAt.at).toLocaleString()}
                    </Typography>
                  </Box>
                )}

                {request.returnedAt?.at && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="success.main" gutterBottom>
                      Returned:
                    </Typography>
                    <Typography variant="body2">
                      {new Date(request.returnedAt.at).toLocaleString()}
                    </Typography>
                  </Box>
                )}

                {request.notes && request.notes.length > 0 && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle2" gutterBottom>
                      Latest Update:
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {request.notes[request.notes.length - 1].content}
                    </Typography>
                  </Box>
                )}

                {request.status === 'Rejected' && request.rejectionReason && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle2" color="error" gutterBottom>
                      Rejection Reason:
                    </Typography>
                    <Typography variant="body2" color="error">
                      {request.rejectionReason}
                    </Typography>
                  </Box>
                )}

                {getRequestActions(request)}

                <Box sx={{ mt: 2 }}>
                  <Typography variant="caption" color="textSecondary">
                    Submitted: {new Date(request.createdAt).toLocaleDateString()}
                  </Typography>
                  {request.status === 'Approved' && request.approvedAt && (
                    <Typography variant="caption" color="textSecondary" sx={{ display: 'block' }}>
                      Approved: {new Date(request.approvedAt).toLocaleDateString()}
                    </Typography>
                  )}
                  {request.status === 'Rejected' && request.rejectedAt && (
                    <Typography variant="caption" color="error" sx={{ display: 'block' }}>
                      Rejected: {new Date(request.rejectedAt).toLocaleDateString()}
                    </Typography>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}

        {requests.length === 0 && !loading && (
          <Grid item xs={12}>
            <Box sx={{ textAlign: 'center', py: 4 }}>
              <Typography variant="body1" color="textSecondary">
                You haven't submitted any overnight requests yet.
              </Typography>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate('/overnight-requests/new')}
                sx={{ mt: 2 }}
              >
                Submit Your First Request
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default MyOvernightRequests; 