import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  List,
  ListItem,
  ListItemText,
  Divider,
  Chip,
  alpha,
  useTheme
} from '@mui/material';
import { Gavel } from '@mui/icons-material';
import axios from '../../../utils/axios';

const Grievances = ({ resident }) => {
  const [grievances, setGrievances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    const fetchGrievances = async () => {
      try {
        const response = await axios.get(`/api/grievances/all`);
        // Filter grievances for this resident
        const residentGrievances = response.data.filter(
          grievance => grievance.resident._id === resident._id
        );
        setGrievances(residentGrievances);
        setLoading(false);
      } catch (err) {
        setError(err.response?.data?.message || 'Failed to fetch grievances');
        setLoading(false);
      }
    };

    fetchGrievances();
  }, [resident._id]);

  const getStatusColor = (status) => {
    switch (status) {
      case 'Resolved':
        return 'success';
      case 'Rejected':
        return 'error';
      case 'In Progress':
        return 'warning';
      case 'Pending':
        return 'info';
      default:
        return 'default';
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <Card
      elevation={0}
      sx={{ 
        height: '100%',
        background: `linear-gradient(45deg, ${alpha(theme.palette.primary.main, 0.02)}, ${alpha(theme.palette.primary.light, 0.05)})`,
        borderRadius: 1,
        border: '1px solid',
        borderColor: 'divider'
      }}
    >
      <Box
        sx={{
          background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
          p: 2,
          borderRadius: '4px 4px 0 0',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Gavel sx={{ color: 'white' }} />
          <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 600 }}>
            Grievances
          </Typography>
        </Box>
      </Box>

      <Box sx={{ p: 2 }}>
        {loading ? (
          <Typography color="text.secondary">Loading...</Typography>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : grievances.length === 0 ? (
          <Typography color="text.secondary">No grievances found</Typography>
        ) : (
          <List sx={{ width: '100%', bgcolor: 'transparent' }}>
            {grievances.map((grievance, index) => (
              <React.Fragment key={grievance._id}>
                <ListItem
                  alignItems="flex-start"
                  sx={{ 
                    px: 0,
                    '&:hover': { 
                      bgcolor: alpha(theme.palette.info.main, 0.05),
                      borderRadius: 1
                    }
                  }}
                >
                  <ListItemText
                    primary={
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
                        <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                          {grievance.violatedRight}
                        </Typography>
                        <Chip 
                          label={grievance.status} 
                          size="small" 
                          color={getStatusColor(grievance.status)}
                          sx={{ ml: 'auto' }}
                        />
                      </Box>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          color="text.primary"
                          sx={{ display: 'block', mt: 0.5 }}
                        >
                          {grievance.statement}
                        </Typography>
                        {grievance.rejectionReason && (
                          <Typography
                            component="span"
                            variant="body2"
                            color="error"
                            sx={{ display: 'block', mt: 0.5 }}
                          >
                            Rejection Reason: {grievance.rejectionReason}
                          </Typography>
                        )}
                        <Typography
                          component="span"
                          variant="caption"
                          color="text.secondary"
                          sx={{ display: 'block', mt: 0.5 }}
                        >
                          Submitted on {formatDate(grievance.createdAt)}
                          {grievance.resolvedAt && ` • Resolved on ${formatDate(grievance.resolvedAt)}`}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                {index < grievances.length - 1 && <Divider component="li" />}
              </React.Fragment>
            ))}
          </List>
        )}
      </Box>
    </Card>
  );
};

export default Grievances; 