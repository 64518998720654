import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  Alert,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL || 'http://192.168.0.135:5001';
axios.defaults.baseURL = API_URL;

const OvernightRequestForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    startDate: null,
    endDate: null,
    reason: ''
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [userProperty, setUserProperty] = useState(null);

  useEffect(() => {
    const fetchUserProperty = async () => {
      try {
        const token = localStorage.getItem('token');
        const userResponse = await axios.get('/api/auth/profile', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const userId = userResponse.data._id;
        const userEmail = userResponse.data.email;

        const propertyResponse = await axios.get('/api/properties', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        let foundProperty = null;
        let foundRoom = null;
        let foundBed = null;

        for (const property of propertyResponse.data) {
          for (const room of property.rooms) {
            const bed = room.beds.find(bed => 
              bed.resident && (bed.resident._id === userId || bed.resident.email === userEmail)
            );
            if (bed) {
              foundProperty = property;
              foundRoom = room;
              foundBed = bed;
              break;
            }
          }
          if (foundProperty) break;
        }

        if (!foundProperty || !foundRoom || !foundBed) {
          setError('No property assignment found. Please contact your administrator.');
          return;
        }

        setUserProperty({
          propertyId: foundProperty._id,
          propertyName: foundProperty.name,
          roomName: foundRoom.name,
          bedName: foundBed.name
        });
      } catch (error) {
        console.error('Error fetching user property:', error);
        setError('Failed to fetch property assignment');
      }
    };
    fetchUserProperty();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleDateChange = (field) => (date) => {
    setFormData({
      ...formData,
      [field]: date
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userProperty) {
      setError('No property assignment found');
      return;
    }

    if (!formData.startDate || !formData.endDate) {
      setError('Please select both start and end dates');
      return;
    }

    if (formData.endDate < formData.startDate) {
      setError('End date must be after start date');
      return;
    }

    setLoading(true);
    setError('');
    setSuccess(false);

    try {
      const token = localStorage.getItem('token');
      await axios.post('/api/overnight', {
        ...formData,
        property: userProperty.propertyId,
        unit: `${userProperty.roomName} - ${userProperty.bedName}`,
        startDate: formData.startDate.toISOString(),
        endDate: formData.endDate.toISOString()
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      setSuccess(true);
      setFormData({
        startDate: null,
        endDate: null,
        reason: ''
      });

      setTimeout(() => {
        navigate('/overnight-requests');
      }, 2000);
    } catch (err) {
      console.error('Error submitting request:', err);
      setError(err.response?.data?.message || 'Failed to submit overnight request');
    } finally {
      setLoading(false);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ maxWidth: 800, mx: 'auto', p: 3 }}>
        <Card elevation={3}>
          <CardContent>
            <Typography variant="h5" component="h2" gutterBottom>
              Submit Overnight Request
            </Typography>

            {userProperty && (
              <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                Property: {userProperty.propertyName}
                <br />
                Room: {userProperty.roomName}
                <br />
                Bed: {userProperty.bedName}
              </Typography>
            )}

            {success && (
              <Alert severity="success" sx={{ mb: 2 }}>
                Overnight request submitted successfully! Redirecting...
              </Alert>
            )}

            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}

            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Start Date"
                    value={formData.startDate}
                    onChange={handleDateChange('startDate')}
                    renderInput={(params) => (
                      <TextField 
                        {...params} 
                        fullWidth 
                        required
                        error={Boolean(params.error)}
                      />
                    )}
                    minDate={new Date()}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="End Date"
                    value={formData.endDate}
                    onChange={handleDateChange('endDate')}
                    renderInput={(params) => (
                      <TextField 
                        {...params} 
                        fullWidth 
                        required
                        error={Boolean(params.error)}
                      />
                    )}
                    minDate={formData.startDate || new Date()}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Reason for Stay"
                    name="reason"
                    value={formData.reason}
                    onChange={handleChange}
                    required
                    multiline
                    rows={4}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading || !userProperty}
                    fullWidth
                  >
                    {loading ? 'Submitting...' : 'Submit Request'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Box>
    </LocalizationProvider>
  );
};

export default OvernightRequestForm; 