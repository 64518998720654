import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  List,
  ListItem,
  ListItemText,
  Divider,
  Chip,
  alpha,
  useTheme
} from '@mui/material';
import { Description } from '@mui/icons-material';
import axios from '../../../utils/axios';

const FormSubmissions = ({ resident }) => {
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        // Get all forms first
        const formsResponse = await axios.get('/api/forms');
        const forms = formsResponse.data;

        // Get submissions for each form
        const allSubmissions = [];
        for (const form of forms) {
          const submissionsResponse = await axios.get(`/api/forms/${form._id}/submissions`);
          const formSubmissions = submissionsResponse.data.filter(
            submission => submission.resident._id === resident._id
          );
          allSubmissions.push(...formSubmissions.map(submission => ({
            ...submission,
            formTitle: form.title
          })));
        }

        setSubmissions(allSubmissions);
        setLoading(false);
      } catch (err) {
        setError(err.response?.data?.message || 'Failed to fetch form submissions');
        setLoading(false);
      }
    };

    fetchSubmissions();
  }, [resident._id]);

  const getStatusColor = (status) => {
    switch (status) {
      case 'approved':
        return 'success';
      case 'rejected':
        return 'error';
      case 'reviewed':
        return 'warning';
      case 'pending':
        return 'info';
      default:
        return 'default';
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <Card
      elevation={0}
      sx={{ 
        height: '100%',
        background: `linear-gradient(45deg, ${alpha(theme.palette.primary.main, 0.02)}, ${alpha(theme.palette.primary.light, 0.05)})`,
        borderRadius: 1,
        border: '1px solid',
        borderColor: 'divider'
      }}
    >
      <Box
        sx={{
          background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
          p: 2,
          borderRadius: '4px 4px 0 0',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Description sx={{ color: 'white' }} />
          <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 600 }}>
            Form Submissions
          </Typography>
        </Box>
      </Box>

      <Box sx={{ p: 2 }}>
        {loading ? (
          <Typography color="text.secondary">Loading...</Typography>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : submissions.length === 0 ? (
          <Typography color="text.secondary">No form submissions found</Typography>
        ) : (
          <List sx={{ width: '100%', bgcolor: 'transparent' }}>
            {submissions.map((submission, index) => (
              <React.Fragment key={submission._id}>
                <ListItem
                  alignItems="flex-start"
                  sx={{ 
                    px: 0,
                    '&:hover': { 
                      bgcolor: alpha(theme.palette.info.main, 0.05),
                      borderRadius: 1
                    }
                  }}
                >
                  <ListItemText
                    primary={
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
                        <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                          {submission.formTitle}
                        </Typography>
                        <Chip 
                          label={submission.status} 
                          size="small" 
                          color={getStatusColor(submission.status)}
                          sx={{ ml: 'auto' }}
                        />
                      </Box>
                    }
                    secondary={
                      <React.Fragment>
                        {submission.notes?.length > 0 && (
                          <Typography
                            component="span"
                            variant="body2"
                            color="text.primary"
                            sx={{ display: 'block', mt: 0.5 }}
                          >
                            Latest Note: {submission.notes[submission.notes.length - 1].content}
                          </Typography>
                        )}
                        <Typography
                          component="span"
                          variant="caption"
                          color="text.secondary"
                          sx={{ display: 'block', mt: 0.5 }}
                        >
                          Submitted on {formatDate(submission.createdAt)}
                          {submission.reviewedAt && ` • Reviewed on ${formatDate(submission.reviewedAt)}`}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                {index < submissions.length - 1 && <Divider component="li" />}
              </React.Fragment>
            ))}
          </List>
        )}
      </Box>
    </Card>
  );
};

export default FormSubmissions; 