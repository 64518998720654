import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Box,
  Typography,
  Avatar,
} from '@mui/material';
import {
  Dashboard,
  People,
  Apartment,
  Assignment,
  Settings,
  Payment,
  Build,
  Message,
  SupervisorAccount,
  Home,
  Receipt,
  EventNote,
  Gavel,
  Description,
  Business,
  NightsStay,
  Report,
  AccountCircle,
  SmartToy,
  AttachMoney as MoneyIcon,
} from '@mui/icons-material';

const drawerWidth = 280;

const Sidebar = ({ open, variant, onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      // If admin is deactivated, log them out
      if (parsedUser.role === 'admin' && !parsedUser.isActive) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/login');
        return;
      }
      setUser(parsedUser);
    }
  }, [navigate]);

  const getMenuItems = () => {
    if (!user) return [];
    
    const menuItems = [];

    // Dashboard
    menuItems.push({
      text: 'Dashboard',
      icon: <Dashboard />,
      path: `/dashboard/${user.role}`,
    });

    // Super Admin specific menu items
    if (user.role === 'superadmin') {
      menuItems.push({
        text: 'Manage Admins',
        icon: <SupervisorAccount />,
        path: '/admins',
      });
    }

    // Admin and Super Admin shared menu items
    if (['admin', 'superadmin'].includes(user.role)) {
      menuItems.push(
        {
          text: 'LINXIQ',
          icon: <SmartToy />,
          path: '/linxiq',
        },
        {
          text: 'Residents',
          icon: <People />,
          path: '/residents',
        },
        {
          text: 'Properties',
          icon: <Business />,
          path: '/properties',
        },
        {
          text: 'Maintenance',
          icon: <Build />,
          path: '/maintenance',
        },
        {
          text: 'Overnight Requests',
          icon: <NightsStay />,
          path: '/overnight',
        },
        {
          text: 'Grievances',
          icon: <Report />,
          path: '/grievances',
        },
        {
          text: 'Payments',
          icon: <Payment />,
          path: '/payments',
        },
        {
          text: 'Forms',
          icon: <Description />,
          path: '/forms',
        },
        {
          text: 'Rent Tracker',
          icon: <MoneyIcon />,
          path: '/rent-tracker',
        },
      );
    }

    // Resident specific menu items
    if (user.role === 'resident') {
      menuItems.push(
        {
          text: 'Maintenance Requests',
          icon: <Build />,
          path: '/maintenance-requests',
        },
        {
          text: 'Overnight Requests',
          icon: <NightsStay />,
          path: '/overnight-requests',
        },
        {
          text: 'Grievances',
          icon: <Report />,
          path: '/my-grievances',
        },
        {
          text: 'Payments',
          icon: <Payment />,
          path: '/my-payments',
        },
        {
          text: 'Forms',
          icon: <Description />,
          path: '/resident/forms',
        }
      );
    }

    // Settings (Super Admin only)
    if (user.role === 'superadmin') {
      menuItems.push({
        text: 'Settings',
        icon: <Settings />,
        path: '/settings',
      });
    }

    return menuItems;
  };

  if (!user) return null;

  return (
    <Drawer
      variant={variant}
      anchor="left"
      open={open}
      onClose={onClose}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          borderRight: '1px solid',
          borderColor: 'divider',
          backgroundImage: 'linear-gradient(145deg, #ffffff 0%, #f8f9ff 100%)',
          boxShadow: '4px 0 20px -4px rgba(0, 0, 0, 0.1)'
        },
      }}
    >
      <Toolbar />
      {user && (
        <Box sx={{ 
          p: 3, 
          borderBottom: '1px solid',
          borderColor: 'divider',
          background: 'linear-gradient(145deg, #ffffff 0%, #f8f9ff 100%)',
        }}>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 2
          }}>
            <Avatar 
              sx={{ 
                width: 56,
                height: 56,
                bgcolor: 'primary.main',
                fontSize: '1.5rem',
                fontWeight: 600,
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
              }}
            >
              {user.firstName?.[0]}
              {user.lastName?.[0]}
            </Avatar>
            <Box>
              <Typography 
                variant="subtitle1"
                sx={{ 
                  fontWeight: 600,
                  letterSpacing: '-0.02em',
                  color: 'text.primary'
                }}
              >
                {user.firstName} {user.lastName}
              </Typography>
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ 
                  fontWeight: 500,
                  letterSpacing: '0.01em'
                }}
              >
                {user.role === 'admin' && user.jobTitle ? user.jobTitle : user.role.charAt(0).toUpperCase() + user.role.slice(1)}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      <List sx={{ p: 2 }}>
        {getMenuItems().map((item) => (
          <ListItem
            button
            key={item.text}
            onClick={() => navigate(item.path)}
            selected={location.pathname === item.path}
            sx={{
              borderRadius: 2,
              mb: 1,
              '&.Mui-selected': {
                bgcolor: 'primary.main',
                color: 'white',
                '& .MuiListItemIcon-root': {
                  color: 'white',
                },
                '&:hover': {
                  bgcolor: 'primary.dark',
                },
              },
              '&:hover': {
                bgcolor: location.pathname === item.path ? 'primary.dark' : 'action.hover',
              },
            }}
          >
            <ListItemIcon 
              sx={{ 
                minWidth: 40,
                color: location.pathname === item.path ? 'inherit' : 'text.primary',
              }}
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText 
              primary={item.text}
              primaryTypographyProps={{
                sx: { 
                  fontWeight: 500,
                  fontSize: '0.95rem'
                }
              }}
            />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar; 