import React, { useState } from 'react';
import { 
  Typography, 
  Box,
  Grid,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import BlockIcon from '@mui/icons-material/Block';
import RateReviewIcon from '@mui/icons-material/RateReview';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: '#ffffff',
  border: '1px solid',
  borderColor: theme.palette.divider,
  borderRadius: `${theme.spacing(2)} !important`,
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
  transition: 'all 0.3s ease-in-out',
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    boxShadow: '0 12px 48px rgba(0, 0, 0, 0.12)',
    transform: 'scale(1.02)',
    borderColor: 'info.main'
  },
  '& .MuiAccordionSummary-root': {
    padding: theme.spacing(2),
    minHeight: 'auto',
    '&.Mui-expanded': {
      minHeight: 'auto',
    }
  },
  '& .MuiAccordionSummary-content': {
    margin: '0 !important'
  },
  '& .MuiAccordionDetails-root': {
    padding: theme.spacing(0, 2, 2, 2),
    borderTop: '1px solid',
    borderTopColor: theme.palette.divider
  }
}));

const StatBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1.5),
  backgroundColor: 'rgba(33, 150, 243, 0.08)',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: 'rgba(33, 150, 243, 0.12)',
    transform: 'translateY(-2px)'
  }
}));

const FormsCard = ({ submissions = [] }) => {
  const [expanded, setExpanded] = useState(false);

  console.log('Form Submissions:', submissions); // Debug log

  // Calculate submission statistics by status
  const totalSubmissions = submissions.length;
  const pendingSubmissions = submissions.filter(s => s.status === 'pending').length;
  const reviewedSubmissions = submissions.filter(s => s.status === 'reviewed').length;
  const approvedSubmissions = submissions.filter(s => s.status === 'approved').length;
  const rejectedSubmissions = submissions.filter(s => s.status === 'rejected').length;

  console.log('Submission counts:', { // Debug log
    total: totalSubmissions,
    pending: pendingSubmissions,
    reviewed: reviewedSubmissions,
    approved: approvedSubmissions,
    rejected: rejectedSubmissions
  });

  // Calculate active submissions (pending + reviewed)
  const activeSubmissions = pendingSubmissions + reviewedSubmissions;

  // Calculate approval rate (approved out of total decided)
  const decidedSubmissions = approvedSubmissions + rejectedSubmissions;
  const approvalRate = decidedSubmissions > 0 
    ? ((approvedSubmissions / decidedSubmissions) * 100).toFixed(0) 
    : 0;

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  return (
    <StyledAccordion 
      expanded={expanded} 
      onChange={handleAccordionChange}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: 'info.main' }} />}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '100%' }}>
          <Box
            sx={{
              width: 48,
              height: 48,
              borderRadius: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'rgba(33, 150, 243, 0.08)'
            }}
          >
            <DescriptionIcon sx={{ color: 'info.main', fontSize: 28 }} />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography variant="h5" sx={{ fontWeight: 700, color: 'text.primary', mb: 0.5 }}>
              {totalSubmissions}
            </Typography>
            <Typography variant="body1" sx={{ color: 'text.secondary', fontWeight: 500 }}>
              Form Submissions
            </Typography>
            {activeSubmissions > 0 && (
              <Typography variant="caption" sx={{ color: 'info.main', fontWeight: 600 }}>
                {activeSubmissions} submissions need attention
              </Typography>
            )}
          </Box>
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <StatBox>
              <PendingIcon sx={{ color: 'warning.main', fontSize: 24 }} />
              <Box>
                <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                  Pending
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                  {pendingSubmissions}
                </Typography>
              </Box>
            </StatBox>
          </Grid>

          <Grid item xs={6}>
            <StatBox>
              <RateReviewIcon sx={{ color: 'info.main', fontSize: 24 }} />
              <Box>
                <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                  Under Review
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                  {reviewedSubmissions}
                </Typography>
              </Box>
            </StatBox>
          </Grid>

          <Grid item xs={6}>
            <StatBox>
              <CheckCircleIcon sx={{ color: 'success.main', fontSize: 24 }} />
              <Box>
                <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                  Approved
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                  {approvedSubmissions}
                </Typography>
              </Box>
            </StatBox>
          </Grid>

          <Grid item xs={6}>
            <StatBox>
              <BlockIcon sx={{ color: 'error.main', fontSize: 24 }} />
              <Box>
                <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                  Rejected
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                  {rejectedSubmissions}
                </Typography>
              </Box>
            </StatBox>
          </Grid>
        </Grid>

        <Box sx={{ 
          mt: 3, 
          p: 2.5, 
          borderRadius: 2,
          bgcolor: 'rgba(33, 150, 243, 0.04)',
          border: '1px solid',
          borderColor: 'info.light',
        }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                Approval Rate
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 700, color: 'info.main' }}>
                {approvalRate}%
              </Typography>
            </Box>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                Need Attention
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 700 }}>
                {activeSubmissions}
              </Typography>
            </Box>
          </Stack>
        </Box>
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default FormsCard; 