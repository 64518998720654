import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stepper,
  Step,
  StepLabel,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Alert,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  Paper,
  IconButton,
  CardMedia
} from '@mui/material';
import { Info as InfoIcon, PhotoCamera, Delete } from '@mui/icons-material';
import axios from '../../../../utils/axios';
import PasswordInput from '../../../common/PasswordInput';

const steps = ['1', '2', '3', '4', '5', '6', '7'];

const stepTitles = {
  0: 'Basic Information',
  1: 'Profile Image',
  2: 'Account Setup',
  3: 'Program Details',
  4: 'Move-in Date',
  5: 'Housing',
  6: 'Emergency Contact'
};

const stepDescriptions = {
  0: 'Enter the resident\'s basic personal information.',
  1: 'Upload a profile image for the resident.',
  2: 'Set up the resident\'s account credentials.',
  3: 'Enter program and treatment details.',
  4: 'Set the resident\'s move-in date. This can be a past or future date.',
  5: 'Assign the resident to an available bed in a property.',
  6: 'Add emergency contact information.'
};

const NewResidentDialog = ({ open, onClose, onSuccess }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [properties, setProperties] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const [formData, setFormData] = useState({
    // Basic Information
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    gender: '',
    dateOfBirth: '',
    
    // Account Setup
    password: '',
    confirmPassword: '',
    
    // Program Details
    levelOfCare: '',
    treatmentAgency: '',
    notes: '',
    
    // Move-in Date
    moveInDate: '',

    // Housing Assignment
    selectedProperty: '',
    selectedRoom: '',
    selectedBed: '',
    
    // Additional Information
    emergencyContact: {
      name: '',
      relationship: '',
      phone: ''
    }
  });

  useEffect(() => {
    if (open) {
      fetchProperties();
    }
  }, [open]);

  const fetchProperties = async () => {
    try {
      const response = await axios.get('/api/properties');
      setProperties(response.data);
    } catch (error) {
      console.error('Error fetching properties:', error);
      setError('Failed to load properties');
    }
  };

  const getAvailableRooms = () => {
    const property = properties.find(p => p._id === formData.selectedProperty);
    return property ? property.rooms : [];
  };

  const getAvailableBeds = () => {
    const room = getAvailableRooms().find(r => r._id === formData.selectedRoom);
    return room ? room.beds.filter(bed => !bed.resident) : [];
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('.')) {
      const [section, field] = name.split('.');
      setFormData(prev => ({
        ...prev,
        [section]: {
          ...prev[section],
          [field]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (password.length < minLength) {
      return 'Password must be at least 8 characters long';
    }
    if (!hasUpperCase) {
      return 'Password must contain at least one uppercase letter';
    }
    if (!hasLowerCase) {
      return 'Password must contain at least one lowercase letter';
    }
    if (!hasNumber) {
      return 'Password must contain at least one number';
    }
    if (!hasSpecialChar) {
      return 'Password must contain at least one special character';
    }
    return null;
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      handleSubmit();
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleClose = () => {
    setActiveStep(0);
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      gender: '',
      dateOfBirth: '',
      password: '',
      confirmPassword: '',
      levelOfCare: '',
      treatmentAgency: '',
      notes: '',
      moveInDate: '',
      selectedProperty: '',
      selectedRoom: '',
      selectedBed: '',
      emergencyContact: {
        name: '',
        relationship: '',
        phone: ''
      }
    });
    setError('');
    handleDeleteImage();
    onClose();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setImageFile(file);
    setImagePreview(URL.createObjectURL(file));
  };

  const handleDeleteImage = () => {
    setImageFile(null);
    setImagePreview(null);
    if (imagePreview) {
      URL.revokeObjectURL(imagePreview);
    }
  };

  const isStepValid = () => {
    switch (activeStep) {
      case 0:
        return formData.firstName && formData.lastName && formData.email && formData.phone && formData.gender && formData.dateOfBirth;
      case 1:
        return true; // Image is optional
      case 2:
        return formData.password && formData.confirmPassword;
      case 3:
        return formData.levelOfCare && formData.treatmentAgency;
      case 4:
        return formData.moveInDate;
      case 5:
        return formData.selectedProperty && formData.selectedRoom && formData.selectedBed;
      case 6:
        return formData.emergencyContact.name && formData.emergencyContact.relationship && formData.emergencyContact.phone;
      default:
        return false;
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError('');

      const passwordError = validatePassword(formData.password);
      if (passwordError) {
        setError(passwordError);
        return;
      }

      if (formData.password !== formData.confirmPassword) {
        setError('Passwords do not match');
        return;
      }

      // Remove confirmPassword and housing fields before sending to register API
      const { confirmPassword, selectedProperty, selectedRoom, selectedBed, ...submitData } = formData;

      // Create the resident with approved status
      const registerResponse = await axios.post('/api/auth/register-resident', {
        ...submitData,
        isApproved: true,
        status: 'active',
        moveInDate: new Date(formData.moveInDate).toISOString()
      });

      // Get the resident ID from the response
      const residentId = registerResponse.data.resident._id;
      if (!residentId) {
        throw new Error('No resident ID returned from registration');
      }

      // Upload image if one was selected
      if (imageFile) {
        const formData = new FormData();
        formData.append('image', imageFile);

        await axios.post(`/api/auth/residents/${residentId}/upload-image`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      }

      // Assign the bed to the new resident
      await axios.put(
        `/api/properties/${selectedProperty}/rooms/${selectedRoom}/beds/${selectedBed}/assign`,
        { residentId }
      );

      onSuccess(registerResponse.data);
      handleClose();
    } catch (error) {
      console.error('Error creating resident:', error);
      setError(error.response?.data?.message || error.message || 'Failed to create resident');
    } finally {
      setLoading(false);
    }
  };

  const renderStepContent = () => {
    const renderStepHelper = (description) => (
      <Paper
        variant="outlined"
        sx={{
          p: 2.5,
          mb: 3,
          bgcolor: 'primary.lighter',
          borderColor: 'primary.light',
          borderRadius: 2,
          display: 'flex',
          alignItems: 'flex-start',
          gap: 1.5
        }}
      >
        <InfoIcon sx={{ color: 'primary.main', mt: 0.25 }} />
        <Typography variant="body2" sx={{ color: 'primary.main', fontWeight: 600 }}>
          {description}
        </Typography>
      </Paper>
    );

    switch (activeStep) {
      case 0:
        return (
          <Stack spacing={3}>
            {renderStepHelper(stepDescriptions[0])}
            <Stack spacing={2}>
              <TextField
                fullWidth
                required
                label="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                    '& fieldset': {
                      borderWidth: 2,
                      borderColor: 'divider'
                    }
                  }
                }}
              />
              <TextField
                fullWidth
                required
                label="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                    '& fieldset': {
                      borderWidth: 2,
                      borderColor: 'divider'
                    }
                  }
                }}
              />
              <TextField
                fullWidth
                required
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                    '& fieldset': {
                      borderWidth: 2,
                      borderColor: 'divider'
                    }
                  }
                }}
              />
              <TextField
                fullWidth
                required
                label="Phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                    '& fieldset': {
                      borderWidth: 2,
                      borderColor: 'divider'
                    }
                  }
                }}
              />
              <TextField
                type="date"
                fullWidth
                required
                label="Date of Birth"
                name="dateOfBirth"
                value={formData.dateOfBirth}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                    '& fieldset': {
                      borderWidth: 2,
                      borderColor: 'divider'
                    }
                  }
                }}
              />
              <Box>
                <Typography variant="subtitle1" sx={{ mb: 1.5, fontWeight: 600 }}>
                  Gender
                </Typography>
                <RadioGroup
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: '0.875rem',
                      fontWeight: 600
                    }
                  }}
                >
                  <FormControlLabel value="Male" control={<Radio />} label="Male" />
                  <FormControlLabel value="Female" control={<Radio />} label="Female" />
                </RadioGroup>
              </Box>
            </Stack>
          </Stack>
        );
      case 1:
        return (
          <Stack spacing={3}>
            {renderStepHelper(stepDescriptions[1])}
            <Box sx={{ position: 'relative', width: '100%', height: 300, bgcolor: 'grey.100', borderRadius: 2 }}>
              {imagePreview ? (
                <CardMedia
                  component="img"
                  height="300"
                  image={imagePreview}
                  alt="Resident preview"
                  sx={{
                    objectFit: 'cover',
                    objectPosition: 'center',
                    borderRadius: 2
                  }}
                />
              ) : (
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '2px dashed',
                    borderColor: 'divider',
                    borderRadius: 2
                  }}
                >
                  <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 500 }}>
                    No image selected
                  </Typography>
                </Box>
              )}
              
              <Box sx={{ 
                position: 'absolute', 
                top: 8, 
                right: 8, 
                display: 'flex', 
                gap: 1 
              }}>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="resident-image-upload"
                  type="file"
                  onChange={handleImageChange}
                />
                <label htmlFor="resident-image-upload">
                  <IconButton
                    component="span"
                    sx={{
                      bgcolor: 'rgba(255, 255, 255, 0.9)',
                      '&:hover': {
                        bgcolor: 'rgba(255, 255, 255, 1)',
                      },
                      width: 40,
                      height: 40,
                      boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                    }}
                  >
                    <PhotoCamera sx={{ fontSize: 20 }} />
                  </IconButton>
                </label>

                {imagePreview && (
                  <IconButton
                    onClick={handleDeleteImage}
                    sx={{
                      bgcolor: 'rgba(255, 255, 255, 0.9)',
                      color: 'error.main',
                      '&:hover': {
                        bgcolor: 'rgba(255, 255, 255, 1)',
                        color: 'error.dark',
                      },
                      width: 40,
                      height: 40,
                      boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                    }}
                  >
                    <Delete sx={{ fontSize: 20 }} />
                  </IconButton>
                )}
              </Box>
            </Box>
            <Typography variant="caption" color="text.secondary" sx={{ mt: 1, fontWeight: 500 }}>
              Upload a clear photo of the resident. This is optional but recommended.
            </Typography>
          </Stack>
        );
      case 2:
        return (
          <Stack spacing={3}>
            {renderStepHelper(stepDescriptions[2])}
            <PasswordInput
              name="password"
              label="Password"
              value={formData.password}
              onChange={handleChange}
              error={!!error}
            />
            <PasswordInput
              name="confirmPassword"
              label="Confirm Password"
              value={formData.confirmPassword}
              onChange={handleChange}
              error={!!error}
              isConfirm={true}
              originalPassword={formData.password}
            />
          </Stack>
        );
      case 3:
        return (
          <Stack spacing={3}>
            {renderStepHelper(stepDescriptions[3])}
            <TextField
              fullWidth
              required
              label="Level of Care (LOC)"
              name="levelOfCare"
              value={formData.levelOfCare}
              onChange={handleChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                  '& fieldset': {
                    borderWidth: 2,
                    borderColor: 'divider'
                  }
                }
              }}
            />
            <TextField
              fullWidth
              required
              label="Treatment Agency"
              name="treatmentAgency"
              value={formData.treatmentAgency}
              onChange={handleChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                  '& fieldset': {
                    borderWidth: 2,
                    borderColor: 'divider'
                  }
                }
              }}
            />
            <TextField
              fullWidth
              label="Additional Notes"
              name="notes"
              value={formData.notes}
              onChange={handleChange}
              multiline
              rows={4}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                  '& fieldset': {
                    borderWidth: 2,
                    borderColor: 'divider'
                  }
                }
              }}
            />
          </Stack>
        );
      case 4:
        return (
          <Stack spacing={3}>
            {renderStepHelper(stepDescriptions[4])}
            <TextField
              type="date"
              fullWidth
              required
              label="Move-in Date"
              name="moveInDate"
              value={formData.moveInDate}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              helperText="Select the date when the resident moved in or will move in"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                  '& fieldset': {
                    borderWidth: 2,
                    borderColor: 'divider'
                  }
                }
              }}
            />
          </Stack>
        );
      case 5:
        return (
          <Stack spacing={3}>
            {renderStepHelper(stepDescriptions[5])}
            <FormControl fullWidth>
              <InputLabel>Select Property</InputLabel>
              <Select
                name="selectedProperty"
                value={formData.selectedProperty}
                onChange={(e) => {
                  handleChange(e);
                  setFormData(prev => ({
                    ...prev,
                    selectedRoom: '',
                    selectedBed: ''
                  }));
                }}
                label="Select Property"
                sx={{
                  borderRadius: 2,
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderWidth: 2,
                    borderColor: 'divider',
                  },
                }}
              >
                {properties.map((property) => (
                  <MenuItem key={property._id} value={property._id}>
                    {property.name}
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="caption" color="text.secondary" sx={{ mt: 1, fontWeight: 500 }}>
                Choose the property where the resident will be housed
              </Typography>
            </FormControl>

            {formData.selectedProperty && (
              <FormControl fullWidth>
                <InputLabel>Select Room</InputLabel>
                <Select
                  name="selectedRoom"
                  value={formData.selectedRoom}
                  onChange={(e) => {
                    handleChange(e);
                    setFormData(prev => ({
                      ...prev,
                      selectedBed: ''
                    }));
                  }}
                  label="Select Room"
                  sx={{
                    borderRadius: 2,
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderWidth: 2,
                      borderColor: 'divider',
                    },
                  }}
                >
                  {getAvailableRooms().map((room) => (
                    <MenuItem key={room._id} value={room._id}>
                      {room.name}
                    </MenuItem>
                  ))}
                </Select>
                <Typography variant="caption" color="text.secondary" sx={{ mt: 1, fontWeight: 500 }}>
                  Select an available room in the chosen property
                </Typography>
              </FormControl>
            )}

            {formData.selectedRoom && (
              <FormControl fullWidth>
                <InputLabel>Select Bed</InputLabel>
                <Select
                  name="selectedBed"
                  value={formData.selectedBed}
                  onChange={handleChange}
                  label="Select Bed"
                  sx={{
                    borderRadius: 2,
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderWidth: 2,
                      borderColor: 'divider',
                    },
                  }}
                >
                  {getAvailableBeds().map((bed) => (
                    <MenuItem key={bed._id} value={bed._id}>
                      {bed.name}
                    </MenuItem>
                  ))}
                </Select>
                <Typography variant="caption" color="text.secondary" sx={{ mt: 1, fontWeight: 500 }}>
                  Assign an available bed to the resident
                </Typography>
              </FormControl>
            )}
          </Stack>
        );
      case 6:
        return (
          <Stack spacing={3}>
            {renderStepHelper(stepDescriptions[6])}
            <TextField
              fullWidth
              required
              label="Emergency Contact Name"
              name="emergencyContact.name"
              value={formData.emergencyContact.name}
              onChange={handleChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                  '& fieldset': {
                    borderWidth: 2,
                    borderColor: 'divider'
                  }
                }
              }}
            />
            <TextField
              fullWidth
              required
              label="Emergency Contact Relationship"
              name="emergencyContact.relationship"
              value={formData.emergencyContact.relationship}
              onChange={handleChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                  '& fieldset': {
                    borderWidth: 2,
                    borderColor: 'divider'
                  }
                }
              }}
            />
            <TextField
              fullWidth
              required
              label="Emergency Contact Phone"
              name="emergencyContact.phone"
              value={formData.emergencyContact.phone}
              onChange={handleChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                  '& fieldset': {
                    borderWidth: 2,
                    borderColor: 'divider'
                  }
                }
              }}
            />
          </Stack>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen}
      PaperProps={{
        sx: {
          borderRadius: fullScreen ? 0 : 2,
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.12)'
        }
      }}
    >
      <DialogTitle sx={{ 
        borderBottom: '1px solid',
        borderColor: 'divider',
        bgcolor: '#f8f9fb',
        py: 2.5,
        px: 3
      }}>
        <Typography variant="h5" component="div" sx={{ fontWeight: 700, color: 'primary.main' }}>
          Add New Resident
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mt: 0.5, fontWeight: 500 }}>
          {stepTitles[activeStep]}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ p: fullScreen ? 2.5 : 3 }}>
        <Stack spacing={3} sx={{ mt: 2 }}>
          {error && (
            <Alert 
              severity="error"
              sx={{ 
                borderRadius: 2,
                '& .MuiAlert-icon': {
                  color: 'error.main'
                }
              }}
            >
              {error}
            </Alert>
          )}
          
          {/* Desktop Stepper */}
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Stepper 
              activeStep={activeStep} 
              sx={{ 
                '& .MuiStepLabel-label': {
                  fontSize: '0.875rem',
                  fontWeight: 600
                },
                '& .MuiStepIcon-root': {
                  width: 28,
                  height: 28,
                  '&.Mui-active': {
                    color: 'primary.main'
                  }
                }
              }}
              alternativeLabel
            >
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel>{stepTitles[index]}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          {/* Mobile Stepper */}
          <Box sx={{ 
            display: { xs: 'block', sm: 'none' },
            mb: 2
          }}>
            <Box sx={{ 
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 1,
              mb: 2
            }}>
              {steps.map((_, index) => (
                <Box
                  key={index}
                  sx={{
                    width: `${100 / steps.length}%`,
                    height: 4,
                    bgcolor: index <= activeStep ? 'primary.main' : 'divider',
                    borderRadius: 1,
                    transition: 'background-color 0.3s ease'
                  }}
                />
              ))}
            </Box>
            <Box sx={{ 
              display: 'flex',
              justifyContent: 'space-between',
              px: 1
            }}>
              {steps.map((label, index) => (
                <Typography
                  key={label}
                  variant="caption"
                  sx={{
                    width: 24,
                    height: 24,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                    bgcolor: index <= activeStep ? 'primary.main' : 'grey.100',
                    color: index <= activeStep ? 'white' : 'text.secondary',
                    fontWeight: 600,
                    fontSize: '0.75rem',
                    transition: 'all 0.3s ease'
                  }}
                >
                  {label}
                </Typography>
              ))}
            </Box>
            <Typography
              variant="subtitle2"
              sx={{
                textAlign: 'center',
                mt: 1,
                color: 'text.secondary',
                fontWeight: 600
              }}
            >
              {stepTitles[activeStep]}
            </Typography>
          </Box>

          {renderStepContent()}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ 
        px: fullScreen ? 2.5 : 3, 
        py: 2.5, 
        bgcolor: '#f8f9fb',
        borderTop: '1px solid',
        borderColor: 'divider'
      }}>
        <Button 
          onClick={handleClose}
          sx={{ 
            borderRadius: 2,
            textTransform: 'none',
            fontWeight: 600,
            px: 3,
            py: 1.25
          }}
        >
          Cancel
        </Button>
        {activeStep > 0 && (
          <Button 
            onClick={handleBack}
            sx={{ 
              borderRadius: 2,
              textTransform: 'none',
              fontWeight: 600,
              px: 3,
              py: 1.25,
              mr: 1
            }}
          >
            Back
          </Button>
        )}
        <Button 
          variant="contained"
          onClick={handleNext}
          disabled={!isStepValid() || loading}
          sx={{ 
            borderRadius: 2,
            textTransform: 'none',
            fontWeight: 600,
            px: 4,
            py: 1.25,
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            }
          }}
        >
          {activeStep === steps.length - 1 ? 'Create Resident' : 'Next'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewResidentDialog; 