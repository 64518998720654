import React, { useState, useEffect } from 'react';
import { Box, Typography, Skeleton } from '@mui/material';
import axios from '../../utils/axios';

const Logo = ({ variant = 'auth' }) => {
  const [logo, setLogo] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [logoSize, setLogoSize] = useState({ topbar: 40, auth: 80 });
  const [appName, setAppName] = useState('Property Management System');
  const [cachedSettings, setCachedSettings] = useState(null);

  useEffect(() => {
    // Try to get cached settings from localStorage
    const cached = localStorage.getItem('appSettings');
    if (cached) {
      const settings = JSON.parse(cached);
      if (settings.logoUrl) {
        const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001';
        setLogo(`${baseUrl}${settings.logoUrl}`);
      }
      if (settings.logoSize) setLogoSize(settings.logoSize);
      if (settings.appName) setAppName(settings.appName);
      setCachedSettings(settings);
      setLoading(false);
    }

    // Fetch fresh settings
    const fetchLogo = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = token ? { Authorization: `Bearer ${token}` } : {};
        
        const response = await axios.get('/api/settings', { headers });
        if (response.data) {
          // Update cache
          localStorage.setItem('appSettings', JSON.stringify(response.data));
          
          if (response.data.logoUrl) {
            const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001';
            setLogo(`${baseUrl}${response.data.logoUrl}`);
          }
          if (response.data.logoSize) setLogoSize(response.data.logoSize);
          if (response.data.appName) setAppName(response.data.appName);
          setCachedSettings(response.data);
        }
      } catch (error) {
        console.error('Error fetching logo:', error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchLogo();
  }, []);

  const getLogoHeight = () => {
    return variant === 'topbar' ? logoSize.topbar : logoSize.auth;
  };

  const getSkeletonDimensions = () => {
    const height = getLogoHeight();
    const width = variant === 'auth' ? 300 : 200;
    return { height, width };
  };

  if (loading && !cachedSettings) {
    const { height, width } = getSkeletonDimensions();
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: variant === 'auth' ? 4 : 0 }}>
        <Skeleton 
          variant="rectangular" 
          width={width} 
          height={height}
          sx={{ 
            borderRadius: 1,
            bgcolor: 'rgba(0, 0, 0, 0.04)'
          }}
        />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mb: variant === 'auth' ? 4 : 0 }}>
      {logo && !error ? (
        <img
          src={logo}
          alt={appName}
          style={{
            height: `${getLogoHeight()}px`,
            maxWidth: variant === 'auth' ? '300px' : '200px',
            objectFit: 'contain'
          }}
          onError={() => {
            setError(true);
            setLogo('');
          }}
        />
      ) : (
        <Typography 
          variant={variant === 'auth' ? 'h4' : 'h6'} 
          component="div" 
          align="center"
        >
          {appName}
        </Typography>
      )}
    </Box>
  );
};

export default Logo; 