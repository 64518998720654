import React from 'react';
import {
  Box,
  Typography,
  Card,
  Grid,
  Stack,
  alpha,
  useTheme
} from '@mui/material';
import { ExitToApp } from '@mui/icons-material';

const DischargeInformation = ({ 
  resident,
  calculateLengthOfStay,
  formatUserName
}) => {
  const theme = useTheme();

  if (resident.status !== 'discharged') return null;

  return (
    <Card
      elevation={0}
      sx={{ 
        height: '100%',
        background: `linear-gradient(45deg, ${alpha(theme.palette.primary.main, 0.02)}, ${alpha(theme.palette.primary.light, 0.05)})`,
        borderRadius: 1,
        border: '1px solid',
        borderColor: 'divider'
      }}
    >
      <Box
        sx={{
          background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
          p: 2,
          borderRadius: '4px 4px 0 0',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <ExitToApp sx={{ color: 'white' }} />
          <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 600 }}>
            Discharge Information
          </Typography>
        </Box>
      </Box>

      <Box sx={{ p: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Stack spacing={2}>
              <Card 
                elevation={0}
                sx={{ 
                  p: 2,
                  borderRadius: 1.5,
                  bgcolor: alpha(theme.palette.info.main, 0.05),
                  border: `1px solid ${alpha(theme.palette.info.main, 0.1)}`
                }}
              >
                <Typography 
                  variant="subtitle2" 
                  color="info.main"
                  sx={{ 
                    fontSize: '0.7rem',
                    fontWeight: 500,
                    mb: 0.5
                  }}
                >
                  Move-out Date
                </Typography>
                <Typography 
                  variant="body2"
                  sx={{ fontWeight: 500 }}
                >
                  {resident.moveOutDate ? new Date(resident.moveOutDate).toLocaleDateString() : 'Not set'}
                </Typography>
              </Card>

              <Card 
                elevation={0}
                sx={{ 
                  p: 2,
                  borderRadius: 1.5,
                  bgcolor: alpha(theme.palette.info.main, 0.05),
                  border: `1px solid ${alpha(theme.palette.info.main, 0.1)}`
                }}
              >
                <Typography 
                  variant="subtitle2" 
                  color="info.main"
                  sx={{ 
                    fontSize: '0.7rem',
                    fontWeight: 500,
                    mb: 0.5
                  }}
                >
                  Length of Stay
                </Typography>
                <Typography 
                  variant="body2"
                  sx={{ fontWeight: 500 }}
                >
                  {calculateLengthOfStay(resident.moveInDate, resident.moveOutDate) || 'Not available'}
                </Typography>
              </Card>

              <Card 
                elevation={0}
                sx={{ 
                  p: 2,
                  borderRadius: 1.5,
                  bgcolor: alpha(theme.palette.info.main, 0.05),
                  border: `1px solid ${alpha(theme.palette.info.main, 0.1)}`
                }}
              >
                <Typography 
                  variant="subtitle2" 
                  color="info.main"
                  sx={{ 
                    fontSize: '0.7rem',
                    fontWeight: 500,
                    mb: 0.5
                  }}
                >
                  Discharge Reason
                </Typography>
                <Typography 
                  variant="body2"
                  sx={{ fontWeight: 500 }}
                >
                  {resident.dischargeReason}
                </Typography>
              </Card>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={2}>
              <Card 
                elevation={0}
                sx={{ 
                  p: 2,
                  borderRadius: 1.5,
                  bgcolor: alpha(theme.palette.info.main, 0.05),
                  border: `1px solid ${alpha(theme.palette.info.main, 0.1)}`
                }}
              >
                <Typography 
                  variant="subtitle2" 
                  color="info.main"
                  sx={{ 
                    fontSize: '0.7rem',
                    fontWeight: 500,
                    mb: 0.5
                  }}
                >
                  Discharge Notes
                </Typography>
                <Typography 
                  variant="body2"
                  sx={{ fontWeight: 500 }}
                >
                  {resident.dischargeNotes || 'No notes provided'}
                </Typography>
              </Card>

              <Card 
                elevation={0}
                sx={{ 
                  p: 2,
                  borderRadius: 1.5,
                  bgcolor: alpha(theme.palette.info.main, 0.05),
                  border: `1px solid ${alpha(theme.palette.info.main, 0.1)}`
                }}
              >
                <Typography 
                  variant="subtitle2" 
                  color="info.main"
                  sx={{ 
                    fontSize: '0.7rem',
                    fontWeight: 500,
                    mb: 0.5
                  }}
                >
                  Resident Flagged
                </Typography>
                <Typography 
                  variant="body2"
                  sx={{ fontWeight: 500 }}
                >
                  {resident.dischargeFlagged ? 'Yes' : 'No'}
                </Typography>
              </Card>

              {resident.dischargeFlagged && resident.flagReason && (
                <Card 
                  elevation={0}
                  sx={{ 
                    p: 2,
                    borderRadius: 1.5,
                    bgcolor: alpha(theme.palette.info.main, 0.05),
                    border: `1px solid ${alpha(theme.palette.info.main, 0.1)}`
                  }}
                >
                  <Typography 
                    variant="subtitle2" 
                    color="info.main"
                    sx={{ 
                      fontSize: '0.7rem',
                      fontWeight: 500,
                      mb: 0.5
                    }}
                  >
                    Flag Reason
                  </Typography>
                  <Typography 
                    variant="body2" 
                    color="error"
                    sx={{ fontWeight: 500 }}
                  >
                    {resident.flagReason}
                  </Typography>
                </Card>
              )}

              <Card 
                elevation={0}
                sx={{ 
                  p: 2,
                  borderRadius: 1.5,
                  bgcolor: alpha(theme.palette.info.main, 0.05),
                  border: `1px solid ${alpha(theme.palette.info.main, 0.1)}`
                }}
              >
                <Typography 
                  variant="subtitle2" 
                  color="info.main"
                  sx={{ 
                    fontSize: '0.7rem',
                    fontWeight: 500,
                    mb: 0.5
                  }}
                >
                  Discharged By
                </Typography>
                <Typography 
                  variant="body2"
                  sx={{ fontWeight: 500 }}
                >
                  {formatUserName(resident.dischargedBy)}
                </Typography>
              </Card>

              <Card 
                elevation={0}
                sx={{ 
                  p: 2,
                  borderRadius: 1.5,
                  bgcolor: alpha(theme.palette.info.main, 0.05),
                  border: `1px solid ${alpha(theme.palette.info.main, 0.1)}`
                }}
              >
                <Typography 
                  variant="subtitle2" 
                  color="info.main"
                  sx={{ 
                    fontSize: '0.7rem',
                    fontWeight: 500,
                    mb: 0.5
                  }}
                >
                  Discharged On
                </Typography>
                <Typography 
                  variant="body2"
                  sx={{ fontWeight: 500 }}
                >
                  {resident.dischargedAt ? new Date(resident.dischargedAt).toLocaleString() : 'Unknown'}
                </Typography>
              </Card>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default DischargeInformation; 