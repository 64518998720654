import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  TextField,
  IconButton,
  Typography,
  CircularProgress,
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Fade,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import HistoryIcon from '@mui/icons-material/History';
import AddIcon from '@mui/icons-material/Add';
import axios from '../../utils/axios';
import { styled } from '@mui/material/styles';

const PageContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  minHeight: '100%',
  display: 'flex',
  backgroundColor: theme.palette.grey[100],
  overflow: 'hidden',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2),
  },
}));

const ChatContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  flex: 1,
  [theme.breakpoints.down('sm')]: {
    borderRadius: 0,
  },
}));

const Header = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1.5),
  },
}));

const MessagesContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  flex: 1,
  '&::-webkit-scrollbar': {
    width: 6,
    height: 6,
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.grey[300],
    borderRadius: theme.shape.borderRadius,
  },
}));

const MessageWrapper = styled(Box)(({ theme, isUser }) => ({
  display: 'flex',
  justifyContent: isUser ? 'flex-end' : 'flex-start',
  maxWidth: '100%',
}));

const Message = styled(Box)(({ theme, isUser }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: theme.spacing(1),
  maxWidth: '80%',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '90%',
  },
}));

const MessageBubble = styled(Box)(({ theme, isUser }) => ({
  padding: theme.spacing(1.5),
  borderRadius: 16,
  backgroundColor: isUser ? theme.palette.primary.main : theme.palette.grey[100],
  color: isUser ? theme.palette.primary.contrastText : theme.palette.text.primary,
  wordBreak: 'break-word',
}));

const InputWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1.5),
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '.MuiOutlinedInput-root': {
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.grey[50],
    },
  },
}));

const LinxiqPage = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [currentConversationId, setCurrentConversationId] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const messagesEndRef = useRef(null);
  const [historyAnchor, setHistoryAnchor] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    fetchConversations();
  }, []);

  const fetchConversations = async () => {
    try {
      const response = await axios.get('/api/ai/conversations');
      setConversations(response.data);
    } catch (error) {
      console.error('Error fetching conversations:', error);
    }
  };

  const loadConversation = async (conversationId) => {
    try {
      const response = await axios.get(`/api/ai/conversations/${conversationId}`);
      setMessages(response.data.messages);
      setCurrentConversationId(conversationId);
    } catch (error) {
      console.error('Error loading conversation:', error);
    }
  };

  const handleNewChat = () => {
    setMessages([]);
    setCurrentConversationId(null);
  };

  const handleDeleteConversation = async () => {
    try {
      await axios.delete(`/api/ai/conversations/${selectedConversation}`);
      setDeleteDialogOpen(false);
      setSelectedConversation(null);
      if (currentConversationId === selectedConversation) {
        setMessages([]);
        setCurrentConversationId(null);
      }
      fetchConversations();
    } catch (error) {
      console.error('Error deleting conversation:', error);
    }
  };

  const handleSend = async () => {
    if (!input.trim()) return;

    const userMessage = input.trim();
    setInput('');
    setMessages(prev => [...prev, { role: 'user', content: userMessage }]);
    setIsLoading(true);

    try {
      const response = await axios.post('/api/ai/chat', {
        message: userMessage,
        conversationId: currentConversationId
      });
      
      setMessages(prev => [...prev, { role: 'assistant', content: response.data.response }]);
      setCurrentConversationId(response.data.conversationId);
      await fetchConversations();
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages(prev => [
        ...prev,
        { role: 'assistant', content: 'Sorry, I encountered an error. Please try again.' },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <PageContainer>
      <ChatContainer>
        <Header>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, minWidth: 0 }}>
            <Typography variant="h6" color="primary" noWrap sx={{ fontWeight: 600 }}>
              LINXIQ
            </Typography>
            {currentConversationId && !isMobile && (
              <Typography variant="body2" color="text.secondary" noWrap sx={{ flex: 1 }}>
                {conversations.find(c => c._id === currentConversationId)?.title}
              </Typography>
            )}
          </Box>
          <Box sx={{ display: 'flex', gap: 0.5 }}>
            <Tooltip title="New Chat">
              <IconButton size="small" onClick={handleNewChat}>
                <AddIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="History">
              <IconButton size="small" onClick={(e) => setHistoryAnchor(e.currentTarget)}>
                <HistoryIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            {currentConversationId && (
              <Tooltip title="Delete Chat">
                <IconButton 
                  size="small" 
                  onClick={() => {
                    setSelectedConversation(currentConversationId);
                    setDeleteDialogOpen(true);
                  }}
                >
                  <DeleteOutlineIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Header>

        <MessagesContainer>
          {messages.map((message, index) => (
            <Fade in key={index}>
              <MessageWrapper isUser={message.role === 'user'}>
                <Message isUser={message.role === 'user'}>
                  {message.role === 'assistant' && (
                    <Avatar sx={{ width: 28, height: 28, bgcolor: 'secondary.main' }}>AI</Avatar>
                  )}
                  <MessageBubble isUser={message.role === 'user'}>
                    <Typography variant="body2">{message.content}</Typography>
                  </MessageBubble>
                  {message.role === 'user' && (
                    <Avatar sx={{ width: 28, height: 28, bgcolor: 'primary.main' }}>U</Avatar>
                  )}
                </Message>
              </MessageWrapper>
            </Fade>
          ))}
          {isLoading && (
            <MessageWrapper>
              <Message>
                <Avatar sx={{ width: 28, height: 28, bgcolor: 'secondary.main' }}>AI</Avatar>
                <Box sx={{ p: 1 }}>
                  <CircularProgress size={16} />
                </Box>
              </Message>
            </MessageWrapper>
          )}
          <div ref={messagesEndRef} />
        </MessagesContainer>

        <InputWrapper>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <StyledTextField
              fullWidth
              multiline
              maxRows={4}
              size="small"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Type a message..."
              variant="outlined"
            />
            <IconButton
              color="primary"
              onClick={handleSend}
              disabled={!input.trim() || isLoading}
              sx={{
                alignSelf: 'flex-end',
                p: 1,
                bgcolor: 'primary.main',
                color: 'white',
                '&:hover': {
                  bgcolor: 'primary.dark',
                },
                '&.Mui-disabled': {
                  bgcolor: 'grey.300',
                },
              }}
            >
              <SendIcon fontSize="small" />
            </IconButton>
          </Box>
        </InputWrapper>
      </ChatContainer>

      <Menu
        anchorEl={historyAnchor}
        open={Boolean(historyAnchor)}
        onClose={() => setHistoryAnchor(null)}
        PaperProps={{
          sx: {
            maxHeight: '60vh',
            width: 280,
            maxWidth: '90vw',
          },
        }}
      >
        {conversations.map((conversation) => (
          <MenuItem
            key={conversation._id}
            onClick={() => {
              loadConversation(conversation._id);
              setHistoryAnchor(null);
            }}
            sx={{ py: 1 }}
          >
            <Typography variant="body2" noWrap>
              {conversation.title}
            </Typography>
          </MenuItem>
        ))}
      </Menu>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Delete Chat</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            Are you sure you want to delete this chat? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button 
            variant="outlined" 
            onClick={() => setDeleteDialogOpen(false)}
          >
            Cancel
          </Button>
          <Button 
            variant="contained" 
            color="error" 
            onClick={handleDeleteConversation}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </PageContainer>
  );
};

export default LinxiqPage; 