import React from 'react';
import { IconButton, Box } from '@mui/material';
import { PhotoCamera, Delete } from '@mui/icons-material';
import axios from '../../../utils/axios';

const ImageUpload = ({ resident, onImageUpdate }) => {
  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await axios.post(`/api/auth/residents/${resident._id}/upload-image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      onImageUpdate(response.data.imageUrl);
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const handleDeleteImage = async () => {
    if (!resident.imageUrl) return;

    try {
      await axios.delete(`/api/auth/residents/${resident._id}/image`);
      onImageUpdate(null);
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  const buttonStyle = {
    bgcolor: 'rgba(255, 255, 255, 0.9)',
    '&:hover': {
      bgcolor: 'rgba(255, 255, 255, 1)',
    },
    width: 40,
    height: 40,
    boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
  };

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id={`resident-image-upload-${resident._id}`}
        type="file"
        onChange={handleImageChange}
      />
      <label htmlFor={`resident-image-upload-${resident._id}`}>
        <IconButton
          component="span"
          sx={buttonStyle}
        >
          <PhotoCamera sx={{ fontSize: 20 }} />
        </IconButton>
      </label>

      {resident.imageUrl && (
        <IconButton
          onClick={handleDeleteImage}
          sx={{
            ...buttonStyle,
            color: 'error.main',
            '&:hover': {
              bgcolor: 'rgba(255, 255, 255, 1)',
              color: 'error.dark',
            }
          }}
        >
          <Delete sx={{ fontSize: 20 }} />
        </IconButton>
      )}
    </Box>
  );
};

export default ImageUpload; 