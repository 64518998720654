import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  Alert,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Box,
  Paper
} from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';

const UnrejectDialog = ({
  open,
  onClose,
  onSubmit,
  data,
  onChange,
  error
}) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.12)'
        }
      }}
    >
      <DialogTitle sx={{ 
        borderBottom: '1px solid',
        borderColor: 'divider',
        bgcolor: '#f8f9fb',
        py: 2.5,
        px: 3
      }}>
        <Typography variant="h5" component="div" sx={{ fontWeight: 700, color: 'primary.main' }}>
          Unreject Resident
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mt: 0.5, fontWeight: 500 }}>
          Choose how to unreject this resident
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ p: 3 }}>
        <Stack spacing={3}>
          {error && (
            <Alert 
              severity="error"
              sx={{ 
                borderRadius: 2,
                '& .MuiAlert-icon': {
                  color: 'error.main'
                }
              }}
            >
              {error}
            </Alert>
          )}

          <Paper
            variant="outlined"
            sx={{
              p: 2.5,
              bgcolor: 'primary.lighter',
              borderColor: 'primary.light',
              borderRadius: 2,
              display: 'flex',
              alignItems: 'flex-start',
              gap: 1.5
            }}
          >
            <InfoIcon sx={{ color: 'primary.main', mt: 0.25 }} />
            <Typography variant="body2" sx={{ color: 'primary.main', fontWeight: 600 }}>
              You can either set the resident's status to pending (requiring approval) or directly activate them.
            </Typography>
          </Paper>

          <Box>
            <Typography variant="subtitle1" sx={{ mb: 1.5, fontWeight: 600 }}>
              New Status
            </Typography>
            <RadioGroup
              value={data.status || 'pending'}
              onChange={(e) => onChange({ ...data, status: e.target.value })}
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: '0.875rem',
                  fontWeight: 600
                }
              }}
            >
              <FormControlLabel 
                value="pending" 
                control={<Radio />} 
                label={
                  <Box>
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      Pending
                    </Typography>
                    <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
                      Resident will need to go through the approval process
                    </Typography>
                  </Box>
                }
              />
              <FormControlLabel 
                value="active" 
                control={<Radio />} 
                label={
                  <Box>
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      Active
                    </Typography>
                    <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
                      Resident will be immediately activated
                    </Typography>
                  </Box>
                }
              />
            </RadioGroup>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ 
        px: 3, 
        py: 2.5, 
        bgcolor: '#f8f9fb',
        borderTop: '1px solid',
        borderColor: 'divider'
      }}>
        <Button 
          onClick={onClose}
          sx={{ 
            borderRadius: 2,
            textTransform: 'none',
            fontWeight: 600,
            px: 3,
            py: 1
          }}
        >
          Cancel
        </Button>
        <Button 
          onClick={onSubmit}
          variant="contained"
          sx={{ 
            borderRadius: 2,
            textTransform: 'none',
            fontWeight: 600,
            px: 3,
            py: 1,
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            }
          }}
        >
          Unreject
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnrejectDialog; 