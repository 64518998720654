import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Chip,
  Stack,
  Button,
  alpha,
  useTheme,
  Grid,
  Checkbox
} from '@mui/material';
import { 
  Person as PersonIcon,
  LocationOn,
  AccessTime,
  Edit as EditIcon,
  Male as MaleIcon,
  Female as FemaleIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  Refresh as RefreshIcon,
  Delete as DeleteIcon
} from '@mui/icons-material';
import { getInitials } from '../details/utils';
import ApprovalDialog from './dialogs/ApprovalDialog';
import UnrejectDialog from './dialogs/UnrejectDialog';
import DeleteDialog from './dialogs/DeleteDialog';
import axios from '../../../utils/axios';

const ResidentCard = ({ resident, onClick, onStatusChange, selected, onSelect }) => {
  const [approvalDialogOpen, setApprovalDialogOpen] = useState(false);
  const [unrejectDialogOpen, setUnrejectDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [unrejectData, setUnrejectData] = useState({ status: 'pending' });
  const [error, setError] = useState('');
  const theme = useTheme();

  const getStatusColor = (status) => {
    switch (status) {
      case 'active':
        return {
          color: 'success',
          bgColor: '#E8F5E9',
          borderColor: '#66BB6A'
        };
      case 'discharged':
      case 'rejected':
        return {
          color: 'error',
          bgColor: '#FEEBE9',
          borderColor: '#EF5350'
        };
      default:
        return {
          color: 'warning',
          bgColor: '#FFF3E0',
          borderColor: '#FFA726'
        };
    }
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const handleReject = async () => {
    try {
      await axios.put(`/api/auth/residents/${resident._id}/approve`, {
        approved: false
      });
      onStatusChange();
    } catch (error) {
      console.error('Error rejecting resident:', error);
    }
  };

  const handleUnreject = async () => {
    try {
      await axios.put(`/api/auth/residents/${resident._id}/unreject`, {
        status: unrejectData.status
      });
      setUnrejectDialogOpen(false);
      onStatusChange();
    } catch (error) {
      console.error('Error unrejecting resident:', error);
      setError(error.response?.data?.message || 'Failed to unreject resident');
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`/api/auth/residents/${resident._id}`);
      setDeleteDialogOpen(false);
      onStatusChange();
    } catch (error) {
      console.error('Error deleting resident:', error);
      setError(error.response?.data?.message || 'Failed to delete resident');
    }
  };

  const statusConfig = getStatusColor(resident.status);

  return (
    <>
      <Card 
        onClick={() => onClick(resident._id)}
        sx={{ 
          cursor: 'pointer',
          position: 'relative',
          borderRadius: 3,
          boxShadow: '0 4px 24px rgba(0, 0, 0, 0.08)',
          '&:hover': {
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.12)',
          },
          ...(selected && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
            borderColor: 'primary.main'
          })
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 12,
            left: 12,
            zIndex: 1
          }}
          onClick={(e) => {
            e.stopPropagation();
            onSelect(resident);
          }}
        >
          <Checkbox
            checked={selected}
          />
        </Box>
        {/* Image Section with Overlay */}
        <Box sx={{ position: 'relative' }}>
          <CardMedia
            component="img"
            height="200"
            image={resident.imageUrl ? `${process.env.REACT_APP_S3_URL}/${resident.imageUrl}` : '/default-profile.jpg'}
            alt={`${resident.firstName} ${resident.lastName}`}
            sx={{
              objectFit: 'cover',
              objectPosition: 'center',
              backgroundColor: 'grey.100'
            }}
          />
          {/* Name and Status Overlay */}
          <Box
            sx={{ 
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              background: 'linear-gradient(to top, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.6) 50%, rgba(0,0,0,0) 100%)',
              pt: 4,
              pb: 2,
              px: 2,
            }}
          >
            <Stack spacing={1}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h6" sx={{ 
                  color: '#fff',
                  fontWeight: 700,
                  textShadow: '0 2px 4px rgba(0,0,0,0.2)'
                }}>
                  {resident.firstName} {resident.lastName}
                </Typography>
                <Stack direction="row" spacing={0.5}>
                  <Chip
                    label={resident.status.charAt(0).toUpperCase() + resident.status.slice(1)}
                    size="small"
                    sx={{
                      height: 24,
                      borderRadius: 1.5,
                      backgroundColor: 'rgba(255,255,255,0.9)',
                      color: `${statusConfig.color}.dark`,
                      border: '1px solid',
                      borderColor: statusConfig.borderColor,
                      fontWeight: 600,
                      '& .MuiChip-label': { px: 1.5, fontSize: '0.75rem' }
                    }}
                  />
                  {resident.gender && (
                    <Chip
                      icon={resident.gender === 'Male' ? 
                        <MaleIcon sx={{ fontSize: '1rem', color: '#1976D2 !important' }} /> : 
                        <FemaleIcon sx={{ fontSize: '1rem', color: '#D81B60 !important' }} />
                      }
                      label={resident.gender}
                      size="small"
                      sx={{
                        height: 24,
                        borderRadius: 1.5,
                        backgroundColor: 'rgba(255,255,255,0.9)',
                        color: resident.gender === 'Male' ? '#1976D2' : '#D81B60',
                        border: '1px solid',
                        borderColor: resident.gender === 'Male' ? '#1976D2' : '#D81B60',
                        fontWeight: 600,
                        '& .MuiChip-label': { px: 1, fontSize: '0.75rem' }
                      }}
                    />
                  )}
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Box>

        {/* Content Section */}
        <CardContent sx={{ p: 2 }}>
          <Grid container spacing={1} sx={{ mb: 1 }}>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <PersonIcon sx={{ fontSize: 16, color: 'text.secondary' }} />
                <Stack spacing={0}>
                  <Typography variant="body2" sx={{ fontWeight: 600, lineHeight: 1.4 }}>
                    {resident.email}
                  </Typography>
                  {resident.phone && (
                    <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500, lineHeight: 1.4 }}>
                      {resident.phone}
                    </Typography>
                  )}
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <AccessTime sx={{ fontSize: 16, color: 'text.secondary' }} />
                <Typography variant="body2" sx={{ fontWeight: 500, color: 'text.secondary', lineHeight: 1.4 }}>
                  Move-in: {resident.moveInDate ? formatDate(resident.moveInDate) : 'Not set'}
                  {resident.status === 'discharged' && resident.moveOutDate && 
                    ` • Out: ${formatDate(resident.moveOutDate)}`
                  }
                </Typography>
              </Box>
            </Grid>
          </Grid>

          {resident.assignment && (
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1,
              p: 1,
              borderRadius: 1,
              bgcolor: alpha(theme.palette.primary.main, 0.04),
              mb: 1
            }}>
              <LocationOn sx={{ fontSize: 16, color: theme.palette.primary.main }} />
              <Typography variant="body2" sx={{ 
                fontWeight: 600,
                color: theme.palette.primary.main
              }}>
                {resident.assignment.property} • Room {resident.assignment.room} • Bed {resident.assignment.bed}
              </Typography>
            </Box>
          )}

          {resident.status === 'pending' ? (
            <Stack direction="row" spacing={1}>
              <Button
                variant="contained"
                color="success"
                fullWidth
                startIcon={<CheckIcon sx={{ fontSize: 16 }} />}
                onClick={(e) => {
                  e.stopPropagation();
                  setApprovalDialogOpen(true);
                }}
                sx={{
                  borderRadius: 1.5,
                  textTransform: 'none',
                  fontWeight: 600,
                  py: 0.75,
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: 'none',
                  }
                }}
              >
                Approve
              </Button>
              <Button
                variant="contained"
                color="error"
                fullWidth
                startIcon={<CloseIcon sx={{ fontSize: 16 }} />}
                onClick={(e) => {
                  e.stopPropagation();
                  handleReject();
                }}
                sx={{
                  borderRadius: 1.5,
                  textTransform: 'none',
                  fontWeight: 600,
                  py: 0.75,
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: 'none',
                  }
                }}
              >
                Reject
              </Button>
            </Stack>
          ) : resident.status === 'rejected' ? (
            <Stack direction="row" spacing={1}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                startIcon={<RefreshIcon sx={{ fontSize: 16 }} />}
                onClick={(e) => {
                  e.stopPropagation();
                  setUnrejectDialogOpen(true);
                }}
                sx={{
                  borderRadius: 1.5,
                  textTransform: 'none',
                  fontWeight: 600,
                  py: 0.75,
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: 'none',
                  }
                }}
              >
                Unreject
              </Button>
              <Button
                variant="contained"
                color="error"
                fullWidth
                startIcon={<DeleteIcon sx={{ fontSize: 16 }} />}
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteDialogOpen(true);
                }}
                sx={{
                  borderRadius: 1.5,
                  textTransform: 'none',
                  fontWeight: 600,
                  py: 0.75,
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: 'none',
                  }
                }}
              >
                Delete
              </Button>
            </Stack>
          ) : resident.status === 'discharged' ? (
            <Stack direction="row" spacing={1}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                startIcon={<EditIcon sx={{ fontSize: 16 }} />}
                onClick={(e) => {
                  e.stopPropagation();
                  onClick(resident._id);
                }}
                sx={{
                  borderRadius: 1.5,
                  textTransform: 'none',
                  fontWeight: 600,
                  py: 0.75,
                  borderWidth: 1.5,
                  '&:hover': {
                    borderWidth: 1.5,
                  }
                }}
              >
                View
              </Button>
              <Button
                variant="contained"
                color="error"
                fullWidth
                startIcon={<DeleteIcon sx={{ fontSize: 16 }} />}
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteDialogOpen(true);
                }}
                sx={{
                  borderRadius: 1.5,
                  textTransform: 'none',
                  fontWeight: 600,
                  py: 0.75,
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: 'none',
                  }
                }}
              >
                Delete
              </Button>
            </Stack>
          ) : null}
        </CardContent>
      </Card>

      <ApprovalDialog
        open={approvalDialogOpen}
        onClose={() => setApprovalDialogOpen(false)}
        resident={resident}
        onApproved={onStatusChange}
      />

      <UnrejectDialog
        open={unrejectDialogOpen}
        onClose={() => {
          setUnrejectDialogOpen(false);
          setError('');
          setUnrejectData({ status: 'pending' });
        }}
        onSubmit={handleUnreject}
        data={unrejectData}
        onChange={setUnrejectData}
        error={error}
      />

      <DeleteDialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
          setError('');
        }}
        onSubmit={handleDelete}
        resident={resident}
        error={error}
      />
    </>
  );
};

export default ResidentCard; 