import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Alert,
  Grid,
  useTheme,
} from '@mui/material';
import axios from '../../utils/axios';

// Import card components
import HeaderCard from './cards/HeaderCard';
import ResidentsCard from './cards/ResidentsCard';
import PaymentsCard from './cards/PaymentsCard';
import MaintenanceRequestsCard from './cards/MaintenanceRequestsCard';
import OvernightRequestsCard from './cards/OvernightRequestsCard';
import GrievancesCard from './cards/GrievancesCard';
import FormsCard from './cards/FormsCard';
import NewResidentsCard from './cards/NewResidentsCard';
import PendingApprovalsCard from './cards/PendingApprovalsCard';

// Cache key for dashboard data
const CACHE_KEY = 'dashboardData';
const CACHE_EXPIRY = 5 * 60 * 1000; // 5 minutes in milliseconds

function SuperAdminDashboard() {
  const theme = useTheme();
  const [error, setError] = useState('');

  // Dashboard state
  const [user, setUser] = useState(null);
  const [residents, setResidents] = useState([]);
  const [payments, setPayments] = useState([]);
  const [maintenanceRequests, setMaintenanceRequests] = useState([]);
  const [overnightRequests, setOvernightRequests] = useState([]);
  const [grievances, setGrievances] = useState([]);
  const [forms, setForms] = useState([]);
  const [formSubmissions, setFormSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadCachedData();
    fetchDashboardData();
  }, []);

  // Load data from cache
  const loadCachedData = () => {
    const cachedData = localStorage.getItem(CACHE_KEY);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      const isExpired = Date.now() - timestamp > CACHE_EXPIRY;

      if (!isExpired) {
        setUser(data.user);
        setResidents(data.residents);
        setPayments(data.payments);
        setMaintenanceRequests(data.maintenanceRequests);
        setOvernightRequests(data.overnightRequests);
        setGrievances(data.grievances);
        setForms(data.forms);
        setFormSubmissions(data.formSubmissions);
        setLoading(false);
      }
    }
  };

  // Save data to cache
  const saveToCache = (data) => {
    const cacheData = {
      data,
      timestamp: Date.now()
    };
    localStorage.setItem(CACHE_KEY, JSON.stringify(cacheData));
  };

  const fetchDashboardData = async () => {
    const hasCachedData = !!localStorage.getItem(CACHE_KEY);
    if (!hasCachedData) {
      setLoading(true);
    }
    setError('');
    
    try {
      // Fetch current user profile
      const userRes = await axios.get('/api/auth/profile');
      const user = userRes.data;

      // Fetch residents and pending approvals
      const residentsRes = await axios.get('/api/auth/residents');
      const residents = residentsRes.data;

      // Fetch payments
      const paymentsRes = await axios.get('/api/payments/all');
      const payments = paymentsRes.data;

      // Fetch maintenance requests
      const maintenanceRes = await axios.get('/api/maintenance/all');
      const maintenanceRequests = maintenanceRes.data;

      // Fetch overnight requests
      const overnightRes = await axios.get('/api/overnight/all');
      const overnightRequests = overnightRes.data;

      // Fetch grievances
      const grievancesRes = await axios.get('/api/grievances/all');
      const grievances = grievancesRes.data;

      // Fetch forms
      const formsRes = await axios.get('/api/forms');
      const forms = formsRes.data;

      // Fetch all form submissions
      const allSubmissions = [];
      for (const form of forms) {
        try {
          const submissionsRes = await axios.get(`/api/forms/${form._id}/submissions`);
          allSubmissions.push(...submissionsRes.data);
        } catch (submissionError) {
          console.error(`Error fetching submissions for form ${form._id}:`, submissionError);
        }
      }

      // Prepare data object
      const newData = {
        user,
        residents,
        payments,
        maintenanceRequests,
        overnightRequests,
        grievances,
        forms,
        formSubmissions: allSubmissions
      };

      // Update state and cache
      setUser(user);
      setResidents(residents);
      setPayments(payments);
      setMaintenanceRequests(maintenanceRequests);
      setOvernightRequests(overnightRequests);
      setGrievances(grievances);
      setForms(forms);
      setFormSubmissions(allSubmissions);
      saveToCache(newData);

    } catch (err) {
      console.error('Error fetching dashboard data:', err);
      setError(err.response?.data?.message || 'Failed to fetch dashboard data');
    } finally {
      setLoading(false);
    }
  };

  // Auto-refresh data every 5 minutes
  useEffect(() => {
    const refreshInterval = setInterval(() => {
      fetchDashboardData();
    }, CACHE_EXPIRY);

    return () => clearInterval(refreshInterval);
  }, []);

  // Calculate pending items for header card
  const pendingApprovals = residents.filter(r => r.status === 'pending').length;
  const pendingMaintenance = maintenanceRequests.filter(r => r.status === 'Pending').length;
  const pendingGrievances = grievances.filter(g => g.status === 'Pending').length;

  if (!localStorage.getItem(CACHE_KEY) && loading) {
    return (
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'background.default',
        }}
      >
        <Typography variant="h6" color="text.primary">
          Loading dashboard data...
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        bgcolor: 'background.default',
        pt: 4,
        pb: 8,
      }}
    >
      <Container maxWidth="xl">
        {error && (
          <Alert 
            severity="error" 
            sx={{ 
              mb: 3,
              borderRadius: 2,
              boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
            }}
          >
            {error}
          </Alert>
        )}

        {/* Header Card */}
        <HeaderCard 
          user={user}
          pendingApprovals={pendingApprovals}
          pendingMaintenance={pendingMaintenance}
          pendingGrievances={pendingGrievances}
        />

        <Grid container spacing={3}>
          {/* Row 1 */}
          <Grid item xs={12} sm={6} md={4}>
            <ResidentsCard residents={residents} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <PaymentsCard payments={payments} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MaintenanceRequestsCard requests={maintenanceRequests} />
          </Grid>

          {/* Row 2 */}
          <Grid item xs={12} sm={6} md={4}>
            <OvernightRequestsCard requests={overnightRequests} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <GrievancesCard grievances={grievances} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormsCard forms={forms} submissions={formSubmissions} />
          </Grid>

          {/* Row 3 - New Cards */}
          <Grid item xs={12} sm={6}>
            <NewResidentsCard residents={residents} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PendingApprovalsCard 
              residents={residents} 
              onStatusChange={fetchDashboardData} 
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default SuperAdminDashboard; 