import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Stack,
  Divider,
  ToggleButtonGroup,
  ToggleButton,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  CircularProgress,
  Alert,
  Skeleton,
  InputAdornment,
} from '@mui/material';
import {
  Check as CheckIcon,
  Close as CloseIcon,
  Person,
  Refresh,
  LocationOn,
  AccessTime,
  ViewModule as ViewModuleIcon,
  ViewList as ViewListIcon,
  Error as ErrorIcon,
  Receipt as ReceiptIcon,
  AttachMoney as MoneyIcon,
} from '@mui/icons-material';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://192.168.0.135:5001';
axios.defaults.baseURL = API_URL;

const PaymentList = () => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rentSettingsDialogOpen, setRentSettingsDialogOpen] = useState(false);
  const [note, setNote] = useState('');
  const [viewMode, setViewMode] = useState('grid');
  const [currentTab, setCurrentTab] = useState('all');
  const [filterProperty, setFilterProperty] = useState('all');
  const [properties, setProperties] = useState([]);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [error, setError] = useState(null);
  const [refundReason, setRefundReason] = useState('');
  const [filterMonth, setFilterMonth] = useState('all');
  const [filterYear, setFilterYear] = useState(new Date().getFullYear().toString());
  const [rentSettings, setRentSettings] = useState({
    defaultAmount: 0,
    defaultDueDate: 1
  });
  const [rentSettingsLoading, setRentSettingsLoading] = useState(false);
  const [success, setSuccess] = useState(null);

  // Generate years from oldest payment to current year
  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    let oldestYear = currentYear;

    // Find oldest payment year
    payments.forEach(payment => {
      const paymentYear = new Date(payment.createdAt).getFullYear();
      if (paymentYear < oldestYear) {
        oldestYear = paymentYear;
      }
    });

    // Generate array of years from oldest to current
    const yearArray = [];
    for (let year = currentYear; year >= oldestYear; year--) {
      yearArray.push(year.toString());
    }
    return yearArray;
  };

  // Add months
  const months = [
    { value: 'all', label: 'All Months' },
    { value: '0', label: 'January' },
    { value: '1', label: 'February' },
    { value: '2', label: 'March' },
    { value: '3', label: 'April' },
    { value: '4', label: 'May' },
    { value: '5', label: 'June' },
    { value: '6', label: 'July' },
    { value: '7', label: 'August' },
    { value: '8', label: 'September' },
    { value: '9', label: 'October' },
    { value: '10', label: 'November' },
    { value: '11', label: 'December' }
  ];

  const fetchPayments = async () => {
    try {
      setError(null);
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/payments/all', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setPayments(response.data);
    } catch (error) {
      setError('Failed to fetch payments. Please try again.');
      console.error('Error fetching payments:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchProperties = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/properties', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setProperties(response.data);
    } catch (error) {
      console.error('Error fetching properties:', error);
    }
  };

  const fetchRentSettings = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/settings', {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.data.rentSettings) {
        setRentSettings(response.data.rentSettings);
      }
    } catch (error) {
      console.error('Error fetching rent settings:', error);
      setError('Failed to fetch rent settings');
    }
  };

  const handleRentSettingsChange = async () => {
    try {
      setRentSettingsLoading(true);
      setError(null);
      const token = localStorage.getItem('token');
      await axios.put('/api/settings/rent', rentSettings, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setSuccess('Rent settings updated successfully');
    } catch (error) {
      setError(error.response?.data?.message || 'Error updating rent settings');
    } finally {
      setRentSettingsLoading(false);
    }
  };

  useEffect(() => {
    fetchPayments();
    fetchProperties();
    fetchRentSettings();
  }, []);

  const handleRefund = async () => {
    try {
      setLoadingUpdate(true);
      setError(null);
      const token = localStorage.getItem('token');
      await axios.post(`/api/payments/${selectedPayment._id}/refund`, {
        refundReason,
        note
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      await fetchPayments();
      setDialogOpen(false);
      setNote('');
      setRefundReason('');
    } catch (error) {
      setError('Failed to process refund. Please try again.');
      console.error('Error processing refund:', error);
    } finally {
      setLoadingUpdate(false);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Completed':
        return 'success';
      case 'Refunded':
        return 'warning';
      case 'Failed':
        return 'error';
      default:
        return 'default';
    }
  };

  const getResidentName = (resident) => {
    if (!resident) return 'Unknown';
    return `${resident.firstName} ${resident.lastName}`;
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const formatAmount = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  const filteredPayments = payments.filter(payment => {
    if (currentTab !== 'all' && payment.status.toLowerCase() !== currentTab) {
      return false;
    }
    if (filterProperty !== 'all' && payment.property?._id !== filterProperty) {
      return false;
    }
    
    // Add date filtering
    const paymentDate = new Date(payment.createdAt);
    const paymentYear = paymentDate.getFullYear().toString();
    const paymentMonth = paymentDate.getMonth().toString();

    if (filterYear !== 'all' && paymentYear !== filterYear) {
      return false;
    }
    if (filterMonth !== 'all' && paymentMonth !== filterMonth) {
      return false;
    }

    return true;
  });

  const PaymentCard = ({ payment }) => (
    <Card
      elevation={0}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        backgroundColor: '#ffffff',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
          borderColor: 'primary.main',
        }
      }}
    >
      <CardContent sx={{ p: 2.5, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Person sx={{ fontSize: 18, color: 'text.secondary' }} />
            <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.main' }}>
              {getResidentName(payment.resident)}
            </Typography>
          </Box>
          <Chip
            label={payment.status}
            color={getStatusColor(payment.status)}
            size="small"
            sx={{ fontWeight: 500, height: 24 }}
          />
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'text.secondary', mb: 2 }}>
          <LocationOn sx={{ fontSize: 18 }} />
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {payment.property?.name} • {payment.unit}
          </Typography>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="h6" color="primary" gutterBottom>
            {formatAmount(payment.amount)}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {payment.description}
          </Typography>
        </Box>

        {payment.status === 'Refunded' && payment.refundReason && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2" color="warning.main" sx={{ fontWeight: 500 }}>
              Refund Reason: {payment.refundReason}
            </Typography>
          </Box>
        )}

        {payment.notes && payment.notes.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              Latest Note:
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {payment.notes[payment.notes.length - 1].content}
            </Typography>
          </Box>
        )}

        <Box sx={{ mt: 'auto' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'text.secondary', mb: 2 }}>
            <AccessTime sx={{ fontSize: 18 }} />
            <Typography variant="caption" sx={{ fontWeight: 500 }}>
              {formatDate(payment.createdAt)}
            </Typography>
          </Box>

          {payment.status !== 'Refunded' && (
            <Button
              variant="outlined"
              fullWidth
              startIcon={<MoneyIcon />}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedPayment(payment);
                setDialogOpen(true);
              }}
              color="primary"
              sx={{
                borderRadius: 1,
                textTransform: 'none',
                fontWeight: 500,
                py: 0.75,
                borderWidth: 1.5
              }}
            >
              Process Refund
            </Button>
          )}
        </Box>
      </CardContent>
    </Card>
  );

  const PaymentTable = ({ payments }) => (
    <TableContainer 
      component={Paper}
      elevation={0}
      sx={{ 
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        overflow: 'hidden',
        backgroundColor: '#ffffff'
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Resident</TableCell>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Property</TableCell>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Amount</TableCell>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Description</TableCell>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Status</TableCell>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Date</TableCell>
            <TableCell sx={{ fontWeight: 600, py: 1.5, bgcolor: '#f5f6f8' }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payments.map((payment) => (
            <TableRow 
              key={payment._id}
              sx={{ 
                '&:hover': {
                  bgcolor: '#f8f9fb'
                }
              }}
            >
              <TableCell sx={{ py: 1.5, color: 'primary.main', fontWeight: 500 }}>
                {getResidentName(payment.resident)}
              </TableCell>
              <TableCell sx={{ py: 1.5 }}>{payment.property?.name} • {payment.unit}</TableCell>
              <TableCell sx={{ py: 1.5 }}>{formatAmount(payment.amount)}</TableCell>
              <TableCell sx={{ py: 1.5 }}>
                <Typography variant="body2">
                  {payment.description}
                </Typography>
              </TableCell>
              <TableCell sx={{ py: 1.5 }}>
                <Chip
                  label={payment.status}
                  color={getStatusColor(payment.status)}
                  size="small"
                  sx={{ fontWeight: 500, height: 24 }}
                />
              </TableCell>
              <TableCell sx={{ py: 1.5 }}>
                {formatDate(payment.createdAt)}
              </TableCell>
              <TableCell sx={{ py: 1.5 }}>
                {payment.status !== 'Refunded' && (
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<MoneyIcon />}
                    onClick={() => {
                      setSelectedPayment(payment);
                      setDialogOpen(true);
                    }}
                    sx={{
                      borderRadius: 1,
                      textTransform: 'none',
                      fontWeight: 500,
                      borderWidth: 1.5,
                      py: 0.5
                    }}
                  >
                    Refund
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const CardSkeleton = () => (
    <Card
      elevation={0}
      sx={{
        height: '100%',
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
      }}
    >
      <CardContent sx={{ p: 2.5 }}>
        <Stack spacing={2}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Skeleton width="60%" height={24} />
            <Skeleton width={80} height={24} />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Skeleton width={20} height={20} variant="circular" />
            <Skeleton width="40%" height={20} />
          </Box>
          <Skeleton width="100%" height={60} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Skeleton width={100} height={24} />
            <Skeleton width={120} height={24} />
          </Box>
          <Skeleton width="100%" height={36} />
        </Stack>
      </CardContent>
    </Card>
  );

  const TableSkeleton = () => (
    <TableContainer 
      component={Paper}
      elevation={0}
      sx={{ 
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        overflow: 'hidden'
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {[...Array(7)].map((_, index) => (
              <TableCell key={index} sx={{ bgcolor: '#f5f6f8' }}>
                <Skeleton width={100} height={24} />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(5)].map((_, index) => (
            <TableRow key={index}>
              {[...Array(7)].map((_, cellIndex) => (
                <TableCell key={cellIndex}>
                  <Skeleton width={cellIndex === 6 ? 120 : 100} height={24} />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Container maxWidth="lg" sx={{ py: 3 }}>
      {error && (
        <Alert 
          severity="error" 
          sx={{ mb: 3 }}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => setError(null)}
            >
              Dismiss
            </Button>
          }
        >
          {error}
        </Alert>
      )}

      {success && (
        <Alert 
          severity="success" 
          sx={{ mb: 3 }}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => setSuccess(null)}
            >
              Dismiss
            </Button>
          }
        >
          {success}
        </Alert>
      )}

      <Paper
        elevation={0}
        sx={{
          mb: 3,
          p: { xs: 2, sm: 2.5 },
          backgroundColor: '#ffffff',
          borderRadius: 1,
          border: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: { xs: 'stretch', md: 'center' },
          gap: { xs: 2, md: 0 },
          mb: { xs: 2, md: 2.5 }
        }}>
          <Box sx={{ flex: 1 }}>
            <Typography 
              variant="h5" 
              component="h1" 
              sx={{ 
                fontWeight: 600, 
                color: 'primary.main',
                mb: { xs: 0.5, md: 0.25 }
              }}
            >
              Payment History
            </Typography>
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{ 
                display: { xs: 'none', sm: 'block' },
                fontWeight: 500
              }}
            >
              View and manage all payment transactions
            </Typography>
          </Box>
          <Stack 
            direction={{ xs: 'column', sm: 'row' }} 
            spacing={2}
            sx={{ 
              width: { xs: '100%', md: 'auto' },
              alignItems: { xs: 'stretch', sm: 'center' }
            }}
          >
            <Button
              startIcon={<MoneyIcon />}
              onClick={() => setRentSettingsDialogOpen(true)}
              variant="outlined"
              sx={{
                borderRadius: 1,
                textTransform: 'none',
                fontWeight: 500,
                borderWidth: 1.5,
                minWidth: { sm: 120 },
                py: 1
              }}
            >
              Rent Settings
            </Button>
            <Button
              startIcon={<Refresh />}
              onClick={fetchPayments}
              variant="outlined"
              sx={{
                borderRadius: 1,
                textTransform: 'none',
                fontWeight: 500,
                borderWidth: 1.5,
                minWidth: { sm: 120 },
                py: 1
              }}
            >
              Refresh
            </Button>
            <ToggleButtonGroup
              value={viewMode}
              exclusive
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  setViewMode(newValue);
                }
              }}
              size="small"
              aria-label="view mode"
              sx={{
                display: 'flex',
                '& .MuiToggleButtonGroup-grouped': {
                  flex: { xs: 1, sm: 'none' },
                  px: { xs: 3, sm: 2 },
                  py: 1,
                  borderRadius: '4px !important',
                  borderColor: 'divider',
                  '&.Mui-selected': {
                    bgcolor: 'primary.main',
                    color: 'white',
                    '&:hover': {
                      bgcolor: 'primary.dark',
                    },
                  },
                },
              }}
            >
              <ToggleButton value="grid" aria-label="grid view">
                <ViewModuleIcon sx={{ mr: 1 }} />
                Grid
              </ToggleButton>
              <ToggleButton value="list" aria-label="list view">
                <ViewListIcon sx={{ mr: 1 }} />
                List
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Box>

        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}>
          <Box sx={{ 
            borderRadius: 1,
            bgcolor: '#f5f6f8',
            border: '1px solid',
            borderColor: 'divider',
            overflow: 'hidden'
          }}>
            <Tabs
              value={currentTab}
              onChange={(e, newValue) => setCurrentTab(newValue)}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              sx={{
                minHeight: 44,
                px: { xs: 1, sm: 2 },
                '& .MuiTab-root': {
                  textTransform: 'none',
                  fontWeight: 500,
                  fontSize: '0.875rem',
                  minHeight: 44,
                  minWidth: { xs: 'auto', sm: 100 },
                  px: { xs: 2, sm: 3 }
                }
              }}
            >
              <Tab label="All Payments" value="all" />
              <Tab 
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    Completed
                    <Chip 
                      label={payments.filter(p => p.status.toLowerCase() === 'completed').length} 
                      size="small"
                      color="success"
                      sx={{ 
                        height: 20,
                        '& .MuiChip-label': { px: 1, fontSize: '0.75rem' }
                      }}
                    />
                  </Box>
                } 
                value="completed" 
              />
              <Tab 
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    Refunded
                    <Chip 
                      label={payments.filter(p => p.status.toLowerCase() === 'refunded').length}
                      size="small"
                      color="warning"
                      sx={{ 
                        height: 20,
                        '& .MuiChip-label': { px: 1, fontSize: '0.75rem' }
                      }}
                    />
                  </Box>
                } 
                value="refunded" 
              />
            </Tabs>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              gap: 2,
              width: '100%'
            }}
          >
            <FormControl 
              size="small" 
              sx={{ 
                minWidth: { xs: '100%', sm: 200 },
                flex: { xs: 1, sm: 'none' }
              }}
            >
              <InputLabel>Property Filter</InputLabel>
              <Select
                value={filterProperty}
                onChange={(e) => setFilterProperty(e.target.value)}
                label="Property Filter"
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'divider',
                  },
                }}
              >
                <MenuItem value="all">All Properties</MenuItem>
                {properties.map((property) => (
                  <MenuItem key={property._id} value={property._id}>
                    {property.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Stack 
              direction={{ xs: 'column', sm: 'row' }} 
              spacing={2}
              sx={{ 
                flex: { xs: 1, sm: 'none' }
              }}
            >
              <FormControl 
                size="small" 
                sx={{ 
                  minWidth: { xs: '100%', sm: 200 }
                }}
              >
                <InputLabel>Month</InputLabel>
                <Select
                  value={filterMonth}
                  onChange={(e) => setFilterMonth(e.target.value)}
                  label="Month"
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'divider',
                    },
                  }}
                >
                  {months.map((month) => (
                    <MenuItem key={month.value} value={month.value}>
                      {month.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl 
                size="small" 
                sx={{ 
                  minWidth: { xs: '100%', sm: 200 }
                }}
              >
                <InputLabel>Year</InputLabel>
                <Select
                  value={filterYear}
                  onChange={(e) => setFilterYear(e.target.value)}
                  label="Year"
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'divider',
                    },
                  }}
                >
                  <MenuItem value="all">All Years</MenuItem>
                  {generateYearOptions().map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Box>
        </Box>
      </Paper>

      {loading ? (
        viewMode === 'grid' ? (
          <Grid container spacing={2}>
            {[...Array(6)].map((_, index) => (
              <Grid item key={index} xs={12} sm={6} lg={4}>
                <CardSkeleton />
              </Grid>
            ))}
          </Grid>
        ) : (
          <TableSkeleton />
        )
      ) : filteredPayments.length === 0 ? (
        <Paper
          elevation={0}
          sx={{
            p: 4,
            textAlign: 'center',
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 1,
            bgcolor: '#ffffff'
          }}
        >
          <ErrorIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
          <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
            No Payments Found
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            {currentTab === 'all' && filterProperty === 'all'
              ? 'There are no payments in the system.'
              : 'No payments match your current filters.'}
          </Typography>
          {(currentTab !== 'all' || filterProperty !== 'all') && (
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                setCurrentTab('all');
                setFilterProperty('all');
              }}
              sx={{
                borderRadius: 1,
                textTransform: 'none',
                fontWeight: 500,
                borderWidth: 1.5
              }}
            >
              Clear All Filters
            </Button>
          )}
        </Paper>
      ) : (
        viewMode === 'grid' ? (
          <Grid container spacing={2}>
            {filteredPayments.map((payment) => (
              <Grid item key={payment._id} xs={12} sm={6} lg={4}>
                <PaymentCard payment={payment} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <PaymentTable payments={filteredPayments} />
        )
      )}

      {/* Rent Settings Dialog */}
      <Dialog 
        open={rentSettingsDialogOpen} 
        onClose={() => !rentSettingsLoading && setRentSettingsDialogOpen(false)} 
        maxWidth="sm" 
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 1,
            boxShadow: '0 8px 32px -4px rgba(0, 0, 0, 0.1)'
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid',
          borderColor: 'divider',
          bgcolor: '#f5f6f8',
          py: 2
        }}>
          Default Rent Settings
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="Default Rent Amount"
              type="number"
              value={rentSettings.defaultAmount}
              onChange={(e) => setRentSettings(prev => ({
                ...prev,
                defaultAmount: Number(e.target.value)
              }))}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              disabled={rentSettingsLoading}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'divider',
                },
              }}
            />
            <TextField
              fullWidth
              label="Default Due Date (Day of Month)"
              type="number"
              value={rentSettings.defaultDueDate}
              onChange={(e) => setRentSettings(prev => ({
                ...prev,
                defaultDueDate: Number(e.target.value)
              }))}
              inputProps={{ min: 1, max: 31 }}
              disabled={rentSettingsLoading}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'divider',
                },
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 2.5, borderTop: '1px solid', borderColor: 'divider' }}>
          <Button 
            onClick={() => setRentSettingsDialogOpen(false)}
            disabled={rentSettingsLoading}
            sx={{
              borderRadius: 1,
              textTransform: 'none',
              fontWeight: 500,
              color: 'text.secondary'
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleRentSettingsChange} 
            variant="contained" 
            color="primary"
            disabled={rentSettingsLoading}
            sx={{
              borderRadius: 1,
              textTransform: 'none',
              fontWeight: 500,
              px: 3,
              minWidth: 100,
              position: 'relative'
            }}
          >
            {rentSettingsLoading ? (
              <CircularProgress
                size={24}
                sx={{
                  color: 'primary.light',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px'
                }}
              />
            ) : (
              'Save Settings'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Refund Dialog */}
      <Dialog 
        open={dialogOpen} 
        onClose={() => !loadingUpdate && setDialogOpen(false)} 
        maxWidth="sm" 
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 1,
            boxShadow: '0 8px 32px -4px rgba(0, 0, 0, 0.1)'
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid',
          borderColor: 'divider',
          bgcolor: '#f5f6f8',
          py: 2
        }}>
          Process Refund
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            {error && (
              <Alert 
                severity="error"
                sx={{ 
                  borderRadius: 1,
                  '& .MuiAlert-icon': {
                    color: 'error.main'
                  }
                }}
              >
                {error}
              </Alert>
            )}
            <TextField
              fullWidth
              label="Refund Reason"
              value={refundReason}
              onChange={(e) => setRefundReason(e.target.value)}
              required
              error={!refundReason}
              helperText={!refundReason ? 'Refund reason is required' : ''}
              disabled={loadingUpdate}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'divider',
                },
              }}
            />
            <TextField
              fullWidth
              label="Add a note"
              multiline
              rows={4}
              value={note}
              onChange={(e) => setNote(e.target.value)}
              disabled={loadingUpdate}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'divider',
                },
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 2.5, borderTop: '1px solid', borderColor: 'divider' }}>
          <Button 
            onClick={() => setDialogOpen(false)}
            disabled={loadingUpdate}
            sx={{
              borderRadius: 1,
              textTransform: 'none',
              fontWeight: 500,
              color: 'text.secondary'
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleRefund} 
            variant="contained" 
            color="primary"
            disabled={loadingUpdate || !refundReason}
            sx={{
              borderRadius: 1,
              textTransform: 'none',
              fontWeight: 500,
              px: 3,
              minWidth: 100,
              position: 'relative'
            }}
          >
            {loadingUpdate ? (
              <CircularProgress
                size={24}
                sx={{
                  color: 'primary.light',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px'
                }}
              />
            ) : (
              'Process Refund'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default PaymentList; 