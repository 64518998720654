import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Radio,
  RadioGroup,
  FormGroup,
  CircularProgress,
  Alert,
  Stack,
  Paper,
  Divider,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../../utils/axios';
import SignatureCanvas from 'react-signature-canvas';

const FormFill = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form, setForm] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [responses, setResponses] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [signaturePad, setSignaturePad] = useState(null);

  useEffect(() => {
    fetchForm();
  }, [id]);

  const fetchForm = async () => {
    try {
      setError(null);
      const response = await axios.get(`/api/forms/${id}`);
      setForm(response.data);
      initializeResponses(response.data.fields);
    } catch (error) {
      setError('Failed to load form. Please try again.');
      console.error('Error fetching form:', error);
    } finally {
      setLoading(false);
    }
  };

  const initializeResponses = (fields) => {
    const initialResponses = {};
    fields.forEach(field => {
      if (field.type === 'checkbox') {
        initialResponses[field._id] = [];
      } else {
        initialResponses[field._id] = '';
      }
    });
    setResponses(initialResponses);
  };

  const handleChange = (fieldId, value) => {
    setResponses(prev => ({
      ...prev,
      [fieldId]: value
    }));
    setValidationErrors(prev => ({
      ...prev,
      [fieldId]: null
    }));
  };

  const handleCheckboxChange = (fieldId, value) => {
    setResponses(prev => {
      const currentValues = prev[fieldId] || [];
      const newValues = currentValues.includes(value)
        ? currentValues.filter(v => v !== value)
        : [...currentValues, value];
      return {
        ...prev,
        [fieldId]: newValues
      };
    });
    setValidationErrors(prev => ({
      ...prev,
      [fieldId]: null
    }));
  };

  const validateResponses = () => {
    const errors = {};
    let isValid = true;

    form.fields.forEach(field => {
      if (field.required) {
        const value = responses[field._id];
        if (!value || (Array.isArray(value) && value.length === 0)) {
          errors[field._id] = 'This field is required';
          isValid = false;
        }
      }

      if (field.validation) {
        const value = responses[field._id];
        if (value) {
          if (field.validation.min && value < field.validation.min) {
            errors[field._id] = `Minimum value is ${field.validation.min}`;
            isValid = false;
          }
          if (field.validation.max && value > field.validation.max) {
            errors[field._id] = `Maximum value is ${field.validation.max}`;
            isValid = false;
          }
          if (field.validation.pattern) {
            const regex = new RegExp(field.validation.pattern);
            if (!regex.test(value)) {
              errors[field._id] = field.validation.message || 'Invalid format';
              isValid = false;
            }
          }
        }
      }
    });

    setValidationErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateResponses()) {
      return;
    }

    try {
      setSubmitting(true);
      setError(null);

      const formData = new FormData();
      const formattedResponses = [];

      for (const field of form.fields) {
        let value = responses[field._id];

        if (field.type === 'signature' && signaturePad) {
          value = signaturePad.toDataURL();
        }

        if (field.type === 'file' && value instanceof File) {
          formData.append('files', value);
          value = null; // The file will be handled separately by the server
        }

        formattedResponses.push({
          field: field._id,
          value: value
        });
      }

      formData.append('responses', JSON.stringify(formattedResponses));

      await axios.post(`/api/forms/${id}/submit`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      navigate('/resident/form-submissions');
    } catch (error) {
      setError('Failed to submit form. Please try again.');
      console.error('Error submitting form:', error);
    } finally {
      setSubmitting(false);
    }
  };

  const renderField = (field) => {
    const value = responses[field._id];
    const error = validationErrors[field._id];

    const getFontSize = (size) => {
      switch (size) {
        case 'small':
          return '1rem';
        case 'medium':
          return '1.25rem';
        case 'large':
          return '1.5rem';
        default:
          return '1rem';
      }
    };

    switch (field.type) {
      case 'header':
        return (
          <Typography
            variant={field.style?.size === 'large' ? 'h4' : field.style?.size === 'medium' ? 'h5' : 'h6'}
            align={field.style?.align || 'left'}
            sx={{
              fontWeight: field.style?.bold ? 600 : 400,
              color: 'primary.main',
              mb: 2
            }}
          >
            {field.content}
          </Typography>
        );

      case 'paragraph':
        return (
          <Typography
            variant="body1"
            align={field.style?.align || 'left'}
            sx={{
              fontSize: getFontSize(field.style?.size),
              fontWeight: field.style?.bold ? 600 : 400,
              mb: 2,
              whiteSpace: 'pre-wrap'
            }}
          >
            {field.content}
          </Typography>
        );

      case 'divider':
        return <Divider sx={{ my: 2 }} />;

      case 'text':
      case 'email':
      case 'phone':
        return (
          <TextField
            fullWidth
            label={field.label}
            value={value}
            onChange={(e) => handleChange(field._id, e.target.value)}
            placeholder={field.placeholder}
            required={field.required}
            error={Boolean(error)}
            helperText={error}
            type={field.type === 'email' ? 'email' : 'text'}
            disabled={submitting}
          />
        );

      case 'textarea':
        return (
          <TextField
            fullWidth
            multiline
            rows={4}
            label={field.label}
            value={value}
            onChange={(e) => handleChange(field._id, e.target.value)}
            placeholder={field.placeholder}
            required={field.required}
            error={Boolean(error)}
            helperText={error}
            disabled={submitting}
          />
        );

      case 'number':
        return (
          <TextField
            fullWidth
            type="number"
            label={field.label}
            value={value}
            onChange={(e) => handleChange(field._id, e.target.value)}
            placeholder={field.placeholder}
            required={field.required}
            error={Boolean(error)}
            helperText={error}
            disabled={submitting}
            InputProps={{
              inputProps: {
                min: field.validation?.min,
                max: field.validation?.max
              }
            }}
          />
        );

      case 'date':
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={field.label}
              value={value ? new Date(value) : null}
              onChange={(date) => handleChange(field._id, date?.toISOString())}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  required={field.required}
                  error={Boolean(error)}
                  helperText={error}
                  disabled={submitting}
                />
              )}
            />
          </LocalizationProvider>
        );

      case 'time':
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              label={field.label}
              value={value ? new Date(`1970-01-01T${value}`) : null}
              onChange={(time) => {
                const timeString = time?.toTimeString().split(' ')[0];
                handleChange(field._id, timeString);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  required={field.required}
                  error={Boolean(error)}
                  helperText={error}
                  disabled={submitting}
                />
              )}
            />
          </LocalizationProvider>
        );

      case 'select':
        return (
          <FormControl
            fullWidth
            required={field.required}
            error={Boolean(error)}
            disabled={submitting}
          >
            <InputLabel>{field.label}</InputLabel>
            <Select
              value={value}
              onChange={(e) => handleChange(field._id, e.target.value)}
              label={field.label}
            >
              {field.options.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
            {error && <FormHelperText>{error}</FormHelperText>}
          </FormControl>
        );

      case 'radio':
        return (
          <FormControl
            component="fieldset"
            required={field.required}
            error={Boolean(error)}
            disabled={submitting}
          >
            <Typography variant="subtitle2" gutterBottom>
              {field.label}
            </Typography>
            <RadioGroup
              value={value}
              onChange={(e) => handleChange(field._id, e.target.value)}
            >
              {field.options.map((option, index) => (
                <FormControlLabel
                  key={index}
                  value={option}
                  control={<Radio />}
                  label={option}
                />
              ))}
            </RadioGroup>
            {error && <FormHelperText>{error}</FormHelperText>}
          </FormControl>
        );

      case 'checkbox':
        return (
          <FormControl
            component="fieldset"
            required={field.required}
            error={Boolean(error)}
            disabled={submitting}
          >
            <Typography variant="subtitle2" gutterBottom>
              {field.label}
            </Typography>
            <FormGroup>
              {field.options.map((option, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={value.includes(option)}
                      onChange={() => handleCheckboxChange(field._id, option)}
                    />
                  }
                  label={option}
                />
              ))}
            </FormGroup>
            {error && <FormHelperText>{error}</FormHelperText>}
          </FormControl>
        );

      case 'file':
        return (
          <Box>
            <Typography variant="subtitle2" gutterBottom>
              {field.label}
            </Typography>
            <input
              type="file"
              onChange={(e) => handleChange(field._id, e.target.files[0])}
              disabled={submitting}
              style={{ display: 'none' }}
              id={`file-input-${field._id}`}
            />
            <label htmlFor={`file-input-${field._id}`}>
              <Button
                variant="outlined"
                component="span"
                disabled={submitting}
                sx={{ mr: 2 }}
              >
                Choose File
              </Button>
            </label>
            {value && (
              <Typography variant="body2" component="span">
                {value.name}
              </Typography>
            )}
            {error && (
              <FormHelperText error>{error}</FormHelperText>
            )}
          </Box>
        );

      case 'signature':
        return (
          <Box>
            <Typography variant="subtitle2" gutterBottom>
              {field.label}
            </Typography>
            <Paper
              variant="outlined"
              sx={{
                p: 1,
                bgcolor: '#fff',
                border: theme => `1px solid ${error ? theme.palette.error.main : theme.palette.divider}`
              }}
            >
              <SignatureCanvas
                ref={(ref) => setSignaturePad(ref)}
                canvasProps={{
                  width: 500,
                  height: 200,
                  className: 'signature-canvas'
                }}
              />
            </Paper>
            <Box sx={{ mt: 1, display: 'flex', gap: 1 }}>
              <Button
                size="small"
                onClick={() => signaturePad?.clear()}
                disabled={submitting}
              >
                Clear
              </Button>
            </Box>
            {error && (
              <FormHelperText error>{error}</FormHelperText>
            )}
          </Box>
        );

      default:
        return null;
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Card elevation={0} sx={{ mb: 3, borderRadius: 1, border: '1px solid', borderColor: 'divider' }}>
        <CardContent>
          <Typography variant="h5" gutterBottom sx={{ color: 'primary.main', fontWeight: 600 }}>
            {form.title}
          </Typography>
          {form.description && (
            <Typography variant="body1" color="text.secondary" paragraph>
              {form.description}
            </Typography>
          )}
        </CardContent>
      </Card>

      {error && (
        <Alert 
          severity="error" 
          sx={{ mb: 3 }}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => setError(null)}
            >
              Dismiss
            </Button>
          }
        >
          {error}
        </Alert>
      )}

      <form onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {form.fields.map((field) => (
            <Card
              key={field._id}
              elevation={0}
              sx={{
                borderRadius: 1,
                border: '1px solid',
                borderColor: 'divider'
              }}
            >
              <CardContent>
                {renderField(field)}
              </CardContent>
            </Card>
          ))}

          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
            <Button
              variant="outlined"
              onClick={() => navigate('/resident/forms')}
              disabled={submitting}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={submitting}
            >
              {submitting ? <CircularProgress size={24} /> : 'Submit Form'}
            </Button>
          </Box>
        </Stack>
      </form>
    </Box>
  );
};

export default FormFill; 