import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stepper,
  Step,
  StepLabel,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Alert,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  Paper
} from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';
import axios from '../../../../utils/axios';

const steps = [
  'Move-in Date',
  'Basic Information',
  'Housing Assignment'
];

const stepDescriptions = {
  0: 'Set the resident\'s move-in date. This can be a past or future date.',
  1: 'Enter the resident\'s basic program information.',
  2: 'Assign the resident to an available bed in a property.'
};

const ApprovalDialog = ({ open, onClose, resident, onApproved }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeStep, setActiveStep] = useState(0);
  const [moveInDate, setMoveInDate] = useState('');
  const [levelOfCare, setLevelOfCare] = useState('');
  const [treatmentAgency, setTreatmentAgency] = useState('');
  const [gender, setGender] = useState('');
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState('');
  const [selectedRoom, setSelectedRoom] = useState('');
  const [selectedBed, setSelectedBed] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      fetchProperties();
    }
  }, [open]);

  const fetchProperties = async () => {
    try {
      const response = await axios.get('/api/properties');
      setProperties(response.data);
    } catch (error) {
      console.error('Error fetching properties:', error);
      setError('Failed to load properties');
    }
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      handleSubmit();
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleClose = () => {
    setActiveStep(0);
    setMoveInDate('');
    setLevelOfCare('');
    setTreatmentAgency('');
    setGender('');
    setSelectedProperty('');
    setSelectedRoom('');
    setSelectedBed('');
    setError('');
    onClose();
  };

  const isStepValid = () => {
    switch (activeStep) {
      case 0:
        return moveInDate;
      case 1:
        return levelOfCare && treatmentAgency && gender;
      case 2:
        return selectedProperty && selectedRoom && selectedBed;
      default:
        return false;
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError('');

      await axios.put(`/api/auth/residents/${resident._id}/details`, {
        levelOfCare,
        treatmentAgency,
        gender
      });

      await axios.put(`/api/auth/residents/${resident._id}/move-in-date`, {
        moveInDate: new Date(moveInDate).toISOString()
      });

      await axios.put(
        `/api/properties/${selectedProperty}/rooms/${selectedRoom}/beds/${selectedBed}/assign`,
        { residentId: resident._id }
      );

      await axios.put(`/api/auth/residents/${resident._id}/approve`, {
        approved: true
      });

      onApproved();
      handleClose();
    } catch (error) {
      console.error('Error approving resident:', error);
      setError(error.response?.data?.message || 'Failed to approve resident');
    } finally {
      setLoading(false);
    }
  };

  const getAvailableRooms = () => {
    const property = properties.find(p => p._id === selectedProperty);
    return property ? property.rooms : [];
  };

  const getAvailableBeds = () => {
    const room = getAvailableRooms().find(r => r._id === selectedRoom);
    return room ? room.beds.filter(bed => !bed.resident) : [];
  };

  const renderStepContent = () => {
    const renderStepHelper = (description) => (
      <Paper
        variant="outlined"
        sx={{
          p: 2.5,
          mb: 3,
          bgcolor: 'primary.lighter',
          borderColor: 'primary.light',
          borderRadius: 2,
          display: 'flex',
          alignItems: 'flex-start',
          gap: 1.5
        }}
      >
        <InfoIcon sx={{ color: 'primary.main', mt: 0.25 }} />
        <Typography variant="body2" sx={{ color: 'primary.main', fontWeight: 600 }}>
          {description}
        </Typography>
      </Paper>
    );

    switch (activeStep) {
      case 0:
        return (
          <Stack spacing={3}>
            {renderStepHelper(stepDescriptions[0])}
            <TextField
              type="date"
              fullWidth
              required
              label="Move-in Date"
              value={moveInDate}
              onChange={(e) => setMoveInDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              helperText="Select the date when the resident moved in or will move in"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                  '& fieldset': {
                    borderWidth: 2,
                    borderColor: 'divider'
                  }
                }
              }}
            />
          </Stack>
        );
      case 1:
        return (
          <Stack spacing={3}>
            {renderStepHelper(stepDescriptions[1])}
            <TextField
              fullWidth
              required
              label="Level of Care (LOC)"
              value={levelOfCare}
              onChange={(e) => setLevelOfCare(e.target.value)}
              helperText="Enter the resident's required level of care"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                  '& fieldset': {
                    borderWidth: 2,
                    borderColor: 'divider'
                  }
                }
              }}
            />
            <TextField
              fullWidth
              required
              label="Treatment Agency"
              value={treatmentAgency}
              onChange={(e) => setTreatmentAgency(e.target.value)}
              helperText="Enter the agency responsible for the resident's treatment"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                  '& fieldset': {
                    borderWidth: 2,
                    borderColor: 'divider'
                  }
                }
              }}
            />
            <Box>
              <Typography variant="subtitle1" sx={{ mb: 1.5, fontWeight: 600 }}>
                Gender
              </Typography>
              <RadioGroup
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontSize: '0.875rem',
                    fontWeight: 600
                  }
                }}
              >
                <FormControlLabel value="Male" control={<Radio />} label="Male" />
                <FormControlLabel value="Female" control={<Radio />} label="Female" />
              </RadioGroup>
              <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block', fontWeight: 500 }}>
                Select the resident's gender for appropriate housing assignment
              </Typography>
            </Box>
          </Stack>
        );
      case 2:
        return (
          <Stack spacing={3}>
            {renderStepHelper(stepDescriptions[2])}
            <FormControl fullWidth>
              <InputLabel>Select Property</InputLabel>
              <Select
                value={selectedProperty}
                onChange={(e) => {
                  setSelectedProperty(e.target.value);
                  setSelectedRoom('');
                  setSelectedBed('');
                }}
                label="Select Property"
                sx={{
                  borderRadius: 2,
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderWidth: 2,
                    borderColor: 'divider',
                  },
                }}
              >
                {properties.map((property) => (
                  <MenuItem key={property._id} value={property._id}>
                    {property.name}
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="caption" color="text.secondary" sx={{ mt: 1, fontWeight: 500 }}>
                Choose the property where the resident will be housed
              </Typography>
            </FormControl>

            {selectedProperty && (
              <FormControl fullWidth>
                <InputLabel>Select Room</InputLabel>
                <Select
                  value={selectedRoom}
                  onChange={(e) => {
                    setSelectedRoom(e.target.value);
                    setSelectedBed('');
                  }}
                  label="Select Room"
                  sx={{
                    borderRadius: 2,
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderWidth: 2,
                      borderColor: 'divider',
                    },
                  }}
                >
                  {getAvailableRooms().map((room) => (
                    <MenuItem key={room._id} value={room._id}>
                      {room.name}
                    </MenuItem>
                  ))}
                </Select>
                <Typography variant="caption" color="text.secondary" sx={{ mt: 1, fontWeight: 500 }}>
                  Select an available room in the chosen property
                </Typography>
              </FormControl>
            )}

            {selectedRoom && (
              <FormControl fullWidth>
                <InputLabel>Select Bed</InputLabel>
                <Select
                  value={selectedBed}
                  onChange={(e) => setSelectedBed(e.target.value)}
                  label="Select Bed"
                  sx={{
                    borderRadius: 2,
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderWidth: 2,
                      borderColor: 'divider',
                    },
                  }}
                >
                  {getAvailableBeds().map((bed) => (
                    <MenuItem key={bed._id} value={bed._id}>
                      {bed.name}
                    </MenuItem>
                  ))}
                </Select>
                <Typography variant="caption" color="text.secondary" sx={{ mt: 1, fontWeight: 500 }}>
                  Assign an available bed to the resident
                </Typography>
              </FormControl>
            )}
          </Stack>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
      PaperProps={{
        sx: {
          borderRadius: fullScreen ? 0 : 2,
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.12)'
        }
      }}
    >
      <DialogTitle sx={{ 
        borderBottom: '1px solid',
        borderColor: 'divider',
        bgcolor: '#f8f9fb',
        py: 2.5,
        px: 3
      }}>
        <Typography variant="h5" component="div" sx={{ fontWeight: 700, color: 'primary.main' }}>
          Approve Resident: {resident?.firstName} {resident?.lastName}
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mt: 0.5, fontWeight: 500 }}>
          Complete the following steps to approve the resident
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ p: fullScreen ? 2.5 : 3 }}>
        <Stack spacing={3} sx={{ mt: 2 }}>
          {error && (
            <Alert 
              severity="error"
              sx={{ 
                borderRadius: 2,
                '& .MuiAlert-icon': {
                  color: 'error.main'
                }
              }}
            >
              {error}
            </Alert>
          )}
          
          <Stepper 
            activeStep={activeStep} 
            sx={{ 
              '& .MuiStepLabel-label': {
                fontSize: '0.875rem',
                fontWeight: 600
              },
              '& .MuiStepIcon-root': {
                width: 32,
                height: 32,
                '&.Mui-active': {
                  color: 'primary.main'
                }
              }
            }}
            orientation={fullScreen ? 'vertical' : 'horizontal'}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {renderStepContent()}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ 
        px: fullScreen ? 2.5 : 3, 
        py: 2.5, 
        bgcolor: '#f8f9fb',
        borderTop: '1px solid',
        borderColor: 'divider'
      }}>
        <Button 
          onClick={handleClose}
          sx={{ 
            borderRadius: 2,
            textTransform: 'none',
            fontWeight: 600,
            px: 3,
            py: 1.25
          }}
        >
          Cancel
        </Button>
        {activeStep > 0 && (
          <Button 
            onClick={handleBack}
            sx={{ 
              borderRadius: 2,
              textTransform: 'none',
              fontWeight: 600,
              px: 3,
              py: 1.25,
              mr: 1
            }}
          >
            Back
          </Button>
        )}
        <Button 
          variant="contained"
          onClick={handleNext}
          disabled={!isStepValid() || loading}
          sx={{ 
            borderRadius: 2,
            textTransform: 'none',
            fontWeight: 600,
            px: 4,
            py: 1.25,
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            }
          }}
        >
          {activeStep === steps.length - 1 ? 'Approve' : 'Next'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApprovalDialog; 