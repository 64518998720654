import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Login from './components/auth/Login';
import SuperAdminDashboard from './components/dashboard/SuperAdminDashboard';
import AdminDashboard from './components/dashboard/AdminDashboard';
import ResidentDashboard from './components/dashboard/ResidentDashboard';
import RegisterSuperAdmin from './components/auth/RegisterSuperAdmin';
import RegisterResident from './components/auth/RegisterResident';
import Layout from './components/layout/Layout';
import SuperAdminSettings from './components/settings/SuperAdminSettings';
import ManageAdmins from './components/admin/ManageAdmins';
import ResidentsPage from './components/residents/list';
import PropertiesPage from './components/properties/PropertiesPage';
import PropertyDetailsPage from './components/properties/PropertyDetailsPage';
import ResidentDetailsPage from './components/residents/details';
import MaintenanceList from './components/maintenance/MaintenanceList';
import MaintenanceRequestForm from './components/maintenance/MaintenanceRequestForm';
import MyMaintenanceRequests from './components/maintenance/MyMaintenanceRequests';
import OvernightList from './components/overnight/OvernightList';
import MyOvernightRequests from './components/overnight/MyOvernightRequests';
import OvernightRequestForm from './components/overnight/OvernightRequestForm';
import GrievanceList from './components/grievances/GrievanceList';
import MyGrievances from './components/grievances/MyGrievances';
import GrievanceForm from './components/grievances/GrievanceForm';
import PaymentList from './components/payments/PaymentList';
import MyPayments from './components/payments/MyPayments';
import PaymentForm from './components/payments/PaymentForm';
import FormList from './components/forms/FormList';
import FormBuilder from './components/forms/FormBuilder';
import FormSubmissionList from './components/forms/FormSubmissionList';
import FormFill from './components/forms/FormFill';
import ResidentForms from './components/forms/ResidentForms';
import MyFormSubmissions from './components/forms/MyFormSubmissions';
import ForgotPassword from './components/auth/ForgotPassword';
import ResetPassword from './components/auth/ResetPassword';
import { SuperAdminProfile, AdminProfile, ResidentProfile } from './components/profile';
import LinxiqPage from './components/linxiq/LinxiqPage';
import RentTracker from './components/rent/RentTracker';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
});

// Protected Route component with Layout
const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  if (!token) {
    return <Navigate to="/login" replace />;
  }
  return <Layout>{children}</Layout>;
};

// Role-based dashboard redirect
const DashboardRedirect = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  switch (user.role) {
    case 'superadmin':
      return <Navigate to="/dashboard/superadmin" replace />;
    case 'admin':
      return <Navigate to="/dashboard/admin" replace />;
    case 'resident':
      return <Navigate to="/dashboard/resident" replace />;
    default:
      return <Navigate to="/login" replace />;
  }
};

// Role-based route guard
const RoleRoute = ({ children, allowedRoles }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (!user || !allowedRoles.includes(user.role)) {
    return <Navigate to="/dashboard" replace />;
  }
  return children;
};

// Add this new component for profile redirection
const ProfileRedirect = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  switch (user.role) {
    case 'superadmin':
      return <Navigate to="/profile/superadmin" replace />;
    case 'admin':
      return <Navigate to="/profile/admin" replace />;
    case 'resident':
      return <Navigate to="/profile/resident" replace />;
    default:
      return <Navigate to="/dashboard" replace />;
  }
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/register/superadmin" element={<RegisterSuperAdmin />} />
          <Route path="/register/resident" element={<RegisterResident />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          
          {/* Protected Dashboard Routes */}
          <Route
            path="/dashboard/superadmin"
            element={
              <ProtectedRoute>
                <SuperAdminDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/admin"
            element={
              <ProtectedRoute>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/resident"
            element={
              <ProtectedRoute>
                <ResidentDashboard />
              </ProtectedRoute>
            }
          />
          
          {/* Admin Management Route */}
          <Route
            path="/admins"
            element={
              <ProtectedRoute>
                <RoleRoute allowedRoles={['superadmin']}>
                  <ManageAdmins />
                </RoleRoute>
              </ProtectedRoute>
            }
          />
          
          {/* Residents Management Route */}
          <Route
            path="/residents"
            element={
              <ProtectedRoute>
                <ResidentsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/residents/:id"
            element={
              <ProtectedRoute>
                <ResidentDetailsPage />
              </ProtectedRoute>
            }
          />

          {/* Properties Routes */}
          <Route
            path="/properties"
            element={
              <ProtectedRoute>
                <PropertiesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/properties/:id"
            element={
              <ProtectedRoute>
                <PropertyDetailsPage />
              </ProtectedRoute>
            }
          />

          {/* Maintenance Routes */}
          <Route
            path="/maintenance"
            element={
              <ProtectedRoute>
                <RoleRoute allowedRoles={['admin', 'superadmin']}>
                  <MaintenanceList />
                </RoleRoute>
              </ProtectedRoute>
            }
          />
          <Route
            path="/maintenance-requests"
            element={
              <ProtectedRoute>
                <RoleRoute allowedRoles={['resident']}>
                  <MyMaintenanceRequests />
                </RoleRoute>
              </ProtectedRoute>
            }
          />
          <Route
            path="/maintenance-requests/new"
            element={
              <ProtectedRoute>
                <RoleRoute allowedRoles={['resident']}>
                  <MaintenanceRequestForm />
                </RoleRoute>
              </ProtectedRoute>
            }
          />

          {/* Overnight Request Routes */}
          <Route
            path="/overnight"
            element={
              <ProtectedRoute>
                <RoleRoute allowedRoles={['admin', 'superadmin']}>
                  <OvernightList />
                </RoleRoute>
              </ProtectedRoute>
            }
          />
          <Route
            path="/overnight-requests"
            element={
              <ProtectedRoute>
                <RoleRoute allowedRoles={['resident']}>
                  <MyOvernightRequests />
                </RoleRoute>
              </ProtectedRoute>
            }
          />
          <Route
            path="/overnight-requests/new"
            element={
              <ProtectedRoute>
                <RoleRoute allowedRoles={['resident']}>
                  <OvernightRequestForm />
                </RoleRoute>
              </ProtectedRoute>
            }
          />

          {/* Grievance Routes */}
          <Route
            path="/grievances"
            element={
              <ProtectedRoute>
                <RoleRoute allowedRoles={['admin', 'superadmin']}>
                  <GrievanceList />
                </RoleRoute>
              </ProtectedRoute>
            }
          />
          <Route
            path="/my-grievances"
            element={
              <ProtectedRoute>
                <RoleRoute allowedRoles={['resident']}>
                  <MyGrievances />
                </RoleRoute>
              </ProtectedRoute>
            }
          />
          <Route
            path="/my-grievances/new"
            element={
              <ProtectedRoute>
                <RoleRoute allowedRoles={['resident']}>
                  <GrievanceForm />
                </RoleRoute>
              </ProtectedRoute>
            }
          />

          {/* Payment Routes */}
          <Route
            path="/payments"
            element={
              <ProtectedRoute>
                <RoleRoute allowedRoles={['admin', 'superadmin']}>
                  <PaymentList />
                </RoleRoute>
              </ProtectedRoute>
            }
          />
          <Route
            path="/my-payments"
            element={
              <ProtectedRoute>
                <RoleRoute allowedRoles={['resident']}>
                  <MyPayments />
                </RoleRoute>
              </ProtectedRoute>
            }
          />
          <Route
            path="/payments/new"
            element={
              <ProtectedRoute>
                <RoleRoute allowedRoles={['resident']}>
                  <PaymentForm />
                </RoleRoute>
              </ProtectedRoute>
            }
          />

          {/* Settings Route */}
          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <SuperAdminSettings />
              </ProtectedRoute>
            }
          />

          {/* Forms Routes */}
          <Route path="/forms" element={<ProtectedRoute><FormList /></ProtectedRoute>} />
          <Route path="/forms/new" element={<ProtectedRoute roles={['admin', 'superadmin']}><FormBuilder /></ProtectedRoute>} />
          <Route path="/forms/edit/:id" element={<ProtectedRoute roles={['admin', 'superadmin']}><FormBuilder /></ProtectedRoute>} />
          <Route path="/forms/:id/submissions" element={<ProtectedRoute roles={['admin', 'superadmin']}><FormSubmissionList /></ProtectedRoute>} />
          <Route path="/forms/:id/fill" element={<ProtectedRoute roles={['resident']}><FormFill /></ProtectedRoute>} />
          <Route path="/resident/forms" element={<ProtectedRoute roles={['resident']}><ResidentForms /></ProtectedRoute>} />
          <Route path="/resident/form-submissions" element={<ProtectedRoute roles={['resident']}><MyFormSubmissions /></ProtectedRoute>} />

          {/* Profile Routes */}
          <Route
            path="/profile/superadmin"
            element={
              <ProtectedRoute>
                <RoleRoute allowedRoles={['superadmin']}>
                  <SuperAdminProfile />
                </RoleRoute>
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile/admin"
            element={
              <ProtectedRoute>
                <RoleRoute allowedRoles={['admin']}>
                  <AdminProfile />
                </RoleRoute>
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile/resident"
            element={
              <ProtectedRoute>
                <RoleRoute allowedRoles={['resident']}>
                  <ResidentProfile />
                </RoleRoute>
              </ProtectedRoute>
            }
          />

          {/* Generic profile route that redirects based on role */}
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <ProfileRedirect />
              </ProtectedRoute>
            }
          />

          {/* LINXIQ Route */}
          <Route
            path="/linxiq"
            element={
              <ProtectedRoute>
                <RoleRoute allowedRoles={['admin', 'superadmin']}>
                  <LinxiqPage />
                </RoleRoute>
              </ProtectedRoute>
            }
          />

          {/* Rent Tracker Route */}
          <Route
            path="/rent-tracker"
            element={
              <ProtectedRoute>
                <RoleRoute allowedRoles={['superadmin', 'admin']}>
                  <RentTracker />
                </RoleRoute>
              </ProtectedRoute>
            }
          />

          {/* Default Routes */}
          <Route path="/dashboard" element={<DashboardRedirect />} />
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
