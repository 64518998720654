import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../../../utils/axios';
import {
  Container,
  Typography,
  Box,
  Paper,
  Grid,
  Button,
  Stack,
  Alert
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
  LocalHospital,
  Work,
  ContactPhone,
  Security
} from '@mui/icons-material';

// Import components
import ResidentCard from './ResidentCard';
import HousingAssignment from './HousingAssignment';
import ContactInformation from './ContactInformation';
import ProgramDetails from './ProgramDetails';
import RentSettingsCard from './RentSettingsCard';
import ResidentNotes from './ResidentNotes';
import DischargeInformation from './DischargeInformation';
import DischargeHistory from './DischargeHistory';
import EmergencyContact from './EmergencyContact';
import HealthInformation from './HealthInformation';
import InsuranceInformation from './InsuranceInformation';
import EmploymentInformation from './EmploymentInformation';
import MaintenanceRequests from './MaintenanceRequests';
import OvernightRequests from './OvernightRequests';
import Grievances from './Grievances';
import Payments from './Payments';
import FormSubmissions from './FormSubmissions';
import ResidentFiles from './ResidentFiles';

// Import dialogs
import AssignmentDialog from './dialogs/AssignmentDialog';
import DischargeDialog from './dialogs/DischargeDialog';
import ReactivateDialog from './dialogs/ReactivateDialog';
import DetailsDialog from './dialogs/DetailsDialog';

// Import utilities
import { calculateLengthOfStay, formatUserName } from './utils';

const InfoSection = ({ title, icon, children }) => (
  <Paper
    elevation={0}
    sx={{
      p: 3,
      borderRadius: 1,
      border: '1px solid',
      borderColor: 'divider',
      bgcolor: '#ffffff',
      mb: 3
    }}
  >
    <Stack spacing={2}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {icon}
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {title}
        </Typography>
      </Box>
      {children}
    </Stack>
  </Paper>
);

const ResidentDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [resident, setResident] = useState(null);
  const [assignment, setAssignment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [properties, setProperties] = useState([]);
  
  // Dialog states
  const [openAssignDialog, setOpenAssignDialog] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState('');
  const [selectedRoom, setSelectedRoom] = useState('');
  const [selectedBed, setSelectedBed] = useState('');
  const [assignmentError, setAssignmentError] = useState('');

  const [openDischargeDialog, setOpenDischargeDialog] = useState(false);
  const [dischargeData, setDischargeData] = useState({
    moveOutDate: null,
    dischargeReason: '',
    dischargeNotes: '',
    dischargeFlagged: false,
    flagReason: ''
  });
  const [dischargeError, setDischargeError] = useState('');

  const [openReactivateDialog, setOpenReactivateDialog] = useState(false);
  const [reactivateData, setReactivateData] = useState({
    newMoveInDate: null
  });
  const [reactivateError, setReactivateError] = useState('');

  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const [detailsData, setDetailsData] = useState({
    levelOfCare: '',
    treatmentAgency: '',
    notes: ''
  });
  const [detailsError, setDetailsError] = useState('');

  const [editMode, setEditMode] = useState({
    moveInDate: false,
    levelOfCare: false,
    treatmentAgency: false,
    notes: false,
    email: false,
    phone: false,
    emergencyContact: {
      name: false,
      relationship: false,
      phone: false
    },
    allergies: false,
    preexistingConditions: false,
    medications: false,
    insurance: {
      provider: false,
      policyNumber: false,
      groupNumber: false,
      primaryInsured: false
    },
    employment: {
      employer: false,
      position: false,
      supervisor: false,
      address: false,
      phone: false
    }
  });

  useEffect(() => {
    fetchData();
    // Set up refresh interval
    const interval = setInterval(fetchData, 10000); // Refresh every 10 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [id]);

  const fetchData = async () => {
    try {
      const [residentRes, propertiesRes] = await Promise.all([
        axios.get(`/api/auth/residents/${id}`),
        axios.get('/api/properties')
      ]);
      
      // Update the status from approved to active if needed
      const updatedResident = {
        ...residentRes.data,
        status: residentRes.data.status === 'approved' ? 'active' : residentRes.data.status
      };
      
      setResident(updatedResident);
      setProperties(propertiesRes.data);

      // Find resident's bed assignment
      const assignment = propertiesRes.data.reduce((found, property) => {
        if (found) return found;
        
        for (const room of property.rooms) {
          const bed = room.beds.find(bed => bed.resident?._id === id);
          if (bed) {
            return {
              property,
              room,
              bed,
              assignedAt: new Date(bed.assignedAt).toLocaleDateString()
            };
          }
        }
        return null;
      }, null);

      setAssignment(assignment);
      setError(null);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.response?.data?.message || 'Failed to fetch resident details');
    } finally {
      setLoading(false);
    }
  };

  const handleAssignmentSubmit = async () => {
    if (!selectedProperty || !selectedRoom || !selectedBed) {
      setAssignmentError('Please select all required fields');
      return;
    }

    try {
      // First, unassign from current bed if they have an assignment
      if (assignment) {
        await axios.put(
          `/api/properties/${assignment.property._id}/rooms/${assignment.room._id}/beds/${assignment.bed._id}/unassign`
        );
      }

      // Then assign to new bed
      await axios.put(
        `/api/properties/${selectedProperty}/rooms/${selectedRoom}/beds/${selectedBed}/assign`,
        { residentId: id }
      );
      
      await fetchData();
      setOpenAssignDialog(false);
      // Reset selection state
      setSelectedProperty('');
      setSelectedRoom('');
      setSelectedBed('');
    } catch (error) {
      console.error('Error assigning resident:', error);
      setAssignmentError(error.response?.data?.message || 'Failed to assign resident');
    }
  };

  const handleDischargeSubmit = async () => {
    if (!dischargeData.moveOutDate || !dischargeData.dischargeReason) {
      setDischargeError('Please fill in all required fields');
      return;
    }

    try {
      // First, unassign the resident if they have an assignment
      if (assignment) {
        await axios.put(
          `/api/properties/${assignment.property._id}/rooms/${assignment.room._id}/beds/${assignment.bed._id}/unassign`
        );
      }

      // Then proceed with the discharge
      await axios.put(`/api/auth/residents/${id}/discharge`, {
        ...dischargeData,
        moveOutDate: new Date(dischargeData.moveOutDate + 'T12:00:00')
      });
      
      await fetchData();
      setOpenDischargeDialog(false);
    } catch (error) {
      console.error('Error discharging resident:', error);
      setDischargeError(error.response?.data?.message || 'Failed to discharge resident');
    }
  };

  const handleReactivateSubmit = async () => {
    if (!reactivateData.newMoveInDate) {
      setReactivateError('Please select a new move-in date');
      return;
    }

    try {
      await axios.put(`/api/auth/residents/${id}/reactivate`, {
        newMoveInDate: new Date(reactivateData.newMoveInDate + 'T12:00:00')
      });
      
      await fetchData();
      setOpenReactivateDialog(false);
    } catch (error) {
      console.error('Error reactivating resident:', error);
      setReactivateError(error.response?.data?.message || 'Failed to reactivate resident');
    }
  };

  const handleDetailsSubmit = async () => {
    try {
      await axios.put(`/api/auth/residents/${id}/details`, detailsData);
      await fetchData();
      setOpenDetailsDialog(false);
    } catch (error) {
      console.error('Error updating resident details:', error);
      setDetailsError(error.response?.data?.message || 'Failed to update resident details');
    }
  };

  const handleEditModeChange = (section, field, value) => {
    setEditMode(prev => ({
      ...prev,
      [section]: typeof prev[section] === 'object' 
        ? { ...prev[section], [field]: value }
        : value
    }));
  };

  const handleSaveField = async (section, field, value) => {
    try {
      let endpoint = `/api/auth/residents/${id}`;
      let data;
      
      // Map section names to correct API endpoints
      switch(section) {
        case 'emergencyContact':
          endpoint += '/emergency-contact';
          // Preserve existing emergency contact data and update only the specified field
          data = {
            ...resident.emergencyContact,
            [field]: value
          };
          break;
        case 'insurance':
          endpoint += '/insurance-info';
          // Preserve existing insurance data and update only the specified field
          data = {
            ...resident.insurance,
            [field]: value
          };
          break;
        case 'employment':
          endpoint += '/employment-info';
          // Preserve existing employment data and update only the specified field
          data = {
            ...resident.employment,
            [field]: value
          };
          break;
        case 'health':
          endpoint += '/health-info';
          data = field ? { [field]: value } : value;
          break;
        default:
          // For direct fields like email, phone, etc.
          endpoint += '/details';
          data = { [section]: value };
      }

      await axios.put(endpoint, data);
      await fetchData();
      handleEditModeChange(section, field, false);
      setError(null);
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to update information');
    }
  };

  const handleImageUpdate = async (imageUrl) => {
    setResident(prev => ({
      ...prev,
      imageUrl
    }));
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Typography>Loading...</Typography>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 3 }}>
      <Box sx={{ mb: 3 }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/residents')}
          sx={{ 
            mb: 2,
            borderRadius: 1,
            textTransform: 'none',
            fontWeight: 500,
            py: 1,
            px: 2
          }}
        >
          Back to Residents
        </Button>
      </Box>

      {error && (
        <Alert 
          severity="error" 
          sx={{ 
            mb: 3,
            borderRadius: 1,
            '& .MuiAlert-icon': {
              color: 'error.main'
            }
          }}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => setError(null)}
              sx={{ 
                textTransform: 'none',
                fontWeight: 500
              }}
            >
              Dismiss
            </Button>
          }
        >
          {error}
        </Alert>
      )}

      <Box sx={{ 
        display: 'flex', 
        gap: 3,
        flexDirection: { xs: 'column', md: 'row' }
      }}>
        {/* Left Sidebar - Sticky on desktop, full width on mobile */}
        <Box
          sx={{
            width: { xs: '100%', md: '380px' },
            flexShrink: 0,
            position: { xs: 'relative', md: 'sticky' },
            top: { xs: 0, md: '24px' },
            alignSelf: 'flex-start'
          }}
        >
          <Stack spacing={3}>
            <ResidentCard
              resident={resident}
              onImageUpdate={handleImageUpdate}
              setError={setError}
              setOpenDischargeDialog={setOpenDischargeDialog}
              setOpenReactivateDialog={setOpenReactivateDialog}
              fetchData={fetchData}
            />

            {/* Housing Assignment Card */}
            <Paper 
              elevation={0} 
              sx={{ 
                p: 3,
                borderRadius: 1,
                border: '1px solid',
                borderColor: 'divider',
                bgcolor: '#ffffff'
              }}
            >
              <HousingAssignment
                assignment={assignment}
                onAssignHousing={() => setOpenAssignDialog(true)}
                propertyId={assignment?.property._id}
              />
            </Paper>
          </Stack>
        </Box>

        {/* Right Content - Scrollable */}
        <Box sx={{ 
          flex: 1,
          width: { xs: '100%', md: 'auto' }
        }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <ContactInformation
                resident={resident}
                editMode={editMode}
                onEditModeChange={handleEditModeChange}
                onSaveField={handleSaveField}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <ProgramDetails
                resident={resident}
                editMode={editMode}
                onEditModeChange={handleEditModeChange}
                onSaveField={handleSaveField}
              />
            </Grid>

            <Grid item xs={12}>
              <RentSettingsCard resident={resident} setError={setError} />
            </Grid>

            <Grid item xs={12}>
              <EmergencyContact
                resident={resident}
                editMode={editMode}
                onEditModeChange={handleEditModeChange}
                onSaveField={handleSaveField}
              />
            </Grid>

            <Grid item xs={12}>
              <HealthInformation
                resident={resident}
                editMode={editMode}
                onEditModeChange={handleEditModeChange}
                onSaveField={handleSaveField}
              />
            </Grid>

            <Grid item xs={12}>
              <InsuranceInformation
                resident={resident}
                editMode={editMode}
                onEditModeChange={handleEditModeChange}
                onSaveField={handleSaveField}
              />
            </Grid>

            <Grid item xs={12}>
              <EmploymentInformation
                resident={resident}
                editMode={editMode}
                onEditModeChange={handleEditModeChange}
                onSaveField={handleSaveField}
              />
            </Grid>

            <Grid item xs={12}>
              <MaintenanceRequests resident={resident} />
            </Grid>

            <Grid item xs={12}>
              <OvernightRequests resident={resident} />
            </Grid>

            <Grid item xs={12}>
              <Grievances resident={resident} />
            </Grid>

            <Grid item xs={12}>
              <Payments resident={resident} />
            </Grid>

            <Grid item xs={12}>
              <FormSubmissions resident={resident} />
            </Grid>

            <Grid item xs={12}>
              <ResidentFiles residentId={resident._id} />
            </Grid>

            <Grid item xs={12}>
              <ResidentNotes
                resident={resident}
                editMode={editMode}
                onEditModeChange={handleEditModeChange}
                onSaveField={handleSaveField}
              />
            </Grid>

            <Grid item xs={12}>
              <DischargeInformation
                resident={resident}
                calculateLengthOfStay={calculateLengthOfStay}
                formatUserName={formatUserName}
              />
            </Grid>

            <Grid item xs={12}>
              <DischargeHistory
                resident={resident}
                formatUserName={formatUserName}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Dialogs */}
      <AssignmentDialog
        open={openAssignDialog}
        onClose={() => setOpenAssignDialog(false)}
        onSubmit={handleAssignmentSubmit}
        properties={properties}
        selectedProperty={selectedProperty}
        selectedRoom={selectedRoom}
        selectedBed={selectedBed}
        onPropertyChange={setSelectedProperty}
        onRoomChange={setSelectedRoom}
        onBedChange={setSelectedBed}
        error={assignmentError}
      />

      <DischargeDialog
        open={openDischargeDialog}
        onClose={() => setOpenDischargeDialog(false)}
        onSubmit={handleDischargeSubmit}
        data={dischargeData}
        onChange={setDischargeData}
        error={dischargeError}
      />

      <ReactivateDialog
        open={openReactivateDialog}
        onClose={() => setOpenReactivateDialog(false)}
        onSubmit={handleReactivateSubmit}
        data={reactivateData}
        onChange={setReactivateData}
        error={reactivateError}
      />

      <DetailsDialog
        open={openDetailsDialog}
        onClose={() => setOpenDetailsDialog(false)}
        onSubmit={handleDetailsSubmit}
        data={detailsData}
        onChange={setDetailsData}
        error={detailsError}
      />
    </Container>
  );
};

export default ResidentDetailsPage; 