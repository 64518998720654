import React, { useState, useEffect } from 'react';
import {
  TextField,
  Box,
  LinearProgress,
  Typography,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const calculatePasswordStrength = (password) => {
  if (!password) return 0;
  
  let score = 0;
  
  // Length check (25 points)
  if (password.length >= 8) score += 25;
  
  // Character variety checks (25 points each)
  if (/[A-Z]/.test(password)) score += 25; // uppercase
  if (/[a-z]/.test(password)) score += 25; // lowercase
  if (/[0-9]/.test(password)) score += 25; // numbers
  if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) score += 25; // special characters
  
  return Math.min(100, score);
};

const getStrengthColor = (strength) => {
  if (strength < 50) return '#f44336'; // red
  if (strength < 75) return '#ff9800'; // orange
  if (strength < 100) return '#ffd700'; // yellow
  return '#4caf50'; // green
};

const getStrengthLabel = (strength) => {
  if (strength < 50) return 'Weak';
  if (strength < 75) return 'Fair';
  if (strength < 100) return 'Good';
  return 'Strong';
};

function PasswordInput({ value, onChange, name, label, error, helperText, isConfirm, originalPassword, disabled }) {
  const [showPassword, setShowPassword] = useState(false);
  const [strength, setStrength] = useState(0);
  const isConfirmPassword = name === 'confirmPassword' || isConfirm;

  useEffect(() => {
    if (!isConfirmPassword) {
      setStrength(calculatePasswordStrength(value));
    }
  }, [value, isConfirmPassword]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const getPasswordMatchStatus = () => {
    if (!value) return null;
    if (value === originalPassword) {
      return { color: '#4caf50', message: 'Passwords match' };
    }
    return { color: '#f44336', message: 'Passwords do not match' };
  };

  const getPasswordRequirements = () => {
    const requirements = [
      {
        met: value.length >= 8,
        text: 'At least 8 characters'
      },
      {
        met: /[A-Z]/.test(value),
        text: 'One uppercase letter'
      },
      {
        met: /[a-z]/.test(value),
        text: 'One lowercase letter'
      },
      {
        met: /[0-9]/.test(value),
        text: 'One number'
      },
      {
        met: /[!@#$%^&*(),.?":{}|<>]/.test(value),
        text: 'One special character'
      }
    ];

    return requirements;
  };

  return (
    <Box>
      <TextField
        margin="normal"
        required
        fullWidth
        name={name}
        label={label}
        type={showPassword ? 'text' : 'password'}
        value={value}
        onChange={onChange}
        error={error || (isConfirmPassword && value && value !== originalPassword)}
        helperText={
          isConfirmPassword && value 
            ? getPasswordMatchStatus()?.message 
            : helperText
        }
        disabled={disabled}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
                disabled={disabled}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {value && !isConfirmPassword && (
        <Box sx={{ mt: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
            <Typography variant="caption" color="textSecondary">
              Password Strength
            </Typography>
            <Typography variant="caption" style={{ color: getStrengthColor(strength) }}>
              {getStrengthLabel(strength)}
            </Typography>
          </Box>
          <LinearProgress
            variant="determinate"
            value={strength}
            sx={{
              height: 8,
              borderRadius: 4,
              backgroundColor: '#e0e0e0',
              '& .MuiLinearProgress-bar': {
                backgroundColor: getStrengthColor(strength),
              },
            }}
          />
          <Box sx={{ mt: 1 }}>
            <Typography variant="caption" color="textSecondary">
              Password must contain:
            </Typography>
            <ul style={{ margin: '4px 0', paddingLeft: '20px' }}>
              {getPasswordRequirements().map((req, index) => (
                <li key={index}>
                  <Typography variant="caption" color={req.met ? 'success.main' : 'text.secondary'}>
                    {req.text}
                  </Typography>
                </li>
              ))}
            </ul>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default PasswordInput; 