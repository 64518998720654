import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Stack,
  Alert,
  FormControlLabel,
  Switch
} from '@mui/material';

const DischargeDialog = ({
  open,
  onClose,
  onSubmit,
  data,
  onChange,
  error
}) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 1,
          boxShadow: '0 8px 32px -4px rgba(0, 0, 0, 0.1)'
        }
      }}
    >
      <DialogTitle sx={{ 
        borderBottom: '1px solid',
        borderColor: 'divider',
        bgcolor: '#f5f6f8',
        py: 2
      }}>
        Discharge Resident
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3} sx={{ mt: 2 }}>
          {error && (
            <Alert 
              severity="error"
              sx={{ 
                borderRadius: 1,
                '& .MuiAlert-icon': {
                  color: 'error.main'
                }
              }}
            >
              {error}
            </Alert>
          )}
          <TextField
            type="date"
            fullWidth
            required
            label="Move-out Date"
            value={data.moveOutDate || ''}
            onChange={(e) => onChange({ ...data, moveOutDate: e.target.value })}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 1,
                '& fieldset': {
                  borderColor: 'divider'
                }
              }
            }}
          />
          <TextField
            fullWidth
            required
            label="Discharge Reason"
            value={data.dischargeReason}
            onChange={(e) => onChange({ ...data, dischargeReason: e.target.value })}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 1,
                '& fieldset': {
                  borderColor: 'divider'
                }
              }
            }}
          />
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Discharge Notes"
            value={data.dischargeNotes}
            onChange={(e) => onChange({ ...data, dischargeNotes: e.target.value })}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 1,
                '& fieldset': {
                  borderColor: 'divider'
                }
              }
            }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={data.dischargeFlagged}
                onChange={(e) => onChange({ ...data, dischargeFlagged: e.target.checked })}
              />
            }
            label="Flag this resident"
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '0.875rem',
                fontWeight: 500,
                color: 'text.primary'
              }
            }}
          />
          {data.dischargeFlagged && (
            <TextField
              fullWidth
              required
              label="Flag Reason"
              value={data.flagReason}
              onChange={(e) => onChange({ ...data, flagReason: e.target.value })}
              error={data.dischargeFlagged && !data.flagReason}
              helperText={data.dischargeFlagged && !data.flagReason ? 'Flag reason is required when flagging a resident' : ''}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 1,
                  '& fieldset': {
                    borderColor: 'divider'
                  }
                }
              }}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2, bgcolor: '#f8f9fb' }}>
        <Button 
          onClick={onClose}
          sx={{ 
            borderRadius: 1,
            textTransform: 'none',
            fontWeight: 500,
            px: 2
          }}
        >
          Cancel
        </Button>
        <Button 
          onClick={onSubmit} 
          variant="contained" 
          color="error"
          disabled={data.dischargeFlagged && !data.flagReason}
          sx={{ 
            borderRadius: 1,
            textTransform: 'none',
            fontWeight: 500,
            px: 2
          }}
        >
          Discharge
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DischargeDialog; 