import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Alert,
  CircularProgress,
  Slider,
  TextField,
  Grid,
  Stack,
  Switch,
  FormControlLabel,
  Divider,
  InputAdornment
} from '@mui/material';
import { Upload, Settings, Edit, Email, AttachMoney } from '@mui/icons-material';
import axios from '../../utils/axios';

function SuperAdminSettings() {
  // Settings State
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [currentLogo, setCurrentLogo] = useState('');
  const [appName, setAppName] = useState('');
  const [logoSizes, setLogoSizes] = useState({
    topbar: 40,
    auth: 80
  });
  const [smtpSettings, setSmtpSettings] = useState({
    host: '',
    port: 587,
    secure: false,
    auth: {
      user: '',
      pass: ''
    },
    from: ''
  });
  const [testEmail, setTestEmail] = useState('');
  const [smtpLoading, setSmtpLoading] = useState(false);
  const [rentSettings, setRentSettings] = useState({
    defaultAmount: 0,
    defaultDueDate: 1
  });

  useEffect(() => {
    fetchCurrentSettings();
  }, []);

  // Settings Functions
  const fetchCurrentSettings = async () => {
    try {
      const response = await axios.get('/api/settings', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      if (response.data.logoUrl) {
        setCurrentLogo(`${process.env.REACT_APP_API_URL}${response.data.logoUrl}`);
      }
      if (response.data.logoSize) {
        setLogoSizes(response.data.logoSize);
      }
      if (response.data.appName) {
        setAppName(response.data.appName);
      }
      if (response.data.smtp) {
        setSmtpSettings(response.data.smtp);
      }
      if (response.data.rentSettings) {
        setRentSettings(response.data.rentSettings);
      }
    } catch (error) {
      console.error('Error fetching settings:', error);
      setError('Failed to fetch settings');
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setError('File size should not exceed 5MB');
        return;
      }

      if (!file.type.startsWith('image/')) {
        setError('Only image files are allowed');
        return;
      }

      setSelectedFile({ file });
      setPreview(URL.createObjectURL(file));
      setError('');
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setError('Please select a file first');
      return;
    }

    setLoading(true);
    setError('');
    setSuccess('');

    const formData = new FormData();
    formData.append('file', selectedFile.file);

    try {
      const response = await axios.post('/api/settings/upload-asset', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });

      setCurrentLogo(`${process.env.REACT_APP_API_URL}${response.data.logoUrl}`);
      setSelectedFile(null);
      setPreview('');
      setSuccess('Logo updated successfully');
    } catch (error) {
      console.error('Upload error:', error);
      setError(error.response?.data?.message || 'Error uploading logo');
    } finally {
      setLoading(false);
    }
  };

  const handleSizeChange = async () => {
    try {
      setLoading(true);
      const response = await axios.put('/api/settings/logo-size', logoSizes, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      
      if (response.data) {
        setSuccess('Logo sizes updated successfully');
        setTimeout(() => window.location.reload(), 1000);
      }
    } catch (error) {
      setError(error.response?.data?.message || 'Error updating logo sizes');
    } finally {
      setLoading(false);
    }
  };

  const handleAppNameChange = async () => {
    try {
      setLoading(true);
      const response = await axios.put('/api/settings/app-name', { appName }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      
      if (response.data) {
        setSuccess('App name updated successfully');
        setTimeout(() => window.location.reload(), 1000);
      }
    } catch (error) {
      setError(error.response?.data?.message || 'Error updating app name');
    } finally {
      setLoading(false);
    }
  };

  const handleSmtpChange = (field, value) => {
    if (field.includes('.')) {
      const [parent, child] = field.split('.');
      setSmtpSettings(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setSmtpSettings(prev => ({
        ...prev,
        [field]: value
      }));
    }
  };

  const handleSaveSmtpSettings = async () => {
    try {
      setSmtpLoading(true);
      setError('');
      setSuccess('');

      await axios.put('/api/settings/smtp', smtpSettings, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });

      setSuccess('SMTP settings saved successfully');
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to save SMTP settings');
    } finally {
      setSmtpLoading(false);
    }
  };

  const handleTestEmail = async () => {
    if (!testEmail) {
      setError('Please enter a test email address');
      return;
    }

    try {
      setSmtpLoading(true);
      setError('');
      setSuccess('');

      await axios.post('/api/settings/smtp/test', { testEmail }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });

      setSuccess('Test email sent successfully');
      setTestEmail('');
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to send test email');
    } finally {
      setSmtpLoading(false);
    }
  };

  const handleRentSettingsChange = async () => {
    try {
      setLoading(true);
      await axios.put('/api/settings/rent', rentSettings, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setSuccess('Rent settings updated successfully');
    } catch (error) {
      setError(error.response?.data?.message || 'Error updating rent settings');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 4 }}>
        {/* App Name Section */}
        <Paper 
          elevation={0}
          sx={{ 
            p: 3, 
            mb: 3,
            backgroundImage: 'linear-gradient(145deg, #ffffff 0%, #f8f9ff 100%)',
            borderRadius: 2,
            border: '1px solid',
            borderColor: 'divider',
            boxShadow: '0 4px 20px -4px rgba(0, 0, 0, 0.1)'
          }}
        >
          <Stack spacing={3}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Edit sx={{ mr: 1, color: 'primary.main' }} />
              <Typography 
                variant="h6" 
                component="h2"
                sx={{ 
                  fontWeight: 600,
                  color: 'text.primary',
                  letterSpacing: '-0.02em'
                }}
              >
                App Name Settings
              </Typography>
            </Box>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={8}>
                <TextField
                  fullWidth
                  label="Application Name"
                  value={appName}
                  onChange={(e) => setAppName(e.target.value)}
                  variant="outlined"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      bgcolor: 'background.paper',
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'primary.main',
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={handleAppNameChange}
                  disabled={loading}
                  sx={{
                    py: 1.5,
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    '&:hover': {
                      boxShadow: '0 6px 16px rgba(0, 0, 0, 0.15)',
                    },
                  }}
                >
                  {loading ? <CircularProgress size={24} /> : 'Update App Name'}
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Paper>

        {/* Logo Settings Section */}
        <Paper 
          elevation={0}
          sx={{ 
            p: 3, 
            mb: 3,
            backgroundImage: 'linear-gradient(145deg, #ffffff 0%, #f8f9ff 100%)',
            borderRadius: 2,
            border: '1px solid',
            borderColor: 'divider',
            boxShadow: '0 4px 20px -4px rgba(0, 0, 0, 0.1)'
          }}
        >
          <Stack spacing={3}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Settings sx={{ mr: 1, color: 'primary.main' }} />
              <Typography 
                variant="h6" 
                component="h2"
                sx={{ 
                  fontWeight: 600,
                  color: 'text.primary',
                  letterSpacing: '-0.02em'
                }}
              >
                Logo Settings
              </Typography>
            </Box>

            <TableContainer 
              component={Paper} 
              elevation={0}
              sx={{ 
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 2,
                overflow: 'hidden'
              }}
            >
              <Table>
                <TableHead>
                  <TableRow sx={{ bgcolor: 'background.default' }}>
                    <TableCell sx={{ fontWeight: 600 }}>Logo Management</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {currentLogo ? (
                        <img 
                          src={currentLogo} 
                          alt="Current Logo" 
                          style={{ maxWidth: '200px', maxHeight: '100px' }}
                        />
                      ) : (
                        <Typography color="text.secondary">
                          No logo uploaded
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" spacing={2}>
                        <input
                          accept="image/*"
                          style={{ display: 'none' }}
                          id="logo-upload"
                          type="file"
                          onChange={handleFileSelect}
                        />
                        <label htmlFor="logo-upload">
                          <Button
                            variant="outlined"
                            component="span"
                            startIcon={<Upload />}
                            sx={{
                              borderColor: 'divider',
                              '&:hover': {
                                borderColor: 'primary.main',
                                bgcolor: 'background.paper',
                              },
                            }}
                          >
                            Select New Logo
                          </Button>
                        </label>
                        {selectedFile && (
                          <Button
                            variant="contained"
                            onClick={handleUpload}
                            disabled={loading}
                            sx={{
                              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                              '&:hover': {
                                boxShadow: '0 6px 16px rgba(0, 0, 0, 0.15)',
                              },
                            }}
                          >
                            {loading ? <CircularProgress size={24} /> : 'Upload Logo'}
                          </Button>
                        )}
                      </Stack>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <TableContainer 
              component={Paper} 
              elevation={0}
              sx={{ 
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 2,
                overflow: 'hidden'
              }}
            >
              <Table>
                <TableHead>
                  <TableRow sx={{ bgcolor: 'background.default' }}>
                    <TableCell sx={{ fontWeight: 600 }}>Location</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Size Control</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Current Size</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Topbar Logo</TableCell>
                    <TableCell sx={{ width: '50%' }}>
                      <Slider
                        value={logoSizes.topbar}
                        onChange={(e, newValue) => setLogoSizes(prev => ({ ...prev, topbar: newValue }))}
                        min={20}
                        max={80}
                        step={1}
                        marks={[
                          { value: 20, label: '20px' },
                          { value: 40, label: '40px' },
                          { value: 60, label: '60px' },
                          { value: 80, label: '80px' },
                        ]}
                        sx={{
                          '& .MuiSlider-thumb': {
                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell>{logoSizes.topbar}px</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Auth Pages Logo</TableCell>
                    <TableCell>
                      <Slider
                        value={logoSizes.auth}
                        onChange={(e, newValue) => setLogoSizes(prev => ({ ...prev, auth: newValue }))}
                        min={40}
                        max={160}
                        step={1}
                        marks={[
                          { value: 40, label: '40px' },
                          { value: 80, label: '80px' },
                          { value: 120, label: '120px' },
                          { value: 160, label: '160px' },
                        ]}
                        sx={{
                          '& .MuiSlider-thumb': {
                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell>{logoSizes.auth}px</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                <Button
                  variant="contained"
                  onClick={handleSizeChange}
                  disabled={loading}
                  sx={{
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    '&:hover': {
                      boxShadow: '0 6px 16px rgba(0, 0, 0, 0.15)',
                    },
                  }}
                >
                  {loading ? <CircularProgress size={24} /> : 'Save Size Settings'}
                </Button>
              </Box>
            </TableContainer>
          </Stack>
        </Paper>

        {/* SMTP Settings Section */}
        <Paper 
          elevation={0}
          sx={{ 
            p: 3, 
            mb: 3,
            backgroundImage: 'linear-gradient(145deg, #ffffff 0%, #f8f9ff 100%)',
            borderRadius: 2,
            border: '1px solid',
            borderColor: 'divider',
            boxShadow: '0 4px 20px -4px rgba(0, 0, 0, 0.1)'
          }}
        >
          <Stack spacing={3}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Email sx={{ mr: 1, color: 'primary.main' }} />
              <Typography 
                variant="h6" 
                component="h2"
                sx={{ 
                  fontWeight: 600,
                  color: 'text.primary',
                  letterSpacing: '-0.02em'
                }}
              >
                SMTP Settings
              </Typography>
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="SMTP Host"
                  value={smtpSettings.host}
                  onChange={(e) => handleSmtpChange('host', e.target.value)}
                  variant="outlined"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      bgcolor: 'background.paper',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="SMTP Port"
                  type="number"
                  value={smtpSettings.port}
                  onChange={(e) => handleSmtpChange('port', parseInt(e.target.value))}
                  variant="outlined"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      bgcolor: 'background.paper',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="SMTP Username"
                  value={smtpSettings.auth.user}
                  onChange={(e) => handleSmtpChange('auth.user', e.target.value)}
                  variant="outlined"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      bgcolor: 'background.paper',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="SMTP Password"
                  type="password"
                  value={smtpSettings.auth.pass}
                  onChange={(e) => handleSmtpChange('auth.pass', e.target.value)}
                  variant="outlined"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      bgcolor: 'background.paper',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="From Email"
                  value={smtpSettings.from}
                  onChange={(e) => handleSmtpChange('from', e.target.value)}
                  variant="outlined"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      bgcolor: 'background.paper',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={smtpSettings.secure}
                      onChange={(e) => handleSmtpChange('secure', e.target.checked)}
                    />
                  }
                  label="Use SSL/TLS"
                />
              </Grid>
            </Grid>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
              <Button
                variant="contained"
                onClick={handleSaveSmtpSettings}
                disabled={smtpLoading}
                sx={{
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  '&:hover': {
                    boxShadow: '0 6px 16px rgba(0, 0, 0, 0.15)',
                  },
                }}
              >
                {smtpLoading ? <CircularProgress size={24} /> : 'Save SMTP Settings'}
              </Button>
            </Box>

            <Divider sx={{ my: 2 }} />

            <Typography variant="h6" sx={{ mb: 2 }}>Test SMTP Configuration</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <TextField
                  fullWidth
                  label="Test Email Address"
                  value={testEmail}
                  onChange={(e) => setTestEmail(e.target.value)}
                  variant="outlined"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      bgcolor: 'background.paper',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={handleTestEmail}
                  disabled={smtpLoading}
                  sx={{
                    py: 1.5,
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    '&:hover': {
                      boxShadow: '0 6px 16px rgba(0, 0, 0, 0.15)',
                    },
                  }}
                >
                  {smtpLoading ? <CircularProgress size={24} /> : 'Send Test Email'}
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Paper>

        {/* Rent Settings Section */}
        <Paper 
          elevation={0}
          sx={{ 
            p: 3, 
            mb: 3,
            backgroundImage: 'linear-gradient(145deg, #ffffff 0%, #f8f9ff 100%)',
            borderRadius: 2,
            border: '1px solid',
            borderColor: 'divider',
            boxShadow: '0 4px 20px -4px rgba(0, 0, 0, 0.1)'
          }}
        >
          <Stack spacing={3}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <AttachMoney sx={{ mr: 1, color: 'primary.main' }} />
              <Typography 
                variant="h6" 
                component="h2"
                sx={{ 
                  fontWeight: 600,
                  color: 'text.primary',
                  letterSpacing: '-0.02em'
                }}
              >
                Default Rent Settings
              </Typography>
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Default Rent Amount ($)"
                  type="number"
                  value={rentSettings.defaultAmount}
                  onChange={(e) => setRentSettings(prev => ({
                    ...prev,
                    defaultAmount: Number(e.target.value)
                  }))}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  variant="outlined"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      bgcolor: 'background.paper',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Default Due Date (Day of Month)"
                  type="number"
                  value={rentSettings.defaultDueDate}
                  onChange={(e) => setRentSettings(prev => ({
                    ...prev,
                    defaultDueDate: Number(e.target.value)
                  }))}
                  inputProps={{ min: 1, max: 31 }}
                  variant="outlined"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      bgcolor: 'background.paper',
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                onClick={handleRentSettingsChange}
                disabled={loading}
                sx={{
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  '&:hover': {
                    boxShadow: '0 6px 16px rgba(0, 0, 0, 0.15)',
                  },
                }}
              >
                {loading ? <CircularProgress size={24} /> : 'Save Rent Settings'}
              </Button>
            </Box>
          </Stack>
        </Paper>

        {/* Error and Success Messages */}
        {error && (
          <Alert 
            severity="error" 
            sx={{ 
              mt: 2,
              borderRadius: 2,
              boxShadow: '0 4px 12px rgba(211, 47, 47, 0.1)'
            }}
          >
            {error}
          </Alert>
        )}
        {success && (
          <Alert 
            severity="success" 
            sx={{ 
              mt: 2,
              borderRadius: 2,
              boxShadow: '0 4px 12px rgba(46, 125, 50, 0.1)'
            }}
          >
            {success}
          </Alert>
        )}
      </Box>
    </Container>
  );
}

export default SuperAdminSettings; 