import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import axios from '../../utils/axios';

function ResidentDashboard() {
  const [profile, setProfile] = useState(null);
  const [assignment, setAssignment] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const [profileRes, propertiesRes] = await Promise.all([
        axios.get('/api/auth/profile'),
        axios.get('/api/properties')
      ]);
      
      setProfile(profileRes.data);

      // Find resident's bed assignment
      const assignment = propertiesRes.data.reduce((found, property) => {
        if (found) return found;
        
        for (const room of property.rooms) {
          const bed = room.beds.find(bed => bed.resident?._id === profileRes.data._id);
          if (bed) {
            return {
              property,
              room,
              bed,
              assignedAt: new Date(bed.assignedAt).toLocaleDateString()
            };
          }
        }
        return null;
      }, null);

      setAssignment(assignment);
    } catch (error) {
      setError('Failed to fetch profile');
    }
  };

  if (!profile) {
    return (
      <Container>
        <Typography>Loading...</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Welcome, {profile.firstName}!
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Personal Information
                </Typography>
                <Typography>
                  <strong>Name:</strong> {profile.firstName} {profile.lastName}
                </Typography>
                <Typography>
                  <strong>Email:</strong> {profile.email}
                </Typography>
                <Typography>
                  <strong>Phone:</strong> {profile.phone}
                </Typography>
                <Typography>
                  <strong>Move-in Date:</strong> {profile.moveInDate ? new Date(profile.moveInDate).toLocaleDateString() : 'Not set'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Housing Assignment
                </Typography>
                {assignment ? (
                  <>
                    <Typography>
                      <strong>Property:</strong> {assignment.property.name}
                    </Typography>
                    <Typography>
                      <strong>Address:</strong> {assignment.property.address}
                    </Typography>
                    <Typography>
                      <strong>Room:</strong> {assignment.room.name}
                    </Typography>
                    <Typography>
                      <strong>Bed:</strong> {assignment.bed.name}
                    </Typography>
                    <Typography>
                      <strong>Assigned Date:</strong> {assignment.assignedAt}
                    </Typography>
                  </>
                ) : (
                  <Typography color="text.secondary">
                    No housing assignment found
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {error && (
          <Typography color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
      </Box>
    </Container>
  );
}

export default ResidentDashboard; 