import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Chip,
  Button,
  CircularProgress,
  Alert
} from '@mui/material';
import {
  Add as AddIcon,
  Refresh,
  Payment as PaymentIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://192.168.0.135:5001';
axios.defaults.baseURL = API_URL;

const MyPayments = () => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const fetchPayments = async () => {
    try {
      setError('');
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/payments/my-payments', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setPayments(response.data);
    } catch (error) {
      console.error('Error fetching payments:', error);
      setError('Failed to fetch payments');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPayments();
  }, []);

  const getStatusColor = (status) => {
    switch (status) {
      case 'Completed':
        return 'success';
      case 'Failed':
        return 'error';
      case 'Refunded':
        return 'warning';
      default:
        return 'default';
    }
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const formatAmount = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h5" component="h2">
          My Payments
        </Typography>
        <Box>
          <Button
            startIcon={<Refresh />}
            onClick={fetchPayments}
            variant="outlined"
            sx={{ mr: 2 }}
          >
            Refresh
          </Button>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            onClick={() => navigate('/payments/new')}
          >
            Make Payment
          </Button>
        </Box>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <Grid container spacing={3}>
        {payments.map((payment) => (
          <Grid item xs={12} md={6} lg={4} key={payment._id}>
            <Card elevation={3}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <PaymentIcon sx={{ mr: 1 }} />
                    <Typography variant="h6" component="h3">
                      {formatAmount(payment.amount)}
                    </Typography>
                  </Box>
                  <Chip
                    label={payment.status}
                    color={getStatusColor(payment.status)}
                    size="small"
                  />
                </Box>

                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Description:
                  </Typography>
                  <Typography variant="body2">
                    {payment.description}
                  </Typography>
                </Box>

                {payment.property && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" gutterBottom>
                      Property:
                    </Typography>
                    <Typography variant="body2">
                      {payment.property.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Unit: {payment.unit}
                    </Typography>
                  </Box>
                )}

                {payment.notes && payment.notes.length > 0 && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" gutterBottom>
                      Latest Note:
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {payment.notes[payment.notes.length - 1].content}
                    </Typography>
                  </Box>
                )}

                {payment.status === 'Refunded' && payment.refundReason && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="error" gutterBottom>
                      Refund Reason:
                    </Typography>
                    <Typography variant="body2" color="error">
                      {payment.refundReason}
                    </Typography>
                  </Box>
                )}

                <Box sx={{ mt: 2 }}>
                  <Typography variant="caption" color="textSecondary">
                    Submitted: {formatDate(payment.createdAt)}
                  </Typography>
                  {payment.status === 'Refunded' && payment.refundedAt && (
                    <Typography variant="caption" color="textSecondary" sx={{ display: 'block' }}>
                      Refunded: {formatDate(payment.refundedAt)}
                    </Typography>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}

        {payments.length === 0 && !loading && (
          <Grid item xs={12}>
            <Box sx={{ textAlign: 'center', py: 4 }}>
              <Typography variant="body1" color="textSecondary">
                You haven't made any payments yet.
              </Typography>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate('/payments/new')}
                sx={{ mt: 2 }}
              >
                Make Your First Payment
              </Button>
            </Box>
          </Grid>
        )}

        {loading && (
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
              <CircularProgress />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default MyPayments; 