import React, { useState } from 'react';
import { 
  Typography, 
  Box,
  Grid,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import { styled } from '@mui/material/styles';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import BlockIcon from '@mui/icons-material/Block';
import HotelIcon from '@mui/icons-material/Hotel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: '#ffffff',
  border: '1px solid',
  borderColor: theme.palette.divider,
  borderRadius: `${theme.spacing(2)} !important`,
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
  transition: 'all 0.3s ease-in-out',
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    boxShadow: '0 12px 48px rgba(0, 0, 0, 0.12)',
    transform: 'scale(1.02)',
    borderColor: 'deepPurple.main'
  },
  '& .MuiAccordionSummary-root': {
    padding: theme.spacing(2),
    minHeight: 'auto',
    '&.Mui-expanded': {
      minHeight: 'auto',
    }
  },
  '& .MuiAccordionSummary-content': {
    margin: '0 !important'
  },
  '& .MuiAccordionDetails-root': {
    padding: theme.spacing(0, 2, 2, 2),
    borderTop: '1px solid',
    borderTopColor: theme.palette.divider
  }
}));

const StatBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1.5),
  backgroundColor: 'rgba(103, 58, 183, 0.08)',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: 'rgba(103, 58, 183, 0.12)',
    transform: 'translateY(-2px)'
  }
}));

const OvernightRequestsCard = ({ requests = [] }) => {
  const [expanded, setExpanded] = useState(false);

  // Get current date for active stay calculations
  const now = new Date();

  // Calculate request statistics
  const totalRequests = requests.length;
  const approvedRequests = requests.filter(r => r.status === 'Approved').length;
  const pendingRequests = requests.filter(r => r.status === 'Pending').length;
  const rejectedRequests = requests.filter(r => r.status === 'Rejected').length;

  // Calculate active stays (approved, departed but not returned)
  const activeStays = requests.filter(r => 
    r.status === 'Approved' && 
    r.departedAt?.at && 
    (!r.returnedAt?.at || new Date(r.returnedAt.at) > now)
  ).length;

  // Calculate upcoming stays (approved but not yet departed)
  const upcomingStays = requests.filter(r =>
    r.status === 'Approved' &&
    !r.departedAt?.at &&
    new Date(r.startDate) > now
  ).length;

  const approvalRate = totalRequests > 0 ? ((approvedRequests / totalRequests) * 100).toFixed(0) : 0;

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  return (
    <StyledAccordion 
      expanded={expanded} 
      onChange={handleAccordionChange}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: 'deepPurple.main' }} />}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '100%' }}>
          <Box
            sx={{
              width: 48,
              height: 48,
              borderRadius: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'rgba(103, 58, 183, 0.08)'
            }}
          >
            <NightsStayIcon sx={{ color: 'deepPurple.main', fontSize: 28 }} />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography variant="h5" sx={{ fontWeight: 700, color: 'text.primary', mb: 0.5 }}>
              {pendingRequests}
            </Typography>
            <Typography variant="body1" sx={{ color: 'text.secondary', fontWeight: 500 }}>
              Pending Overnights
            </Typography>
          </Box>
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <StatBox>
              <CheckCircleIcon sx={{ color: 'success.main', fontSize: 24 }} />
              <Box>
                <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                  Approved
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                  {approvedRequests}
                </Typography>
              </Box>
            </StatBox>
          </Grid>

          <Grid item xs={6}>
            <StatBox>
              <PendingIcon sx={{ color: 'warning.main', fontSize: 24 }} />
              <Box>
                <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                  Pending
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                  {pendingRequests}
                </Typography>
              </Box>
            </StatBox>
          </Grid>

          <Grid item xs={6}>
            <StatBox>
              <BlockIcon sx={{ color: 'error.main', fontSize: 24 }} />
              <Box>
                <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                  Rejected
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                  {rejectedRequests}
                </Typography>
              </Box>
            </StatBox>
          </Grid>

          <Grid item xs={6}>
            <StatBox>
              <HotelIcon sx={{ color: 'deepPurple.main', fontSize: 24 }} />
              <Box>
                <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                  Active
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                  {activeStays + upcomingStays}
                </Typography>
              </Box>
            </StatBox>
          </Grid>
        </Grid>

        <Box sx={{ 
          mt: 3, 
          p: 2.5, 
          borderRadius: 2,
          bgcolor: 'rgba(103, 58, 183, 0.04)',
          border: '1px solid',
          borderColor: 'deepPurple.light',
        }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                Approval Rate
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 700, color: 'deepPurple.main' }}>
                {approvalRate}%
              </Typography>
            </Box>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                Pending Review
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 700 }}>
                {pendingRequests}
              </Typography>
            </Box>
          </Stack>
        </Box>
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default OvernightRequestsCard; 